import Skeleton from "react-loading-skeleton";
import { FC } from "react";
import { MainRouteTabs } from "components/Main/MainRouteTabs";

export const LoadingContent = () => {
  return (
    <>
      <MainRouteTabs isLoading />
      <div className="p-6 space-y-16">
        <div>
          <Skeleton className="h-16 w-16 mb-4" />
          <Skeleton count={4} className="mt-2" />
        </div>
        <div>
          <Skeleton className="w-3/4 mb-4" />
          <Skeleton
            containerClassName="flex space-between"
            count={4}
            className={`
              flex-1
              mx-2
              md:mx-4
              first:ml-0
              last:ml-0
              aspect-square
              lg:aspect-video
            `}
          />
        </div>
      </div>
    </>
  );
};
export const LoadingState: FC = () => {
  return (
    <div className="pt-[50px] pb-20 relative">
      <div
        className={`
          w-full
          flex
          justify-start
          fixed
          left-0
          top-0
          right-0
          items-center
          h-[50px]
          bg-slate-50
          border-b-slate-300
          px-4
        `}
      >
        <Skeleton className="w-32" />
      </div>
      <LoadingContent />
    </div>
  );
};
