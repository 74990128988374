import { FC, HTMLAttributes } from "react";
import { cx } from "@libs/utils/cx";
import { isString } from "@libs/utils/types";
import { FloatingTooltip, FloatingTooltipProps } from "components/UI/FloatingTooltip";

const cxThemes = {
  darkBackground: ({ disabled }: { disabled?: boolean }) => cx(disabled ? "text-greyLight" : "text-white "),
  // eslint-disable-next-line unused-imports/no-unused-vars
  info: (params: { disabled?: boolean }) => "text-greyLight",
  default: ({ disabled }: { disabled?: boolean }) => cx(disabled ? "text-greyLight" : "text-greyMedium"),
  primary: ({ disabled }: { disabled?: boolean }) => cx(disabled ? "text-greyLight" : "text-primaryTheme"),
  error: ({ disabled }: { disabled?: boolean }) => cx(disabled ? "text-greyLight" : "text-red"),
  success: ({ disabled }: { disabled?: boolean }) => cx(disabled ? "text-greyLight" : "text-green"),
  warning: ({ disabled }: { disabled?: boolean }) => cx(disabled ? "text-greyLight" : "text-orange"),
  slate300: ({ disabled }: { disabled?: boolean }) => cx(disabled ? "text-greyLight" : "text-slate-300"),
  slate500: ({ disabled }: { disabled?: boolean }) => cx(disabled ? "text-greyLight" : "text-slate-500"),
  slate600: ({ disabled }: { disabled?: boolean }) => cx(disabled ? "text-greyLight" : "text-slate-600"),
  slate700: ({ disabled }: { disabled?: boolean }) => cx(disabled ? "text-greyLight" : "text-slate-700"),
  slate900: ({ disabled }: { disabled?: boolean }) => cx(disabled ? "text-greyLight" : "text-slate-900"),
  custom: () => "",
};

export type ButtonIconThemes = keyof typeof cxThemes;

export const cxButtonIconSizes = {
  xs: "h-3 w-3",
  sm: "h-4 w-4",
  md: "h-5 w-5",
  lg: "h-6 w-6",
  xl: "h-7 w-7",
  xxl: "h-8 w-8",
};

export type ButtonIconSizes = keyof typeof cxButtonIconSizes;

export type ButtonIconContentProps = {
  Icon: IconComponent;
  className?: string;
  iconClassName?: string;
  theme?: ButtonIconThemes;
  size?: ButtonIconSizes;
  tooltip?: Omit<FloatingTooltipProps, "children">;
  disabled?: boolean;
  loading?: boolean;
  tooltipToAriaLabel?: boolean;
};

export const ButtonIconContent: FC<ButtonIconContentProps & HTMLAttributes<HTMLDivElement>> = ({
  theme = "default",
  Icon,
  className,
  iconClassName,
  disabled,
  tooltip = { content: "" },
  size = "md",
  // By default we use the tooltip content as the aria-label
  // if there is a clickable element above this, that item should own the aria label
  // to ease selection of clickable elements in pendo
  tooltipToAriaLabel = true,
  ...rest
}) => {
  return (
    <FloatingTooltip {...tooltip}>
      <div className={cx("flex items-center justify-center", className)} {...rest}>
        <Icon
          role="img"
          aria-label={tooltipToAriaLabel && isString(tooltip.content) ? tooltip.content : undefined}
          className={cx(iconClassName, cxButtonIconSizes[size], cxThemes[theme]({ disabled }))}
        />
      </div>
    </FloatingTooltip>
  );
};
