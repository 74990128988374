import { MINUTE_IN_SECONDS, SECOND_IN_MS } from "@libs/utils/date";

export const REACT_RENDER_ROOT = "archy-root";

// To test increment to 61 seconds:
//export const IDLE_TIMEOUT = 61_000;

// 30 minutes of inactivity will sign the user out
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const SIGNED_IN_IDLE_TIMEOUT = 30 * MINUTE_IN_SECONDS * SECOND_IN_MS;
