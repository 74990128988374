import { useTranslation } from "react-i18next";
import React from "react";
import { PatientInsuranceVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { semibold16 } from "assets/styles/textSize";
import { useRelationship } from "hooks/useRelations";
import { NamedField } from "components/UI/NamedField";

const useInsuranceHeader = (ordinal: PatientInsuranceVO["insuranceOrdinal"]) => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    switch (ordinal) {
      case "PRIMARY": {
        return t("Primary Insurance");
      }
      case "SECONDARY": {
        return t("Secondary Insurance");
      }
      default: {
        return t("Other Insurance");
      }
    }
  }, [t, ordinal]);
};

const useSubscriberType = (type: PatientInsuranceVO["type"]) => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    switch (type) {
      case "PRIMARY_SUBSCRIBER": {
        return t("Primary Subscriber");
      }
      case "DEPENDENT": {
        return t("Dependent");
      }
      default: {
        return t("Other");
      }
    }
  }, [t, type]);
};

export const InsuranceListItem: React.FC<{ patientInsurance: PatientInsuranceVO }> = ({
  patientInsurance,
}) => {
  const { subscriber, type, insuranceOrdinal, relationshipWithSubscriber } = patientInsurance;
  const { carrier, employer, externalMemberId, ssnLastFour, firstName, lastName } = subscriber;
  const headerText = useInsuranceHeader(insuranceOrdinal);
  const subscriberType = useSubscriberType(type);
  const isDependent = type === "DEPENDENT";

  const { t } = useTranslation();
  const relationship = useRelationship(relationshipWithSubscriber);

  return (
    <div className="py-4 space-y-4">
      <div className="flex justify-between">
        <div className={cx("text-secondaryTheme", semibold16)}>{headerText}</div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 max-w-md">
        <NamedField title={t("Subscriber Type")}>{subscriberType}</NamedField>
        {isDependent && (
          <>
            <NamedField title={t("Subscriber First Name")}>{firstName}</NamedField>
            <NamedField title={t("Subscriber Last Name")}>{lastName}</NamedField>
            <NamedField title={t("Relationship with Subscriber")}>{relationship?.label}</NamedField>
          </>
        )}
        <NamedField title={t("Insurance Carrier")}>{carrier}</NamedField>
        {employer && (
          <NamedField title={isDependent ? t("Subscriber Employer") : t("Employer")}>{employer}</NamedField>
        )}
        {externalMemberId && <NamedField title={t("Subscriber ID")}>{externalMemberId}</NamedField>}
        {ssnLastFour && (
          <NamedField title={isDependent ? t("Subscriber SSN") : t("SSN")}>XXX-XX-{ssnLastFour}</NamedField>
        )}
      </div>
    </div>
  );
};
