import {
  CreatePaymentProfileByTokenRequest,
  GuestCheckoutPaymentRequest,
  MultiInvoicePaymentRequest,
  PaymentProfileVO,
} from "@libs/api/generated-api";
import { invalidateInvoices } from "api/billing/cache";
import {
  updateCachedData,
  updateCachedListWithCreatedItem,
  updateCachedListWithDeletedItem,
} from "api/cache";
import { makeMutation } from "api/mutations";
import { getQueryKey } from "api/queries";

export const createPaymentProfileByToken = makeMutation({
  mutationKey: ["practices", "createPaymentProfileByToken"],
  formatParams: (args: {
    patientId: number;
    practiceId: number;
    data: CreatePaymentProfileByTokenRequest;
  }) => [args.patientId, args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { patientId, practiceId }) => {
      updateCachedListWithCreatedItem(
        queryClient,
        { queryKey: [getQueryKey("practices", "getAllPaymentProfilesByPatient"), { patientId, practiceId }] },
        data.data.data,
        "isDefault"
      );
      queryClient.invalidateQueries([
        getQueryKey("practices", "getAllPaymentProfilesByPatient"),
        { patientId, practiceId },
      ]);
    },
  }),
});

export const createGuestCheckoutPayment = makeMutation({
  mutationKey: ["public", "createGuestCheckoutPayment"],
  formatParams: (args: { practiceUuid: string; data: GuestCheckoutPaymentRequest }) => [
    args.practiceUuid,
    args.data,
  ],
});

export const createPatientInitiatedMultiInvoicePaymentRecord = makeMutation({
  mutationKey: ["practices", "createPatientInitiatedMultiInvoicePaymentRecord"],
  formatParams: (args: { practiceId: number; patientId: number; data: MultiInvoicePaymentRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { patientId, practiceId, data: { commit } }) => {
      if (!commit) {
        return;
      }

      const invoiceUUids = response.data.data.invoices.map((invoice) => invoice.uuid);

      invalidateInvoices({ queryClient, patientId, practiceId, invoiceUUids });
    },
  }),
});

export const markPaymentProfileAsDefault = makeMutation({
  mutationKey: ["practices", "markPaymentProfileAsDefault"],
  formatParams: (args: { patientId: number; practiceId: number; paymentProfileUuid: string }) => [
    args.patientId,
    args.practiceId,
    args.paymentProfileUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, patientId, paymentProfileUuid }) => {
      updateCachedData<PaymentProfileVO[]>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getAllPaymentProfilesByPatient"), { practiceId, patientId }],
          exact: true,
        },
        (data) => {
          return data
            .map((item) => {
              if (item.uuid === paymentProfileUuid || item.isDefault) {
                return {
                  ...item,
                  isDefault: item.uuid === paymentProfileUuid,
                };
              }

              return item;
            })
            .sort((a, b) => (a.isDefault && !b.isDefault ? -1 : !a.isDefault && b.isDefault ? 1 : 0));
        }
      );
    },
  }),
});

export const deletePaymentProfile = makeMutation({
  mutationKey: ["practices", "markPaymentProfileForDeletion"],
  formatParams: (args: { patientId: number; practiceId: number; paymentProfileUuid: string }) => [
    args.patientId,
    args.practiceId,
    args.paymentProfileUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, patientId, paymentProfileUuid }) => {
      updateCachedListWithDeletedItem<PaymentProfileVO>()(
        queryClient,
        { queryKey: [getQueryKey("practices", "getAllPaymentProfilesByPatient"), { practiceId, patientId }] },
        "uuid",
        paymentProfileUuid
      );
    },
  }),
});
