import {
  MessageSubscriptionVO,
  PatientVO,
  UpdateMessageSubscriptionRequest,
  UpdatePatientRequest,
} from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { updateCachedData } from "api/cache";
import { makeMutation } from "api/mutations";
import { getQueryKey } from "api/queries";

export const updatePatient = makeMutation({
  mutationKey: ["practices", "updatePatient"],
  formatParams: (args: { practiceId: number; patientId: number; data: UpdatePatientRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { patientId, practiceId, data }) => {
      if (isDefined(data.personalDetails.ssn)) {
        updateCachedData<string>(
          queryClient,
          {
            queryKey: [
              getQueryKey("practices", "getSsn"),
              {
                practiceId,
                userId: patientId,
              },
            ],
            exact: true,
          },
          (lastSsn) => {
            return data.personalDetails.ssn ?? lastSsn;
          }
        );
      }

      updateCachedData<PatientVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getPatient"), { practiceId, patientId }],
          exact: false,
        },
        (lastData) => {
          const { ssn } = data.personalDetails;

          const updateData = {
            ...lastData,
            ...data,
            personalDetails: {
              ...data.personalDetails,
              ssnLastFour:
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                ssn?.length === 9 ? ssn.slice(-4, ssn.length) : lastData.personalDetails.ssnLastFour,
            },
          };

          return updateData;
        }
      );
    },
  }),
});

export const updateMessageSubscription = makeMutation({
  mutationKey: ["public", "updateMessageSubscription"],
  formatParams: (args: {
    practiceId?: number;
    practiceUuid: string;
    messageSubscriptionUuid: string;
    updates: UpdateMessageSubscriptionRequest;
    patientId?: number;
  }) => [args.practiceUuid, args.messageSubscriptionUuid, args.updates],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { messageSubscriptionUuid, practiceId, practiceUuid, patientId }) => {
      updateCachedData<MessageSubscriptionVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("public", "getMessageSubscription"),
            { practiceUuid, messageSubscriptionUuid },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );

      if (practiceId && patientId) {
        updateCachedData<MessageSubscriptionVO>(
          queryClient,
          {
            queryKey: [getQueryKey("practices", "getEmailSubscriptionByPatient"), { practiceId, patientId }],
            exact: true,
          },
          () => {
            return response.data.data;
          }
        );
      }
    },
  }),
});
