/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Font } from "@react-pdf/renderer";

import MrDafoeRegular from "assets/fonts/MrDafoe-Regular.ttf";
import OpenSansRegular from "assets/fonts/OpenSans-Regular.ttf";
import OpenSansItalic from "assets/fonts/OpenSans-Italic.ttf";
import OpenSansSemiBold from "assets/fonts/OpenSans-SemiBold.ttf";
import OpenSansSemiBoldItalic from "assets/fonts/OpenSans-SemiBoldItalic.ttf";
import OpenSansBold from "assets/fonts/OpenSans-Bold.ttf";
import OpenSansBoldItalic from "assets/fonts/OpenSans-BoldItalic.ttf";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: OpenSansRegular,
    },
    {
      src: OpenSansItalic,
      fontStyle: "italic",
      fontWeight: 400,
    },
    {
      src: OpenSansSemiBold,
      fontWeight: 600,
    },
    {
      src: OpenSansSemiBoldItalic,
      fontWeight: 600,
      fontStyle: "italic",
    },
    {
      src: OpenSansBold,
      fontWeight: 700,
    },
    {
      src: OpenSansBoldItalic,
      fontWeight: 700,
      fontStyle: "italic",
    },
  ],
});

Font.register({
  family: "Mr Dafoe",
  src: MrDafoeRegular,
});
