import "./index.css";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { Amplify } from "aws-amplify";
import React from "react";
import ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { REACT_RENDER_ROOT } from "config/react";
import { env } from "env";
import { App } from "./App";
import { awsconfig } from "./config/aws-exports";
import "i18n/config";

import { initializeSentry } from "./components/Main/initializeSentry";

if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
  initializeSentry({
    appEnv: env.REACT_APP_ENVIRONMENT,
    release: env.REACT_APP_RELEASE_VERSION,
    dsn: "https://d56f43aa4ff4481db34a48f71165d885@o1034289.ingest.sentry.io/6437910",
  });
}

Amplify.configure(awsconfig);

const container = document.querySelector(`#${REACT_RENDER_ROOT}`);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOMClient.createRoot(container!);

root.render(
  <SentryErrorBoundary>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </SentryErrorBoundary>
);
