import React from "react";
import { PatientFormTaskVO, PracticeInfoVO } from "@libs/api/generated-api";
import { useFilteredForm } from "components/PatientForms/hooks/useFilteredForm";
import { PracticePDFHeader } from "components/PatientForms/FormPDFElements/PracticePDFHeader";
import { PatientAttributes } from "components/PatientForms/utils";
import { PatientFormContentItem } from "components/PatientForms/FormPDFElements/PatientFormContentItem";

const EMPTY_RESPONSES = {};

type Props = {
  task: PatientFormTaskVO;
  practice: PracticeInfoVO;
  patientPersonalDetails: PatientAttributes;
  dob: string;
  logo?: {
    w: number;
    h: number;
    url: string;
  };
};

export const FormTaskPDFContent: React.FC<Props> = ({ task, practice, patientPersonalDetails, logo }) => {
  const matchedForm = useFilteredForm({
    edit: false,
    responses: EMPTY_RESPONSES,
    form: task.form,
    patientAttributes: patientPersonalDetails,
  });

  return (
    <>
      <PracticePDFHeader logo={logo} practice={practice} patientPersonalDetails={patientPersonalDetails} />
      {matchedForm.content.map((item) => {
        return item.content.map((contentItem) => (
          <PatientFormContentItem
            key={item.uuid}
            contentItem={contentItem}
            patientPersonalDetails={patientPersonalDetails}
            responsesById={EMPTY_RESPONSES}
            edit
          />
        ));
      })}
    </>
  );
};
