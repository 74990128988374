import React, { ChangeEvent, FC, useState } from "react";
import heic2any from "heic2any";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ReactComponent as CameraIcon } from "assets/icons/cameraIcon.svg";
import { ReactComponent as PictureCircle } from "assets/icons/pictureCircle.svg";
import { ReactComponent as DrawerCamera } from "assets/icons/DrawerCamera.svg";
import { ReactComponent as DrawerGallery } from "assets/icons/DrawerGallery.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete-grey.svg";
import { UploadPhotoModal } from "components/UploadPhotoModal";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";
import { normal14, semibold20, semibold12 } from "assets/styles/textSize";
import { Modal } from "components/UI/Modal";
import { ModalContent } from "components/UI/ModalComponents";
import { useHandleError } from "api/handleErrorResponse";

const cxStyles = {
  imageOption: "flex gap-4",
};

/**
 * @deprecated
 */
export const CameraComponent: FC<{
  fieldText?: string;
  setImage?: (image: File) => void;
  profilePicture?: string;
  showDelete?: boolean;
  showName?: boolean;
  patientEmail?: string;
  patientName?: string;
  deleteProfilePicture?: Func;
  // eslint-disable-next-line complexity
}> = ({
  fieldText,
  profilePicture,
  setImage = null,
  showDelete = false,
  deleteProfilePicture,
  showName = false,
  patientName = "",
  patientEmail = "",
}) => {
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState(false);
  const desktopCapture = useBoolean(false);
  const handleError = useHandleError();
  const closeDesktopCapture = () => {
    desktopCapture.off();
    setShowDrawer(false);
  };

  const onImageCaptured = async (e: ChangeEvent<HTMLInputElement>) => {
    setShowDrawer(false);

    let file = e.target.files?.[0];

    if (!file) {
      return;
    }

    try {
      if (file.type.toLowerCase().includes("hei")) {
        // get image as blob url
        const blobUrl = URL.createObjectURL(file);
        // convert "fetch" the new blob url
        const blobRes = await fetch(blobUrl);
        // convert response to blob
        const blob = await blobRes.blob();

        // convert to PNG - response is blob
        file = (await heic2any({ blob })) as File;
      }

      setImage?.(file);
    } catch (err) {
      handleError(err);
    }
    // doSomethingWithFiles(files);
  };

  const { screenMatches } = useResponsiveBreakpoint();

  const largeScreen = screenMatches.has("md");

  return (
    <div>
      <div>
        <div
          className={`
            w-full
            flex
            flex-row
            justify-center
            items-center
            pb-3
            cursor-pointer
          `}
        >
          <button type="button" className="relative" onClick={() => setShowDrawer(true)}>
            {profilePicture && profilePicture !== "" ? (
              <img src={profilePicture} alt="profile" className="w-24 object-contain rounded-full" />
            ) : (
              <PictureCircle className="h-20 w-20 rounded-full" />
            )}
            <div
              className={`
                absolute
                w-6
                h-6
                bottom-0.5
                right-0.5
                border-primaryTheme
                border
                rounded-full
                justify-center
                items-center
                bg-white
                flex
              `}
            >
              <CameraIcon />
            </div>
          </button>

          {showName && (
            <div className="mx-3 w-full">
              <div className={cx("truncate", semibold20)}>{patientName}</div>
              <div className={cx("truncate", normal14)}>{patientEmail}</div>
            </div>
          )}
        </div>
        <div className={semibold12}>{fieldText}</div>
      </div>

      {showDrawer && (
        <Modal title="Update Profile Picture" onClose={() => setShowDrawer(false)} size="3xs">
          <ModalContent className="flex flex-col p-4 gap-4">
            <div className={cxStyles.imageOption}>
              <div>
                <DrawerCamera />
              </div>

              <div className="flex">
                {largeScreen ? (
                  <button type="button" onClick={desktopCapture.on} className={normal14}>
                    {t("app.page.labels.takeNewProfilePicture")}
                  </button>
                ) : (
                  <>
                    <label className={normal14} htmlFor="take-picture">
                      {t("app.page.labels.takeNewProfilePicture")}
                    </label>
                    <input
                      type="file"
                      id="take-picture"
                      style={{ display: "none" }}
                      accept="image/png,image/heic,image/jpg,image/jpeg,image/x-png,image/heif"
                      capture="user"
                      onChange={onImageCaptured}
                    />
                  </>
                )}

                {desktopCapture.isOn && <UploadPhotoModal onRequestClose={closeDesktopCapture} />}
              </div>
            </div>
            <div className={cxStyles.imageOption}>
              <div>
                <DrawerGallery />
              </div>
              <div>
                <label htmlFor="upload-pic" className={cx("flex cursor-pointer", normal14)}>
                  {t("app.page.labels.selectNewProfilePicture")}
                </label>
                <input id="upload-pic" type="file" style={{ display: "none" }} onChange={onImageCaptured} />
              </div>
            </div>
            {profilePicture && profilePicture !== "" && showDelete && (
              <button
                type="button"
                className={cxStyles.imageOption}
                onClick={() => {
                  if (deleteProfilePicture) {
                    deleteProfilePicture();
                    setShowDrawer(false);
                  }
                }}
              >
                <div>
                  <DeleteIcon />
                </div>
                <div>
                  <div className={normal14}>{t("app.page.labels.deleteProfilePicture")}</div>
                </div>
              </button>
            )}
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};
