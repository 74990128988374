import Skeleton from "react-loading-skeleton";
import React from "react";
import { cx } from "@libs/utils/cx";
import { normal16, semibold12 } from "assets/styles/textSize";
import { RequiredAsterisk } from "components/UI/RequiredAsterisk";

export const NamedField: React.FC<{
  children?: React.ReactNode | null;
  title: string;
  loading?: boolean;
  className?: string;
  required?: boolean;
  containerClassName?: string;
}> = ({ title, children, loading = false, className, required, containerClassName }) => {
  return (
    <div className={cx("space-y-1", containerClassName)}>
      <div className={semibold12}>
        {title} {required && <RequiredAsterisk />}
      </div>

      <div className={cx(normal16, className)}>{loading ? <Skeleton /> : children ?? "-"}</div>
    </div>
  );
};
