import React from "react";
import { useTranslation } from "react-i18next";
import { FormSelectInputElementVO } from "@libs/api/generated-api";
import { mirroredOptions } from "@libs/utils/forms";
import { isDefined } from "@libs/utils/types";
import { PatientResponses, ResponseChangedCallback } from "components/PatientForms/hooks/usePatientResponses";
import { PatientFormOtherOption } from "components/PatientForms/FormElements/PatientFormOtherOption";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { Invalidation } from "components/PatientForms/hooks/usePatientFormValidation";
import { OTHER_ID } from "components/PatientForms/utils";
import { PatientFormSelectExplicitElement } from "components/PatientForms/FormElements/PatientFormSelectExplicitElement";
import { PatientFormSelectMultipleElement } from "components/PatientForms/FormElements/PatientFormSelectMultipleElement";
import { PatientFormFieldTitle } from "components/PatientForms/FormElements/FormFieldTitle";

type Props = {
  id: string;
  element: FormSelectInputElementVO;
  edit: boolean;
  responsesById: PatientResponses;
  invalidation?: Invalidation;
  onChangeResponse: ResponseChangedCallback;
};

export const PatientFormSelectElement: React.FC<Props> = ({
  element,
  id,
  edit,
  responsesById,
  onChangeResponse,
  invalidation,
}) => {
  const { t } = useTranslation();
  const { uuid, title, settings, options } = element;
  const formatSettings = new Set(settings);
  const prevResponse = responsesById[uuid];
  const required = settings.includes("REQUIRED");
  const prevSelectResponse = prevResponse?.type === "SELECT" ? prevResponse : undefined;
  const canSelectMultiple = formatSettings.has("ALLOW_MULTIPLE_SELECTIONS");
  const hasOtherOption = formatSettings.has("ALLOW_ADDITIONAL_OPTION");
  const error = invalidation?.error;
  const otherOptionRef = React.useRef<HTMLInputElement>(null);
  const elementOptions = React.useMemo(() => {
    const allOptions: { label: string | React.ReactNode; value: string }[] = mirroredOptions(options);
    const otherValue = prevSelectResponse?.other;
    const selected = prevSelectResponse?.responses
      ? options.filter((value) => Boolean(prevSelectResponse.responses[value]))
      : [];

    if (isDefined(otherValue)) {
      selected.push("other");
    }

    if (hasOtherOption) {
      allOptions.push({
        label: canSelectMultiple ? (
          <PatientFormOtherOption
            ref={otherOptionRef}
            id={id}
            edit={edit}
            questionUuid={uuid}
            isMulti
            response={prevSelectResponse}
            onChangeResponse={onChangeResponse}
          />
        ) : (
          t("Other")
        ),
        value: OTHER_ID,
      });
    }

    return {
      allOptions,
      selectedValue: selected[0],
      selectedSet: new Set(selected),
    };
  }, [options, prevSelectResponse, hasOtherOption, canSelectMultiple, id, edit, uuid, onChangeResponse, t]);
  const sharedProps = {
    required,
    id,
    className: "max-w-23rem",
    edit,
    menuPortalTarget: document.body,
    name: uuid,
    isClearable: !required,
    isSearchable: false,
    label: title,
  };
  const handleOtherToggled = React.useCallback((checked: boolean) => {
    if (checked) {
      otherOptionRef.current?.focus();
    }
  }, []);

  return (
    <div className="flex flex-col gap-1">
      {formatSettings.has("ALLOW_MULTIPLE_SELECTIONS") ? (
        <PatientFormSelectMultipleElement
          onChangeResponse={onChangeResponse}
          id={id}
          edit={edit}
          responsesById={responsesById}
          onToggledOther={handleOtherToggled}
          options={elementOptions.allOptions}
          required={required}
          selectedOptions={elementOptions.selectedSet}
          uuid={uuid}
          title={title}
        />
      ) : formatSettings.has("ENFORCE_EXPLICIT_CONSENT") ? (
        <PatientFormSelectExplicitElement
          onChangeResponse={onChangeResponse}
          uuid={uuid}
          title={title}
          invalidation={invalidation}
          options={elementOptions.allOptions}
          selectedOptions={elementOptions.selectedSet}
          edit={edit}
          responsesById={responsesById}
        />
      ) : (
        <div className="flex flex-col gap-2">
          <PatientFormFieldTitle title={title} required={required} />
          <div className="max-w-sm">
            <FormFieldSelect<string, SelectOption<string>>
              {...sharedProps}
              label={undefined}
              display="label"
              emptyReadOnlyValue={t("Not Provided")}
              options={elementOptions.allOptions}
              onChange={(newOption) => {
                if (newOption?.value === OTHER_ID) {
                  otherOptionRef.current?.focus();
                }

                onChangeResponse(uuid, {
                  type: "SELECT",
                  responses: newOption
                    ? newOption.value === OTHER_ID
                      ? {}
                      : { [newOption.value]: true }
                    : {},
                  other: newOption?.value === OTHER_ID ? "" : undefined,
                });
              }}
              error={error}
              value={elementOptions.selectedValue}
            />
            {elementOptions.selectedValue === OTHER_ID && (
              <PatientFormOtherOption
                ref={otherOptionRef}
                id={id}
                edit={edit}
                questionUuid={uuid}
                response={prevSelectResponse}
                placeholder={t("Please elaborate")}
                onChangeResponse={onChangeResponse}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
