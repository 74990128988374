import { FormEvent, useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useTranslation } from "react-i18next";
import { dataURLtoFile } from "@libs/utils/dataUrl";
import { Button } from "components/UI/Button";
import { useApiMutations } from "api/mutations";
import { uploadProfileImage } from "api/user/mutations";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useHandleError } from "api/handleErrorResponse";
import { Modal } from "components/UI/Modal";
import { ModalContent, ModalFooter, ModalForm } from "components/UI/ModalComponents";
import { useUserContext } from "contexts/UserContext";
import { EditProfileImageModal } from "components/Account/EditProfileImageModal";

interface Props {
  onRequestClose: Func;
}

export const QUALITY = 0.8;
export const MAX_PROFILE_IMAGE_SIZE = 500;

export const UploadPhotoModal = ({ onRequestClose }: Props) => {
  const { practiceId, id: userId } = useCurrentUser();
  const webcamRef = useRef<Webcam | null>(null);
  const { refetchUser } = useUserContext();

  const [selectedImage, setSelectedImage] = useState<null | File>(null);
  const { t } = useTranslation();

  const handleError = useHandleError();

  const [uploadProfileImageMutation] = useApiMutations([uploadProfileImage]);

  const capturePhoto = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const imageSrc = webcamRef.current?.getScreenshot();

    if (imageSrc) {
      const file = dataURLtoFile(imageSrc, "image.png");

      setSelectedImage(file);
    }
  };

  const handleUploadPhoto = useCallback(
    (resizedImage: File) => {
      uploadProfileImageMutation.mutate(
        { practiceId, userId, image: resizedImage },
        {
          onSuccess: () => {
            refetchUser();
            onRequestClose();
          },
          onError: handleError,
        }
      );
    },
    [uploadProfileImageMutation, practiceId, userId, handleError, refetchUser, onRequestClose]
  );

  if (selectedImage) {
    return (
      <EditProfileImageModal
        onClose={onRequestClose}
        onDone={handleUploadPhoto}
        initialImage={selectedImage}
        isSaving={uploadProfileImageMutation.isLoading}
      />
    );
  }

  return (
    <Modal title={t("Upload Profile Picture")} onClickOutside={onRequestClose} onClose={onRequestClose}>
      <ModalForm className="flex flex-col w-full" onSubmit={capturePhoto}>
        <ModalContent className="flex items-center justify-center w-full my-8">
          <Webcam ref={webcamRef} screenshotFormat="image/jpeg" width={360} mirrored={true} />
        </ModalContent>
        <ModalFooter>
          <Button theme="secondary" onClick={onRequestClose}>
            {t("Cancel")}
          </Button>
          <Button type="submit" className="ml-4">
            {t("Take Photo")}
          </Button>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
