import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { semibold16 } from "assets/styles/textSize";
import { Header } from "components/Main/Header/Header";
import { ReactComponent as CheckIcon } from "assets/icons/currentColor/check.svg";

export const PaymentCompleteScreen: React.FC<{
  email: string;
  isLoading?: boolean;
  children?: React.ReactNode;
}> = ({ email, isLoading, children }) => {
  const { t } = useTranslation();

  return (
    <div className="h-full bg-white">
      <Header signedOut />
      <div className="h-[50px]" />
      <div className="flex flex-col p-4 items-center">
        <div
          className={`
            flex
            flex-col
            items-center
            gap-6
            w-full
            text-center
            max-w-md
            overflow-y-auto
            pt-10
          `}
        >
          <div className={semibold16}>{t("Payment Complete!")}</div>
          <CheckIcon className="text-greenDark w-10 h-10" />
          {isLoading ? (
            <Skeleton className="w-10" />
          ) : (
            <p>
              {t("pages.guestCheckout.receiptEmailed", {
                email,
              })}
            </p>
          )}
          {isLoading ? null : children}
        </div>
      </div>
    </div>
  );
};
