import { ReactNode, FC } from "react";
import { cx } from "@libs/utils/cx";
import { Layout } from "components/UI/OptionInputList";
import { FormFieldLabel } from "components/UI/FormFieldLabel";
import { FormFieldError } from "components/UI/FormFieldError";

interface Props {
  layout?: Layout;
  label?: ReactNode;
  required?: boolean;
  error?: string;
  className?: string;
  labelClassName?: string;
  children?: ReactNode;
  inline?: boolean;
  description?: ReactNode;
}

export const LabelledOptions: FC<Props> = ({
  className,
  label,
  description,
  error,
  required,
  children,
  labelClassName,
  layout = "horiz",
  inline,
}) => {
  const errorContent = error ? <FormFieldError className="mt-1 mb-2">{error}</FormFieldError> : null;

  return label ? (
    <div className={cx(layout === "flat" && "flex", className)}>
      <FormFieldLabel
        content={label}
        error={error}
        required={required}
        className={cx(labelClassName, layout === "flat" ? "items-center" : layout === "custom" ? "" : "mb-2")}
        inline={inline}
      />
      {description ? <div className="mb-3 text-xs">{description}</div> : null}
      {errorContent}
      <div className={cx(layout === "flat" && "flex-none ml-2")}>{children}</div>
    </div>
  ) : (
    <div className={className}>
      {children}
      {errorContent}
    </div>
  );
};
