import { ApiErrorResponse } from "api/queries";
import { isHttpResponseError } from "api/utils";

// When submitting kiosk code/DOB to acquire submission token, it will throw the below errors if submission is invalid
export const getIsKioskCodeOrDOBError = (resp?: ApiErrorResponse | null) => {
  if (!resp) {
    return undefined;
  }

  const errorCode = isHttpResponseError(resp) ? resp.error.errors?.[0].errorCode : ("UNKNOWN_ERROR" as const);

  return errorCode === "RESOURCE_NOT_FOUND" || errorCode === "FIELD_VALIDATION_ERROR";
};
