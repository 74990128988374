import { Auth } from "aws-amplify";
import React from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useUserContext } from "contexts/UserContext";

export const useSignOut = () => {
  const loading = useBoolean(false);
  const { selectAccount } = useUserContext();
  const signOut = React.useCallback(async () => {
    loading.on();
    await Auth.signOut();
    selectAccount(null);
    loading.off();
  }, [selectAccount, loading]);

  return {
    isLoading: loading.isOn,
    signOut,
  };
};
