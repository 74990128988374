import React from "react";
import { FamilyRelationVO } from "@libs/api/generated-api";

export type PatientRelationship = {
  label: string;
  value: FamilyRelationVO["relation"];
};
export const getRelations = (): PatientRelationship[] => {
  const values: { label: string; value: FamilyRelationVO["relation"] }[] = [
    {
      label: "Self",
      value: "SELF",
    },
    {
      label: "Parent",
      value: "PARENT",
    },
    {
      label: "Child",
      value: "CHILD",
    },
    {
      label: "Spouse",
      value: "SPOUSE",
    },
    {
      label: "Sibling",
      value: "SIBLING",
    },
    {
      label: "Grandparent",
      value: "GRANDPARENT",
    },
    {
      label: "Grandchild",
      value: "GRANDCHILD",
    },
    {
      label: "Other",
      value: "OTHER",
    },
  ];

  return values as PatientRelationship[];
};

export const usePatientRelations = () => {
  return React.useMemo(() => getRelations(), []);
};

export const useFamilyRelations = () => {
  return React.useMemo(() => {
    const relations = getRelations();

    return relations.filter((item) => item.value !== "SELF");
  }, []);
};

export const useRelationship = (relation?: FamilyRelationVO["relation"]) => {
  const patientRelations = usePatientRelations();

  return patientRelations.find((item) => item.value === relation);
};
