import { ReactNode } from "react";
import { cx } from "@libs/utils/cx";

export interface FormFieldContainerProps {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  error?: string;
  edit?: boolean;
}

const cxStyle = {
  container: ({ disabled, error, edit }: { disabled: boolean; error?: string; edit?: boolean }) =>
    cx(
      "flex-1 h-5",
      edit && (disabled ? "bg-white" : "bg-greyLightest"),
      error ? "border border-red" : "focus-within:border-primaryTheme hover:border-primaryTheme"
    ),
};

export const FormFieldNarrowContainer: React.FC<FormFieldContainerProps> = ({
  children,
  className,
  disabled = false,
  error,
  edit,
}) => {
  return <div className={cx(cxStyle.container({ disabled, error, edit }), className)}>{children}</div>;
};
