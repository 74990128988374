import { cx } from "@libs/utils/cx";
import { Header } from "components/Main/Header/Header";
import { MainRouteTabs } from "components/Main/MainRouteTabs";
import { useLayoutContext } from "contexts/LayoutContext";

/*

To customize header or tabs shown in a logged-in component, can do the following:

  const { headerOffEffect, tabsOffEffect } = useLayoutContext();
  React.useEffect(headerOffEffect, [headerOffEffect]);
    React.useEffect(headerOffEffect, [headerOffEffect]);
*/

export const AuthLayout: React.FC<{ children?: React.ReactNode; isLoading?: boolean }> = ({ children }) => {
  const { header, tabs } = useLayoutContext();

  return (
    <div className={cx("relative min-h-0 h-full", header.isOn && "pt-[50px]")}>
      {header.isOn && <Header />}
      {tabs.isOn ? <MainRouteTabs isLoading={false}>{children}</MainRouteTabs> : children}
    </div>
  );
};
