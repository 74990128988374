import { View } from "@react-pdf/renderer";
import { pdfStyles } from "components/PatientForms/FormPDFElements/styles";

export const Box: React.FC<{ children: React.ReactNode; wrap?: boolean }> = ({ children, wrap }) => {
  return (
    <View style={[pdfStyles.box]} wrap={wrap}>
      {children}
    </View>
  );
};
