import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { cx } from "@libs/utils/cx";
import { SignInWrapper } from "components/SignIn/SignInWrapper";
import { usePracticeInfo } from "hooks/usePracticeInfo";
import { PoweredBy } from "components/UI/PoweredBy";
import { formatThumbnailData } from "utils/user";
import { semibold20 } from "assets/styles/textSize";
import { ButtonInternalLink } from "components/UI/ButtonLink";
import { paths } from "utils/paths";
import { AlreadyHaveAnAccount } from "components/SignIn/AlreadyHaveAnAccount";
import { ReactComponent as PlaceholderIcon } from "assets/icons/Placeholder_32x32.svg";
import { cxSignInStyles } from "components/SignIn/cxSignInStyles";
import { useEmailQueryParam } from "components/SignIn/hooks/useEmailQueryParam";

const CenteredBranding: React.FC = () => {
  const { practice, isLoading } = usePracticeInfo();

  const getThumbnail = (thumbnail?: string, name?: string) => {
    if (thumbnail) {
      return <img src={formatThumbnailData(thumbnail)} alt={name} className="h-10 w-10" />;
    }

    return <PlaceholderIcon className="rounded-full w-10 h-10" />;
  };

  if (practice || isLoading) {
    return (
      <div className="flex flex-col space-y-4 items-center justify-center">
        {isLoading || !practice ? (
          <Skeleton className="w-10 h-10" inline />
        ) : (
          getThumbnail(practice.logo?.thumbnail, practice.name)
        )}
        {isLoading || !practice ? (
          <Skeleton className="w-36 mb-1" inline />
        ) : (
          <div className={cx("text-secondaryTheme", semibold20)}>{practice.name}</div>
        )}
        <PoweredBy textClassName="text-greyMedium" className="text-secondaryTheme" />
      </div>
    );
  }

  return <PoweredBy textClassName="text-greyMedium" className="text-secondaryTheme" />;
};

export const WelcomeLanding: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { practice, isLoading } = usePracticeInfo(true);
  const userEmail = useEmailQueryParam();
  const practiceName: string = practice?.name ?? i18n.t("app.labels.aDentalPractice");

  return (
    <SignInWrapper includeBranding={false}>
      <CenteredBranding />
      <div className="w-full text-center gap-6 flex flex-col">
        <div>
          {isLoading ? (
            <Skeleton className="w-full h-4 mb-1" count={4} />
          ) : (
            t("pages.welcome.inviteMessage", { practiceName })
          )}
        </div>
        <ButtonInternalLink
          className={cxSignInStyles.button}
          to={paths.signUp({ email: userEmail ?? undefined })}
        >
          {t("Sign Up")}
        </ButtonInternalLink>
        <AlreadyHaveAnAccount />
      </div>
    </SignInWrapper>
  );
};
