import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PatientOutstandingBalanceSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import {
  fetchPatientOutstandingBalanceSummary,
  getInvoicesInfiniteQuery,
  getStatementsInfiniteQuery,
} from "api/billing/queries";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useInfiniteApiQuery } from "api/infiniteQueries";
import { getInfiniteQueryPagingDetails } from "api/utils";
import { semibold20 } from "assets/styles/textSize";
import { ToggleGroup } from "components/UI/ToggleGroup";
import { InvoicesQuery, PathDefinitions, paths } from "utils/paths";
import { InvoiceList } from "components/Billing/InvoicesRoute/InvoiceList";
import { ButtonInternalLink } from "components/UI/ButtonLink";
import { useLayoutContext } from "contexts/LayoutContext";
import { StatementsList } from "components/Billing/InvoicesRoute/StatementsList";
import { useSearchQueryParams } from "hooks/useSearchQueryParams";
import { ApiQueryResult, useApiQueries } from "api/queries";

const isInvoicesTab = (tab: InvoicesQuery["tab"]) => {
  return tab === "pending" || tab === "completed";
};

const useCanProceedToPayment = ({
  tab,
  paymentSummaryQuery,
}: {
  tab: InvoicesQuery["tab"];
  paymentSummaryQuery: ApiQueryResult<PatientOutstandingBalanceSummaryVO>;
}) => {
  return tab === "pending" && (paymentSummaryQuery.data?.outstandingBalance ?? 0) > 0;
};

export const InvoicesRoute: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchParams = useSearchQueryParams<PathDefinitions.invoices>();

  const { tabsOnBottom } = useLayoutContext();
  const tab = ((searchParams.get("tab") ?? "pending") as InvoicesQuery["tab"]) ?? "pending";
  const { id: patientId, practiceId } = useCurrentUser();
  const sharedParams = {
    patientId,
    practiceId,
    pageNumber: 1,
    pageSize: 20,
    includePayments: true,
    includePayerInfo: true,
    includeOrders: false,
  };
  const [paymentSummaryQuery] = useApiQueries([
    fetchPatientOutstandingBalanceSummary({
      args: {
        practiceId,
        patientId,
      },
    }),
  ]);
  const unpaidInvoicesQuery = useInfiniteApiQuery(
    getInvoicesInfiniteQuery({
      args: {
        ...sharedParams,
        states: ["FINALIZED", "PARTIALLY_PAID"],
      },
    })
  );
  const paidInvoicesQuery = useInfiniteApiQuery(
    getInvoicesInfiniteQuery({
      args: {
        ...sharedParams,
        states: ["PAID"],
      },
    })
  );
  const statementsQuery = useInfiniteApiQuery(
    getStatementsInfiniteQuery({
      args: {
        patientId,
        practiceId,
        pageNumber: 1,
        pageSize: 20,
      },
      queryOptions: {
        enabled: tab === "statements",
      },
    })
  );
  const unpaidCount = getInfiniteQueryPagingDetails(unpaidInvoicesQuery.data)?.totalElements ?? 0;
  const paidCount = getInfiniteQueryPagingDetails(paidInvoicesQuery.data)?.totalElements ?? 0;
  const options = useMemo(
    () => [
      {
        label: t("app.pages.payments.pending", {
          invoiceCount: unpaidInvoicesQuery.isLoading ? "  " : unpaidCount,
        }),
        value: "pending" as const,
      },
      {
        label: t("app.pages.payments.completed", {
          invoiceCount: paidInvoicesQuery.isLoading ? "  " : paidCount,
        }),
        value: "completed" as const,
      },
      {
        label: t("app.pages.payments.statements"),
        value: "statements" as const,
      },
    ],
    [t, unpaidInvoicesQuery.isLoading, unpaidCount, paidInvoicesQuery.isLoading, paidCount]
  );
  const canProceedToPayment = useCanProceedToPayment({ tab, paymentSummaryQuery });

  return (
    <>
      <div className="px-4 mt-6 flex flex-1 justify-center overflow-y-auto">
        <div className="flex flex-col gap-3 w-full max-w-lg">
          <div className={semibold20}>{t("app.pages.payments.page.header")}</div>
          <div className="flex flex-col items-center mb-8">
            <ToggleGroup
              size="lg"
              options={options}
              selectedValue={tab}
              onChange={(_, option) => {
                navigate(paths.invoices({ tab: option.value === "pending" ? undefined : option.value }));
              }}
            />
          </div>
          {isInvoicesTab(tab) ? (
            <InvoiceList
              invoicesQuery={tab === "pending" ? unpaidInvoicesQuery : paidInvoicesQuery}
              emptyContent={
                tab === "pending" ? t("No pending invoices found.") : t("No completed invoices found.")
              }
            />
          ) : (
            <StatementsList statementsQuery={statementsQuery} />
          )}

          <div className="h-16" />
        </div>
      </div>
      {canProceedToPayment && (
        <div
          className={cx(
            `left-0
             right-0
             flex
             gap-8
             z-10
             items-center
             justify-center
             bg-white
             shadow-[2px_-2px_4px_rgba(188,188,188,0.25)]
             px-4
             h-16
             border-b
             border-b-slate-300
             fixed`,
            tabsOnBottom ? "bottom-[3.5rem]" : "bottom-0"
          )}
        >
          <ButtonInternalLink className="w-full max-w-md" to={PathDefinitions.pay}>
            {t("Pay Balance")}
          </ButtonInternalLink>
        </div>
      )}
    </>
  );
};
