/**
 * Used to dedupe items in an array based on a given property
 * @param arr The array to be made unique
 * @param getKey A function that returns the unique key for each item in the array
 * @returns array with items removed that had the same key from getKey.
 */
export const uniqueBy = <T>(arr: T[], getKey: (item: T) => string | number) => {
  const seen = new Set();

  return arr.filter((item) => {
    const k = getKey(item);

    return seen.has(k) ? false : seen.add(k);
  });
};

/**
 * Groups an array by a specific field's value.
 * @param array The array of the map by.
 * @param field The field to group by.
 * @returns An object where each key is the value of the field in the array.
 */
export const toMap = <T extends Record<K, PropertyKey>, K extends keyof T>(
  array: T[],
  field: K
): Record<string | number, T | undefined> =>
  array.reduce(
    (accum, value) => {
      const groupByValue = value[field];

      accum[groupByValue] = value;

      return accum;
    },
    {} as Record<T[K], T | undefined>
  );

/**
 * Used to retrieve the first item in an array
 * @param arr The array to retrieve item from
 * @returns first item in array or undefined if empty.
 */
export const getFirstItem = <T>(arr?: T[]) => {
  return arr?.[0];
};
