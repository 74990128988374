import { forwardRef } from "react";
import { cx } from "@libs/utils/cx";

export const cxStyles = {
  themes: {
    white: "bg-white ring-1 ring-greyLightest",
    grey: "bg-greyLightest",
    magenta: "bg-magentaLight text-magentaDark",
    red: "bg-redLight text-redDark",
    orange: "bg-orangeLight text-orangeDark",
    yellow: "bg-yellowLigh text-yellowDark",
    green: "bg-greenLight text-greenDark",
    blue: "bg-blueLight text-blueDark",
  },
  sizes: {
    sm: "text-xs/4 py-1 px-2 h-6",
    md: "text-xs/4 py-2 px-3 h-8",
    lg: "text-sm p-3 h-10",
  },
};

export interface PillProps {
  className?: string;
  children: React.ReactNode;
  theme?: keyof typeof cxStyles.themes;
  size?: keyof typeof cxStyles.sizes;
}

export const Pill = forwardRef<HTMLDivElement, PillProps>(
  ({ className, theme = "white", size = "sm", ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cx(
          "rounded-full text-center whitespace-nowrap",
          className,
          cxStyles.themes[theme],
          cxStyles.sizes[size]
        )}
        {...props}
      />
    );
  }
);
