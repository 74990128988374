import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import { clampImageDimensions } from "@libs/utils/image";
import { blobToFile } from "@libs/utils/dataUrl";
import { AsyncButton } from "components/UI/AsyncButton";
import { Button } from "components/UI/Button";
import { Modal } from "components/UI/Modal";
import { ModalContent, ModalFooter, ModalForm } from "components/UI/ModalComponents";
import { UploadPhotoEditor } from "components/UploadPhotoEditor";
import { MAX_PROFILE_IMAGE_SIZE } from "components/UploadPhotoModal";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";

const INNER_WIDTH_INSET_PERCENT = 0.8;

export const EditProfileImageModal: React.FC<{
  initialImage: File;
  onClose: () => void;
  isSaving: boolean;
  onDone: (updatedImage: File) => void;
}> = ({ initialImage, onClose, onDone, isSaving }) => {
  const { t } = useTranslation();
  const canvasRef = useRef<AvatarEditor | null>(null);
  const handleImageUpload = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (canvasRef.current !== null) {
        canvasRef.current.getImageScaledToCanvas().toBlob(async (blob) => {
          if (blob === null) {
            return;
          }

          const resized = await clampImageDimensions(blob, {
            w: MAX_PROFILE_IMAGE_SIZE,
            h: MAX_PROFILE_IMAGE_SIZE,
          });

          if (resized.blob) {
            const file = blobToFile(resized.blob, "profile-image.png");

            onDone(file);
          }
        }, "image/png");
      }
    },
    [onDone]
  );
  const { screenMatches } = useResponsiveBreakpoint();

  const isSmallScreen = !screenMatches.has("sm");
  const uploadEditorSize = useMemo(
    () => (isSmallScreen ? window.innerWidth * INNER_WIDTH_INSET_PERCENT : undefined),
    [isSmallScreen]
  );

  return (
    <Modal title={t("Upload Profile Picture")} onClickOutside={onClose} onClose={onClose}>
      <ModalForm onSubmit={handleImageUpload}>
        <ModalContent padding="sm">
          <UploadPhotoEditor
            imageFile={initialImage}
            canvasRef={canvasRef}
            onDeleteImage={onClose}
            size={uploadEditorSize}
          />
        </ModalContent>
        <ModalFooter>
          <Button type="button" theme="secondary" onClick={onClose}>
            Cancel
          </Button>
          <AsyncButton isLoading={isSaving} type="submit">
            Save
          </AsyncButton>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
