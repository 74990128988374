import { cx } from "@libs/utils/cx";

export const FooterButtons: React.FC<{ children: React.ReactNode; absolute?: boolean }> = ({
  children,
  absolute = false,
}) => {
  return (
    <>
      <div className="h-16" />
      <div
        className={cx(
          `bottom-0
           left-0
           right-0
           h-16
           w-full
           bg-white
           flex
           justify-center
           items-center
           shadow-[2px_-2px_4px_rgba(188,188,188,0.25)]
           px-4`,
          absolute ? "absolute" : "fixed"
        )}
      >
        {children}
      </div>
    </>
  );
};
