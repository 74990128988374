import { SelfBookingAppointmentRequest } from "@libs/api/generated-api";
import { makeMutation } from "api/mutations";

export const bookAppointment = makeMutation({
  mutationKey: ["public", "bookAppointment"],
  formatParams: (args: { practiceUuid: string; data: SelfBookingAppointmentRequest }) => [
    args.practiceUuid,
    args.data,
  ],
});
