import { useTranslation } from "react-i18next";
import { Button } from "components/UI/Button";
import { ReactComponent as EditIcon } from "assets/icons/currentColor/edit.svg";

export const EditButton: React.FC<{ onClick: Func }> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button theme="link" size="small" className="flex gap-1 items-center" onClick={onClick}>
      <EditIcon className="w-3.5 h-3.5 text-primaryTheme" />
      {t("Edit")}
    </Button>
  );
};
