import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { setUser as setSentryUser, setTag as setSentryTag } from "@sentry/react";
import { UserInfoVO } from "@libs/api/generated-api";
import { getAccountSelected, SelectedAccountV1, setAccountSelected } from "storage/accountSelected";
import { PathDefinitions } from "utils/paths";
import { useSyncOnce } from "hooks/useSyncOnce";
import { setSentryPracticeProperties, setSentryUserProperties } from "components/Main/initializeSentry";

export const useAccountSelected = (data?: UserInfoVO[]) => {
  const [acctSelectedState, setAccountSelectedState] = React.useState<SelectedAccountV1 | null>(
    getAccountSelected()
  );
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleAccountSelected = React.useCallback(
    (acct: SelectedAccountV1 | null) => {
      const patientId = acct?.patientId.toString();
      const user = data?.find((item) => {
        return item.id === acct?.patientId;
      });
      const onboardingState = user?.onboardingState;

      setSentryTag("onboardingState", onboardingState);
      setSentryUser(
        patientId
          ? {
              id: patientId,
            }
          : null
      );

      if (acct === null) {
        setSentryUserProperties(null);
        setSentryPracticeProperties(null);
      } else if (user) {
        setSentryUserProperties(user);
      }

      if (acct?.patientId !== acctSelectedState?.patientId) {
        setAccountSelected(acct);
        queryClient.invalidateQueries();
        setAccountSelectedState(acct);

        const hasLoggedInBefore = Boolean(acctSelectedState);

        if (hasLoggedInBefore) {
          if (onboardingState === "COMPLETED") {
            navigate(PathDefinitions.home);
          } else {
            navigate(PathDefinitions.onboarding);
          }
        }
      }
    },
    [data, acctSelectedState, queryClient, navigate]
  );

  useSyncOnce((loadedUserInfo) => {
    const accountFound = acctSelectedState
      ? loadedUserInfo.find((user) => user.id === acctSelectedState.patientId)
      : undefined;

    if (loadedUserInfo.length === 1) {
      const [patient] = loadedUserInfo;

      // There is only one account, auto-select it.
      handleAccountSelected(
        accountFound
          ? {
              patientId: accountFound.id,
              thumbnail: accountFound.thumbnail,
            }
          : {
              patientId: patient.id,
              thumbnail: patient.thumbnail,
            }
      );
    } else if (!accountFound) {
      setAccountSelectedState(null);
      setAccountSelected(null);
    }
  }, data);

  return {
    handleAccountSelected,
    accountSelected: acctSelectedState,
  };
};
