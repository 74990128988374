import React from "react";
import { useTranslation } from "react-i18next";
import { UserInfoVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useCurrentUser } from "hooks/useCurrentUser";
import { AvatarButton } from "components/UI/AvatarButton";
import { ButtonMenu } from "components/UI/ButtonMenu";
import { ReactComponent as SignOutIcon } from "assets/icons/currentColor/sign-out.svg";
import { ReactComponent as Checkmark } from "assets/icons/currentColor/checkmark.svg";

import { useUserContext } from "contexts/UserContext";

import { AsyncButton } from "components/UI/AsyncButton";
import { useSignOut } from "hooks/useSignOut";
import { UserAvatar } from "components/UI/UserAvatar";
import { normal14, semibold14 } from "assets/styles/textSize";
import { HeaderPracticeLink } from "components/Main/Header/HeaderPracticeLink";

const MenuContent: React.FC<{ onSelectAccount: (user: UserInfoVO) => void; users: UserInfoVO[] }> = ({
  onSelectAccount,
  users,
}) => {
  const { id } = useCurrentUser();
  const { t } = useTranslation();
  const { signOut, isLoading } = useSignOut();

  return (
    <div
      className={`
        min-w-[280px]
        flex
        flex-col
        justify-center
        divide-y
        divide-greyLighter
      `}
    >
      {users.map((item) => {
        const selected = id === item.id;

        return (
          <button
            key={item.id}
            type="button"
            className={`
              flex
              flex-row
              space-x-2
              items-center
              hover:bg-greyLightest
              py-3
              px-4
            `}
            onClick={() => {
              onSelectAccount(item);
            }}
          >
            <div className="w-7 h-7">
              <UserAvatar thumbnail={item.thumbnail} />
            </div>
            <div
              className={cx(selected ? semibold14 : normal14, selected ? "text-greyDark" : "text-greyMedium")}
            >
              {item.fullDisplayName}
            </div>
            <div className="flex-1" />
            <div>{selected && <Checkmark className="text-green" />}</div>
          </button>
        );
      })}
      <div className="px-4 py-2">
        <AsyncButton
          isLoading={isLoading}
          className="flex w-full justify-center items-center"
          onClick={signOut}
        >
          <SignOutIcon className="mr-1" />
          {t("app.page.labels.signOut")}
        </AsyncButton>
      </div>
    </div>
  );
};

export const cxHeaderStyles = {
  container: `
    w-full
    left-0
    top-0
    right-0
    h-[50px]
    flex-none
    bg-slate-50
    border-b
    border-b-slate-300
    flex
    items-center
    justify-between
    py-0
    px-4
    z-10
    fixed
  `,
};
export const Header: React.FC<{ reloadOnUserChanged?: boolean; signedOut?: boolean }> = ({
  reloadOnUserChanged = false,
  signedOut = false,
}) => {
  const currentUser = useCurrentUser();
  const menu = useBoolean(false);
  const { users, selectAccount } = useUserContext();

  return (
    <div className={cxHeaderStyles.container}>
      <HeaderPracticeLink />
      {!signedOut && (
        <div className="flex items-center">
          <ButtonMenu
            placement="bottom-end"
            isOpen={menu.isOn}
            onRequestOpen={menu.on}
            className="z-20"
            menuContent={
              <MenuContent
                onSelectAccount={(user) => {
                  menu.off();
                  selectAccount({
                    patientId: user.id,
                    thumbnail: user.thumbnail,
                  });

                  if (reloadOnUserChanged) {
                    window.location.reload();
                  }
                }}
                users={users}
              />
            }
            onRequestClose={menu.off}
          >
            {(props) => <AvatarButton {...props} thumbnail={currentUser.thumbnail} className="h-10 w-10" />}
          </ButtonMenu>
        </div>
      )}
    </div>
  );
};
