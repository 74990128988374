import { FC, HTMLAttributes, PropsWithChildren, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { ReactComponent as CancelIcon } from "assets/icons/currentColor/cancel-outline.svg";
import { ButtonIcon } from "components/UI/ButtonIcon";
import { Form, FormProps } from "components/UI/Form";

export const ModalFooterButtons: FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children,
}) => {
  return <div className={cx("flex justify-center items-center gap-x-3", className)}>{children}</div>;
};

export const ModalCloseButton: React.FC<{ onClick: Func }> = ({ onClick }) => (
  <ButtonIcon
    tooltip={{ content: "Close", theme: "SMALL" }}
    theme="primary"
    Icon={CancelIcon}
    onClick={onClick}
  />
);

export const ModalHeader: FC<{
  className?: string;
  paddingClassName?: string;
  onClose?: Func;
  children?: ReactNode;
}> = ({ className, children, paddingClassName, onClose }) => {
  return (
    <div
      className={cx(
        `flex
         flex-none
         border-b
         border-greyLighter
         justify-between
         items-center`,
        paddingClassName ?? "px-5 py-4 lg:px-8 lg:py-6",
        className
      )}
    >
      <div role="heading" aria-level={1} className="text-base font-sansSemiBold">
        {children}
      </div>
      {onClose && <ModalCloseButton onClick={onClose} />}
    </div>
  );
};

const cxPaddingStyle = {
  sm: "p-5",
  md: "py-4 px-6",
  lgEven: "py-6 px-8",
  lg: "pt-5 px-8 pb-12",
  confirm: "py-8 px-10",
};

export const ModalContent: FC<{
  className?: string;
  padding?: keyof typeof cxPaddingStyle;
  scrollClassName?: string;
  children?: ReactNode;
}> = ({ className, padding, children, scrollClassName = "overflow-y-auto" }) => {
  return (
    <div className={cx("min-h-0 flex-1", scrollClassName, padding && cxPaddingStyle[padding], className)}>
      {children}
    </div>
  );
};

export const ModalForm: FC<FormProps> = ({ ...props }) => {
  return <Form {...props} className={cx("min-h-0 flex-1 flex flex-col", props.className)} />;
};

export const ModalContainer: FC<
  PropsWithChildren<{
    centerVertically?: boolean;
    className?: string;
    style?: HTMLAttributes<HTMLDivElement>["style"];
  }>
> = ({ children, className, style, centerVertically }) => {
  return (
    <div
      style={style}
      role="dialog"
      aria-modal
      className={cx(
        "absolute left-1/2 bg-white rounded flex flex-col",
        className,
        centerVertically ? "animate-modalEnterCentered top-1/2" : "animate-modalEnter top-[12.5%]"
      )}
    >
      {children}
    </div>
  );
};

export const ModalFooter: FC<{
  className?: string;
  paddingClassName?: string;
  children?: ReactNode;
  actions?: boolean;
}> = ({ paddingClassName, children, className, actions = true }) => {
  return (
    <div className={cx("border-t-greyLighter border-t flex-none", paddingClassName ?? "py-4", className)}>
      {actions ? <ModalFooterButtons>{children}</ModalFooterButtons> : children}
    </div>
  );
};
