import { ChangeEvent, MouseEvent, ReactNode, Fragment } from "react";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { ValueTypes } from "@libs/components/UI/OptionInput";
import { numericRegex } from "@libs/utils/regex";
import { isOneOf } from "@libs/utils/isOneOf";
import { toggleSet } from "@libs/utils/toggleSet";

export interface OptionInputOption<V extends ValueTypes> {
  value: V;
  label: ReactNode;
  disabled?: boolean;
  error?: string;
}

interface OptionClassNameProps<V extends ValueTypes, T extends OptionInputOption<V>> {
  checked: boolean | undefined;
  disabled: boolean | undefined;
  value: V;
  option: T;
}

interface BaseOptionInputListProps<V extends ValueTypes, T extends OptionInputOption<V>> {
  options: T[];
  name?: string;
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>, option: T) => void;
  className?: string;
  optionClassName?: string | ((props: OptionClassNameProps<V, T>) => string);
  children?: ReactNode;
}

export interface RenderOptionItemProps<V extends ValueTypes> {
  disabled?: boolean;
  error?: string;
  type: "radio" | "checkbox";
  checked?: boolean;
  className?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick: (e: MouseEvent<HTMLElement>) => void;
  name: string;
  value: V;
  children: OptionInputOption<V>["label"];
}

export type Layout = "horiz" | "vert" | "flat" | "custom";

export interface RadioInputListProps<V extends ValueTypes, T extends OptionInputOption<V>>
  extends BaseOptionInputListProps<V, T> {
  selectedValue: V | undefined;
  onChange?: (e: ChangeEvent<HTMLInputElement>, option: T) => void;
  type: "radio";
  parseNumbers?: boolean;
}

export interface CheckboxInputListProps<V extends ValueTypes, T extends OptionInputOption<V>>
  extends BaseOptionInputListProps<V, T> {
  selectedValues: Set<V>;
  onChange?: (updatedSet: Set<V>, e: ChangeEvent<HTMLInputElement>, option: T) => void;
  type: "checkbox";
  parseNumbers?: boolean;
}

type OptionInputListProps<V extends ValueTypes, T extends OptionInputOption<V>> =
  | CheckboxInputListProps<V, T>
  | RadioInputListProps<V, T>;

export const applyOptionClassName = <V extends ValueTypes, T extends OptionInputOption<V>>(
  optionClassName: undefined | string | ((props: OptionClassNameProps<V, T>) => string),
  props: OptionClassNameProps<V, T>
) =>
  optionClassName === undefined || typeof optionClassName === "string"
    ? optionClassName
    : optionClassName(props);

export const OptionInputList = <V extends ValueTypes, T extends OptionInputOption<V>>({
  options,
  className,
  optionClassName,
  disabled,
  renderOptionItem,
  children,
  name,
  parseNumbers = true,
  ...rest
}: OptionInputListProps<V, T> & {
  renderOptionItem: (props: RenderOptionItemProps<V>, index: number, option: T) => ReactNode;
}) => {
  const optionsGroupId = useEnsureId({ customId: name });

  return (
    <div className={className}>
      {options.map((option, index) => {
        const { value, label, disabled: optionDisabled, error: optionError } = option;
        const checked =
          rest.type === "checkbox" ? rest.selectedValues.has(value) : rest.selectedValue === value;
        const disabledProp = disabled || optionDisabled;

        return (
          <Fragment key={typeof value === "boolean" ? String(value) : value}>
            {renderOptionItem(
              {
                error: optionError,
                disabled: disabledProp,
                type: rest.type,
                className: applyOptionClassName(optionClassName, {
                  disabled: disabledProp,
                  checked,
                  value,
                  option,
                }),
                onChange: (e) => {
                  if (rest.type === "checkbox") {
                    const targetValue =
                      parseNumbers && numericRegex.test(e.target.value)
                        ? Number(e.target.value)
                        : isOneOf(e.target.value, ["true", "false"])
                          ? e.target.value === "true"
                          : e.target.value;

                    rest.onChange?.(toggleSet(rest.selectedValues, targetValue), e, option);
                  } else {
                    rest.onChange?.(e, option);
                  }
                },
                onClick: (e) => {
                  rest.onClick?.(e, option);
                },
                checked,
                name: optionsGroupId,
                value,
                children: label,
              },
              index,
              option
            )}
          </Fragment>
        );
      })}
      {children}
    </div>
  );
};
