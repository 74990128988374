import { ChangeEvent, Ref } from "react";
import AvatarEditor, { Position } from "react-avatar-editor";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { half } from "@libs/utils/math";
import { ReactComponent as Trash } from "assets/icons/currentColor/delete.svg";
import { useObjectState } from "hooks/useObjectState";

interface Props {
  onDeleteImage: Func;
  imageFile: File;
  canvasRef: Ref<AvatarEditor>;
  size?: number;
  contentsClassName?: string;
}

export const UploadPhotoEditor: React.FC<Props> = ({
  onDeleteImage,
  imageFile,
  canvasRef,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  size = 310,
  contentsClassName,
}) => {
  const [state, setState] = useObjectState({
    image: "",
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    preview: null,
    width: size,
    height: size,
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    borderRadius: half(size) + 50, // borderRadius*2 - 50 = width OR height for circular image.
  });
  const { t } = useTranslation();

  const handleScale = (e: ChangeEvent<HTMLInputElement>) => {
    const scale = Number.parseFloat(e.target.value);

    setState({ scale });
  };

  const handlePositionChange = (position: Position) => {
    setState({ position });
  };

  return (
    <div>
      <div className="flex flex-wrap items-center justify-center relative">
        <div className="top-3 right-2 absolute cursor-pointer">
          <Trash className="fill-white" onClick={onDeleteImage} />
        </div>
        <AvatarEditor
          ref={canvasRef}
          scale={state.scale}
          width={state.width}
          height={state.height}
          position={state.position}
          onPositionChange={handlePositionChange}
          rotate={state.rotate}
          borderRadius={state.borderRadius}
          image={imageFile}
        />
      </div>
      <div className="text-center mt-3 text-xs">{t("Drag to reposition image")}</div>
      <div className={cx("my-5 mx-auto text-left flex flex-col items-center", contentsClassName)}>
        <div className="flex items-center">
          <div className="w-14">{t("Zoom")}:</div>
          <input
            name="scale"
            type="range"
            onChange={handleScale}
            min={state.allowZoomOut ? "0.1" : "1"}
            max="2"
            step="0.01"
            value={state.scale}
            className="w-[200px] accent-secondaryTheme block"
          />
        </div>
        <div className="flex items-center">
          <div className="w-14">{t("Rotate")}:</div>
          <input
            name="scale"
            type="range"
            onChange={(e) => setState({ rotate: Number.parseInt(e.target.value) })}
            min={-180}
            max={180}
            step={1}
            value={state.rotate}
            className="w-[200px] accent-secondaryTheme block"
          />
        </div>
      </div>
    </div>
  );
};
