import { Link } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { semibold14 } from "assets/styles/textSize";
import { ButtonIconContent } from "components/UI/ButtonIconContent";

const cxStyles = {
  container: "flex py-2 gap-2 items-center",
};

export const ItemContents: React.FC<{
  Icon: IconComponent;
  children: React.ReactNode;
}> = ({ Icon, children }) => {
  return (
    <>
      <ButtonIconContent Icon={Icon} />
      <div className={semibold14}>{children}</div>
    </>
  );
};

export const AccountLinkItem: React.FC<{
  to: string;
  Icon: IconComponent;
  children: React.ReactNode;
  external?: boolean;
  className?: string;
}> = ({ to, external, className, ...rest }) => {
  const containerClassName = cx(className, cxStyles.container);

  return external ? (
    <a className={containerClassName} href={to}>
      <ItemContents {...rest} />
    </a>
  ) : (
    <Link to={to} className={containerClassName}>
      <ItemContents {...rest} />
    </Link>
  );
};

export const AccountButtonItem: React.FC<{
  onClick: Func;
  Icon: IconComponent;
  children: React.ReactNode;
}> = ({ onClick, ...rest }) => {
  return (
    <button type="button" className={cxStyles.container} onClick={onClick}>
      <ItemContents {...rest} />
    </button>
  );
};
