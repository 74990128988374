import React, { ReactNode, useState, useMemo, useEffect, useCallback, TransitionEvent } from "react";
import { createPortal } from "react-dom";
import { cx } from "@libs/utils/cx";
import { useDisableScrollBody } from "hooks/useDisableScrollBody";
import { usePortalElement } from "contexts/PortalContext";
import { FlyoverHeader, FlyoverHeaderProps } from "components/UI/FlyoverComponents";
import { Backdrop } from "components/UI/Backdrop";
import { ClickOutside } from "contexts/ClickOutsideListenerContext";

const cxSizeStyles = {
  xs: "max-w-[24rem]",
  sm: "max-w-[28rem]",
  md: "max-w-[36rem]",
  lg: "max-w-[48rem]",
};

export type FlyoverProps = {
  onClose: () => void;
  onClickAway?: Func;
  children?: ReactNode | ((flyover: { close: Func }) => ReactNode);
  overlay?: ReactNode;
  title?: string;
  size?: keyof typeof cxSizeStyles;
  clickAwayToClose?: boolean;
  dataTestId?: string;
  triggerClose?: boolean;
  headerSize?: FlyoverHeaderProps["size"];
  /**
   * A function that is called when the CSS transition on the flyover component ends.
   *
   * @param e - The `TransitionEvent` object that triggered the function.
   * @param options - An object containing the `show` boolean value that indicates whether the
   * flyover is shown or hidden.
   * @returns void
   */
  onTransitionEnd?: (e: TransitionEvent<HTMLDivElement>, options: { show: boolean }) => void;
};

export const Flyover: React.FC<FlyoverProps> = ({
  onClose,
  onClickAway,
  children,
  overlay = <Backdrop />,
  title,
  size = "sm",
  clickAwayToClose = false,
  triggerClose,
  dataTestId,
  onTransitionEnd,
}) => {
  const [show, setShow] = useState(false);
  const close = useCallback(() => setShow(false), []);
  const contextValue = useMemo(() => ({ close }), [close]);

  const currentPortal = usePortalElement();

  useDisableScrollBody();

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (triggerClose) {
      close();
    }
  }, [triggerClose, close]);

  return (
    <ClickOutside>
      {(tracker) =>
        currentPortal &&
        createPortal(
          <div
            {...tracker}
            className={`
              absolute
              inset-0
              pointer-events-none
              *:pointer-events-auto
              text-greyDark
              z-10
            `}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className={cx("absolute inset-0 transition-opacity", show ? "opacity-100" : "opacity-0")}
              onClick={() => {
                if (clickAwayToClose) {
                  contextValue.close();
                }

                onClickAway?.();
              }}
            >
              {overlay}
            </div>

            <div
              onTransitionEnd={(e) => {
                if (e.currentTarget === e.target && e.propertyName === "transform" && !show) {
                  onClose();
                }

                onTransitionEnd?.(e, { show });
              }}
              className={cx(
                `transition-transform
                 will-change-transform
                 ease-out
                 absolute
                 top-0
                 right-0
                 bottom-0
                 overflow-hidden
                 shadow-[0px_0px_24px_0px_#0000003D]
                 flex
                 flex-col
                 bg-white
                 h-full
                 text-left`,
                show ? "translate-x-0" : "translate-x-full",
                cxSizeStyles[size]
              )}
              data-testid={dataTestId}
            >
              {title && (
                <FlyoverHeader size="sm" onClose={contextValue.close}>
                  {title}
                </FlyoverHeader>
              )}
              {typeof children === "function" ? children(contextValue) : children}
            </div>
          </div>,
          currentPortal
        )
      }
    </ClickOutside>
  );
};
