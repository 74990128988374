import Skeleton from "react-loading-skeleton";
import { FormattedNumber } from "react-intl";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { centsToDollars } from "@libs/utils/currency";
import { getFullUrl } from "@libs/utils/location";
import { useApiQueries } from "api/queries";
import { useCurrentUser } from "hooks/useCurrentUser";
import { getInvoiceByUuid, printInvoice } from "api/billing/queries";
import { useHideTabs } from "contexts/LayoutContext";
import { PathDefinitions, paths } from "utils/paths";

import { TitleBar } from "components/UI/TitleBar";
import { DownloadAssetLink } from "components/UI/DownloadAssetLink";
import { FooterButtons } from "components/Billing/FooterButtons";
import { ButtonInternalLink } from "components/UI/ButtonLink";
import { semibold14 } from "assets/styles/textSize";
import { Cell, InvoiceItems } from "components/Billing/InvoiceDetailsRoute/InvoiceItems";
import { useInvoiceUuid } from "components/Billing/hooks/useInvoiceUuid";
import { Card } from "components/UI/Cards";
import { PracticeHeader } from "components/Billing/PracticeHeader";
import { InvoiceSummary } from "components/Billing/InvoiceSummary";
import { PaymentHistory } from "components/Billing/InvoiceDetailsRoute/PaymentHistory";
import { useSearchQueryParams } from "hooks/useSearchQueryParams";

// eslint-disable-next-line complexity
export const InvoiceDetailsRoute: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  useHideTabs();

  const { practiceId, id: patientId } = useCurrentUser();
  const invoiceUuid = useInvoiceUuid();
  const searchParams = useSearchQueryParams<PathDefinitions.invoiceDetails>();
  const [{ data: invoiceDetails, isLoading }] = useApiQueries([
    getInvoiceByUuid({
      args: {
        practiceId,
        invoiceUuid,
        patientId,
        query: {
          includePracticeInfo: true,
          includePayerInfo: true,
          includeOrders: true,
          includePayments: true,
          paymentStates: ["SETTLED"],
        },
      },
    }),
  ]);

  const [printInvoiceQuery] = useApiQueries([
    printInvoice({
      args: { practiceId, patientId, invoiceUuid },
      queryOptions: { enabled: false },
    }),
  ]);

  return (
    <>
      <div className="relative">
        <TitleBar
          backTo={searchParams.get("from") ?? PathDefinitions.invoices}
          title={t("app.pages.payments.invoice.header")}
          rightContent={<DownloadAssetLink onClickGetAssetLink={printInvoiceQuery.refetch} />}
        />
      </div>
      <div className="w-full bg-white p-4 flex flex-col gap-4">
        <div className="flex flex-col gap-4 w-full max-w-2xl self-center">
          <PracticeHeader address={invoiceDetails?.practiceAddress} />
          <Card inCarousel={false}>
            {isLoading || !invoiceDetails ? (
              <Skeleton count={15} />
            ) : (
              <div>
                <div
                  className={`
                    border-b
                    border-b-1
                    border-b-slate-300
                    py-2
                    gap-2
                    flex
                    flex-col
                  `}
                >
                  <InvoiceSummary invoice={invoiceDetails} />
                  {invoiceDetails.note && (
                    <div className="text-xs">
                      {t("payments.page.description.note", { note: invoiceDetails.note })}
                    </div>
                  )}
                </div>
                <div
                  className={`
                    flex
                    flex-col
                    gap-4
                    py-4
                    border-b
                    border-b-1
                    border-b-slate-300
                  `}
                >
                  {invoiceDetails.entries.map((item, index) => {
                    return <InvoiceItems key={index} item={item} />;
                  })}
                </div>
                <div
                  className={`
                    flex
                    flex-col
                    gap-4
                    py-4
                    border-b
                    border-b-1
                    border-b-slate-300
                  `}
                >
                  <Cell>
                    <div>{t("payments.page.subtotal")}</div>
                    <FormattedNumber
                      value={centsToDollars(invoiceDetails.subtotalAmount ?? 0)}
                      style="currency"
                      currencyDisplay="symbol"
                      currency={invoiceDetails.currency}
                    >
                      {(value: string) => <div className={semibold14}>{value}</div>}
                    </FormattedNumber>
                  </Cell>
                  <Cell>
                    <div>{t("payments.page.discount")}</div>
                    <FormattedNumber
                      value={centsToDollars(invoiceDetails.discountAmount ?? 0)}
                      style="currency"
                      currencyDisplay="symbol"
                      currency={invoiceDetails.currency}
                    >
                      {(value: string) => <div className={semibold14}>{value}</div>}
                    </FormattedNumber>
                  </Cell>
                  <Cell>
                    <div>{t("payments.page.taxCalculated")}</div>
                    <FormattedNumber
                      value={centsToDollars(invoiceDetails.taxAmount ?? 0)}
                      style="currency"
                      currencyDisplay="symbol"
                      currency={invoiceDetails.currency}
                    >
                      {(value: string) => <div className={semibold14}>{value}</div>}
                    </FormattedNumber>
                  </Cell>

                  <Cell>
                    <div>{t("payments.page.total")}</div>
                    <FormattedNumber
                      value={centsToDollars(invoiceDetails.amount)}
                      style="currency"
                      currencyDisplay="symbol"
                      currency={invoiceDetails.currency}
                    >
                      {(value: string) => <div className={semibold14}>{value}</div>}
                    </FormattedNumber>
                  </Cell>
                  <Cell>
                    <div>{t("payments.page.paid")}</div>
                    <FormattedNumber
                      value={centsToDollars(invoiceDetails.amountPaid)}
                      style="currency"
                      currencyDisplay="symbol"
                      currency={invoiceDetails.currency}
                    >
                      {(value: string) => <div className={semibold14}>{value}</div>}
                    </FormattedNumber>
                  </Cell>
                </div>

                <Cell className="pt-4">
                  <div className={semibold14}>{t("payments.page.remaining")}</div>
                  <FormattedNumber
                    value={centsToDollars(invoiceDetails.amount - invoiceDetails.amountPaid)}
                    style="currency"
                    currencyDisplay="symbol"
                    currency={invoiceDetails.currency}
                  >
                    {(value: string) => <div className={cx("text-secondaryTheme", semibold14)}>{value}</div>}
                  </FormattedNumber>
                </Cell>
              </div>
            )}
            <div className={invoiceDetails?.state === "PAID" ? undefined : "h-16"} />
          </Card>
          {invoiceDetails && invoiceDetails.payments.length > 0 && (
            <Card>
              <div className="flex flex-col gap-2">
                <div className={semibold14}>{t("payments.page.history")}</div>
                <PaymentHistory payments={invoiceDetails.payments} invoiceAmount={invoiceDetails.amount} />
              </div>
            </Card>
          )}
        </div>
      </div>
      {invoiceDetails && invoiceDetails.state !== "PAID" && invoiceDetails.amount > 0 && (
        <FooterButtons>
          <ButtonInternalLink
            className="w-full max-w-lg"
            to={paths.pay({ invoiceUuids: [invoiceUuid], from: getFullUrl(location) })}
          >
            {t("app.pages.payments.invoice.pay")}
          </ButtonInternalLink>
        </FooterButtons>
      )}
    </>
  );
};
