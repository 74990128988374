import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { ButtonLink } from "components/UI/ButtonLink";

export const AnonUserMessagePage: React.FC<{
  logoUrl?: string;
  title: ReactNode;
  subTitle: ReactNode;
  homeUrl?: string;
  children?: ReactNode;
  align?: "center" | "left";
  padding?: "p-6" | "";
}> = ({ logoUrl, title, subTitle, homeUrl, children, padding = "p-6", align = "center" }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        "max-w-sm h-full flex text-sm",
        align === "center" ? "justify-center self-center" : "",
        padding
      )}
    >
      <div
        className={cx("flex flex-col", align === "center" ? "justify-center items-center text-center" : "")}
      >
        {logoUrl && (
          <img src={logoUrl} alt={t("Practice Logo")} className="object-contain w-[80px] h-[80px] mb-6" />
        )}
        <div className="font-sansSemiBold">{title}</div>
        {subTitle}
        {children}
        {homeUrl && (
          <ButtonLink theme="link" href={homeUrl} className="mt-7">
            {t("HomePage")}
          </ButtonLink>
        )}
      </div>
    </div>
  );
};
