import { generatePath } from "react-router-dom";
import { isDefined } from "@libs/utils/types";

export enum PathDefinitions {
  signIn = "/signIn",
  signUp = "/signUp",
  forgotPasswordSignedOut = "/forgot-password",
  resetPassword = "/reset-password",
  loggedOutLanding = "/",

  welcomePage = "/welcome",
  waitingRoom = "/waiting-room",
  onboardingDone = "/onboarding/done",
  onboarding = "/onboarding",
  accountSettings = "/account/:setting",
  accountProfile = "/account/profile",

  appointmentDetails = "/appointments/:appointmentId",

  insurances = "/insurances",
  insurancesAdd = "/insurances/new",

  paymentMethods = "/payment-methods",
  paymentMethodsAdd = "/payment-methods/new",

  invoiceDetails = "/invoice-details/:invoiceUuid",
  invoicePay = "/invoice-details/:invoiceUuid/pay",
  pay = "/pay",
  // Tabs
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  home = "/",
  appointments = "/appointments",
  invoices = "/invoices",
  account = "/account",

  // Old public routes, redirect to new public routes
  selfBookingOld = "/self-booking/:practiceUuid",
  previewFormOld = "/form-preview/:practiceUuid/:formUuid",
  formTasksOld = "/form-tasks/:practiceUuid",
  kioskOld = "/kiosk/:practiceUuid",

  // New Public routes
  selfBooking = "/p/:practiceUuid/self-booking",
  previewForm = "/p/:practiceUuid/form-preview/:formUuid",
  formTasks = "/p/:practiceUuid/form-tasks",
  kiosk = "/p/:practiceUuid/kiosk",
  guestCheckout = "/p/:practiceUuid/guest-checkout",
  emailPreferences = "/p/:practiceUuid/email-preferences/:subscriptionUuid",
  confirmAppointment = "p/:practiceUuid/confirm-appointment/:confirmToken",

  externalArchy = "https://www.archy.com",
}
export enum AccountSettings {
  changePassword = "change-password",
  forgotPassword = "forgot-password",
  insurances = "insurances",
  medicalHistory = "medical-history",
  dentalHistory = "dental-history",
  forms = "profile",
}

export enum RouteQueryKeys {
  invoiceUuid = "invoiceUuid",
  patientToken = "patientToken",
  returnUrl = "returnUrl",
}

export type AccountParams = {
  setting: AccountSettings;
};

export type InsuranceParams = {
  insuranceId: string;
};

type FromQuery = {
  from: string;
};

type AppointmentDetailQuery = {
  fullScreen?: "1";
} & FromQuery;

export type PayQuery = {
  invoiceUuids?: string[];
  from?: string;
};
export type InvoiceParams = {
  invoiceUuid: string;
};
export type InvoicesQuery = {
  tab?: "pending" | "completed" | "statements";
};

export type AccountProfileQuery = {
  form: "personal" | "contact";
  edit?: "1";
};

export type AppointmentParams = {
  appointmentId: string;
};

export type PracticeUuidParams = {
  practiceUuid: string;
};
export type SelfBookingParams = PracticeUuidParams;
type EmailParams = { email?: string };
export type SignUpQuery = EmailParams;
export type SignInQuery = { returnUrl?: string } & EmailParams;

export type PreviewFormPathParams = PracticeUuidParams & {
  formUuid: string;
};
export type PreviewFormQuery = {
  age: string;
  gender: string;
  firstName: string;
  lastName: string;
};

export type FormTasksQuery = {
  // Comma delimited string:
  formTaskUuids: string;
  dob?: string;
  print?: string;
};

export type GuestCheckoutQuery = {
  patientToken: string;
};

export type EmailPreferencesParams = PracticeUuidParams & { subscriptionUuid: string };
export type ConfirmAppointmentParams = PracticeUuidParams & { confirmToken: string };

export type FormTasksPathParams = PracticeUuidParams;
export type KioskPathParams = PracticeUuidParams;
export type WelcomePageQuery = { uuid?: string } & EmailParams;
export type AddPaymentMethodQuery = { successUrl?: string; from?: string };
export type PathConfigs = {
  [PathDefinitions.signIn]: { query?: SignInQuery };
  [PathDefinitions.signUp]: { query?: SignUpQuery };

  [PathDefinitions.welcomePage]: { query?: WelcomePageQuery };
  [PathDefinitions.accountSettings]: { params: AccountParams; query?: undefined };

  [PathDefinitions.appointmentDetails]: { params: AppointmentParams; query?: AppointmentDetailQuery };
  [PathDefinitions.invoices]: { query?: InvoicesQuery };
  [PathDefinitions.invoiceDetails]: { params: InvoiceParams; query?: FromQuery };
  [PathDefinitions.accountProfile]: { query: AccountProfileQuery };
  [PathDefinitions.guestCheckout]: { query: GuestCheckoutQuery };

  [PathDefinitions.pay]: { query?: PayQuery };
  [PathDefinitions.paymentMethodsAdd]: { query?: AddPaymentMethodQuery };
  [PathDefinitions.previewForm]: { params: PreviewFormPathParams; query?: PreviewFormQuery };
  [PathDefinitions.selfBooking]: { params: SelfBookingParams; query?: { messageCampaignUuid?: string } };
  [PathDefinitions.formTasks]: { params: FormTasksPathParams; query?: FormTasksQuery };
  [PathDefinitions.kiosk]: { params: KioskPathParams; query?: undefined };
  [PathDefinitions.emailPreferences]: { parmas: EmailPreferencesParams; query?: undefined };
  [PathDefinitions.confirmAppointment]: {
    params: ConfirmAppointmentParams;
    query?: undefined;
  };
};

type Query = Record<string, string | string[]>;
type PathConfig = {
  params?: {
    [s: string]: string;
  };
  query?: Query;
};

const buildUrl = (path: PathDefinitions, config?: PathConfig) => {
  const formattedPath = config?.params ? generatePath(path as string, config.params) : path;
  const configQuery = config?.query;

  if (configQuery) {
    const stringQueryParams: Record<string, string> = {};

    for (const [key, value] of Object.entries(configQuery)) {
      stringQueryParams[key] = Array.isArray(value) ? value.join(",") : value;
    }

    const params = new URLSearchParams(stringQueryParams);

    params.sort();

    const query = params.toString();

    if (query.length) {
      return `${formattedPath}?${query}`;
    }
  }

  return formattedPath;
};

export const getPath = <P extends keyof PathConfigs>(path: P, config: PathConfigs[P]) => {
  if (Object.values(config.query ?? {}).some(isDefined)) {
    return buildUrl(path, config);
  }

  return buildUrl(path, { ...config, query: undefined });
};

export const paths = {
  signIn: (query?: SignInQuery) => getPath(PathDefinitions.signIn, { query }),
  signUp: (query?: SignUpQuery) => getPath(PathDefinitions.signUp, { query }),
  resetPassword: () => PathDefinitions.resetPassword,
  loggedInRedirect: () => PathDefinitions.home,
  waitingRoom: () => PathDefinitions.waitingRoom,
  welcomePage: (query?: WelcomePageQuery) => getPath(PathDefinitions.welcomePage, { query }),
  appointments: () => PathDefinitions.appointments,
  appointmentDetails: (params: AppointmentParams, query: AppointmentDetailQuery) =>
    getPath(PathDefinitions.appointmentDetails, { params, query }),
  invoices: (query?: InvoicesQuery) => getPath(PathDefinitions.invoices, { query }),
  invoiceDetails: (params: InvoiceParams, query?: FromQuery) =>
    getPath(PathDefinitions.invoiceDetails, { params, query }),
  pay: (query?: PayQuery) => getPath(PathDefinitions.pay, { query }),
  accountSetting: (params: AccountParams) => getPath(PathDefinitions.accountSettings, { params }),
  accountProfile: (query: AccountProfileQuery) => getPath(PathDefinitions.accountProfile, { query }),
  addInsurance: () => PathDefinitions.insurancesAdd,
  addPaymentMethod: (query?: AddPaymentMethodQuery) =>
    Object.values(query ?? {}).some(isDefined)
      ? getPath(PathDefinitions.paymentMethodsAdd, { query })
      : PathDefinitions.paymentMethodsAdd,
  selfBooking: (params: SelfBookingParams) => getPath(PathDefinitions.selfBooking, { params }),
  formTasks: (params: FormTasksPathParams, query?: FormTasksQuery) =>
    getPath(PathDefinitions.formTasks, { params, query }),
  kiosk: (params: KioskPathParams) => getPath(PathDefinitions.kiosk, { params }),
};
