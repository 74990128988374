import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { ReactComponent as OnboardingHomeLogo } from "assets/icons/OnboardingHomeBackground.svg";
import { useCurrentUser } from "hooks/useCurrentUser";
import { usePracticeInfo } from "hooks/usePracticeInfo";
import { Button } from "components/UI/Button";
import { normal14, normal20 } from "assets/styles/textSize";

type Props = {
  onCompleted: Func;
};

export const OnboardingHome: React.FC<Props> = ({ onCompleted }) => {
  const { fullName } = useCurrentUser();
  const { practice } = usePracticeInfo();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full px-6 py-8 gap-4">
      <div className="text-secondaryTheme text-2xl">
        {t("app.pages.user.salutation", {
          patientName: fullName,
        })}
      </div>
      <div className={cx("text-secondaryTheme", normal20)}>
        {t("app.page.messages.welcomeToExclaimed", { practiceName: practice?.name })}
      </div>
      <div>
        <OnboardingHomeLogo />
      </div>
      <div>
        <div className={normal14}>{t("app.page.messages.answer.few.questions.for.profile")}</div>
      </div>
      <div className="w-full flex justify-center">
        <Button className="rounded-full w-full mt-4 max-w-lg" onClick={onCompleted}>
          {t("Get Started")}
        </Button>
      </div>
    </div>
  );
};
