import React, { forwardRef } from "react";

import { cx } from "@libs/utils/cx";
import { isString } from "@libs/utils/types";
import { ButtonIconContent, ButtonIconContentProps } from "components/UI/ButtonIconContent";
import { Spinner } from "components/UI/Spinner";

export const cxStyles = {
  container: (props?: { disabled?: boolean }) =>
    cx("block w-fit", props?.disabled ? "cursor-default" : "cursor-pointer"),
};

export type ButtonIconProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonIconContentProps & {
    iconGlyphClassName?: string;
  };

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
  (
    {
      Icon,
      theme,
      size,
      className,
      iconClassName,
      iconGlyphClassName,
      tooltip,
      loading = false,
      type = "button",
      disabled,
      ...buttonAttrs
    },
    ref
  ) => {
    if (loading) {
      return (
        <Spinner
          size={["sm", "xs"].includes(size ?? "sm") ? "sm" : "md"}
          variant="primary"
          animation="border"
        />
      );
    }

    return (
      <button
        ref={ref}
        //  eslint-disable-next-line react/button-has-type
        type={type}
        disabled={disabled}
        className={cx(cxStyles.container({ disabled }), className)}
        aria-label={isString(tooltip?.content) ? tooltip.content : undefined}
        {...buttonAttrs}
      >
        <ButtonIconContent
          Icon={Icon}
          theme={theme}
          size={size}
          className={iconClassName}
          iconClassName={iconGlyphClassName}
          disabled={Boolean(disabled)}
          tooltip={tooltip}
          tooltipToAriaLabel={false}
        />
      </button>
    );
  }
);
