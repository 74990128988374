import {
  DAY_IN_HOURS,
  HOUR_IN_MINUTES,
  MINUTE_IN_SECONDS,
  SECOND_IN_MS,
  YEAR_IN_DAYS,
} from "@libs/utils/date";

import { createStorage } from "storage/createStorage";
import { StorageNamespaces } from "storage/namespaces";

export type SelectedAccountV1 = {
  patientId: number;
  thumbnail?: string;
};

const year = YEAR_IN_DAYS * DAY_IN_HOURS * HOUR_IN_MINUTES * MINUTE_IN_SECONDS * SECOND_IN_MS;

const accountsVersion = "v1";
const accountsKey = "accountSelected";

const accountsStorage = createStorage(StorageNamespaces.accounts);

export const getAccountSelected = () => {
  return accountsStorage.get<SelectedAccountV1>(accountsKey, accountsVersion);
};

export const setAccountSelected = (account: SelectedAccountV1 | null) => {
  return accountsStorage.set(accountsKey, account, {
    expires: year,
    version: accountsVersion,
  });
};

const oldStoreTokenKey = "SIGNIN_DETAIL";
const migrateFromOldStore = () => {
  const signinDetailJson = localStorage.getItem(oldStoreTokenKey);

  if (signinDetailJson !== null) {
    const oldAccountData = JSON.parse(signinDetailJson) as {
      patientId?: number;
      thumbnail?: string;
    };

    if (oldAccountData.patientId !== undefined) {
      setAccountSelected(oldAccountData as SelectedAccountV1);
    }
  }

  localStorage.removeItem(oldStoreTokenKey);
};

migrateFromOldStore();
