import React from "react";

import { useSearchParams } from "react-router-dom";

import { email as isValidEmail } from "@libs/utils/validators";

export const useEmailQueryParam = () => {
  const [searchParams] = useSearchParams();
  const emailQueryParamValue = searchParams.get("email");

  return React.useMemo(() => {
    if (isValidEmail(emailQueryParamValue)) {
      return emailQueryParamValue;
    }

    return undefined;
  }, [emailQueryParamValue]);
};
