import { createContext, useContext } from "react";
import { PracticeInfoVO, UserInfoVO } from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";
import { SelectedAccountV1 } from "storage/accountSelected";

export interface UserContextValue {
  users: UserInfoVO[];
  currentUser: UserInfoVO;
  practice: PracticeInfoVO;
  refetchUser: () => void;
  refetchPractice: () => void;
  selectAccount: (acct: SelectedAccountV1 | null) => void;
}

const Context = createContext<UserContextValue>({
  currentUser: {} as UserInfoVO,
  users: [],
  practice: {} as PracticeInfoVO,
  refetchUser: noop,
  refetchPractice: noop,
  selectAccount: noop,
});

Context.displayName = "UserContext";
export const UserContext = Context;
export const useUserContext = () => useContext(UserContext);
