import { useParams } from "react-router-dom";
import { UpdateMessageSubscriptionRequest } from "@libs/api/generated-api";
import { formatAddress } from "@libs/utils/address";
import { useApiMutations } from "api/mutations";
import { useApiQueries } from "api/queries";
import { getPracticeInfo } from "api/user/queries";
import { QueryResult } from "components/UI/QueryResult";
import { EmailPreferencesParams } from "utils/paths";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { useHandleError } from "api/handleErrorResponse";
import { PoweredBy } from "components/UI/PoweredBy";
import { EmailPreferencesForm } from "components/Account/EmailPreferencesForm";
import { EmailPreferencesUpdated } from "components/Account/EmailPreferencesUpdated";
import { getMessageSubscription } from "api/patient/queries";
import { updateMessageSubscription } from "api/patient/mutations";

export const EmailPreferencesRoute: React.FC = () => {
  const handleError = useHandleError();
  const { practiceUuid, subscriptionUuid } = useParams<EmailPreferencesParams>();
  // It's gauranteed if we are on this route.
  const uuid = practiceUuid as string;
  const messageSubscriptionUuid = subscriptionUuid as string;

  const [practiceQuery, messageSubscriptionQuery] = useApiQueries([
    getPracticeInfo({
      args: {
        practiceUuid: uuid,
      },
    }),
    getMessageSubscription({
      args: {
        practiceUuid: uuid,
        messageSubscriptionUuid,
      },
    }),
  ]);
  const [updateMessageSubscriptionMutation] = useApiMutations([updateMessageSubscription]);

  const handleSubmit = (updates: UpdateMessageSubscriptionRequest) => {
    updateMessageSubscriptionMutation.mutate(
      {
        practiceUuid: uuid,
        messageSubscriptionUuid,
        updates,
      },
      {
        onError: handleError,
      }
    );
  };

  const logoUrl = practiceQuery.data?.logo?.url;
  const address = formatAddress(practiceQuery.data?.addressDetails);

  return (
    <div className="h-full bg-white flex flex-col justify-stretch">
      {updateMessageSubscriptionMutation.isSuccess ? (
        <EmailPreferencesUpdated onBack={updateMessageSubscriptionMutation.reset} logoUrl={logoUrl} />
      ) : (
        <>
          <div className="bg-slate-50 flex-none">
            <div className="max-w-xl mx-auto min-h-[160px] p-6">
              <QueryResult queries={[practiceQuery]}>
                {practiceQuery.data ? (
                  <AnonUserMessagePage
                    align="left"
                    padding=""
                    logoUrl={practiceQuery.data.logo?.url}
                    title={practiceQuery.data.name}
                    subTitle={
                      <div>
                        <div>{address.lineOne}</div>
                        <div>{address.lineTwo}</div>
                      </div>
                    }
                  />
                ) : null}
              </QueryResult>
            </div>
          </div>
          <div className="flex-1 min-h-0 overflow-y-auto">
            <div className="max-w-xl mx-auto p-6 flex flex-col gap-y-10 h-full">
              <QueryResult queries={[messageSubscriptionQuery]}>
                {messageSubscriptionQuery.data ? (
                  <EmailPreferencesForm
                    onSubmit={handleSubmit}
                    isSaving={updateMessageSubscriptionMutation.isLoading}
                    preferences={messageSubscriptionQuery.data}
                  />
                ) : null}
                <div className="flex justify-center">
                  <PoweredBy className="flex flex-col items-center" textClassName="block" />
                </div>
              </QueryResult>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
