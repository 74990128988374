import { useTranslation } from "react-i18next";
import OTPInput from "otp-input-react";
import { FormEvent, useCallback, useState } from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { Form } from "components/UI/Form";
import { Button } from "components/UI/Button";
import { FormFieldError } from "components/UI/FormFieldError";

type Props = {
  practice: PracticeInfoVO;
  onSubmit: (pin: string) => void;
  error?: string;
  onChange: (pin: string) => void;
};

const OTP_LENGTH = 4;

export const KioskPinForm: React.FC<Props> = ({ practice, onSubmit, error, onChange }) => {
  const { t } = useTranslation();
  const [pinInput, setPinInput] = useState("");

  const handleInputChanged = useCallback(
    (pin: string) => {
      onChange(pin);
      setPinInput(pin);
    },
    [onChange]
  );
  const handleOneTimeCodeSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit(pinInput);
    },
    [onSubmit, pinInput]
  );

  return (
    <div className="flex flex-col h-full items-center">
      <AnonUserMessagePage
        title={t("app.page.messages.welcomeTo", { practiceName: practice.name })}
        subTitle={t("kiosk.pin")}
        logoUrl={practice.logo?.url}
      >
        <Form
          id="one-timecode-form"
          className="mt-8 flex flex-col items-center justify-center gap-8"
          onSubmit={handleOneTimeCodeSubmit}
        >
          {error && <FormFieldError>{error}</FormFieldError>}
          <OTPInput
            autoFocus
            disabled={false}
            inputClassName="border border-greyLight w-10 mr-2.5 box-border rounded-[5px]"
            // Styles set in `inputStyles` take precedence over
            // `inputClassName` and we need to override the built-in
            // defaults which forces us to style here and in the CSS class.
            inputStyles={{ height: "40px" }}
            onChange={handleInputChanged}
            OTPLength={OTP_LENGTH}
            otpType="string"
            placeholder="----"
            value={pinInput}
          />

          <Button theme="link" type="submit" disabled={pinInput.length < OTP_LENGTH}>
            {t("Next")}
          </Button>
        </Form>
      </AnonUserMessagePage>
    </div>
  );
};
