/* eslint-disable @typescript-eslint/no-magic-numbers */

const AFTER_AREA_CODE = 3;
const BEFORE_LAST_SECTION = 6;

/**
 * Formats a string of phone number digits (full (10) or partial) to a US phone
 * number (XXX) XXX-XXXX
 *
 * @param value a string of 10 digits
 * @returns a formatted phone number in US format
 */
export const phoneNumberFormatter = (value?: string) => {
  if (!value) {
    return "";
  }

  const characters = [...value];
  let formatted = "(";

  for (const [index, char] of characters.entries()) {
    if (index === AFTER_AREA_CODE) {
      formatted += `) ${char}`;
    } else if (index === BEFORE_LAST_SECTION) {
      formatted += `-${char}`;
    } else {
      formatted += char;
    }
  }

  return formatted;
};
export const tConvert = (time: string) => {
  // Check correct time format and split into components
  const matches = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (matches.length > 1) {
    // If time format correct

    matches[4] = Number(matches[1]) < 12 ? " AM" : " PM"; // Set AM/PM
    matches[1] = `${Number(matches[1]) % 12}` || "12"; // Adjust hours
  }

  return matches.slice(1).join(""); // return adjusted time or original string
};
