import { useTranslation } from "react-i18next";
import { formatCurrency } from "@libs/utils/currency";
import { formatDate } from "@libs/utils/date";
import { normal14, semibold16, semibold20 } from "assets/styles/textSize";

export const PatientBalanceAsOf: React.FC<{ value: number; asOfDate: Date }> = ({ value, asOfDate }) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-col gap-2">
        <div className={semibold16}>{t("Patient Balance")}</div>
        <div className={normal14}>
          {t("app.billing.paymentAsOfDate", {
            date: formatDate(asOfDate),
            interpolation: { escapeValue: false },
          })}
        </div>
      </div>
      <div className={semibold20}>{formatCurrency(value)}</div>
    </div>
  );
};
