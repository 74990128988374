import { StorageNamespaces } from "storage/namespaces";
import { createStorage } from "storage/createStorage";

const schedulingStorage = createStorage(StorageNamespaces.scheduling);
const version = "v1";

export const getLastVisitedDate = () => {
  return schedulingStorage.get<string>("lastVisitedDate", version);
};

export const clearLastVisitedDate = () => {
  schedulingStorage.clear("lastVisitedDate");
};
