import Skeleton from "react-loading-skeleton";
import { cx } from "@libs/utils/cx";
import { semibold14, semibold20 } from "assets/styles/textSize";
import { PoweredBy } from "components/UI/PoweredBy";
import { usePracticeInfo } from "hooks/usePracticeInfo";
import { formatThumbnailData } from "utils/user";

export type Props = {
  size?: "sm" | "lg";
  includePoweredBy?: boolean;
};

const cxStyles = {
  text: "text-slate-900",
};

// eslint-disable-next-line complexity
export const PracticeBranding: React.FC<Props> = ({ size = "lg", includePoweredBy = true }) => {
  const { practice, isLoading } = usePracticeInfo();
  const thumbnail = formatThumbnailData(practice?.logo?.thumbnail);

  if (practice || isLoading) {
    return (
      <div className="flex gap-2 items-center">
        {isLoading || !practice ? (
          <Skeleton className="w-10 h-10" inline />
        ) : (
          thumbnail && (
            <img
              src={thumbnail}
              alt={practice.name}
              className={cx("rounded-sm", size === "lg" ? "h-10 w-10" : "h-7 w-7 border-greyLightest border")}
            />
          )
        )}
        <div className="flex flex-col">
          {isLoading || !practice ? (
            <Skeleton className="w-36 mb-1" inline />
          ) : (
            <div className={cx(cxStyles.text, size === "lg" ? semibold20 : semibold14)}>{practice.name}</div>
          )}
          {includePoweredBy && <PoweredBy className={cxStyles.text} size={size} />}
        </div>
      </div>
    );
  }

  return <PoweredBy className={cxStyles.text} size={size} />;
};
