import React from "react";
import { FormVO, PersonalDetailsVO } from "@libs/api/generated-api";
import { canPatientViewSection, filterForm } from "components/PatientForms/utils";
import { PatientResponses } from "components/PatientForms/hooks/usePatientResponses";

export const useFilteredForm = ({
  form,
  patientAttributes,
}: {
  edit: boolean;
  form: FormVO;
  patientAttributes: Pick<PersonalDetailsVO, "age" | "gender">;
  responses: PatientResponses;
}) => {
  return React.useMemo(() => {
    return filterForm(form, (element) => {
      if (element.type === "SECTION") {
        return canPatientViewSection(patientAttributes, element);
      }

      return true;
    });
  }, [form, patientAttributes]);
};
