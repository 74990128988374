import React from "react";
import { cx } from "@libs/utils/cx";
import { ReactComponent as InfoIcon } from "assets/icons/currentColor/info.svg";
import { NamedField } from "components/UI/NamedField";
import { SignatureRequiredDisclosure } from "components/Onboarding/SignatureRequiredDisclosure";
import { Checkbox } from "components/UI/Checkbox";
import { UseSignature } from "components/Onboarding/hooks/useSignature";
import { normal10 } from "assets/styles/textSize";
import { SignaturePad } from "components/UI/SignaturePad";

const ErrorText: React.FC<{ children?: React.ReactNode; className?: string }> = ({ children, className }) => {
  if (!children) {
    return null;
  }

  return (
    <div className={cx("text-red flex gap-1", normal10, className)}>
      <InfoIcon className="w-4 h-4" />
      <div>{children}</div>
    </div>
  );
};

type Props = {
  checkboxText: string;
  onUpdateSignature: UseSignature["updateSignature"];
  signature: UseSignature["signature"];
  validation: UseSignature["validation"];
  title?: string;
  className?: string;
  id: string;
};
export const SignedRelease: React.FC<Props> = ({
  checkboxText,
  onUpdateSignature,
  signature,
  validation,
  title,
  id,
  className,
}) => {
  const checkboxContents = (
    <>
      <div className="flex flex-row gap-2 items-center">
        <Checkbox
          name={checkboxText}
          onChange={(e) => onUpdateSignature({ checked: e.target.checked })}
          id={id}
          checked={signature.checked}
        >
          {checkboxText}
        </Checkbox>
      </div>
      <ErrorText className="pt-1">{validation.result.checked.$error}</ErrorText>
    </>
  );

  return (
    <div className={cx("flex flex-col", className)}>
      <div className="flex flex-col gap-4">
        {title ? (
          <NamedField title={title} required>
            {checkboxContents}
          </NamedField>
        ) : (
          checkboxContents
        )}

        <SignatureRequiredDisclosure />

        <ErrorText>{validation.result.signatureText.$error}</ErrorText>
        <SignaturePad
          id={`signed-release-${id}`}
          onChangeSignature={(text: string) => onUpdateSignature({ signatureText: text })}
          value={signature.signatureText}
        />
      </div>
    </div>
  );
};
