import React from "react";
import { PatientResponses, ResponseChangedCallback } from "components/PatientForms/hooks/usePatientResponses";
import { CheckboxList } from "components/UI/CheckboxList";
import { OTHER_ID } from "components/PatientForms/utils";
import { ReadOnlyOptions } from "components/PatientForms/FormElements/ReadOnlyOptions";

type Props = {
  id: string;
  edit: boolean;
  responsesById: PatientResponses;
  error?: string;
  onChangeResponse: ResponseChangedCallback;
  onToggledOther: (checked: boolean) => void;
  options: { label: string | React.ReactNode; value: string }[];
  required?: boolean;
  selectedOptions: Set<string>;
  uuid: string;
  title: string;
};

export const PatientFormSelectMultipleElement: React.FC<Props> = ({
  id,
  edit,
  uuid,
  title,
  responsesById,
  onChangeResponse,
  onToggledOther,
  options,
  required,
  error,
  selectedOptions,
}) => {
  const prevResponse = responsesById[uuid];
  const prevSelectResponse = prevResponse?.type === "SELECT" ? prevResponse : undefined;

  const sharedProps = {
    required,
    id,
    className: "max-w-23rem",
    edit,
    menuPortalTarget: document.body,
    name: uuid,
    isClearable: !required,
    isSearchable: false,
    label: title,
  };
  const handleCheckboxToggled = React.useCallback(
    (updatedSet: Set<string>) => {
      const responses = Object.fromEntries(
        [...updatedSet].filter((item) => item !== OTHER_ID).map((value) => [value, true])
      );
      const didToggleOtherOn = updatedSet.has(OTHER_ID) && !selectedOptions.has(OTHER_ID);
      const didToggleOtherOff = !updatedSet.has(OTHER_ID) && selectedOptions.has(OTHER_ID);

      if (didToggleOtherOn || didToggleOtherOff) {
        onToggledOther(didToggleOtherOn);
      }

      onChangeResponse(uuid, {
        type: "SELECT",
        responses,
        other: didToggleOtherOn ? "" : didToggleOtherOff ? undefined : prevSelectResponse?.other,
      });
    },
    [selectedOptions, onChangeResponse, uuid, prevSelectResponse?.other, onToggledOther]
  );

  return edit ? (
    <CheckboxList
      {...sharedProps}
      labelTheme="regular"
      error={error}
      options={options}
      selectedValues={selectedOptions}
      layout="vert"
      onChange={handleCheckboxToggled}
    />
  ) : (
    <ReadOnlyOptions
      required={required}
      selected={[...selectedOptions]}
      otherResponse={prevSelectResponse?.other}
      title={title}
    />
  );
};
