import React from "react";
import { AddressVO } from "@libs/api/generated-api";
import { PracticeBranding } from "components/UI/PracticeBranding";
import { usePracticeInfo } from "hooks/usePracticeInfo";
import { ReadOnlyAddress } from "components/UI/ReadOnlyAddress";

export const PracticeHeader: React.FC<{ address?: AddressVO }> = ({ address }) => {
  const { practice } = usePracticeInfo();

  const addressDetails = address || practice?.addressDetails;

  if (!addressDetails) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <PracticeBranding size="sm" includePoweredBy={false} />
      <ReadOnlyAddress addressDetails={addressDetails} className="text-xs" />
    </div>
  );
};
