/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useScript from "react-script-hook";
import { useTranslation } from "react-i18next";
import { colors } from "@libs/design.config";
import { required, eq } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useObjectState } from "hooks/useObjectState";

export const submitFinixForm = (
  form: FinixFormV1,
  environment: FinixEnvironment,
  applicationId: string,
  formSubmissionCallback: FinixFormSubmissionCallback
) => {
  form.submit(environment, applicationId, formSubmissionCallback);
};

export type CardDraftState = {
  isFSACard?: boolean;
  isDefaultCard: boolean;
  isChargingAuthorized: boolean;
};

const FINIX_ELEMENT_ID = "form-element";

export const useCardDraft = (options: { includeAcknowedgeFuturePayments?: boolean }) => {
  const { t } = useTranslation();
  const [cardDraftState, updateCardDraft] = useObjectState<CardDraftState>({
    isDefaultCard: false,
    isFSACard: undefined,
    isChargingAuthorized: false,
  });
  const schema = useMemo(() => {
    const requiredError = t("required");

    return {
      isFSACard: [{ $v: required, $error: requiredError }],
      isChargingAuthorized: [
        {
          $v: eq(true),
          $error: t("Please authorize card to continue."),
          $ignore: !options.includeAcknowedgeFuturePayments,
        },
      ],
    };
  }, [options.includeAcknowedgeFuturePayments, t]);
  const validation = useValidation(cardDraftState, schema);

  return {
    validation,
    updateCardDraft,
    cardDraftState,
  };
};

export const useFinixForm = ({
  finixEnvironment,
  applicationId,
  formSubmissionHandler,
}: {
  finixEnvironment: FinixEnvironment;
  applicationId: string;
  formSubmissionHandler: FinixFormSubmissionCallback;
}) => {
  const { t } = useTranslation();
  const finixFormRef = useRef<FinixFormV1 | null>(null);
  const domElementLoaded = useBoolean(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formState, setFormState] = useState<FinixFormState>();
  const [formInvalid, setFormInvalid] = useState(true);
  const [binInformation, setBinInformation] = useState<FinixBinInformation>();
  const [initialized, setInitialized] = useState(false);
  const formChangedHandler: FinixFormChangedHandler = useCallback(
    (updatedFormState, updatedBinInformation, formHasErrors) => {
      setFormState(updatedFormState);

      if (updatedBinInformation) {
        setBinInformation(updatedBinInformation);
      }

      setFormInvalid(formHasErrors);
    },
    []
  );
  const [loading, error] = useScript({
    src: "https://js.finix.com/v/1/finix.js",
    checkForExisting: true,
  });

  useEffect(() => {
    if (loading || error || finixFormRef.current || domElementLoaded.isOff) {
      return;
    }

    finixFormRef.current = window.Finix.CardTokenForm(FINIX_ELEMENT_ID, {
      styles: {
        default: {
          fontFamily: "'Open Sans', 'Helvetica Neue', sans-serif",
          color: colors.greyDark,
          border: `1px solid ${colors.greyLightest}`,
          borderRadius: "0.375rem",
          padding: "0 16px",
          fontSize: "0.875rem",
        },
      },
      placeholders: {
        name: t("card.details.name.placeholder"),
        number: t("card.details.number.placeholder"),
        expiration_date: t("card.details.date.placeholder"),
        security_code: t("card.details.security_code.placeholder"),
      },
      labels: {
        name: t("card.details.name"),
        number: t("card.details.number"),
        expiration_date: t("card.details.date"),
        security_code: t("card.details.security_code"),
      },
      hideErrorMessages: true,
      onUpdate: formChangedHandler,
      onLoad: () => {
        setInitialized(true);
      },
    });
  }, [error, loading, formChangedHandler, initialized, t, domElementLoaded.isOff]);

  const isFormValid = Boolean(formState && !formInvalid);

  const submitFormWrapper = useCallback(() => {
    setIsSubmitting(true);
    submitFinixForm(finixFormRef.current as FinixFormV1, finixEnvironment, applicationId, (err, res) => {
      setIsSubmitting(false);
      formSubmissionHandler(err, res);
    });
  }, [applicationId, finixEnvironment, formSubmissionHandler]);

  return useMemo(
    () => ({
      submitForm: finixFormRef.current && initialized ? submitFormWrapper : undefined,
      formState,
      binInformation,
      isFormValid,
      loadingFinix: loading || !initialized,
      handleDomRef: domElementLoaded.on,
      isSubmitting,
      elementId: FINIX_ELEMENT_ID,
      failedLoadingFinixScript: error,
    }),
    [
      binInformation,
      domElementLoaded.on,
      error,
      formState,
      initialized,
      isFormValid,
      isSubmitting,
      loading,
      submitFormWrapper,
    ]
  );
};
export type UseCardDraft = ReturnType<typeof useCardDraft>;
export type UseFinixForm = ReturnType<typeof useFinixForm>;
