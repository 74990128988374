import React from "react";
import { FormFieldSsnInput } from "components/UI/FormFieldSsnInput";
import { FormFieldRevealSsnInput, FormFieldRevealSsnInputProps } from "components/UI/FormFieldRevealSsnInput";

export type FormFieldUserSsnInputProps = Omit<FormFieldRevealSsnInputProps, "ssnLastFour"> & {
  ssnLastFour?: string;
};

export const FormFieldUserSsnInput: React.FC<FormFieldUserSsnInputProps> = ({
  userId,
  onSsnError,
  ...props
}) => {
  return props.ssnLastFour ? (
    <FormFieldRevealSsnInput
      {...props}
      userId={userId}
      onSsnError={onSsnError}
      ssnLastFour={props.ssnLastFour}
    />
  ) : (
    <FormFieldSsnInput {...props} />
  );
};
