import { cx } from "@libs/utils/cx";
import { BaseOptionInputProps, OptionInput } from "@libs/components/UI/OptionInput";

export type RadioProps = Omit<BaseOptionInputProps, "name"> & { name: string };

const cxStyles = {
  container: ({ disabled }: { disabled?: boolean }) => cx("flex items-center", !disabled && "cursor-pointer"),
  fake: `
    flex-none
    inline
    align-bottom
    w-4
    h-4
    rounded-full
    border
    border-greyLight
    peer-hover:border-greyDark
    peer-focus:border-greyDark
    peer-disabled:border-greyLighter
    peer-checked:border-primaryTheme
    peer-checked:peer-hover:border-primaryTheme
    peer-checked:peer-focus:border-primaryTheme
    peer-checked:peer-disabled:border-greyLight
    peer-disabled:bg-transparent
    peer-checked:bg-selected-radio-gradient
    peer-checked:peer-disabled:bg-disabled-selected-radio-gradient
  `,
  darkFake: `
    dark:peer-disabled:border-greyMedium
    dark:peer-hover:border-greyLightest
    dark:peer-focus:border-greyLightest
    dark:peer-checked:border-greyMedium
    dark:peer-checked:peer-hover:border-greyLight
    dark:peer-checked:peer-focus:border-greyLight
    dark:peer-checked:peer-disabled:border-greyLight
    dark:peer-disabled:bg-transparent
    dark:peer-checked:bg-dark-selected-radio-gradient
  `,
  content: `
    text-sm
    ml-1.5
    block
    peer-checked:text-primaryTheme
    peer-disabled:text-greyLight
    peer-checked:peer-disabled:text-greyLight
  `,
  darkContent: `
    dark:text-greyLightest
    dark:peer-checked:text-greyLightest
    dark:peer-checked:peer-disabled:text-greyLightest
  `,
};

export const Radio: React.FC<RadioProps> = ({ className, includeDarkMode = true, ...props }) => {
  return (
    <OptionInput
      className={cx(cxStyles.container({ disabled: props.disabled }), className)}
      contentClassName={cx(cxStyles.content, includeDarkMode && cxStyles.darkContent)}
      type="radio"
      customContent={<span className={cx(cxStyles.fake, includeDarkMode && cxStyles.darkFake)} />}
      {...props}
    />
  );
};
