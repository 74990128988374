import { cx } from "@libs/utils/cx";
import { ReactComponent as BackIcon } from "assets/icons/currentColor/left-caret.svg";
import { normal12, semibold16 } from "assets/styles/textSize";
import { InternalLink } from "components/UI/Link";

type Props = {
  backTo: string;
  title: React.ReactNode;
  heightClass?: string;
  children?: React.ReactNode;
  rightContent?: React.ReactNode;
  titleDisclosure?: string;
  responsiveBackButton?: boolean;
};
export const TitleBar: React.FC<Props> = ({
  backTo,
  title,
  heightClass = "h-12",
  children,
  titleDisclosure,
  rightContent,
  responsiveBackButton = false,
}) => {
  return (
    <>
      <div
        className={cx(
          `shadow-[2px_2px_4px_rgba(188,188,188,0.25)]
           absolute
           top-0
           left-0
           right-0
           z-0
           flex
           flex-col
           justify-center
           bg-white
           px-3`,
          heightClass
        )}
      >
        <div className="items-center flex justify-between">
          <div className="items-center flex gap-2 flex-1">
            <InternalLink className={responsiveBackButton ? "sm:hidden" : undefined} to={backTo}>
              <BackIcon />
            </InternalLink>
            <div className={semibold16}>{title}</div>
            <div className={normal12}>{titleDisclosure}</div>
          </div>
          {rightContent}
        </div>
        {children}
      </div>
      <div className={cx("flex-none", heightClass)} />
    </>
  );
};
