import { forwardRef } from "react";
import { cx } from "@libs/utils/cx";
import { BaseOptionInputProps, OptionInput } from "@libs/components/UI/OptionInput";
import { ReactComponent as CheckedIcon } from "assets/icons/currentColor/checked-checkbox.svg";
import { ReactComponent as UnCheckedIcon } from "assets/icons/currentColor/unchecked-checkbox.svg";
import { ReactComponent as IntermediateCheckedIcon } from "assets/icons/currentColor/multiple-selected-checkbox.svg";

type CheckboxSize = "md" | "lg";

export interface CheckboxProps extends BaseOptionInputProps {
  checkboxSize?: CheckboxSize;
  indeterminate?: boolean;
  textEllipsis?: boolean;
  theme?: "default" | "subtle";
  verticalAlign?: "center" | "start";
}

const cxStyles = {
  container: ({ disabled, verticalAlign }: { disabled?: boolean; verticalAlign: "start" | "center" }) =>
    cx(
      "flex relative",
      !disabled && "cursor-pointer",
      verticalAlign === "center" ? "items-center" : "items-start"
    ),
  fake: ({ theme = "default", disabled }: { theme?: "subtle" | "default"; disabled?: boolean }) =>
    cx(
      `inline
       align-bottom
       w-6
       h-6
       z-0
       rounded-full
       fill-greyLight
       peer-disabled:fill-greyLighter
       dark:peer-disabled:fill-greyMedium
       peer-focus:fill-greyDark
       dark:peer-focus:fill-greyLightest
       peer-checked:fill-primaryTheme
       dark:peer-checked:fill-primaryTheme
       peer-checked:peer-focus:fill-primaryTheme
       peer-checked:peer-disabled:fill-greyLight
       dark:peer-checked:peer-hover:fill-primaryTheme
       dark:peer-checked:peer-focus:fill-primaryTheme
       dark:peer-checked:peer-disabled:fill-primaryTheme`,
      !disabled &&
        "peer-hover:fill-greyDark dark:peer-hover:fill-greyLightest peer-checked:peer-hover:fill-primaryTheme dark:peer-checked:peer-hover:fill-primaryTheme",
      theme === "subtle"
        ? `hover:outline
  hover:outline-8
  focus:outline
  focus:outline-8
  dark:hover:outline-white/25
  dark:hover:bg-white/25
  hover:outline-black/5
  hover:bg-black/5
  dark:focus:outline-white/25
  dark:focus:bg-white/25
  focus:outline-black/5
  focus:bg-black/5`
        : `peer-hover:outline
  peer-hover:outline-8
  peer-focus:outline
  peer-focus:outline-8
  dark:peer-hover:outline-white/25
  dark:peer-hover:bg-white/25
  peer-hover:outline-black/5
  peer-hover:bg-black/5
  dark:peer-focus:outline-white/25
  dark:peer-focus:bg-white/25
  peer-focus:outline-black/5
  peer-focus:bg-black/5`
    ),
  content: (subtle: boolean) =>
    cx("text-sm block ml-1.5 flex-1", !subtle && "dark:text-greyLightest peer-disabled:text-greyLight"),
  size: (size: CheckboxSize) => (size === "md" ? "w-5 h-5" : "w-6 h-6"),
};

export const CheckState = ({
  checked,
  className,
  indeterminate,
}: {
  checked?: boolean;
  className: string;
  indeterminate?: boolean;
}) => {
  return checked && indeterminate ? (
    <IntermediateCheckedIcon className={className} />
  ) : checked ? (
    <CheckedIcon className={className} />
  ) : (
    <UnCheckedIcon className={className} />
  );
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      checkboxSize = "md",
      indeterminate,
      textEllipsis = false,
      verticalAlign = "center",
      theme,
      ...props
    },
    ref
  ) => {
    return (
      <OptionInput
        className={cx(cxStyles.container({ disabled: props.disabled, verticalAlign }), className)}
        contentClassName={cx(cxStyles.content(textEllipsis))}
        customContent={
          <CheckState
            checked={props.checked}
            indeterminate={indeterminate}
            className={cx(cxStyles.fake({ theme, disabled: props.disabled }), cxStyles.size(checkboxSize))}
          />
        }
        ref={ref}
        type="checkbox"
        {...props}
      />
    );
  }
);
