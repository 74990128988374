import React from "react";

/*
  An effect to run once and only once, when data has been loaded.
*/
export const useSyncOnce = <T = unknown>(onLoad: (data: T) => void, data?: T) => {
  const hasLoaded = React.useRef(false);

  React.useEffect(() => {
    if (!hasLoaded.current && data) {
      onLoad(data);
      hasLoaded.current = true;
    }
  }, [data, onLoad]);
};
