import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { SECOND_IN_MS } from "@libs/utils/date";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { Button } from "components/UI/Button";

const AUTOCLOSE_SECONDS = 10;

export const FormTasksCompletedKiosk: React.FC<{ practice: PracticeInfoVO }> = ({ practice }) => {
  const { t } = useTranslation();
  const { logo } = practice;
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timer.current) {
      return undefined;
    }

    timer.current = setTimeout(() => {
      window.location.reload();
    }, SECOND_IN_MS * AUTOCLOSE_SECONDS);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    };
  }, []);

  return (
    <div className="flex flex-col h-full items-center">
      <AnonUserMessagePage
        logoUrl={logo?.url}
        title={t("app.formTasks.completedTitle")}
        subTitle={t("app.formTasks.completedClosingSoon")}
      >
        <Button
          theme="link"
          className="mt-8"
          onClick={() => {
            if (timer.current) {
              clearTimeout(timer.current);
              timer.current = null;
            }

            window.location.reload();
          }}
        >
          Close
        </Button>
      </AnonUserMessagePage>
    </div>
  );
};
