import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreatePaymentProfile } from "components/Billing/hooks/useCreatePaymentProfile";
import { finixAppId, finixEnv } from "config/finix";
import { useCardDraft, useFinixForm } from "hooks/useFinixForm";
import { NewCardForm } from "components/Account/NewCardForm";
import { useHideTabs } from "contexts/LayoutContext";
import { TitleBar } from "components/UI/TitleBar";
import { PathDefinitions } from "utils/paths";
import { StickySubmissionFooter } from "components/UI/StickySubmissionFooter";
import { useSearchQueryParams } from "hooks/useSearchQueryParams";

export const AddPaymentMethodRoute: React.FC = () => {
  const navigate = useNavigate();
  const cardDraftProps = useCardDraft({
    includeAcknowedgeFuturePayments: true,
  });

  useHideTabs();

  const searchParams = useSearchQueryParams<PathDefinitions.paymentMethodsAdd>();

  const successUrl = searchParams.get("successUrl");
  const fromUrl = searchParams.get("from");
  const backUrl = fromUrl ?? PathDefinitions.paymentMethods;
  const { t } = useTranslation();
  const handleAddPaymentMethodSuccess = useCallback(() => {
    navigate(successUrl ?? PathDefinitions.paymentMethods);
  }, [navigate, successUrl]);

  const handleGoBack = useCallback(() => {
    navigate(backUrl);
  }, [navigate, backUrl]);

  const { createPaymentProfile, isSaving } = useCreatePaymentProfile({
    onSavedNewCardHandler: handleAddPaymentMethodSuccess,
    isFSACard: Boolean(cardDraftProps.cardDraftState.isFSACard),
    isDefaultCard: cardDraftProps.cardDraftState.isDefaultCard,
  });
  const finixUseFormProps = useFinixForm({
    finixEnvironment: finixEnv,
    applicationId: finixAppId,
    formSubmissionHandler: createPaymentProfile,
  });

  return (
    <div className="relative flex flex-col h-full min-h-0">
      <TitleBar backTo={backUrl} title={t("Add Payment Method")} />
      <div className="flex justify-center flex-1 overflow-y-auto">
        <div className="w-full flex flex-col max-w-md p-6">
          <NewCardForm
            includeAcknowedgeFuturePayments
            includeDefaultCardPreference
            {...finixUseFormProps}
            {...cardDraftProps}
          />
        </div>
      </div>
      <StickySubmissionFooter
        secondaryText={t("Cancel")}
        primaryText={t("Save")}
        onClickSecondary={handleGoBack}
        secondaryButtonDisabled={finixUseFormProps.isSubmitting || isSaving}
        onClickPrimary={() => {
          if (cardDraftProps.validation.validate().$isValid) {
            finixUseFormProps.submitForm?.();
          }
        }}
        primaryButtonDisabled={
          finixUseFormProps.isSubmitting ||
          isSaving ||
          cardDraftProps.validation.result.$isValid === false ||
          !finixUseFormProps.isFormValid
        }
        isSubmitting={isSaving || finixUseFormProps.isSubmitting}
        className="sticky"
      />
    </div>
  );
};
