import { env } from "env";

const appHost =
  env.REACT_APP_ENVIRONMENT === "development"
    ? "https://portal.grindfoundry-preprod.com"
    : window.location.origin;

export const awsconfig = {
  Auth: {
    userPoolId: env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    endpoint: env.REACT_APP_COGNITO_ENDPOINT,
    oauth: {
      domain: env.REACT_APP_AUTH_DOMAIN,
      scope: ["email", "profile", "openid"],
      redirectSignIn: appHost,
      redirectSignOut: appHost,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};
