import { useLocation } from "react-router-dom";
import { InvoiceVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { InvoiceSummary } from "components/Billing/InvoiceSummary";
import { CardLink } from "components/UI/Cards";
import { paths } from "utils/paths";

export const InvoiceCard: React.FC<{ invoice: InvoiceVO; inCarousel?: boolean }> = ({
  invoice,
  inCarousel,
}) => {
  const location = useLocation();

  return (
    <CardLink
      key={invoice.uuid}
      className="hover:border-secondaryTheme"
      inCarousel={inCarousel}
      to={paths.invoiceDetails({ invoiceUuid: invoice.uuid }, { from: getFullUrl(location) })}
    >
      <InvoiceSummary invoice={invoice} />
    </CardLink>
  );
};
