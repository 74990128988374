import React from "react";
import { isDefined } from "@libs/utils/types";
import { OptionInputOption } from "components/UI/OptionInputList";
import { RadioList, RadioListProps } from "components/UI/RadioList";
import { useYesNoOptions } from "hooks/useYesNoOptions";

type Props = Omit<
  RadioListProps<string, OptionInputOption<string>>,
  "layout" | "selectedValue" | "onChange" | "options"
> & {
  selectedValue?: true | false;
  onChange: (value?: boolean) => void;
};
export const YesNoQuestion: React.FC<Props> = (props) => {
  const radioOptions = useYesNoOptions();

  return (
    <RadioList
      {...props}
      options={radioOptions}
      selectedValue={isDefined(props.selectedValue) ? props.selectedValue.toString() : undefined}
      onChange={(e) => {
        props.onChange(isDefined(e.target.value) ? e.target.value === "true" : undefined);
      }}
    />
  );
};
