import React from "react";
import { getLatestFormSubmissionQuery, getPatientPublishedFormQuery, UUIDOrSlug } from "api/forms/queries";
import { getPatientQuery } from "api/patient/queries";
import { useApiQueries } from "api/queries";
import { useUserContext } from "contexts/UserContext";
import { QueryResult } from "components/UI/QueryResult";
import { OnboardingLoading } from "components/Onboarding/OnboardingLoading";
import { LoadedPatientForm } from "components/PatientForms/LoadedPatientForm";
import { useAuthorizedFormSubmission } from "components/PatientForms/hooks/useAuthorizedFormSubmission";

type Props = {
  uuidOrSlug: UUIDOrSlug;
  edit?: boolean;
  onSuccess?: Func;
  onPrevious?: Func;
  fixedFooter?: boolean;
  reconsent?: boolean;
};

export const PatientForm: React.FC<Props> = (props) => {
  const { currentUser, practice } = useUserContext();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { practiceId, id: patientId, onboardingState } = currentUser;
  const [latestSubmissionQuery, publishedFormQuery, patientQuery] = useApiQueries([
    getLatestFormSubmissionQuery({ args: { practiceId, uuidOrSlug: props.uuidOrSlug, patientId } }),
    getPatientPublishedFormQuery({ args: { practiceId, patientId, uuidOrSlug: props.uuidOrSlug } }),
    getPatientQuery({ args: { practiceId, patientId, includeContactPatientDetails: false } }),
  ]);
  const { data: formData } = publishedFormQuery;
  const { data: responseData } = latestSubmissionQuery;
  const { handleSubmit, isSubmitting } = useAuthorizedFormSubmission({
    uuidOrSlug: props.uuidOrSlug,
    onSuccess: props.onSuccess,
    practiceId,
    patientId,
    onboardingState,
  });

  return (
    <QueryResult
      queries={[latestSubmissionQuery, publishedFormQuery, patientQuery]}
      loading={<OnboardingLoading />}
    >
      {formData && patientQuery.data && responseData && (
        <LoadedPatientForm
          practice={practice}
          formData={props.edit ? formData : responseData.form}
          showInWizard={onboardingState !== "COMPLETED"}
          responses={responseData.responses}
          patientAttributes={patientQuery.data.personalDetails}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          warnWhenNavigating
          {...props}
        />
      )}
    </QueryResult>
  );
};
