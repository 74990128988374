import { PracticeInfoVO } from "@libs/api/generated-api";

const getFileExtension = (urlString: string) => {
  const url = new URL(urlString);
  // url path looks like:
  // /assets/practice/<practiceUuid>/profile/8da937b7-9448-40c1-bfbb-b2c005059add.png
  // we pull the extension off of the end
  const fileParts = url.pathname.split("/").at(-1)?.split(".");

  const defaultExtension = "png";

  return fileParts && fileParts.length > 1 ? fileParts.at(-1) ?? defaultExtension : defaultExtension;
};

export const getPracticeLogoDeprecated = (practice: PracticeInfoVO) => {
  if (practice.logo?.thumbnail && practice.logo.url) {
    const logoParts = {
      thumbnail: practice.logo.thumbnail,
      extension: getFileExtension(practice.logo.url),
    };

    return {
      url: `data:image/${logoParts.extension};base64,${logoParts.thumbnail}`,
      w: 100,
      h: 100,
    };
  }

  return undefined;
};

// export const getPracticeLogoBase64 = async (practice: PracticeInfoVO) => {
//   if (practice.logo?.url && practice.logo.thumbnail) {
//     const base64ImageUrl = await fetchImageAsBase64(practice.logo.url);

//     return new Promise<{ w: number; h: number; url: string }>((resolve) => {
//       const img = new Image();

//       img.src = base64ImageUrl;
//       img.decode().then(() => {
//         resolve({ w: img.width, h: img.height, url: base64ImageUrl });
//       });
//     });
//   }

//   return undefined;
// };
