import { StyleSheet } from "@react-pdf/renderer";
import "./fonts";

export const SPACE = {
  xs: 2,
  sm: 4,
  md: 8,
  lg: 16,
  xl: 24,
};
export const BORDER_RADIUS = {
  xs: 2,
  sm: 4,
  md: 8,
};
export const PAGE_PADDING = 24;
export const pdfStyles = StyleSheet.create({
  // Used to wrap most question elements
  box: {
    flexDirection: "column",
    marginBottom: SPACE.md,
  },

  // Used to wrap consent elements
  consent: {
    marginBottom: SPACE.xl,
  },
  page: {
    padding: PAGE_PADDING,
  },
  option: {
    flexDirection: "row",

    // justifyContent: "center",
    alignItems: "center",
  },
});
