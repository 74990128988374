/* eslint-disable complexity */
import React from "react";
import { useTranslation } from "react-i18next";
import { FormDateInputElementVO } from "@libs/api/generated-api";
import { formatAsISODate, formatDate, getDateIfValid } from "@libs/utils/date";
import { PatientResponses, ResponseChangedCallback } from "components/PatientForms/hooks/usePatientResponses";
import { FormFieldDatePicker } from "components/UI/FormFieldDatePicker";

type Props = {
  id: string;
  element: FormDateInputElementVO;
  edit: boolean;
  responsesById: PatientResponses;
  onChangeResponse: ResponseChangedCallback;
  error?: string;
};

export const PatientFormDateElement: React.FC<Props> = ({
  element,
  id,
  edit,
  responsesById,
  onChangeResponse,
  error,
}) => {
  const { t } = useTranslation();
  const notProvided = t("Not Provided");
  const { title, settings, uuid } = element;
  const required = settings.includes("REQUIRED");
  const props = {
    required,
    id,
    edit,
    label: title,
    formatReadOnlyValue: (date?: Date | null) => {
      return date ? formatDate(date) : notProvided;
    },
  };
  const response = responsesById[uuid];

  return (
    <FormFieldDatePicker
      title={title}
      {...props}
      error={error}
      className="max-w-md"
      // value={!ignoreChecked ? getDateIfValid(answer.additionalInfo) : undefined}
      maxDate={settings.includes("PAST") ? new Date() : undefined}
      minDate={settings.includes("FUTURE") ? new Date() : undefined}
      selected={response?.type === "DATE" ? getDateIfValid(response.response) : undefined}
      pickerHiddenOnMobile
      onChange={(date) => {
        onChangeResponse(uuid, {
          type: "DATE",
          response: date ? formatAsISODate(date) : undefined,
        });
      }}
    />
  );
};
