import React from "react";
import { intervalToDuration } from "date-fns";
import { PreparePatientFormSubmissionResponse } from "@libs/api/generated-api";
import { getLocalDate } from "@libs/utils/date";
import { PatientAttributes } from "components/PatientForms/utils";

export const usePatientAttributesFromFormTasks = (
  formTasksResponse: PreparePatientFormSubmissionResponse
) => {
  const { patient, gender, dob } = formTasksResponse;

  const patientAttributes: PatientAttributes = React.useMemo(
    () => ({
      age: intervalToDuration({ start: getLocalDate(dob), end: new Date() }).years,
      gender,
      firstName: patient.firstName,
      lastName: patient.lastName,
      dob,
    }),
    [dob, gender, patient.firstName, patient.lastName]
  );

  return patientAttributes;
};
