import { UseQueryOptions, UseQueryResult, useQueries } from "@tanstack/react-query";
import { useContext } from "react";
import { Api, ErrorResponse, HttpResponse, PageDetails, RequestParams } from "@libs/api/generated-api";
import { ApiClientContext } from "contexts/ApiClientContext";

type ApiInstance = InstanceType<typeof Api>;

export interface ApiQueriesContext {
  api: ApiInstance;
  headers?: HeadersInit;
}

export interface ApiResponseData<D = unknown> {
  pageDetails?: PageDetails;
  status?: string;
  traceId?: string;
  data: D;
}

// TODO this should actually be HttpResponse<ApiResponseData<D>, null>;
// because response.error is null unless there is an error.
// However the generated api return type is incorrectly typed as
// HttpResponse<ApiResponseData<D>, ErrorResponse> so we have to use that
export type ApiResponse<D = unknown> = HttpResponse<ApiResponseData<D>, ErrorResponse>;
export type ApiErrorResponse = HttpResponse<null, ErrorResponse>;
export interface QuerySettings<P, D> {
  args: P;
  queryOptions?: UseQueryOptions<ApiResponse<D>, ApiErrorResponse>;
  requestOptions?: RequestParams;
}

interface OptionalQuerySettings<P, D> extends Omit<QuerySettings<P, D>, "args"> {
  args?: P;
}

type ExtractData<T> = T extends Promise<ApiResponse<infer D>> ? D : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Query<D = any> = (
  context: ApiQueriesContext
) => UseQueryOptions<ApiResponse<D>, ApiErrorResponse>;

type QueryFunc<P, D> = (options: QuerySettings<P, D>) => Query<D>;

type QueryFuncOptionalArgs<P, D> = (options?: OptionalQuerySettings<P, D>) => Query<D>;

type MappedReturnTypes<T> = {
  [K in keyof T]: T[K] extends Query<infer D> ? UseQueryResult<ApiResponse<D>, ApiErrorResponse> : never;
};
type GetApiQueryResult<T> = T extends UseQueryResult<infer U, unknown> ? U : never;
type GetApiResponseData<T> = T extends UseQueryResult<ApiResponse<infer U>, unknown> ? U : never;

type MapToUnwrapped<T extends UseQueryResult<unknown, ApiErrorResponse>> = Omit<T, "data"> & {
  apiResponse: GetApiQueryResult<T> | undefined;
  data: GetApiResponseData<T> | undefined;
};
export type MappedQueryResults<T extends UseQueryResult<unknown, ApiErrorResponse>[]> = {
  [K in keyof T]: MapToUnwrapped<T[K]>;
};

export type ApiQueryResult<T = unknown> = MapToUnwrapped<UseQueryResult<ApiResponse<T>, ApiErrorResponse>>;

export type Head<T extends unknown[]> = T extends [...infer F, RequestParams?] ? F : never;

export type Domains = Extract<
  keyof Omit<ApiInstance, "baseUrl" | "setSecurityData" | "abortRequest" | "request">,
  string
>;

export type ApiName<D extends Domains> = Extract<keyof ApiInstance[D], string>;

export type GetApiMethod<D extends Domains, A extends ApiName<D>> = ApiInstance[D][A] extends (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any
) => Promise<ApiResponse<unknown>>
  ? ApiInstance[D][A]
  : never;

export type ApiMethodData<D extends Domains, A extends ApiName<D>> = ExtractData<
  ReturnType<GetApiMethod<D, A>>
>;

export interface MakeQueryOptions<D extends Domains, A extends ApiName<D>, P> {
  queryKey: [D, A];
  formatParams: (args: P) => [...Head<Parameters<GetApiMethod<D, A>>>];
  requestOptions?: RequestParams;
  queryOptions?: UseQueryOptions<ApiResponse<ApiMethodData<D, A>>, ApiErrorResponse>;
}

interface MakeQueryOptionsOptionalArgs<D extends Domains, A extends ApiName<D>, P>
  extends Omit<MakeQueryOptions<D, A, P>, "formatParams"> {
  formatParams: (args?: P) => [...Head<Parameters<GetApiMethod<D, A>>>];
}

type MakeQuery = <D extends Domains, A extends ApiName<D>, P>(
  options: MakeQueryOptions<D, A, P>
) => QueryFunc<P, ApiMethodData<D, A>>;

type MakeOptionalArgsQuery = <D extends Domains, A extends ApiName<D>, P>(
  options: MakeQueryOptionsOptionalArgs<D, A, P>
) => QueryFuncOptionalArgs<P, ApiMethodData<D, A>>;

export const getQueryKey = <D extends Domains, A extends ApiName<D>>(domain: D, apiCall: A) => {
  return [domain, apiCall];
};

export const mergeRequestParams = (
  contextRequestOptions: { headers?: HeadersInit },
  requestOptions?: RequestParams
): RequestParams => {
  if (requestOptions) {
    if (requestOptions.headers) {
      return {
        ...requestOptions,
        headers: {
          ...contextRequestOptions.headers,
          ...requestOptions.headers,
        },
      };
    }

    return {
      ...requestOptions,
      headers: contextRequestOptions.headers,
    };
  }

  return contextRequestOptions;
};

export const makeQuery: MakeQuery =
  (options) =>
  ({ args, queryOptions, requestOptions }) =>
  ({ api, headers }) => ({
    queryKey: [getQueryKey(...options.queryKey), args],
    queryFn: () => {
      const [domain, apiCall] = options.queryKey;
      const method = api[domain][apiCall] as GetApiMethod<typeof domain, typeof apiCall>;

      return method(
        ...options.formatParams(args),
        mergeRequestParams({ headers }, { ...options.requestOptions, ...requestOptions })
      ) as Promise<ApiResponse<ApiMethodData<typeof domain, typeof apiCall>>>;
    },
    ...options.queryOptions,
    ...queryOptions,
  });

export const makeQueryOptionalArgs: MakeOptionalArgsQuery =
  (options) =>
  (callOptions) =>
  ({ api, headers }) => ({
    queryKey: [getQueryKey(...options.queryKey), callOptions?.args],
    queryFn: () => {
      const [domain, apiCall] = options.queryKey;
      const method = api[domain][apiCall] as GetApiMethod<typeof domain, typeof apiCall>;

      return method(
        ...options.formatParams(callOptions?.args),
        mergeRequestParams({ headers }, callOptions?.requestOptions)
      ) as Promise<ApiResponse<ApiMethodData<typeof domain, typeof apiCall>>>;
    },
    ...options.queryOptions,
    ...callOptions?.queryOptions,
  });

const unwrapQuery = (result: UseQueryResult<ApiResponse<unknown>, unknown>) => {
  const response = result.data;
  const gfData = response?.data;
  const actualData = gfData?.data;

  // HACK: Make react-query v4 behave like react-query v3 when it comes to
  // enabled/isLoading. Revert this PR when we upgrade to react-query v5:
  // https://github.com/grindfoundryinc/grindfoundry-ui/pull/1831. More info is
  // included in the PR description and comments.
  const isLoadingV3 = result.isLoading && result.fetchStatus !== "idle";

  return {
    ...result,
    data: actualData,
    apiResponse: response,
    isLoading: isLoadingV3,
  };
};

export const useApiQueries = <T extends Query[]>(configs: [...T]) => {
  const { httpClient } = useContext(ApiClientContext);
  const mapped = configs.map((config) => config({ api: httpClient }));
  const queries = useQueries({ queries: mapped });

  return queries.map((item) => unwrapQuery(item)) as unknown as MappedQueryResults<MappedReturnTypes<T>>;
};
