import { FormVO, PracticeInfoVO } from "@libs/api/generated-api";
import { formatDate } from "@libs/utils/date";
import { PatientFormContentItem } from "components/PatientForms/FormPDFElements/PatientFormContentItem";
import { PDFDocument } from "components/PatientForms/FormPDFElements/PDFDocument";
import { PracticePDFHeader } from "components/PatientForms/FormPDFElements/PracticePDFHeader";
import { useFilteredForm } from "components/PatientForms/hooks/useFilteredForm";
import { PatientResponses, usePatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { PatientAttributes } from "components/PatientForms/utils";

export type Props = {
  practice: PracticeInfoVO;
  patientPersonalDetails: PatientAttributes;
  formData: FormVO;
  responses: PatientResponses;
  logo?: {
    w: number;
    h: number;
    url: string;
  };
};
export const PatientFormPdf: React.FC<Props> = ({
  formData,
  responses,
  patientPersonalDetails,
  practice,
  logo,
}) => {
  const matchedForm = useFilteredForm({
    edit: false,
    responses,
    form: formData,
    patientAttributes: patientPersonalDetails,
  });
  const { responsesById } = usePatientResponses({
    latestForm: formData,
    latestResponses: responses,
    reconsent: false,
  });

  return (
    <PDFDocument title={matchedForm.title} subject={matchedForm.description} practice={practice}>
      <PracticePDFHeader
        practice={practice}
        patientPersonalDetails={patientPersonalDetails}
        dateCompleted={formatDate(new Date())}
        dob={patientPersonalDetails.dob}
        logo={logo}
      />
      {matchedForm.content.map((item) => {
        return item.content.map((contentItem) => (
          <PatientFormContentItem
            key={item.uuid}
            contentItem={contentItem}
            patientPersonalDetails={patientPersonalDetails}
            responsesById={responsesById}
            edit={false}
          />
        ));
      })}
    </PDFDocument>
  );
};
