import React from "react";
import { useTranslation } from "react-i18next";
import { eq, required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { useObjectState } from "hooks/useObjectState";

export const useSignature = () => {
  const { t } = useTranslation();
  const [signature, updateSignature] = useObjectState({ signatureText: "", checked: false });
  const signatureSchema = React.useMemo(() => {
    return {
      signatureText: [{ $v: required, $error: t("Please sign") }],
      checked: [{ $v: eq(true), $error: t("Mandatory field") }],
    };
  }, [t]);
  const validation = useValidation(signature, signatureSchema);

  return {
    validation,
    signature,
    updateSignature,
  };
};

export type UseSignature = ReturnType<typeof useSignature>;
