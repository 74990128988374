import { makeQuery } from "api/queries";
import { cacheForSession, oneMinuteCache } from "api/utils";

export const getPatientAppointments = makeQuery({
  queryKey: ["practices", "getPatientAppointments"],
  formatParams: (args: {
    patientId: number;
    practiceId: number;
    query: {
      dateMode?: "FUTURE" | "PAST";
      includeStates?: (
        | "UNSCHEDULED"
        | "UNCONFIRMED"
        | "CONFIRMED"
        | "CANCELED"
        | "NO_SHOW"
        | "ARRIVED"
        | "IN_PROGRESS"
        | "COMPLETED"
        | "_DELETED"
      )[];
      excludeStates?: (
        | "UNSCHEDULED"
        | "UNCONFIRMED"
        | "CONFIRMED"
        | "CANCELED"
        | "NO_SHOW"
        | "ARRIVED"
        | "IN_PROGRESS"
        | "COMPLETED"
        | "_DELETED"
      )[];
      pageNumber: number;
      pageSize: number;
    };
  }) => [args.patientId, args.practiceId, args.query],
  queryOptions: oneMinuteCache,
});

export const getPatientQuery = makeQuery({
  queryKey: ["practices", "getPatient"],
  formatParams: ({
    practiceId,
    patientId,
    includeContactPatientDetails = false,
  }: {
    practiceId: number;
    patientId: number;
    includeContactPatientDetails?: boolean;
  }) => [patientId, practiceId, { includeContactPatientDetails }],
  queryOptions: oneMinuteCache,
});

export const getEmailSubscriptionByPatient = makeQuery({
  queryKey: ["practices", "getEmailSubscriptionByPatient"],
  formatParams: ({ practiceId, patientId }: { practiceId: number; patientId: number }) => [
    patientId,
    practiceId,
  ],
  queryOptions: oneMinuteCache,
});

export const getMessageSubscription = makeQuery({
  queryKey: ["public", "getMessageSubscription"],
  formatParams: ({
    practiceUuid,
    messageSubscriptionUuid,
  }: {
    practiceUuid: string;
    messageSubscriptionUuid: string;
  }) => [practiceUuid, messageSubscriptionUuid],
  queryOptions: cacheForSession,
});

export const getPatientSummary = makeQuery({
  queryKey: ["practices", "getPatientSummary"],
  formatParams: ({ practiceId, patientId }: { practiceId: number; patientId: number }) => [
    patientId,
    practiceId,
  ],
  queryOptions: oneMinuteCache,
});
