import React from "react";
import { useTranslation } from "react-i18next";
import { getPatientInsurancesQuery } from "api/patientInsurance/queries";
import { useApiQueries } from "api/queries";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useHideTabs } from "contexts/LayoutContext";
import { TitleBar } from "components/UI/TitleBar";
import { PathDefinitions } from "utils/paths";
import { InsurancesList } from "components/PatientInsurances/InsurancesList";
import { AddButton } from "components/UI/AddButton";

export const PatientInsurancesPage: React.FC = () => {
  const { t } = useTranslation();
  const { id: patientId, practiceId } = useCurrentUser();

  useHideTabs();

  const [insurancesQuery] = useApiQueries([getPatientInsurancesQuery({ args: { patientId, practiceId } })]);
  const { data: insurances, isLoading } = insurancesQuery;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const canAddInsurance = (insurances?.length ?? 0) < 2;

  return (
    <div className="relative flex flex-col h-full min-h-0">
      <TitleBar responsiveBackButton backTo={PathDefinitions.account} title={t("Insurance Information")} />
      <div className="p-4 divide-y divide-y-greyLighter overflow-y-auto">
        <InsurancesList query={insurancesQuery} />
        {!isLoading && canAddInsurance && (
          <div>
            <AddButton to={PathDefinitions.insurancesAdd}>{t("Add Insurance")}</AddButton>
          </div>
        )}
      </div>
    </div>
  );
};
