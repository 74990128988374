import React, { FormEvent } from "react";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { useValidation } from "@libs/hooks/useValidation";
import {
  SignInState,
  StepType,
  getEmailValidationSchema,
  getPasswordValidationSchema,
} from "components/SignIn/validationUtil";

import { normal12, normal14 } from "assets/styles/textSize";
import { Form } from "components/UI/Form";
import { FormFieldInput } from "components/UI/FormFieldInput";
import { useObjectState } from "hooks/useObjectState";
import { FormFieldPassword } from "components/UI/FormFieldPassword";
import { AsyncButton } from "components/UI/AsyncButton";
import { PathDefinitions, paths } from "utils/paths";
import { InternalLink } from "components/UI/Link";
import { SignInWrapper } from "components/SignIn/SignInWrapper";
import { useAuthErrorHandler } from "hooks/useAuthErrorHandler";
import { CognitoErrorCode, userRequiresEmailConfirmation } from "utils/auth";
import { OneTimeCodeForm } from "components/ForgotPassword/OneTimeCodeForm";
import { ConfirmEmailToSignIn } from "components/SignIn/ConfirmEmailToSignIn";
import { useEmailQueryParam } from "components/SignIn/hooks/useEmailQueryParam";
import { ThirdPartySignInButton } from "components/UI/ThirdPartySignInButton";
import { DividerWithText } from "components/SignIn/DividerWithText";
import { useSearchQueryParams } from "hooks/useSearchQueryParams";

export const ErrorMessage: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={cx("text-red text-center", normal12)}>{children}</div>
);

const useSubmitHandler = () => {
  const { t } = useTranslation();
  const emailQueryParam = useEmailQueryParam();
  const [signInState, setSignInState] = useObjectState<SignInState>({
    email: emailQueryParam ?? "",
    password: "",
    isLoading: false,
    step: StepType.SIGN_IN,
  });
  const signInSchema = React.useMemo(() => {
    return {
      email: getEmailValidationSchema(signInState.email),
      password: getPasswordValidationSchema({ password: signInState.password }),
    };
  }, [signInState.email, signInState.password]);

  const signInForm = useValidation(
    { email: signInState.email, password: signInState.password },
    signInSchema
  );
  const updateErrorData = React.useCallback(
    (errorMessage: string, cognitoErrorCode: CognitoErrorCode) => {
      setSignInState({ errorMessage, cognitoErrorCode });
    },
    [setSignInState]
  );
  const { handleAuthError } = useAuthErrorHandler(updateErrorData);
  const searchParams = useSearchQueryParams<PathDefinitions.signIn>();

  const getRedirectToLoggedInUrl = React.useCallback(() => {
    const returnUrl = searchParams.get("returnUrl");

    return returnUrl || "/";
  }, [searchParams]);

  const handleSignIn = React.useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const validate = signInForm.validate();

      if (!validate.$isValid) {
        return;
      }

      setSignInState({ isLoading: true });

      if (signInState.email && signInState.password) {
        try {
          await Auth.signIn(signInState.email, signInState.password);
        } catch (error) {
          if (error instanceof Error) {
            setSignInState({
              isLoading: false,
            });
            handleAuthError(error);
          }

          return;
        }

        // if (signInState.rememberMe) {
        //   await handleRememberMe(authUser);
        // }

        window.location.href = getRedirectToLoggedInUrl();
      } else {
        setSignInState({
          errorMessage: t("Invalid username or password"),
          isLoading: false,
        });
      }
    },
    [
      handleAuthError,
      signInForm,
      setSignInState,
      signInState.email,
      signInState.password,
      getRedirectToLoggedInUrl,
      t,
    ]
  );

  return {
    handleSignIn,
    signInState,
    setSignInState,
    validation: signInForm.result,
  };
};

export const MainSignIn: React.FC = () => {
  const { handleSignIn, signInState, setSignInState, validation } = useSubmitHandler();
  const { t } = useTranslation();
  const requiresConfirmation = userRequiresEmailConfirmation(signInState.cognitoErrorCode);

  return (
    <SignInWrapper title={t("app.page.labels.signin")}>
      {signInState.step === StepType.SIGN_IN ? (
        requiresConfirmation ? (
          <ConfirmEmailToSignIn
            text={t("signIn.confirmEmailToSignIn")}
            onClickCancel={() => {
              setSignInState({
                errorMessage: undefined,
                cognitoErrorCode: undefined,
              });
            }}
            onClickReConfirm={async () => {
              setSignInState({ isLoading: true });
              await Auth.resendSignUp(signInState.email);
              setSignInState({
                step: StepType.ONE_TIME_CODE,
                isLoading: false,
              });
            }}
            cognitoErrorCode={signInState.cognitoErrorCode}
            errorMessage={signInState.errorMessage}
          />
        ) : (
          <div className="flex flex-col gap-6">
            <ThirdPartySignInButton className="w-full" provider="Google">
              {t("signIn.signInWith", { thirdParty: "Google" })}
            </ThirdPartySignInButton>

            <DividerWithText className="text-sm text-greyMedium uppercase">{t("or")}</DividerWithText>
            <Form
              id="sign-up-page"
              fieldLayout="labelOut"
              className="flex flex-col space-y-5"
              onSubmit={handleSignIn}
            >
              <FormFieldInput
                type="email"
                label="Email"
                placeholder="example@gmail.com"
                autoComplete="username"
                value={signInState.email}
                error={validation.email.$error}
                onChange={(e) => {
                  setSignInState({
                    email: e.target.value,
                    errorMessage: undefined,
                  });
                }}
                id="signin-email"
              />
              <div className="flex flex-col items-end space-y-1">
                <FormFieldPassword
                  label="Password"
                  id="signin-password"
                  layout="labelOut"
                  autoComplete="current-password"
                  placeholder="Enter Password"
                  className="w-full"
                  value={signInState.password}
                  error={validation.password.$error}
                  onChange={(e) => {
                    setSignInState({
                      password: e.target.value,
                      errorMessage: undefined,
                    });
                  }}
                />
                <InternalLink className="text-sm" to={PathDefinitions.forgotPasswordSignedOut}>
                  {t("app.page.labels.forgotPassword")}
                </InternalLink>
              </div>
              {signInState.errorMessage && <ErrorMessage>{signInState.errorMessage}</ErrorMessage>}
              <div className="flex flex-col justify-center items-center gap-3">
                <AsyncButton isLoading={signInState.isLoading} className="w-full" type="submit" size="large">
                  {t("app.page.labels.signin")}
                </AsyncButton>
                <div className={cx("text-center", normal14)}>
                  {t("app.page.labels.wantToCreateNewProfileText")}{" "}
                  <InternalLink to={paths.signUp()}>{t("app.page.labels.signupHere")}</InternalLink>
                </div>
              </div>
            </Form>
          </div>
        )
      ) : (
        <OneTimeCodeForm email={signInState.email} password={signInState.password} />
      )}
    </SignInWrapper>
  );
};
