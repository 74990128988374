import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";
import { useApiMutations } from "api/mutations";
import { rescheduleAppointment } from "api/scheduling/mutations";
import { useCurrentUser } from "hooks/useCurrentUser";
import { usePracticeInfo } from "hooks/usePracticeInfo";
import { ReactComponent as BlueCheck } from "assets/icons/modalInfoIcon.svg";
import { ResponsiveActionModal } from "components/UI/ResponsiveActionModal";
// import { ReactComponent as Loading } from "assets/icons/modalLoading.svg";
// import { ReactComponent as WarningIcon } from "assets/icons/modalWarningIcon.svg";
// import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

export const RescheduleAppointmentModal: React.FC<{ onClose: Func; goToAppointments: Func }> = ({
  onClose,
  goToAppointments,
}) => {
  const [{ mutateAsync: mutateRescheduleAppointment, isLoading }] = useApiMutations([rescheduleAppointment]);
  const { t } = useTranslation();
  const { appointmentId } = useParams();
  const { practice } = usePracticeInfo();

  const { practiceId, id: patientId } = useCurrentUser();

  const onClickConfirm = React.useCallback(() => {
    if (appointmentId) {
      return mutateRescheduleAppointment({
        practiceId,
        patientId,
        appointmentId: Number.parseInt(appointmentId, 10),
      });
    }

    return Promise.resolve();
  }, [appointmentId, mutateRescheduleAppointment, patientId, practiceId]);

  return (
    <ResponsiveActionModal
      isLoading={isLoading}
      text={t("app.pages.labels.reschedule.confirm.message", {
        practiceName: practice?.name ?? "the practice",
      })}
      completedContent={t("Reschedule request successfully submitted")}
      tertiaryButtonLabel={t("Back to Upcoming Appointments")}
      onClickPrimaryButton={onClickConfirm}
      onClose={onClose}
      secondaryButtonLabel={t("No")}
      primaryButtonLabel={t("Yes")}
      onClickSecondaryButton={onClose}
      onClickTertiaryButton={goToAppointments}
      icon={<BlueCheck />}
    />
  );
};
