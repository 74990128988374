import { ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { ValueTypes } from "@libs/components/UI/OptionInput";
import { Radio } from "components/UI/Radio";
import {
  applyOptionClassName,
  Layout,
  OptionInputList,
  OptionInputOption,
  RadioInputListProps,
  RenderOptionItemProps,
} from "components/UI/OptionInputList";
import { LabelledOptions } from "components/UI/LabelledOptions";

export interface RadioListProps<V extends ValueTypes, T extends OptionInputOption<V>>
  extends Omit<RadioInputListProps<V, T>, "type"> {
  layout?: Layout;
  label?: ReactNode;
  labelSizeClassName?: string;
  required?: boolean;
  error?: string;
  inline?: boolean;
  verticalLayout?: "compact" | "normal" | "comfortable";
  optionListClassName?: string;
  labelClassName?: string;
  renderOptionItem?: (props: RenderOptionItemProps<V>) => ReactNode;
}

export const RadioList = <V extends ValueTypes, T extends OptionInputOption<V>>({
  className,
  optionClassName,
  optionListClassName,
  labelSizeClassName,
  labelClassName,
  label,
  error,
  required,
  inline,
  layout = "horiz",
  verticalLayout = "comfortable",
  renderOptionItem = (props) => <Radio {...props} />,
  ...rest
}: RadioListProps<V, T>) => {
  return (
    <LabelledOptions
      label={label}
      error={error}
      required={required}
      layout={layout}
      className={className}
      inline={inline}
      labelClassName={cx(labelSizeClassName ?? "text-sm", labelClassName ?? "font-sansSemiBold")}
    >
      <OptionInputList
        {...rest}
        type="radio"
        className={cx(
          layout !== "custom" && "flex",
          layout === "vert" ? "flex-col" : layout === "custom" ? "" : "items-center flex-wrap",
          optionListClassName
        )}
        optionClassName={(props) =>
          cx(
            "text-sm",
            verticalLayout === "comfortable" ? "py-2" : verticalLayout === "normal" ? "py-1" : "py-0",
            layout === "vert" ? "!mt-1 first:!mt-0" : layout === "custom" ? "" : "mr-8 last:mr-0",
            applyOptionClassName(optionClassName, props)
          )
        }
        renderOptionItem={renderOptionItem}
      />
    </LabelledOptions>
  );
};
