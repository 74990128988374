import React, { useMemo } from "react";
// import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { cx } from "@libs/utils/cx";
import { Checkbox } from "components/UI/Checkbox";
import { UseCardDraft, UseFinixForm } from "hooks/useFinixForm";
import { FinixScriptLoadErrorBanner } from "components/Billing/FinixScriptLoadErrorBanner";
import { RadioList } from "components/UI/RadioList";
import { FormFieldError } from "components/UI/FormFieldError";
import { normal14 } from "assets/styles/textSize";

type Props = {
  className?: string;
  title?: React.ReactNode;
  includeDefaultCardPreference?: boolean;
  includeAcknowedgeFuturePayments?: boolean;
  handleDomRef: Func;
} & UseFinixForm &
  UseCardDraft;

// eslint-disable-next-line complexity
export const NewCardForm: React.FC<Props> = ({
  title,
  elementId,
  failedLoadingFinixScript,
  className,
  loadingFinix,
  cardDraftState,
  validation,
  updateCardDraft,
  handleDomRef,
  includeAcknowedgeFuturePayments = false,
  includeDefaultCardPreference = false,
}) => {
  const { t } = useTranslation();
  const cardOptions = useMemo(() => {
    return [
      {
        label: t("Credit Card"),
        value: "credit_card" as const,
      },
      {
        label: t("FSA/HSA Card"),
        value: "fsa_card" as const,
      },
    ];
  }, [t]);

  return (
    <div className={cx("flex flex-col gap-3 pb-6", className)}>
      {failedLoadingFinixScript && <FinixScriptLoadErrorBanner />}
      <div className="flex">
        <RadioList
          disabled={loadingFinix}
          label={title ?? t("app.page.labels.account.managePaymentCardType")}
          error={validation.result.isFSACard.$error}
          selectedValue={
            cardDraftState.isFSACard === undefined
              ? undefined
              : cardDraftState.isFSACard
                ? "fsa_card"
                : "credit_card"
          }
          labelClassName="text-md"
          options={cardOptions}
          onChange={(e, op) => {
            updateCardDraft({ isFSACard: op.value === "fsa_card" });
          }}
        />
      </div>
      {loadingFinix && <Skeleton containerClassName="mt-6 flex flex-col gap-1" count={3} className="h-10" />}
      <div
        id={elementId}
        ref={handleDomRef}
        className={cx("flex flex-col w-full", loadingFinix && "hidden", normal14)}
      />
      <div className="flex flex-col gap-4">
        {includeDefaultCardPreference && (
          <Checkbox
            id="save-as-default"
            checked={cardDraftState.isDefaultCard}
            disabled={cardDraftState.isFSACard || loadingFinix}
            className="text-sm"
            onChange={(e) => {
              updateCardDraft({
                isDefaultCard: e.target.checked,
              });
            }}
          >
            {t("app.page.labels.account.managePaymentSaveAsDefaultMessage")}
          </Checkbox>
        )}

        {includeAcknowedgeFuturePayments && (
          <>
            <div className="border-t border-t-greyLighest" />

            <Checkbox
              id="future-payments"
              name="Acknowledgement"
              className="text-sm"
              required
              disabled={loadingFinix}
              checked={cardDraftState.isChargingAuthorized}
              onChange={(e) => {
                updateCardDraft({
                  isChargingAuthorized: e.target.checked,
                });
              }}
            >
              {t("payment.info.acknowledgement")}
            </Checkbox>
          </>
        )}

        {validation.result.isChargingAuthorized.$error && (
          <FormFieldError>{validation.result.isChargingAuthorized.$error}</FormFieldError>
        )}
      </div>
    </div>
  );
};
