import { FC } from "react";
import { ReactComponent as HideIcon } from "assets/icons/currentColor/hide.svg";
import { ReactComponent as EyeIcon } from "assets/icons/currentColor/eye.svg";
import { ButtonIcon } from "components/UI/ButtonIcon";

export const SsnRevealButton: FC<{
  revealSsn: boolean;
  isSsnLoading: boolean;
  disabled?: boolean;
  onClick: Func;
  className?: string;
}> = ({ disabled, isSsnLoading, revealSsn, onClick, className }) => (
  <ButtonIcon
    size="sm"
    loading={isSsnLoading}
    type="button"
    theme="primary"
    className={className}
    disabled={disabled}
    tooltip={{ content: disabled ? "" : revealSsn ? "Conceal SSN" : "Reveal SSN", theme: "SMALL" as const }}
    onClick={onClick}
    Icon={revealSsn ? HideIcon : EyeIcon}
  />
);
