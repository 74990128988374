import React from "react";

import { useTranslation } from "react-i18next";
import { SignInWrapper } from "components/SignIn/SignInWrapper";
import { normal14 } from "assets/styles/textSize";
import { SignedOutLayout } from "components/Main/SignedOutLayout";
import { AsyncButton } from "components/UI/AsyncButton";
import { useSignOut } from "hooks/useSignOut";

export const WaitingRoom: React.FC<{
  onRetry: () => void;
  isLoading: boolean;
}> = ({ onRetry, isLoading }) => {
  const { t } = useTranslation();
  const { signOut, isLoading: signingOut } = useSignOut();

  return (
    <SignedOutLayout>
      <SignInWrapper title="Waiting for Approval" includeBranding={false}>
        <div className="flex flex-col space-y-4">
          <div className={normal14}>A provider must approve your account.</div>
          <AsyncButton onClick={onRetry} type="button" isLoading={isLoading}>
            {t("Retry")}
          </AsyncButton>
          <AsyncButton isLoading={signingOut} onClick={signOut} theme="secondary">
            {t("app.page.labels.signOut")}
          </AsyncButton>
        </div>
      </SignInWrapper>
    </SignedOutLayout>
  );
};
