import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { cx } from "@libs/utils/cx";

export const LoadingContent: FC<{ containerClassName?: string; className?: string }> = ({
  containerClassName,
  className,
}) => {
  return (
    <Skeleton
      containerClassName={cx("h-full w-full min-h-[inherit] block", containerClassName)}
      inline={true}
      className={cx("h-full w-full min-h-[inherit] block", className)}
    />
  );
};
