import { Link } from "react-router-dom";
import { PracticeBranding } from "components/UI/PracticeBranding";
import { PathDefinitions } from "utils/paths";

export const HeaderPracticeLink: React.FC<{ includePoweredBy?: boolean }> = ({ includePoweredBy }) => (
  <Link className="flex items-center" to={PathDefinitions.home}>
    <div className="flex">
      <PracticeBranding size="sm" includePoweredBy={includePoweredBy} />
    </div>
  </Link>
);
