import React, { FormEvent, useCallback } from "react";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";

import { useValidation } from "@libs/hooks/useValidation";
import { SignUpForm } from "components/SignIn/SignUpForm";

import {
  SignUpState,
  getEmailValidationSchema,
  getPasswordValidationSchema,
  StepType,
} from "components/SignIn/validationUtil";
import { useObjectState } from "hooks/useObjectState";
import { OneTimeCodeForm } from "components/ForgotPassword/OneTimeCodeForm";
import { SignInWrapper } from "components/SignIn/SignInWrapper";
import { useAuthErrorHandler } from "hooks/useAuthErrorHandler";
import { useEmailQueryParam } from "components/SignIn/hooks/useEmailQueryParam";

export const FirstSignIn: React.FC = () => {
  const { t } = useTranslation();
  const emailQueryParam = useEmailQueryParam();
  const [signUpState, updateSignInState] = useObjectState<SignUpState>({
    confirmPassword: "",
    email: emailQueryParam ?? "",
    errorMessage: "",
    isLoading: false,
    password: "",
    step: StepType.SIGN_IN,
  });

  const signUpForm = useValidation(
    {
      confirmPassword: signUpState.confirmPassword,
      email: signUpState.email,
      password: signUpState.password,
    },
    {
      confirmPassword: getPasswordValidationSchema({
        password: signUpState.confirmPassword,
        comparisonPassword: signUpState.password,
        validatePwRules: true,
      }),
      email: getEmailValidationSchema(signUpState.email),
      password: getPasswordValidationSchema({
        password: signUpState.password,
      }),
    }
  );
  const updateErrorMessage = React.useCallback(
    (errorMessage: string) => {
      updateSignInState({
        errorMessage,
      });
    },
    [updateSignInState]
  );
  const { handleAuthError } = useAuthErrorHandler(updateErrorMessage);

  const handleSignUp = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const validate = signUpForm.validate();

      if (!validate.$isValid) {
        return;
      }

      try {
        await Auth.signUp({
          username: signUpState.email,
          password: signUpState.password,
          attributes: {
            email: signUpState.email,
          },
        });
        updateSignInState({
          step: StepType.ONE_TIME_CODE,
        });
      } catch (error) {
        handleAuthError(error);
      }

      updateSignInState({ isLoading: false });
    },
    [signUpForm, handleAuthError, updateSignInState, signUpState.email, signUpState.password]
  );

  return (
    <SignInWrapper title={t("app.page.labels.signup")}>
      {signUpState.step === StepType.SIGN_IN ? (
        <SignUpForm
          handleSignUp={handleSignUp}
          signUpState={signUpState}
          handleSignUpStateChange={updateSignInState}
          onResetValidation={signUpForm.reset}
          validation={signUpForm.result}
          canEditEmail={!emailQueryParam}
        />
      ) : (
        <OneTimeCodeForm email={signUpState.email} password={signUpState.password} />
      )}
    </SignInWrapper>
  );
};
