import { useTranslation } from "react-i18next";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { Button } from "components/UI/Button";

export const FormTasksCompleted: React.FC<{ practice: PracticeInfoVO }> = ({ practice }) => {
  const { t } = useTranslation();
  const { website, logo } = practice;

  return (
    <div className="flex flex-col h-full items-center">
      <AnonUserMessagePage
        logoUrl={logo?.url}
        title={t("app.formTasks.completedTitle")}
        subTitle={t("app.formTasks.completedBody")}
      >
        {website && (
          <Button
            theme="link"
            size="large"
            onClick={() => website && window.location.replace(website)}
            className="mt-7"
          >
            {t("HomePage")}
          </Button>
        )}
      </AnonUserMessagePage>
    </div>
  );
};
