import React, { forwardRef } from "react";
import { cx } from "@libs/utils/cx";

export const cxStyles = {
  container: "text-center rounded font-sansSemiBold box-border",
  themes: {
    primary: ({ disabled }: { disabled: boolean }) =>
      cx(
        "border",
        disabled
          ? "bg-greyLighter text-greyLight cursor-default border-greyLightest"
          : "border-primaryTheme bg-primaryTheme text-white  hover:bg-opacity-70"
      ),
    secondary: ({ disabled }: { disabled: boolean }) =>
      cx(
        "border",
        disabled
          ? "text-greyLighter border-greyLighter cursor-default"
          : "border-primaryTheme text-primaryTheme  hover:text-opacity-70 hover:border-opacity-70"
      ),

    dangerZone: ({ disabled }: { disabled: boolean }) =>
      cx(
        "border",
        disabled
          ? "bg-greyLightest text-greyLight cursor-default border-greyLightest"
          : "border-red bg-red text-white hover:bg-opacity-70"
      ),
    tertiary: ({ disabled }: { disabled: boolean }) =>
      cx(
        "bg-white border border-greyLighter",
        disabled
          ? "text-greyLighter cursor-default"
          : "hover:border-primaryTheme hover:text-opacity-70 hover:border-opacity-70"
      ),

    link: ({ disabled }: { disabled: boolean }) =>
      cx(disabled ? "text-greyLight cursor-default" : "text-primaryTheme"),
  },
  sizes: {
    // allows you to define size via class name
    custom: "",
    large: "py-2 px-3 text-base",
    small: "py-1 px-2 text-sm",
  },
};

export interface ButtonProps {
  theme?: keyof typeof cxStyles.themes;
  size?: keyof typeof cxStyles.sizes;
  // A way to make a button disabled withouth changing the style
  inactive?: boolean;
}

export const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      className,
      children,
      type = "button",
      theme = "primary",
      size = "large",
      inactive,
      disabled = false,
      ...rest
    },
    ref
  ) => (
    <button
      {...{ ...rest, disabled: disabled || inactive }}
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={cx(
        cxStyles.container,
        cxStyles.themes[theme]({ disabled }),
        theme !== "link" && cxStyles.sizes[size],
        className
      )}
    >
      {children}
    </button>
  )
);
