import React, { useMemo, FC } from "react";

import { setTag as setSentryTag } from "@sentry/react";
import { UNAUTHORIZED } from "@libs/utils/statusCodes";
import { getPracticeInfo, getUserLoginInfo } from "api/user/queries";
import { useApiQueries, ApiErrorResponse } from "api/queries";
import { UserContext } from "contexts/UserContext";
import { useSignOutListeners } from "hooks/useSignoutListeners";
import { WaitingRoom } from "components/SignIn/WaitingRoom";
import { SelectPatient } from "components/SignIn/SelectPatient";
import { PRACTICE_UUID_KEY } from "hooks/usePracticeInfo";
import { LoadingState } from "components/Main/LoadingState";
import { useAccountSelected } from "components/Main/useAccountSelected";
import { LayoutContextProvider } from "contexts/LayoutContext";
import { AuthedRoutes } from "components/Main/AuthedRoutes";
import { setSentryUserProperties } from "components/Main/initializeSentry";
import { useInstrumentationForPractice } from "hooks/useInstrumentationForPractice";

export const SignedInApp: FC = () => {
  const [loadError, setLoadError] = React.useState<ApiErrorResponse | undefined>(undefined);
  const [{ data, isLoading, error, refetch: refetchUser }] = useApiQueries([
    getUserLoginInfo({
      queryOptions: {
        retry: 2,
        refetchOnWindowFocus: !loadError,
        onSuccess: (response) => {
          setLoadError(undefined);

          const user = response.data.data[0];

          localStorage.setItem(PRACTICE_UUID_KEY, user.practiceUuid);
          setSentryTag("practiceId", user.practiceId);
          setSentryUserProperties(user);
        },
        onError: setLoadError,
      },
    }),
  ]);
  const { accountSelected, handleAccountSelected } = useAccountSelected(data);

  const currentUser = React.useMemo(() => {
    return data?.find((patient) => patient.id === accountSelected?.patientId);
  }, [data, accountSelected]);

  const [{ data: practiceData, refetch: refetchPractice }] = useApiQueries([
    getPracticeInfo({
      args: {
        practiceUuid: currentUser?.practiceUuid as string,
      },
      queryOptions: { enabled: Boolean(currentUser?.practiceUuid) },
    }),
  ]);

  useInstrumentationForPractice(practiceData);

  const contextValue = useMemo(() => {
    if (!currentUser || !practiceData || !data) {
      return null;
    }

    return {
      currentUser,
      practice: practiceData,
      users: data,
      refetchUser,
      refetchPractice,
      selectAccount: handleAccountSelected,
    };
  }, [currentUser, practiceData, data, refetchUser, refetchPractice, handleAccountSelected]);

  useSignOutListeners();

  const isLockedOut = (loadError?.status as unknown as number) === UNAUTHORIZED;

  if (isLockedOut) {
    return <WaitingRoom onRetry={refetchUser} isLoading={isLoading} />;
  } else if (!accountSelected && !isLoading && !error && data) {
    return (
      <SelectPatient
        users={data}
        onUserSelected={(user) =>
          handleAccountSelected({
            patientId: user.id,
            thumbnail: user.thumbnail,
          })
        }
      />
    );
  }

  return error ? (
    <div>Sorry, there was an error loading your account. Please try again in a few minutes.</div>
  ) : isLoading || !contextValue ? (
    <LoadingState />
  ) : (
    <UserContext.Provider value={contextValue}>
      <LayoutContextProvider>
        <AuthedRoutes />
      </LayoutContextProvider>
    </UserContext.Provider>
  );
};
