import { makeQuery, makeQueryOptionalArgs } from "api/queries";
import { cacheForSession, oneMinuteCache } from "api/utils";

export const getUserLoginInfo = makeQueryOptionalArgs({
  queryKey: ["user", "getUserLoginInfo"],
  formatParams: () => [],
  queryOptions: cacheForSession,
});

export const getPracticeInfo = makeQuery({
  queryKey: ["practices", "getPracticeInfo"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: cacheForSession,
});

export const getSsn = makeQuery({
  queryKey: ["practices", "getSsn"],
  formatParams: ({ userId, practiceId }: { practiceId: number; userId: number }) => [userId, practiceId],
  queryOptions: oneMinuteCache,
});
