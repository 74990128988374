import { redirect, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { formatAsISODate } from "@libs/utils/date";
import { fetchGuestCheckoutSummary } from "api/billing/queries";
import { useApiQueries } from "api/queries";
import { PathDefinitions, PracticeUuidParams } from "utils/paths";
import { ConfirmDOBForm } from "components/ConfirmDOBForm";
import { getPracticeInfo } from "api/user/queries";
import { QueryResult } from "components/UI/QueryResult";
import { GuestCheckoutForm } from "components/GuestCheckout/GuestCheckoutForm";
import { PaymentCompleteScreen } from "components/Billing/PaymentCompleteScreen";
import { useSearchQueryParams } from "hooks/useSearchQueryParams";
import { useInstrumentationForPractice } from "hooks/useInstrumentationForPractice";
import { getIsKioskCodeOrDOBError } from "utils/getIsKioskCodeOrDOBError";

const useRouteParams = () => {
  const { practiceUuid } = useParams<PracticeUuidParams>();

  if (!practiceUuid) {
    throw new Error("practiceUuid is required for guest-checkout");
  }

  return { practiceUuid };
};

export const GuestCheckoutRoute: React.FC = () => {
  const [paymentDoneEmail, setPaymentDoneEmail] = useState("");
  const { practiceUuid } = useRouteParams();
  const searchParams = useSearchQueryParams<PathDefinitions.guestCheckout>();
  const patientToken = searchParams.get("patientToken") ?? "";
  const [submittedDob, setSubmittedDob] = useState("");
  const [practiceQuery, checkoutSummaryQuery] = useApiQueries([
    getPracticeInfo({
      args: { practiceUuid },
    }),
    fetchGuestCheckoutSummary({
      args: { practiceUuid, patientToken, data: { patientDob: submittedDob } },
      queryOptions: {
        enabled: Boolean(submittedDob),
        refetchOnWindowFocus: false,
      },
    }),
  ]);

  useInstrumentationForPractice(practiceQuery.data);

  useEffect(() => {
    if (patientToken.length === 0) {
      redirect(PathDefinitions.loggedOutLanding);
    }
  }, [patientToken.length]);

  const isFormSubmissionError = getIsKioskCodeOrDOBError(checkoutSummaryQuery.error);
  const handleDobSubmitted = useCallback((dob: Date) => {
    setSubmittedDob(formatAsISODate(dob));
  }, []);

  return (
    <QueryResult queries={[practiceQuery]}>
      {practiceQuery.data &&
        (!submittedDob || isFormSubmissionError ? (
          <ConfirmDOBForm
            practice={practiceQuery.data}
            onSubmit={handleDobSubmitted}
            error={isFormSubmissionError}
          />
        ) : paymentDoneEmail ? (
          <PaymentCompleteScreen email={paymentDoneEmail} />
        ) : (
          <GuestCheckoutForm
            checkoutSummaryQuery={checkoutSummaryQuery}
            practiceUuid={practiceUuid}
            patientToken={patientToken}
            onPaymentComplete={setPaymentDoneEmail}
            practice={practiceQuery.data}
          />
        ))}
    </QueryResult>
  );
};
