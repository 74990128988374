import { useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { captureException as sentryCaptureException } from "@sentry/react";
import { ErrorResponse, HttpResponse } from "@libs/api/generated-api";

export const useGlobalApiErrorHandler = () => {
  const location = useLocation();
  const locationRef = useRef(location);

  locationRef.current = location;

  const handleGlobalApiError = useCallback(
    async (response: HttpResponse<unknown, ErrorResponse | undefined>) => {
      try {
        // We attempt deserializing the response, if deserialization fails, we then attempt to read the response as text
        const jsonResponse = response.clone();

        // If deserialization is successful, then the code making the request will hander the successfully deserialized error
        await jsonResponse.json();
      } catch {
        const textResponse = response.clone();
        const textContent = await textResponse.text();

        sentryCaptureException("Api Deserialization Error", {
          extra: {
            status: textContent,
            statusText: response.statusText,
            error: response.error,
          },
        });
      }
    },
    []
  );

  return handleGlobalApiError;
};
