import { useSearchParams, useParams } from "react-router-dom";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MINUTE_IN_SECONDS, SECOND_IN_MS, formatAsISODate } from "@libs/utils/date";
import { half } from "@libs/utils/math";
import { useBoolean } from "@libs/hooks/useBoolean";
import { preparePatientFormTasksQuery } from "api/forms/queries";
import { useApiQueries } from "api/queries";
import { FormTasksPathParams } from "utils/paths";
import { ApiClientContext } from "contexts/ApiClientContext";
import { QueryResult } from "components/UI/QueryResult";
import {
  LoadedPatientFormTasks,
  getFormTaskExpiry,
} from "components/PatientForms/PatientFormTasks/LoadedPatientFormTasks";
import { getPracticeInfo } from "api/user/queries";
import { DateOfBirthForm } from "components/DateOfBirthForm";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { getIsKioskCodeOrDOBError } from "utils/getIsKioskCodeOrDOBError";

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const IDLE_TIMEOUT = MINUTE_IN_SECONDS * SECOND_IN_MS * 6;
const PATIENT_IDLE_VALUES = {
  // Reloads page after six minutes (3 minutes of inactivity, 3 minutes with prompt)
  timeout: IDLE_TIMEOUT,
  // Prompts user after 3 minutes
  promptBeforeIdle: half(IDLE_TIMEOUT),
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  eventsThrottle: 10 * SECOND_IN_MS,
  name: "patientForms",
};

const useFormTaskQueryParams = () => {
  const [searchParams] = useSearchParams({ formTaskUuids: "" });
  const formTaskUuids = searchParams.get("formTaskUuids")?.split(",") ?? [];

  return {
    formTaskUuids,
    dob: searchParams.get("dob") ?? undefined,
    print: searchParams.get("print") ?? undefined,
    searchParams,
  };
};

// eslint-disable-next-line complexity
export const PatientFormTasksRoute = () => {
  const { t } = useTranslation();
  const params = useParams<FormTasksPathParams>();
  const practiceUuid = params.practiceUuid ?? "";
  const { formTaskUuids, print, dob: dobQueryParam } = useFormTaskQueryParams();
  const [dob, setDob] = React.useState<string | undefined>(dobQueryParam);
  const [expiresAt, setExpiresAt] = React.useState<Date>(getFormTaskExpiry());
  const errorOccurred = useBoolean(false);
  const { setFormTasksSessionToken } = React.useContext(ApiClientContext);
  const isPrinting = print === "1";
  const [tasksQuery, practiceQuery] = useApiQueries([
    preparePatientFormTasksQuery({
      args: { practiceUuid, data: { formTaskUuids, dob: dob ?? "" } },
      queryOptions: {
        enabled: Boolean(dob),
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: (data) => {
          setFormTasksSessionToken(data.data.data.token);
          setExpiresAt(getFormTaskExpiry());
        },
        onError: errorOccurred.on,
      },
    }),
    getPracticeInfo({
      args: { practiceUuid },
    }),
  ]);
  const handleSessionExpired = useCallback(async () => {
    const result = await tasksQuery.refetch();

    setFormTasksSessionToken(result.data?.data.data.token);
    setExpiresAt(getFormTaskExpiry());
  }, [setFormTasksSessionToken, tasksQuery]);
  const isFormSubmissionError = getIsKioskCodeOrDOBError(tasksQuery.error);

  return (dob && !isFormSubmissionError) || isPrinting ? (
    <QueryResult queries={[tasksQuery, practiceQuery]}>
      {tasksQuery.data && practiceQuery.data && (
        <LoadedPatientFormTasks
          formTasksResponse={tasksQuery.data}
          practice={practiceQuery.data}
          print={isPrinting}
          idleConfig={PATIENT_IDLE_VALUES}
          onSessionExpired={handleSessionExpired}
          expiresAt={expiresAt}
          onComplete={() => {
            setFormTasksSessionToken(undefined);
          }}
        />
      )}
    </QueryResult>
  ) : (
    <div className="flex flex-col h-full items-center">
      <AnonUserMessagePage
        title={t("Welcome")}
        subTitle={t("app.formTasks.dobEntryTitle")}
        logoUrl={practiceQuery.data?.logo?.url}
      >
        <DateOfBirthForm
          error={errorOccurred.isOn ? isFormSubmissionError : undefined}
          initialDate={dob}
          onChange={errorOccurred.off}
          onSubmit={(selectedDob) => {
            setDob(formatAsISODate(selectedDob));
          }}
        />
      </AnonUserMessagePage>
    </div>
  );
};
