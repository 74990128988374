import { Link } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { ReactComponent as PlusIcon } from "assets/icons/currentColor/plus-circle-outline.svg";
import { Button } from "components/UI/Button";

export const AddButton: React.FC<{
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
  to?: string;
}> = ({ className, children, onClick, to }) => {
  const classes = cx(
    `text-primaryTheme
                     flex
                     items-center
                     space-x-2
                     my-4
                     text-sm
                     hover:text-opacity-70`,
    className
  );
  const child = (
    <>
      <PlusIcon className="h-5 w-5" />
      <div>{children}</div>
    </>
  );

  return to ? (
    <Link to={to} className={cx("font-sansSemiBold text-sm", classes)}>
      {child}
    </Link>
  ) : (
    <Button size="custom" theme="link" onClick={onClick} className={classes}>
      {child}
    </Button>
  );
};
