import React from "react";
import { useNavigate } from "react-router-dom";
import { ForgotPassword, useForgotPasswordState } from "components/ForgotPassword";
import { paths, AccountSettings } from "utils/paths";
import { TitleBar } from "components/UI/TitleBar";
import { useHideTabs } from "contexts/LayoutContext";

export const AccountForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const onPasswordReset = React.useCallback(() => {
    navigate(paths.accountSetting({ setting: AccountSettings.changePassword }));
  }, [navigate]);

  useHideTabs();

  const { forgotPasswordState, handleUpdateForgotPasswordState, stepTitle } = useForgotPasswordState();

  return (
    <div className="relative flex flex-col gap-5 h-full min-h-0">
      <TitleBar
        responsiveBackButton
        backTo={paths.accountSetting({ setting: AccountSettings.changePassword })}
        title={stepTitle}
      />
      <div className="px-5">
        <ForgotPassword
          onPasswordReset={onPasswordReset}
          forgotPasswordState={forgotPasswordState}
          onUpdateForgotPasswordState={handleUpdateForgotPasswordState}
        />
      </div>
    </div>
  );
};
