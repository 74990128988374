import { FC, useCallback } from "react";
import { useForcedSignOutContext } from "contexts/ForcedSignOutContext";
import { useSignOut } from "hooks/useSignOut";
import { SIGNED_IN_IDLE_TIMEOUT } from "config/react";
import { IdleTimer } from "components/Main/IdleTimer";

const SIGNED_IN_PROMPT_TIMEOUT = 300_000;
const SIGNED_IN_THROTTLE_ACTIVITY = 60_000;
const SIGNED_IN_IDLE_VALUES = {
  timeout: SIGNED_IN_IDLE_TIMEOUT,
  promptBeforeIdle: SIGNED_IN_PROMPT_TIMEOUT,
  eventsThrottle: SIGNED_IN_THROTTLE_ACTIVITY,
  name: "session",
};

export const SessionIdleTimer: FC = () => {
  const forcedSignOut = useForcedSignOutContext();
  const { signOut } = useSignOut();
  const handleIdle = useCallback(() => {
    forcedSignOut.current = true;
    signOut();
  }, [forcedSignOut, signOut]);

  return <IdleTimer onIdle={handleIdle} {...SIGNED_IN_IDLE_VALUES} />;
};
