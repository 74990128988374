import { StorageNamespaces } from "storage/namespaces";

export class MemoryStorage implements Storage {
  private _data = new Map<string, string>();

  clear(): void {
    this._data.clear();
  }

  getItem(key: string): string | null {
    return this._data.get(key) ?? null;
  }

  removeItem(key: string): void {
    this._data.delete(key);
  }

  key(index: number): string | null {
    return [...this._data.keys()][index] ?? null;
  }

  setItem(key: string, value: string): void {
    this._data.set(key, value);
  }

  get length(): number {
    return this._data.size;
  }
}

function hasLocalStorage() {
  try {
    const storage = window.localStorage;
    const featureTestKey = "gfLocalStorageFeatureTest";

    storage.setItem(featureTestKey, "1");
    storage.removeItem(featureTestKey);

    return true;
  } catch {
    return false;
  }
}

export function hasSessionStorage() {
  try {
    const storage = window.sessionStorage;
    const featureTestKey = "gfStorageFeatureTest";

    storage.setItem(featureTestKey, "1");
    storage.removeItem(featureTestKey);

    return true;
  } catch {
    return false;
  }
}

const isExpired = (ms: number) => Date.now() > ms;

interface StorageItem<T> {
  value: T;
  version: string;
  expires: number;
}

export function wrapStorage(storage: Storage) {
  return (namespace: StorageNamespaces) => {
    const getKey = (key: string) => {
      return `${namespace}_${key}`;
    };

    function set(key: string, value: unknown, options: { expires: number; version: string }) {
      const storageKey = getKey(key);

      storage.setItem(
        storageKey,
        JSON.stringify({
          value,
          version: options.version,
          expires: options.expires + Date.now(),
        })
      );
    }

    function clear(key: string) {
      const storageKey = getKey(key);

      storage.removeItem(storageKey);
    }

    function parse<T>(key: string): StorageItem<T> | null {
      const storageKey = getKey(key);
      const json = storage.getItem(storageKey);

      if (typeof json === "string") {
        return JSON.parse(json) as StorageItem<T>;
      }

      return json;
    }

    function get<T>(key: string, version: string): null | T {
      const item = parse<T>(key);

      if (!item) {
        return null;
      }

      if (isExpired(item.expires)) {
        clear(key);

        return null;
      }

      if (version && item.version !== version) {
        clear(key);

        return null;
      }

      return item.value;
    }

    return {
      get,
      set,
      clear,
    };
  };
}

let safeStorage: Storage;

if (typeof window === "undefined") {
  safeStorage = new MemoryStorage();
} else if (hasLocalStorage()) {
  safeStorage = window.localStorage;
} else if (hasSessionStorage()) {
  safeStorage = window.sessionStorage;
} else {
  safeStorage = new MemoryStorage();
}

export const createStorage = wrapStorage(safeStorage);
export type LocalStore = ReturnType<typeof createStorage>;
