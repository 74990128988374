import React from "react";
import { useTranslation } from "react-i18next";
import { Style } from "@react-pdf/types";

import { View } from "@react-pdf/renderer";
import {
  FormDateInputElementVO,
  FormNumberInputElementVO,
  FormTextInputElementVO,
} from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { Box } from "components/PatientForms/FormPDFElements/Box";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { FormElementTitle } from "components/PatientForms/FormPDFElements/FormElementTitle";
import { ResponseValue } from "components/PatientForms/hooks/usePatientResponses";

type Props = {
  element: FormDateInputElementVO | FormNumberInputElementVO | FormTextInputElementVO;
  response?: ResponseValue;
  edit: boolean;
};

const getStyleForType = (type: "TEXT_INPUT" | "NUMBER_INPUT" | "DATE_INPUT") => {
  const style: Style = {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    height: type === "TEXT_INPUT" ? 30 : 15,
  };

  return style;
};

// eslint-disable-next-line complexity
export const FormTextElement: React.FC<Props> = ({ element, response, edit }) => {
  const { settings, title } = element;
  const { t } = useTranslation();
  const required = settings.includes("REQUIRED");
  const textResponse = response?.type === "NUMBER" || response?.type === "STRING" ? response : undefined;
  const dateResponse = response?.type === "DATE" ? response : undefined;

  return (
    <Box wrap={false}>
      <FormElementTitle required={required}>{title}</FormElementTitle>
      <Text bold>
        {dateResponse?.response && formatISODate(dateResponse.response)}
        {textResponse && textResponse.response}
        {!textResponse && !dateResponse && !edit && t("Not Provided")}
      </Text>
      {!textResponse && !dateResponse && edit && <View style={getStyleForType(element.type)} />}
    </Box>
  );
};
