import { StyleSheet, View, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { colors } from "@libs/design.config";
import { formatISODate } from "@libs/utils/date";
import { half } from "@libs/utils/math";
import { formatAddress } from "@libs/utils/address";
import { isDefined } from "@libs/utils/types";
import { formatPhoneNumber } from "@libs/utils/phone";
import { containImage } from "@libs/utils/image";
import { PatientAttributes } from "components/PatientForms/utils";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { PAGE_PADDING, SPACE } from "components/PatientForms/FormPDFElements/styles";
import { ArchyPdfLogo } from "components/PatientForms/FormPDFElements/ArchyLogo";

const styles = StyleSheet.create({
  box: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.greyLightest,

    // Makes the header flush with the page
    paddingHorizontal: PAGE_PADDING,
    paddingVertical: half(PAGE_PADDING),
    marginLeft: -PAGE_PADDING,
    marginRight: -PAGE_PADDING,
    marginTop: -PAGE_PADDING,
    marginBottom: half(PAGE_PADDING),
  },
  leftSide: {
    flexDirection: "row",
    alignItems: "center",
  },
  practiceDetails: {
    flexDirection: "column",
  },
  rightSide: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-start",
  },
  image: {
    marginRight: 10,
  },
  rightAlign: {
    textAlign: "right",
  },
});

type Props = {
  practice: PracticeInfoVO;
  patientPersonalDetails: PatientAttributes;
  title?: string;
  dateCompleted?: string;
  dob?: string;
  logo?: {
    w: number;
    h: number;
    url: string;
  };
};

const getContactString = ({ email, call }: { email: string; call?: string }) => {
  return [email, call ? formatPhoneNumber(call) : undefined].filter(isDefined).join(" • ");
};

const getImageStyles = (logo: { w: number; h: number }) => {
  const imageContained = containImage(logo, { w: 58, h: 58 });

  return [styles.image, imageContained];
};

export const PracticePDFHeader: React.FC<Props> = ({
  practice,
  patientPersonalDetails,
  title,
  dateCompleted,
  dob,
  logo,
}) => {
  const { t } = useTranslation();
  const { firstName, lastName } = patientPersonalDetails;
  const { lineOne, lineTwo } = formatAddress(practice.addressDetails);

  return (
    <View style={styles.box}>
      <View style={styles.leftSide}>
        {logo && <Image src={logo.url} style={getImageStyles(logo)} />}
        <View style={styles.practiceDetails}>
          <Text bold size="base">
            {practice.name}
          </Text>
          <View style={{ flexDirection: "column", gap: 1 }}>
            <Text size="sm">{lineOne}</Text>
            <Text size="sm">{lineTwo}</Text>
            <Text size="sm">
              {t("pdf.header.contact", {
                contactDetails: getContactString({ email: practice.email, call: practice.phoneNumber }),
              })}
            </Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text size="sm" style={{ marginRight: SPACE.sm }}>
                {t("app.page.labels.placeholder.poweredBy")}
              </Text>
              <ArchyPdfLogo fill={colors.greyDark} style={{ width: 29, height: 9 }} />
            </View>
          </View>
        </View>
      </View>
      <View style={styles.rightSide}>
        {title && <Text style={styles.rightAlign}>{title}</Text>}
        <Text style={styles.rightAlign} bold>
          {firstName} {lastName}
        </Text>
        {dob && <Text style={styles.rightAlign}>DOB: {formatISODate(dob)}</Text>}
        {dateCompleted && <Text style={styles.rightAlign}>Completed: {dateCompleted}</Text>}
      </View>
    </View>
  );
};
