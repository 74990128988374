import { FC, MouseEventHandler, useCallback, useMemo, useRef } from "react";
import { add, format, parse, isValid, startOfYear } from "date-fns";
import DatePicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from "react-datepicker";
import { InputMask, MaskEventHandler } from "@react-input/mask";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { isDefined } from "@libs/utils/types";
import { ReactComponent as CancelIcon } from "assets/icons/currentColor/cancel-1.svg";
import { FormField, FormFieldProps } from "components/UI/FormField";
import { cxFormFieldStyle } from "components/UI/formFieldStyle";
import { FloatingTooltipProps } from "components/UI/FloatingTooltip";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { useMergeFormContext, useFormContext } from "contexts/FormContext";
import { ButtonIcon } from "components/UI/ButtonIcon";
import { ReactComponent as LeftCaret } from "assets/icons/currentColor/left-caret.svg";
import { ReactComponent as RightCaret } from "assets/icons/currentColor/right-caret.svg";
import { IS_MOBILE_DEVICE } from "utils/userAgent";
import { DatePickerBase } from "components/UI/DatePickerBase";

type FormFieldDatepickerProps = FormFieldProps & ReactDatePickerProps;

export interface FormFieldSelectMenusDatepickerProps extends Omit<FormFieldDatepickerProps, "value"> {
  formatReadOnlyValue?: (date?: Date | null) => string | JSX.Element;
  Icon?: IconComponent;
  onClickIcon?: MouseEventHandler<HTMLButtonElement>;
  iconTooltip?: Omit<FloatingTooltipProps, "children">;
  pickerHiddenOnMobile?: boolean;
}

const MIN_YEAR = 1900;
const MIN_DATE = new Date(MIN_YEAR, 0, 1);
const MAX_DATE = new Date("12/31/2099");

const MONTHS_PER_YEAR = 12;
const MASK_CHARACTER = "_";
const MASKED_DATE = "__/__/____";
const cxStyles = {
  select: `
    py-3
    px-5
    flex
    justify-center
    items-center
    text-primaryTheme
    font-sansSemiBold
    border
    border-greyLighter
    rounded
    appearance-none
    text-sm/4
    outline-none
    cursor-pointer
    bg-white
  `,
};
const CustomFormFieldDatePickerHeader: React.FC<{
  params: ReactDatePickerCustomHeaderProps;
  minDate?: Date;
  maxDate?: Date;
}> = ({ params, minDate = MIN_DATE, maxDate = MAX_DATE }) => {
  const monthOptions = useMemo(() => {
    const options = [];
    const startDate = startOfYear(new Date());

    for (let i = 0; i < MONTHS_PER_YEAR; i++) {
      const monthDate = add(startDate, { months: i });
      const optionText = monthDate.toLocaleString(navigator.language, { month: "short" });

      options.push(
        <option key={i} value={i}>
          {optionText}
        </option>
      );
    }

    return options;
  }, []);

  const yearOptions = useMemo(() => {
    const options = [];
    const maxYear = maxDate.getFullYear();

    for (let i = minDate.getFullYear(); i <= maxYear; ++i) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return options;
  }, [minDate, maxDate]);

  return (
    <div className="flex flex-row w-full justify-between mb-6 -mt-2">
      <ButtonIcon
        Icon={LeftCaret}
        theme="primary"
        disabled={params.prevMonthButtonDisabled}
        onClick={() => params.decreaseMonth()}
        tooltip={{ content: "Previous Month" }}
      />
      <select
        className={cxStyles.select}
        value={params.date.getMonth()}
        onChange={(e) => params.changeMonth(Number(e.target.value))}
      >
        {monthOptions}
      </select>
      <select
        className={cxStyles.select}
        value={params.date.getFullYear()}
        onChange={(e) => params.changeYear(Number(e.target.value))}
      >
        {yearOptions}
      </select>
      <ButtonIcon
        Icon={RightCaret}
        theme="primary"
        disabled={params.nextMonthButtonDisabled}
        onClick={() => params.increaseMonth()}
        tooltip={{ content: "Next Month" }}
      />
    </div>
  );
};

const useMobileDatePickerProps = ({
  onChange,
  canOpenDatepicker,
}: {
  canOpenDatepicker: boolean;
  onChange: FormFieldSelectMenusDatepickerProps["onChange"];
}): Partial<FormFieldDatepickerProps> => {
  const handleMask: MaskEventHandler = useCallback(
    (e) => {
      const value = e.target.value;

      if (value.includes("_")) {
        onChange(null, undefined);
      } else {
        const parsedDate = parse(value, "MM/dd/yyyy", new Date());

        if (!isValid(parsedDate)) {
          onChange(null, undefined);
        }
      }
    },
    [onChange]
  );

  return useMemo(() => {
    if (canOpenDatepicker) {
      return {};
    }

    return {
      open: false,
      customInput: (
        <InputMask
          mask={MASKED_DATE}
          replacement={{ [MASK_CHARACTER]: /\d/ }}
          showMask
          separate
          onMask={handleMask}
        />
      ),
    };
  }, [canOpenDatepicker, handleMask]);
};

// eslint-disable-next-line complexity
export const FormFieldDatePicker: FC<Omit<FormFieldSelectMenusDatepickerProps, "portalId">> = ({
  disabled,
  required,
  label,
  error,
  isClearable,
  className,
  children,
  id,
  edit = true,
  layout,
  formatReadOnlyValue,
  maxDate,
  disableErrorMessage,
  Icon,
  onClickIcon,
  iconTooltip,
  pickerHiddenOnMobile,
  onChange,
  ...datePickerOptions
}) => {
  const canOpenDatepicker = !(pickerHiddenOnMobile && IS_MOBILE_DEVICE);

  const formContext = useFormContext();
  const fieldId = useEnsureId({ customId: id });
  const mergedFormContext = useMergeFormContext(formContext, { layout });
  const mobileDatepickerProps = useMobileDatePickerProps({ onChange, canOpenDatepicker });

  const datePickerRef = useRef<DatePicker>(null);

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Tab") {
      datePickerRef.current?.setOpen(false);
    }
  }, []);

  return (
    <FormField
      disabled={disabled}
      required={required}
      label={label}
      error={error}
      edit={edit}
      layout={mergedFormContext.layout}
      className={cx(className)}
      disableErrorMessage={disableErrorMessage}
      id={fieldId}
    >
      <div className={cxFormFieldStyle.wrapper}>
        {edit ? (
          <>
            <DatePickerBase
              id={id}
              className={cx(
                cxFormFieldStyle.control({
                  hasIcon: true,
                  hasLabel: Boolean(label),
                  layout: mergedFormContext.layout,
                }),
                cxFormFieldStyle.input
              )}
              calendarStartDay={0}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              yearDropdownItemNumber={4}
              scrollableYearDropdown
              placeholderText="MM/DD/YYYY"
              minDate={MIN_DATE}
              maxDate={maxDate || MAX_DATE}
              onChange={onChange}
              {...datePickerOptions}
              {...mobileDatepickerProps}
              disabled={disabled}
              ref={datePickerRef}
              onKeyDown={onKeyDown}
              renderCustomHeader={(params) => (
                <CustomFormFieldDatePickerHeader
                  minDate={MIN_DATE}
                  maxDate={maxDate || MAX_DATE}
                  params={params}
                />
              )}
            />
            {isClearable && isDefined(datePickerOptions.selected) && (
              <ButtonIcon
                className="absolute right-8 bottom-1 z-10"
                Icon={CancelIcon}
                disabled={disabled}
                size="sm"
                theme="primary"
                onClick={() => onChange(null, undefined)}
              />
            )}
            {canOpenDatepicker && (
              <div
                className={cxFormFieldStyle.iconContainer({
                  layout: mergedFormContext.layout,
                  clickable: Boolean(onClickIcon),
                })}
              >
                {onClickIcon ? (
                  <ButtonIcon
                    Icon={Icon ?? CalendarIcon}
                    onClick={onClickIcon}
                    tooltip={iconTooltip}
                    disabled={disabled}
                    theme="primary"
                    size="sm"
                  />
                ) : Icon ? (
                  <Icon className={cxFormFieldStyle.icon({ disabled })} />
                ) : (
                  <CalendarIcon className={cxFormFieldStyle.icon({ disabled })} />
                )}
              </div>
            )}

            {children}
          </>
        ) : (
          <span className={cxFormFieldStyle.controlValueOnly({ layout: mergedFormContext.layout })}>
            {formatReadOnlyValue
              ? formatReadOnlyValue(datePickerOptions.selected)
              : datePickerOptions.selected
                ? `${format(datePickerOptions.selected, "MM/dd/yyyy")}`
                : "-"}
          </span>
        )}
      </div>
    </FormField>
  );
};
