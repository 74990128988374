import { StorageNamespaces } from "storage/namespaces";
import { createStorage } from "storage/createStorage";

const activityStorage = createStorage(StorageNamespaces.activity);
const version = "v1";

export const isRecentlyActive = () => {
  return Boolean(activityStorage.get<true>("recentlyActive", version));
};

export const setRecentlyActive = (expires: number) => {
  activityStorage.set("recentlyActive", true, {
    version,
    expires,
  });
};
