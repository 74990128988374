import { FC, PropsWithChildren, ReactNode } from "react";

interface Props {
  fallback: ReactNode;
}

export const SafelyRenderedContent: FC<PropsWithChildren<Props>> = ({ children, fallback }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children || fallback}</>;
};
