import { Auth } from "aws-amplify";
import { getFullUrl } from "@libs/utils/location";
import { paths } from "utils/paths";

export const getToken = async () => {
  try {
    const session = await Auth.currentSession();

    return session.getIdToken().getJwtToken();
  } catch (error) {
    if (error === "No current user") {
      return "";
    }

    throw error;
  }
};

export const getSignOutUrl = (forced?: boolean) =>
  forced ? paths.signIn({ returnUrl: getFullUrl(window.location) }) : paths.signIn();

export enum CognitoErrorCode {
  NotAuthorizedException = "NotAuthorizedException",
  UserNotFoundException = "UserNotFoundException",
  InvalidPasswordException = "InvalidPasswordException",
  CodeMismatchException = "CodeMismatchException",
  LimitExceededException = "LimitExceededException",
  Unknown = "Unknown",
  UsernameExistsException = "UsernameExistsException",
  InvalidParameterException = "InvalidParameterException",
  PasswordResetRequiredException = "PasswordResetRequiredException",
  UserNotConfirmedException = "UserNotConfirmedException",
}

const allCodes = new Set<string>(Object.values(CognitoErrorCode));

export const isCognitoErrorCode = (code: string) => {
  return allCodes.has(code);
};

export const userRequiresEmailConfirmation = (code?: CognitoErrorCode) => {
  return (
    code === CognitoErrorCode.PasswordResetRequiredException ||
    code === CognitoErrorCode.UserNotConfirmedException
  );
};
