import React, { FormEventHandler } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FormFieldInput } from "components/UI/FormFieldInput";
import { FormFieldPassword } from "components/UI/FormFieldPassword";
import { AsyncButton } from "components/UI/AsyncButton";
import { Form } from "components/UI/Form";
import { ReactComponent as LockIcon } from "assets/icons/currentColor/lock.svg";
import { SignInErrors, SignUpState, SignUpValidationResult } from "components/SignIn/validationUtil";
import { PasswordGuidelines } from "components/SignIn/PasswordGuidelines";
import { cxSignInStyles } from "components/SignIn/cxSignInStyles";
import { AlreadyHaveAnAccount } from "components/SignIn/AlreadyHaveAnAccount";
import { Checkbox } from "components/UI/Checkbox";
import { ThirdPartySignInButton } from "components/UI/ThirdPartySignInButton";
import { DividerWithText } from "components/SignIn/DividerWithText";
import { FootnoteLink } from "components/UI/FootnoteLink";

type Props = {
  signUpState: SignUpState;
  handleSignUp: FormEventHandler<HTMLFormElement>;
  onResetValidation: () => void;
  handleSignUpStateChange: (s: Partial<SignUpState>) => void;
  validation: SignUpValidationResult;
  canEditEmail?: boolean;
};
type SignInInputType = "confirmPassword" | "email" | "password";

export const SignUpForm: React.FC<Props> = ({
  handleSignUp,
  signUpState,
  handleSignUpStateChange,
  onResetValidation,
  validation,
  canEditEmail = true,
}) => {
  const { t } = useTranslation();
  const termsConditionsChecked = useBoolean(false);
  const handleInputChange = React.useCallback(
    (inputName: SignInInputType, value: string) => {
      onResetValidation();
      handleSignUpStateChange({ errorMessage: "", [inputName]: value });
    },
    [onResetValidation, handleSignUpStateChange]
  );
  const emailSignIn = (
    <FormFieldInput
      error={validation.email.$error ?? signUpState.errorMessage}
      id="email"
      label={canEditEmail ? t("Email") : t("Invite sent to")}
      layout="labelOut"
      disabled={!canEditEmail}
      autoComplete="username"
      name="email"
      Icon={canEditEmail ? undefined : LockIcon}
      onChange={(event) => handleInputChange("email", event.target.value)}
      placeholder="example@gmail.com"
      type="email"
      value={signUpState.email}
    />
  );

  return (
    <Form id="first-sign-in-form" className="flex flex-col gap-6 w-full pb-6" onSubmit={handleSignUp}>
      {!canEditEmail && emailSignIn}

      <div className="flex flex-col gap-2">
        <ThirdPartySignInButton
          className="w-full"
          provider="Google"
          onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}
        >
          {t("signIn.signInWith", { thirdParty: "Google" })}
        </ThirdPartySignInButton>
        <div className="text-xs">{t("signup.googleSigninWarning")}</div>
      </div>
      <DividerWithText className="text-sm text-greyMedium uppercase">{t("or")}</DividerWithText>

      {canEditEmail && emailSignIn}

      <PasswordGuidelines />

      <FormFieldPassword
        disableErrorMessage={validation.password.$error === SignInErrors.PASSWORD_MISMATCH}
        error={validation.password.$error}
        id="password"
        layout="labelOut"
        label="Password"
        autoComplete="new-password"
        name="password"
        onChange={(event) => handleInputChange("password", event.target.value)}
        placeholder="Enter Password"
        value={signUpState.password}
      />
      <FormFieldPassword
        error={validation.confirmPassword.$error}
        id="confirmPassword"
        layout="labelOut"
        label="Confirm Password"
        name="confirmPassword"
        autoComplete="new-password"
        onChange={(event) => handleInputChange("confirmPassword", event.target.value)}
        placeholder="Enter Password"
        value={signUpState.confirmPassword}
      />
      <Checkbox
        id="terms-conditions"
        name="Terms"
        theme="subtle"
        checked={termsConditionsChecked.isOn}
        onChange={termsConditionsChecked.toggle}
      >
        <div className="text-sm">
          <Trans
            i18nKey="app.page.labels.signUpConditions"
            components={{
              termsConditions: <FootnoteLink href="https://www.archy.com/terms-of-use" target="_blank" />,
              privacyPolicy: <FootnoteLink href="https://www.archy.com/privacy-policy/" target="_blank" />,
            }}
          />
        </div>
      </Checkbox>
      <AsyncButton
        className={cxSignInStyles.button}
        displayLoadingText
        isLoading={signUpState.isLoading}
        type="submit"
        disabled={!termsConditionsChecked.isOn}
      >
        {signUpState.isLoading ? t("Submitting") : t("Sign Up")}
      </AsyncButton>

      <AlreadyHaveAnAccount email={signUpState.email} />
    </Form>
  );
};
