import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { SelfBookingParams } from "utils/paths";
import { SelfBookingV1, getSelfBookingData, setSelfBookingData } from "storage/selfBooking";

export const useSelfBookingState = () => {
  const { practiceUuid } = useParams<SelfBookingParams>();
  const storedState = getSelfBookingData();
  const [localState, setLocalState] = useState(storedState || {});

  const setState = useCallback(
    (partialState: Partial<SelfBookingV1>) => {
      const newState = { ...storedState, ...partialState };

      setSelfBookingData(newState);
      setLocalState(newState);
    },
    [storedState]
  );

  const resetState = useCallback(() => {
    setSelfBookingData(null);
    setLocalState({});
  }, []);

  return useMemo(
    () => ({
      practiceUuid: practiceUuid as string,
      state: localState,
      setState,
      resetState,
    }),
    [practiceUuid, localState, setState, resetState]
  );
};
