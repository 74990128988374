import { PatientFormConsent } from "components/PatientForms/FormPDFElements/PatientFormConsent";
import { PatientFormInputElement } from "components/PatientForms/FormPDFElements/PatientFormInputElement";
import { PatientFormProcedureTableElement } from "components/PatientForms/FormPDFElements/PatientFormProcedureTableElement";
import { PatientFormSection } from "components/PatientForms/FormPDFElements/PatientFormSection";
import { PatientFormTextBlock } from "components/PatientForms/FormPDFElements/PatientFormTextBlock";
import { PatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { FormElement, isInputElement, PatientAttributes } from "components/PatientForms/utils";

type Props = {
  patientPersonalDetails: PatientAttributes;
  responsesById: PatientResponses;
  contentItem: FormElement;
  edit: boolean;
};

export const PatientFormContentItem: React.FC<Props> = ({
  contentItem,
  patientPersonalDetails,
  responsesById,
  edit,
}) => {
  const sharedProps = {
    responsesById,
    edit,
  };
  const key = contentItem.uuid;

  if (isInputElement(contentItem)) {
    return <PatientFormInputElement key={key} element={contentItem} {...sharedProps} />;
  }

  switch (contentItem.type) {
    case "CONSENT": {
      return <PatientFormConsent key={key} element={contentItem} {...sharedProps} />;
    }
    case "SECTION": {
      return (
        <PatientFormSection
          key={key}
          patientPersonalDetails={patientPersonalDetails}
          element={contentItem}
          {...sharedProps}
        />
      );
    }
    case "TEXT_BLOCK": {
      return <PatientFormTextBlock key={key} element={contentItem} {...sharedProps} />;
    }
    case "PROCEDURE_TABLE": {
      return <PatientFormProcedureTableElement key={key} element={contentItem} />;
    }
    default: {
      return null;
    }
  }
};
