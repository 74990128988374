import { forwardRef } from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { CurrencyLocales, dollarsToCents, formatCurrency } from "@libs/utils/currency";
import { useMergeFormContext, useFormContext } from "contexts/FormContext";
import { FormField, FormFieldProps } from "./FormField";
import { cxFormFieldStyle } from "./formFieldStyle";

export type FormFieldCurrencyInputProps = { Icon?: IconComponent; inputClassName?: string } & FormFieldProps &
  Omit<CurrencyInputProps, "decimalSeparator">;

export type FormFieldCurrencyInputHandler = NonNullable<FormFieldCurrencyInputProps["onValueChange"]>;

const DECIMAL_PLACES = 2;

export const FormFieldCurrencyInput = forwardRef<HTMLInputElement, FormFieldCurrencyInputProps>(
  (
    {
      disabled,
      value,
      required,
      label,
      error,
      layout,
      edit = true,
      className,
      Icon,
      id,
      intlConfig = { locale: "en-US", currency: "USD" },
      decimalScale = DECIMAL_PLACES,
      decimalsLimit = DECIMAL_PLACES,
      inputClassName,
      children,
      ...rest
    },
    ref
  ) => {
    const fieldId = useEnsureId({ customId: id });
    const formContext = useFormContext();
    const mergedFormContext = useMergeFormContext(formContext, { layout });

    return (
      <FormField
        edit={edit}
        disabled={disabled}
        required={required}
        label={label}
        error={error}
        layout={mergedFormContext.layout}
        className={className}
        id={fieldId}
      >
        {edit ? (
          <div className={cxFormFieldStyle.wrapper}>
            <CurrencyInput
              id={fieldId}
              ref={ref}
              disabled={disabled}
              value={value}
              intlConfig={intlConfig}
              decimalScale={decimalScale}
              decimalsLimit={decimalsLimit}
              required={required}
              className={cx(
                cxFormFieldStyle.control({
                  hasIcon: Boolean(Icon),
                  hasLabel: Boolean(label),
                  layout: mergedFormContext.layout,
                }),
                cxFormFieldStyle.input,
                inputClassName
              )}
              {...rest}
            />
            {Icon ? (
              <div className={cxFormFieldStyle.iconContainer({ layout: mergedFormContext.layout })}>
                <Icon role="img" className={cxFormFieldStyle.icon({ disabled })} />
              </div>
            ) : null}
            {children}
          </div>
        ) : (
          <span className={cxFormFieldStyle.controlValueOnly({ layout: mergedFormContext.layout })}>
            {Number.isNaN(Number(value))
              ? "-"
              : formatCurrency(dollarsToCents(Number(value)), intlConfig.locale as CurrencyLocales)}
          </span>
        )}
      </FormField>
    );
  }
);
