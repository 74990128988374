// NOTE: Passing `undefined` as the first arg to `Intl.NumberFormat`
// will default to the user's locale. We may want to do something

import { decimalAdjust, DecimalPlaces } from "@libs/utils/math";

// with this in the future.
const DEFAULT_ISO_LANGUAGE_CODE = "en-US";

const CENTS_PER_DOLLAR = 100;

const countryToCurrencyFormatter = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "en-US": Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }),
};

const roundToTwo = (num: number) => decimalAdjust("round", num, DecimalPlaces.hundredth);

export const centsToDollars = (cents: number) => roundToTwo(cents / CENTS_PER_DOLLAR);
export const dollarsToCents = (dollars: number) => roundToTwo(dollars * CENTS_PER_DOLLAR);

/**
 * Converts the given number of cents to dollars and returns the result as a
 * string. If the input is undefined or null, it returns an empty string.
 *
 * @param cents - The number of cents to convert to dollars.
 * @returns The converted dollar amount as a string.
 */
export const centsToDollarsString = (cents: number | undefined) =>
  cents == null ? "" : String(centsToDollars(cents));

export type CurrencyLocales = keyof typeof countryToCurrencyFormatter;

export const getCurrencyFormatter = (languageCode?: CurrencyLocales) => {
  return languageCode
    ? countryToCurrencyFormatter[languageCode]
    : countryToCurrencyFormatter[DEFAULT_ISO_LANGUAGE_CODE];
};

export const formatCurrency = (cents: number, languageCode?: CurrencyLocales) => {
  const formatter = getCurrencyFormatter(languageCode);

  return formatter.format(centsToDollars(cents));
};

/**
 * Formats a currency value as a delta, indicating whether the value has
 * increased or decreased. If the value is positive, the delta is prefixed with
 * a plus sign. If the value is negative, the delta is prefixed with a minus
 * sign.
 *
 * @param cents - The currency value to format as a delta, as cents.
 * @param languageCode - The currency code to use for formatting the value.
 * @returns A string representation of the currency value as a delta.
 */
export const formatCurrencyAsDelta = (cents: number, languageCode?: CurrencyLocales) => {
  const formatted = formatCurrency(cents, languageCode);

  return cents >= 0 ? `+ ${formatted}` : formatted;
};

export const currencyValueToCents = (value: string | undefined) => {
  const parsed = value === undefined ? Number.NaN : Number.parseFloat(value);

  if (Number.isNaN(parsed)) {
    return undefined;
  }

  return dollarsToCents(parsed);
};
export const centsToCurrencyValue = (cents: number | undefined) => {
  if (cents === undefined) {
    return "";
  }

  const dollars = centsToDollars(cents);

  return String(dollars);
};

export const currencyValueOrDash = (amount?: number) => (amount ? formatCurrency(amount) : "-");
