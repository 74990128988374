import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { ReactComponent as SuccessIcon } from "assets/icons/currentColor/check-circle-1-outline.svg";
import { ButtonIcon } from "components/UI/ButtonIcon";
import { ReactComponent as WarningIcon } from "assets/icons/currentColor/warning.svg";
import { ReactComponent as CloseIcon } from "assets/icons/currentColor/cancel-outline.svg";

const Icons = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: WarningIcon,
  info: WarningIcon,
} as const;

type Theme = keyof typeof Icons;

const cxThemesClassName: Record<Theme, { container: string; icon: string }> = {
  success: {
    container: "bg-greenLight",
    icon: "text-greenDark",
  },
  warning: {
    container: "bg-orangeLight",
    icon: "text-orangeDark",
  },
  error: {
    container: "bg-redLight",
    icon: "text-redDark",
  },
  info: {
    container: "bg-whiteLight",
    icon: "text-greyMedium",
  },
};

const cxStyles = {
  container: (theme: Theme) => cxThemesClassName[theme].container,
  icon: (theme: Theme) => cxThemesClassName[theme].icon,
};

const cxIconAlignment = {
  center: "items-center",
  start: "items-start",
};

interface Props {
  theme: Theme;
  className?: string;
  paddingClassName?: string;
  includeIcon?: boolean;
  children?: ReactNode;
  onRequestClose?: Func;
  contentAlignment?: keyof typeof cxIconAlignment;
}

export const Banner: FC<Props> = ({
  theme,
  children,
  className,
  contentAlignment = "center",
  paddingClassName = "px-3 py-2",
  includeIcon = true,
  onRequestClose,
}) => {
  const Icon = Icons[theme];

  return (
    <div
      role="banner"
      className={cx(
        "flex justify-between gap-x-3",
        cxIconAlignment[contentAlignment],
        cxStyles.container(theme),
        paddingClassName,
        className
      )}
    >
      <div className={cx("flex gap-x-3", cxIconAlignment[contentAlignment])}>
        {includeIcon ? <Icon className={cx("h-5 w-5 flex-none", cxStyles.icon(theme))} /> : null}
        {children}
      </div>

      {onRequestClose ? (
        <ButtonIcon
          Icon={CloseIcon}
          className={cxStyles.icon(theme)}
          onClick={onRequestClose}
          theme="custom"
        />
      ) : null}
    </div>
  );
};
