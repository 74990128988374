import React, { forwardRef } from "react";
import { cx } from "@libs/utils/cx";
import { UserAvatar } from "components/UI/UserAvatar";

type Props = {
  thumbnail?: string;
  className?: string;
  onClick?: () => void;
};

export const AvatarButton = forwardRef<HTMLButtonElement, Props>(
  // eslint-disable-next-line complexity
  ({ thumbnail, className, ...props }, ref) => {
    return (
      <button ref={ref} type="button" className={cx("rounded-full hover:opacity-70", className)} {...props}>
        <UserAvatar thumbnail={thumbnail} />
      </button>
    );
  }
);
