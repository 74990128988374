import React from "react";

import { useTranslation } from "react-i18next";
import {
  ContactDetailsVO,
  MessageSubscriptionVO,
  OnboardingOptionsVO,
  PatientVO,
  PersonalDetailsVO,
  UpdateMessageSubscriptionRequest,
  UpdatePatientRequest,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Form } from "components/UI/Form";
import { useObjectState } from "hooks/useObjectState";
import { semibold16 } from "assets/styles/textSize";
import { useCurrentUser } from "hooks/useCurrentUser";
import { PersonalDetailFields } from "components/Account/PersonalDetailFields";
import { ContactDetailFields } from "components/Account/ContactDetailFields";
import { EmailPreferencesFields } from "components/Account/EmailPreferencesFields";

type Props = {
  onSubmit: (generalInfo: UpdatePatientRequest, preferences: UpdateMessageSubscriptionRequest) => void;
  id: string;
  info: PatientVO;
  showPreferredPronouns?: boolean;
  form: "contact" | "personal";
  edit: boolean;
  preferences: MessageSubscriptionVO;
  onboardingOptions: OnboardingOptionsVO;
};

export const AccountForm: React.FC<Props> = ({
  onSubmit,
  id,
  info,
  showPreferredPronouns = false,
  edit,
  form,
  preferences,
  onboardingOptions,
}) => {
  const { t } = useTranslation();
  const { id: patientId } = useCurrentUser();

  const [userInfo, updateUserInfo] = useObjectState({
    personalDetails: info.personalDetails,
    contactDetails: info.contactDetails,
    additionalInformation: info.additionalInformation ?? {},
  });
  const [emailPreferences, setEmailPreferences] = React.useState<UpdateMessageSubscriptionRequest>({
    unsubscribeAll: preferences.unsubscribeAll,
    unsubscribeMarketing: preferences.unsubscribeMarketing,
    unsubscribeTransactional: preferences.unsubscribeTransactional,
  });

  const { personalDetails, contactDetails } = userInfo;

  const handlePersonalDetailsUpdated = React.useCallback(
    (partial: Partial<PersonalDetailsVO>) => {
      updateUserInfo({ personalDetails: { ...personalDetails, ...partial } });
    },
    [personalDetails, updateUserInfo]
  );
  const handleContactDetailsUpdated = React.useCallback(
    (partial: Partial<ContactDetailsVO>) => {
      updateUserInfo({ contactDetails: { ...contactDetails, ...partial } });
    },
    [contactDetails, updateUserInfo]
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(userInfo, emailPreferences);
    },
    [onSubmit, userInfo, emailPreferences]
  );

  return (
    <Form id={id} className="flex flex-col gap-5 p-5 h-full min-h-0" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 max-w-xl">
        {form === "contact" ? (
          <>
            <div className={cx("text-secondaryTheme", semibold16)}>{t("Contact Details")}</div>
            <ContactDetailFields
              contact={info.contact}
              canEditEmail={!info.contactDetails.email}
              contactDetails={contactDetails}
              onContactDetailsUpdated={handleContactDetailsUpdated}
              edit={edit}
            />
            <div className={cx("text-secondaryTheme mt-4", semibold16)}>{t("Email Preferences")}</div>
            <EmailPreferencesFields
              edit={edit}
              preferences={emailPreferences}
              onUpdate={setEmailPreferences}
            />
          </>
        ) : (
          <>
            <div className={cx("text-secondaryTheme", semibold16)}>{t("Personal Details")}</div>
            <PersonalDetailFields
              edit={edit}
              onPersonalDetailsUpdated={handlePersonalDetailsUpdated}
              personalDetails={personalDetails}
              patientId={patientId}
              onboardingOptions={onboardingOptions}
              showPreferredPronouns={showPreferredPronouns}
            />
          </>
        )}
        <div className="h-32" />
      </div>
    </Form>
  );
};
