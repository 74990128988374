import React from "react";
import { View } from "@react-pdf/renderer";
import { FormConsentElementVO } from "@libs/api/generated-api";
import { PatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { PatientFormLexicalRootNode } from "components/PatientForms/FormPDFElements/PatientFormLexicalRootNode";
import { Box } from "components/PatientForms/FormPDFElements/Box";
import { PDFCheckbox } from "components/PatientForms/FormPDFElements/PDFCheckbox";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { PDFSignatureText } from "components/PatientForms/FormPDFElements/PDFSignatureText";

type Props = {
  element: FormConsentElementVO;
  responsesById: PatientResponses;
  edit: boolean;
};

export const PatientFormConsent: React.FC<Props> = ({ element, responsesById, edit }) => {
  const { content: lexicalJson, statement, uuid, acceptsInitials } = element;

  // const handleError = useHandleError();
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const response = responsesById[uuid];

  const consentResponse = response?.type === "CONSENT" ? response : undefined;
  const consentStatement = <Text bold={edit}>{statement}</Text>;

  return (
    <View style={{ paddingBottom: 25 }}>
      <Box>
        <PatientFormLexicalRootNode lexicalJson={lexicalJson} />
      </Box>
      <View wrap={false}>
        {statement &&
          (edit ? (
            consentStatement
          ) : (
            <PDFCheckbox checked={Boolean(consentResponse?.consentedAt)}>{consentStatement}</PDFCheckbox>
          ))}

        <PDFSignatureText date={consentResponse?.consentedAt} acceptsInitials={acceptsInitials} edit={edit}>
          {consentResponse?.signature}
        </PDFSignatureText>
      </View>
    </View>
  );
};
