import { forwardRef } from "react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Auth } from "aws-amplify";
import { cx } from "@libs/utils/cx";
import { ButtonIconContent } from "components/UI/ButtonIconContent";
import { ReactComponent as GoogleIcon } from "assets/icons/currentColor/google.svg";

// Converts enum to union:
export type ProviderType = `${CognitoHostedUIIdentityProvider}`;
type Props = {
  provider: ProviderType;
};

const PROVIDER_ICON_MAP: Record<ProviderType, IconComponent> = {
  ["Google"]: GoogleIcon,
  ["COGNITO"]: () => null,
  ["Facebook"]: () => null,
  ["LoginWithAmazon"]: () => null,
  ["SignInWithApple"]: () => null,
};

export const ThirdPartySignInButton = forwardRef<
  HTMLButtonElement,
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, children, type = "button", disabled = false, provider, ...rest }, ref) => (
  <button
    {...{ ...rest, disabled }}
    onClick={() => Auth.federatedSignIn({ provider: provider as CognitoHostedUIIdentityProvider })}
    ref={ref}
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={cx(
      `flex
       items-center
       px-4
       py-3
       relative
       bg-white
       rounded-md
       font-sansSemiBold
       gap-2
       border-[#DADCE0]
       border
       hover:border-primaryTheme
       hover:border-opacity-70
       text-sm
       text-center
       text-greyDark
       hover:text-greyMedium`,
      className
    )}
  >
    <div className="absolute left-4 top-3">
      <ButtonIconContent size="md" theme="default" Icon={PROVIDER_ICON_MAP[provider]} />
    </div>
    <div className="flex-1 text-center">{children}</div>
  </button>
));
