import { ValueTypes } from "@libs/components/UI/OptionInput";
import { ToggleButtonList, ToggleButtonRadioListProps } from "components/UI/ToggleButtonList";
import { OptionInputOption } from "components/UI/OptionInputList";
import { ToggleButtonShape } from "components/UI/ToggleButton";

type Size = "sm" | "md" | "lg";

const sizeToShapes: Record<Size, ToggleButtonShape> = {
  sm: "toggleGroupSmall",
  md: "toggleGroupMedium",
  lg: "toggleGroupLarge",
};

export const ToggleGroup = <V extends ValueTypes, T extends OptionInputOption<V>>({
  size,
  ...rest
}: Omit<
  ToggleButtonRadioListProps<V, T>,
  "type" | "shape" | "layout" | "containerLayout" | "listClassName"
> & {
  size: keyof typeof sizeToShapes;
}) => {
  return (
    <ToggleButtonList
      layout="custom"
      shape={sizeToShapes[size]}
      listClassName="ring-1 ring-slate-300 rounded flex w-fit gap-x-0.5"
      type="radio"
      {...rest}
    />
  );
};
