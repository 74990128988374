import { forwardRef, InputHTMLAttributes, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { isNumber } from "@libs/utils/types";
import { FormField, FormFieldProps } from "components/UI/FormField";
import { FloatingTooltip, FloatingTooltipProps } from "components/UI/FloatingTooltip";
import { cxFormFieldStyle } from "components/UI/formFieldStyle";
import { useMergeFormContext, useFormContext } from "contexts/FormContext";
import { ButtonIcon } from "components/UI/ButtonIcon";

export type FormatReadOnlyValue = (value: string | undefined | null, id: string) => ReactNode;
export const EMPTY_CELL = "-";
interface BaseProps {
  Icon?: IconComponent;
  iconOnClick?: Func;
  iconTooltip?: Omit<FloatingTooltipProps, "children">;
  disableErrorMessage?: boolean;
  inputClassName?: string;
  formatReadOnlyValue?: FormatReadOnlyValue;
  value?: string | undefined | null;
  iconClassName?: string;
}

export type FormFieldInputProps = BaseProps &
  FormFieldProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, keyof FormFieldProps | "value">;

export const FormFieldInput = forwardRef<HTMLInputElement, FormFieldInputProps>(
  (
    {
      disabled,
      required,
      label,
      error,
      Icon,
      iconOnClick,
      iconTooltip = { content: "" },
      className,
      children,
      type = "text",
      layout,
      id,
      edit = true,
      disableErrorMessage = false,
      inputClassName,
      iconClassName,
      formatReadOnlyValue,
      ...rest
    },
    ref
  ) => {
    const formContext = useFormContext();
    const fieldId = useEnsureId({ customId: id });

    const mergedFormContext = useMergeFormContext(formContext, { layout });
    const controlStyles = cxFormFieldStyle.control({
      hasIcon: Boolean(Icon),
      hasLabel: Boolean(label),
      layout: mergedFormContext.layout,
    });
    const value = rest.value;
    const isValueBound = "value" in rest;

    return (
      <FormField
        disabled={disabled}
        required={required}
        label={label}
        error={error}
        layout={mergedFormContext.layout}
        className={className}
        edit={edit}
        disableErrorMessage={disableErrorMessage}
        id={fieldId}
      >
        {edit ? (
          <div className={cxFormFieldStyle.wrapper}>
            <input
              id={fieldId}
              ref={ref}
              type={type}
              disabled={disabled}
              className={cx(controlStyles, cxFormFieldStyle.input, inputClassName)}
              {...rest}
              value={isValueBound ? value ?? "" : undefined}
            />
            {Icon ? (
              iconOnClick ? (
                <ButtonIcon
                  className={cxFormFieldStyle.iconContainer({
                    layout: mergedFormContext.layout,
                    clickable: true,
                  })}
                  Icon={Icon}
                  onClick={iconOnClick}
                  iconClassName={iconClassName}
                />
              ) : (
                <div
                  className={cxFormFieldStyle.iconContainer({
                    layout: mergedFormContext.layout,
                    clickable: Boolean(iconTooltip.content),
                  })}
                >
                  <FloatingTooltip {...iconTooltip}>
                    <Icon role="img" className={cx(cxFormFieldStyle.icon({ disabled }), iconClassName)} />
                  </FloatingTooltip>
                </div>
              )
            ) : null}
            {children}
          </div>
        ) : (
          <span className={cxFormFieldStyle.controlValueOnly({ layout: mergedFormContext.layout })}>
            {formatReadOnlyValue
              ? formatReadOnlyValue(value, fieldId)
              : isNumber(value) || ![undefined, null, ""].includes(value)
                ? value
                : "-"}
          </span>
        )}
      </FormField>
    );
  }
);
