import { createContext, useMemo, useCallback, useState, PropsWithChildren } from "react";
import { Api, ErrorResponse, HttpResponse } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { noop } from "@libs/utils/noop";
import { getToken } from "utils/auth";
import { env } from "env";

const Context = createContext<{
  httpClient: InstanceType<typeof Api>;
  setFormTasksSessionToken: (token?: string) => void;
}>({
  httpClient: {} as InstanceType<typeof Api>,
  setFormTasksSessionToken: noop,
});

Context.displayName = "ApiClientContext";
export const ApiClientContext = Context;

const isPublicApi = (request: RequestInfo) => {
  if (typeof request === "string") {
    const parts = request.split(env.REACT_APP_API_HOST);

    return parts[1].startsWith("/public");
  }

  return false;
};

export const ApiClientProvider: React.FC<
  PropsWithChildren<{
    onErrorResponse: (response: HttpResponse<unknown, ErrorResponse | undefined>) => void;
    useToken: boolean;
  }>
> = ({ children, useToken, onErrorResponse }) => {
  const [formTasksSessionToken, setFormTasksSessionToken] = useState<string | undefined>();
  const handleFormTaskToken = useCallback((token?: string) => {
    setFormTasksSessionToken(token);
  }, []);
  const apiClient = useMemo(() => {
    return {
      setFormTasksSessionToken: handleFormTaskToken,
      httpClient: new Api({
        // eslint-disable-next-line complexity
        customFetch: async (...fetchParams: Parameters<typeof fetch>) => {
          let token = undefined;
          const firstArg = fetchParams[0];

          const url =
            typeof firstArg === "string"
              ? firstArg
              : firstArg instanceof Request
                ? firstArg.url
                : firstArg.href;

          if (isPublicApi(url) && formTasksSessionToken) {
            token = formTasksSessionToken;
          } else if (useToken) {
            token = await getToken();
          }

          const [info, options] = fetchParams;
          let newOptions = options;

          if (
            token &&
            options?.headers &&
            !Array.isArray(options.headers) &&
            !(options.headers instanceof Headers)
          ) {
            newOptions = {
              ...options,
              headers: {
                ...options.headers,
                Authorization: `Bearer ${token}`,
              },
            };
          }

          const response = await fetch(info, newOptions);

          if (!response.ok) {
            onErrorResponse(response.clone() as HttpResponse<null, ErrorResponse | undefined>);
          }

          return response;
        },
        baseUrl: env.REACT_APP_API_HOST,
        baseApiParams: {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "X-Grindfoundry-Portal": "patient",
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "X-GF-PATH": getFullUrl(location),
          },
        },
      }),
    };
  }, [handleFormTaskToken, formTasksSessionToken, useToken, onErrorResponse]);

  return <Context.Provider value={apiClient}>{children}</Context.Provider>;
};
