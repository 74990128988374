import { cx } from "@libs/utils/cx";
import {
  CheckboxOptionInputProps,
  OptionInput,
  RadioOptionInputProps,
} from "@libs/components/UI/OptionInput";
import { isOneOf } from "@libs/utils/isOneOf";
import { cxStyles as buttonCxStyles } from "components/UI/Button";

export type ToggleButtonShape = keyof typeof cxShapes;
export type ToggleButtonTheme = keyof typeof cxTheme;

type BaseProps = {
  shape?: ToggleButtonShape;
  theme?: ToggleButtonTheme;
  contentClassName?: string;
  error?: string;
  baseTextColor?: "text-primaryTheme" | "text-greyDark";
};

export type RadioToggleButtonProps = RadioOptionInputProps & BaseProps;
export type CheckboxToggleButtonProps = CheckboxOptionInputProps & BaseProps;
export type ToggleButtonProps = RadioToggleButtonProps | CheckboxToggleButtonProps;

type ThemeOptions = { disabled: boolean; checked?: boolean; error?: string };

export const cxTheme = {
  primary: ({ disabled, error, checked }: ThemeOptions) =>
    cx(
      disabled
        ? checked
          ? "bg-slate-200 ring-slate-200 text-slate-400"
          : "bg-white ring-white text-slate-400"
        : cx(
            "cursor-pointer",
            error
              ? "bg-redLight ring-red"
              : checked
                ? "bg-archyBlue-50 ring-archyBlue-500"
                : "bg-white ring-greyLighter hover:bg-slate-100 active:bg-slate-50"
          )
    ),
  secondary: ({ disabled, error, checked }: ThemeOptions) =>
    cx(
      "hover:text-opacity-70 hover:ring-opacity-70",
      disabled
        ? "bg-white ring-greyLighter"
        : cx(
            "cursor-pointer",
            error
              ? "bg-redLight ring-red"
              : checked
                ? "bg-actionLight ring-primaryTheme"
                : "bg-white ring-primaryTheme"
          )
    ),
};

type ShapeOptions = { checked?: boolean };

const cxShapes = {
  pill: () => "ring-1 rounded-2xl text-xs/4 py-1 px-2 h-6 box-border",
  mediumPill: () => "ring-1 rounded-full text-xs/4 py-2 px-3 h-8 box-border",
  largePill: () => "ring-1 rounded-full text-sm p-3 h-10 box-border",
  normal: () => "ring-1 h-7 text-xs/4 px-2 rounded box-border",
  smallButton: () => cx("ring-1", buttonCxStyles.sizes.small, buttonCxStyles.container),
  smallSquare: () => "ring-1 w-6 h-6 text-xs/4 rounded-sm items-center",
  mediumSquare: () => "ring-1 h-7 w-7 text-xs/4 rounded items-center",
  square: () => "ring-1 w-8 h-8 text-xs/4 rounded-sm items-center",
  // so that a toggle button can be the same height as a form fiel input with
  // layout labelOut
  inputLabelOutSquare: () => "ring-1 w-[34px] h-[34px] text-xs/4 rounded items-center",
  toggleGroupSmall: ({ checked }: ShapeOptions) => cx("rounded py-0.5 px-3 text-xs", checked && "ring-1"),
  toggleGroupMedium: ({ checked }: ShapeOptions) => cx("rounded py-2 px-3 text-xs", checked && "ring-1"),
  toggleGroupLarge: ({ checked }: ShapeOptions) => cx("rounded py-2 px-3 text-sm", checked && "ring-1"),
};

const cxStyles = {
  container: ({
    checked,
    disabled = false,
    shape,
    theme,
    error,
  }: {
    checked?: boolean;
    disabled?: boolean;
    error?: string;
    shape: ToggleButtonShape;
    theme: ToggleButtonTheme;
  }) =>
    cx(
      "flex justify-center items-center",
      cxShapes[shape]({ checked }),
      cxTheme[theme]({ disabled, checked, error })
    ),
  content: ({
    disabled,
    shape,
    theme,
    error,
    baseTextColor,
  }: {
    disabled?: boolean;
    checked?: boolean;
    shape: ToggleButtonShape;
    theme: ToggleButtonTheme;
    error?: string;
    baseTextColor: string;
  }) =>
    cx(
      "truncate block",
      disabled
        ? "text-greyLight"
        : error
          ? "text-red"
          : theme === "secondary"
            ? "text-primaryTheme"
            : baseTextColor,
      isOneOf(shape, ["smallSquare", "square"]) && "block"
    ),
};

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  className,
  error,
  shape = "normal",
  theme = "primary",
  baseTextColor = "text-greyDark",
  contentClassName,
  ...props
}) => {
  return (
    <OptionInput
      className={cx(
        cxStyles.container({ checked: props.checked, disabled: props.disabled, shape, theme, error }),
        className
      )}
      contentClassName={cx(
        cxStyles.content({
          disabled: props.disabled,
          checked: props.checked,
          shape,
          theme,
          error,
          baseTextColor,
        }),
        contentClassName
      )}
      {...props}
    />
  );
};
