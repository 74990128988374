import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import React, { useCallback, useMemo, useRef } from "react";
import { cx } from "@libs/utils/cx";
import { passRefs } from "@libs/utils/forms";
import { useClickOutsideTracker } from "contexts/ClickOutsideListenerContext";
import { usePortalId } from "contexts/PortalContext";

const DatePickerBaseWithRef = <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>(
  props: ReactDatePickerProps<CustomModifierNames, WithRange>,
  ref: React.ForwardedRef<DatePicker>
) => {
  const portalId = usePortalId();
  const clickOutsideProps = useClickOutsideTracker();

  const datePickerRef = useRef<DatePicker>(null);

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Tab") {
      datePickerRef.current?.setOpen(false);
    }
  }, []);
  const refs: (current: DatePicker) => void = useMemo(() => passRefs([ref, datePickerRef]), [ref]);

  return (
    <DatePicker
      portalId={portalId}
      wrapperClassName="react-datepicker-archy-datepicker-wrapper"
      popperContainer={(popperProps) => (
        <div {...popperProps} className="z-10 relative" {...clickOutsideProps} />
      )}
      {...props}
      popperClassName={cx(
        `react-datepicker-archy-patient-popper
         react-datepicker-archy-patient-calendar`,
        props.popperClassName
      )}
      ref={refs}
      onKeyDown={onKeyDown}
    />
  );
};

export const DatePickerBase = React.forwardRef(DatePickerBaseWithRef) as <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>(
  props: ReactDatePickerProps<CustomModifierNames, WithRange> & { ref?: React.ForwardedRef<DatePicker> }
) => ReturnType<typeof DatePickerBaseWithRef>;
