import { useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import { format } from "date-fns";
import { formatAddress } from "@libs/utils/address";
import { getLocalDate } from "@libs/utils/date";
import { formatPhoneNumber } from "@libs/utils/phone";
import { useApiMutations } from "api/mutations";
import { useApiQueries } from "api/queries";
import { getPracticeInfo } from "api/user/queries";
import { QueryResult } from "components/UI/QueryResult";
import { ConfirmAppointmentParams } from "utils/paths";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { useHandleError } from "api/handleErrorResponse";

import { viewAppointmentConfirmationDetails } from "api/scheduling/queries";
import { confirmAppointment } from "api/scheduling/mutations";

export const ConfirmAppointmentRoute: React.FC = () => {
  const handleError = useHandleError();
  const { practiceUuid, confirmToken } = useParams<ConfirmAppointmentParams>();
  const uuid = practiceUuid as string;
  const confirm = confirmToken as string;

  const [practiceQuery, appointmentDetailsQuery] = useApiQueries([
    getPracticeInfo({
      args: {
        practiceUuid: uuid,
      },
    }),
    viewAppointmentConfirmationDetails({
      args: {
        practiceUuid: uuid,
        confirmToken: confirm,
      },
    }),
  ]);
  const [confirmAppointmentMutation] = useApiMutations([confirmAppointment]);

  const confirmAppointmentMutate = confirmAppointmentMutation.mutate;
  const handleSubmit = useCallback(() => {
    confirmAppointmentMutate(
      {
        confirmToken: confirm,
      },
      {
        onError: handleError,
      }
    );
  }, [handleError, confirm, confirmAppointmentMutate]);

  const logoUrl = practiceQuery.data?.logo?.url;
  const address = formatAddress(practiceQuery.data?.addressDetails);

  const date = useMemo(() => {
    if (appointmentDetailsQuery.data) {
      return getLocalDate(appointmentDetailsQuery.data.date, appointmentDetailsQuery.data.startTime);
    }

    return null;
  }, [appointmentDetailsQuery.data]);

  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <div className="h-full bg-white">
      <div
        className={`
          max-w-xl
          min-h-72
          mx-auto
          h-full
          flex
          items-center
          justify-center
          p-6
        `}
      >
        <QueryResult queries={[practiceQuery, confirmAppointmentMutation, appointmentDetailsQuery]}>
          {practiceQuery.data && date && confirmAppointmentMutation.isSuccess ? (
            <div>
              <AnonUserMessagePage
                padding=""
                logoUrl={logoUrl}
                title={practiceQuery.data.name}
                subTitle={
                  <>
                    <p className="font-sansSemiBold mt-2">Your appointment is confirmed</p>
                    <div className="flex flex-col gap-y-2 mt-4">
                      <p>
                        We will see you at {format(date, "h:mm a")} on {format(date, "MMMM do")}.
                      </p>

                      {practiceQuery.data.phoneNumber ? (
                        <p>
                          If you need to make changes to your appointment please call us at{" "}
                          {formatPhoneNumber(practiceQuery.data.phoneNumber)}
                        </p>
                      ) : null}

                      <div>
                        <div>{address.lineOne}</div>
                        <div>{address.lineTwo}</div>
                      </div>
                    </div>
                  </>
                }
              />
            </div>
          ) : null}
        </QueryResult>
      </div>
    </div>
  );
};
