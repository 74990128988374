import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UpdateMessageSubscriptionRequest, UpdatePatientRequest } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { AccountForm } from "components/Account/AccountForm";
import { useApiMutations } from "api/mutations";
import { useApiQueries } from "api/queries";
import { QueryResult } from "components/UI/QueryResult";
import { useCurrentUser } from "hooks/useCurrentUser";
import { paths, PathDefinitions } from "utils/paths";
import { useHideTabs } from "contexts/LayoutContext";
import { StickySubmissionFooter } from "components/UI/StickySubmissionFooter";
import { getEmailSubscriptionByPatient, getPatientQuery } from "api/patient/queries";
import { useHandleError } from "api/handleErrorResponse";
import { updateMessageSubscription, updatePatient } from "api/patient/mutations";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";
import { ReactComponent as EditIcon } from "assets/icons/currentColor/edit.svg";
import { getOnboardingOptions } from "api/forms/queries";
import { ToggleHeader } from "components/UI/ToggleHeader";
import { ButtonIcon } from "components/UI/ButtonIcon";
import { useSearchQueryParams } from "hooks/useSearchQueryParams";

const useQueryParams = () => {
  const searchParams = useSearchQueryParams<PathDefinitions.accountProfile>();

  return React.useMemo(() => {
    const form: "contact" | "personal" = searchParams.get("form") === "personal" ? "personal" : "contact";

    return {
      form,
    };
  }, [searchParams]);
};

export const AccountProfile: React.FC = () => {
  const { t } = useTranslation();
  const { form } = useQueryParams();
  const edit = useBoolean(false);
  const { screenMatches } = useResponsiveBreakpoint();
  const { id: patientId, practiceId, practiceUuid } = useCurrentUser();
  const patientFormId = `patient-profile-form-${form}`;
  const handleError = useHandleError();
  const [updatePatientMutation, updateMessageSubscriptionMutation] = useApiMutations([
    updatePatient,
    updateMessageSubscription,
  ]);
  const [infoQuery, onboardingOptionsQuery, emailSubscriptionQuery] = useApiQueries([
    getPatientQuery({ args: { patientId, practiceId, includeContactPatientDetails: true } }),
    getOnboardingOptions({ args: { practiceId } }),
    getEmailSubscriptionByPatient({ args: { practiceId, patientId } }),
  ]);

  const handleSubmit = async (
    generalInfo: UpdatePatientRequest,
    updateMessageSubscriptionRequest: UpdateMessageSubscriptionRequest
  ) => {
    if (!infoQuery.data || !emailSubscriptionQuery.data) {
      return;
    }

    try {
      await Promise.all([
        updateMessageSubscriptionMutation.mutateAsync({
          practiceId,
          patientId,
          messageSubscriptionUuid: emailSubscriptionQuery.data.uuid,
          practiceUuid,
          updates: updateMessageSubscriptionRequest,
        }),
        updatePatientMutation.mutateAsync({
          practiceId,
          patientId,
          data: generalInfo,
        }),
      ]);
      edit.off();
    } catch (error) {
      handleError(error);
    }
  };

  const navigate = useNavigate();

  useHideTabs();

  const toggleHeaderOptions = useMemo(() => {
    return [
      { label: t("Personal Details"), value: "personal" as const },
      { label: t("Contact Details"), value: "contact" as const },
    ];
  }, [t]);

  return (
    <div className="relative h-full min-h-0 flex flex-col">
      <ToggleHeader
        backTo={screenMatches.has("sm") ? PathDefinitions.home : PathDefinitions.account}
        heightClass="h-16"
        options={toggleHeaderOptions}
        selectedValue={form === "personal" ? "personal" : "contact"}
        onChange={(_e, option) => {
          navigate(paths.accountProfile({ form: option.value }));
        }}
        disabled={edit.isOn}
        rightContent={
          edit.isOff ? <ButtonIcon theme="primary" Icon={EditIcon} onClick={edit.on} /> : undefined
        }
      />

      <div className="flex-1 gap-4 overflow-y-auto">
        <QueryResult queries={[infoQuery, onboardingOptionsQuery, emailSubscriptionQuery]}>
          {infoQuery.data && onboardingOptionsQuery.data && emailSubscriptionQuery.data && (
            <AccountForm
              id={patientFormId}
              onSubmit={handleSubmit}
              edit={edit.isOn}
              form={form}
              info={infoQuery.data}
              preferences={emailSubscriptionQuery.data}
              onboardingOptions={onboardingOptionsQuery.data}
            />
          )}
        </QueryResult>
        {edit.isOn && (
          <StickySubmissionFooter
            form={patientFormId}
            secondaryText={t("Cancel")}
            primaryText={t("Save")}
            onClickSecondary={edit.off}
            isSubmitting={updatePatientMutation.isLoading || updateMessageSubscriptionMutation.isLoading}
            className="absolute"
          />
        )}
      </div>
    </div>
  );
};
