import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { getPatientAppointments } from "api/patient/queries";
import { useApiQueries } from "api/queries";
import { useCurrentUser } from "hooks/useCurrentUser";
import { getSelfBookingConfig } from "api/self-booking/queries";
import { useInfiniteApiQuery } from "api/infiniteQueries";
import { getInvoicesInfiniteQuery, getPaymentProfiles } from "api/billing/queries";
import { useFlattenPages } from "api/utils";
import { semibold16, semibold20, semibold14 } from "assets/styles/textSize";
import { InternalLink } from "components/UI/Link";
import { AccountSettings, PathDefinitions, paths } from "utils/paths";
import { LoadingContent } from "components/Main/LoadingState";
import { AppointmentCard, NoticeCard } from "components/UI/Cards";
import { NoAppointmentsCard } from "components/AppointmentsRoute/NoAppointmentsCard";
import { InvoiceCard } from "components/Billing/InvoiceCard";
import { OnboardingCard } from "components/HomeRoute/OnboardingCard";

const cxStyles = {
  cardHeader: "flex justify-between px-4",
  section: "px-4",
  card: `
    border
    border-slate-300
    p-3
    rounded-md
    ml-4
    last:mr-4
    mb-2
    hover:border-secondaryTheme
    bg-white
    min-w-72
  `,
};

const HomeSection: React.FC<{
  title: string;
  children: React.ReactNode;
  viewAllLink: string;
  empty: boolean;
  emptyMessage: React.ReactNode;
}> = ({ title, children, viewAllLink, empty, emptyMessage }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3">
      <div className={cxStyles.cardHeader}>
        <div className={semibold16}>{title}</div>
        {!empty && (
          <InternalLink to={viewAllLink} className={semibold14}>
            {t("home.page.viewAllLink")}
          </InternalLink>
        )}
      </div>
      <div
        className={`
          w-full
          flex
          overflow-x-auto
          md:flex-col
          md:overflow-x-visible
        `}
      >
        {empty ? emptyMessage : children}
      </div>
    </div>
  );
};

export const HomeRoute: React.FC = () => {
  const { t } = useTranslation();
  const { practiceId, practiceUuid, id: patientId, fullName, onboardingState } = useCurrentUser();

  const unpaidInvoicesQuery = useInfiniteApiQuery(
    getInvoicesInfiniteQuery({
      args: {
        patientId,
        practiceId,
        pageNumber: 1,
        pageSize: 20,
        includePayments: true,
        includePayerInfo: true,
        includeOrders: false,
        states: ["FINALIZED", "PARTIALLY_PAID"],
      },
    })
  );
  const unpaidInvoices = useFlattenPages(unpaidInvoicesQuery.data);

  const [aptsQuery, paymentProfilesQuery, selfBookableConfigQuery] = useApiQueries([
    getPatientAppointments({
      args: { patientId, practiceId, query: { dateMode: "FUTURE", pageNumber: 1, pageSize: 20 } },
    }),
    getPaymentProfiles({ args: { patientId, practiceId } }),
    getSelfBookingConfig({
      args: {
        practiceUuid,
      },
    }),
  ]);
  const { data: apts } = aptsQuery;

  if ([aptsQuery, paymentProfilesQuery, selfBookableConfigQuery].some((q) => q.isLoading)) {
    return <LoadingContent />;
  }

  return (
    <div className="flex flex-col md:items-center w-full">
      <div className="flex flex-col gap-4 md:max-w-lg">
        <div className={cx("flex flex-col gap-2 mt-4", cxStyles.section)}>
          <div className={semibold20}>
            {t("app.pages.user.salutation", { patientName: fullName?.split(" ")[0] })}
          </div>
          <div>{t("home.page.welcome")}</div>
        </div>
        <OnboardingCard onboardingState={onboardingState} />
        {(unpaidInvoices?.length ?? 0) > 0 && (
          <HomeSection
            title={t("home.page.PendingPayments")}
            viewAllLink={PathDefinitions.invoices}
            empty={unpaidInvoices?.length === 0}
            emptyMessage={null}
          >
            {unpaidInvoices?.map((invoice) => {
              return <InvoiceCard inCarousel key={invoice.uuid} invoice={invoice} />;
            })}
          </HomeSection>
        )}
        <HomeSection
          title={t("home.page.upcomingApts")}
          viewAllLink={PathDefinitions.appointments}
          empty={apts?.length === 0}
          emptyMessage={
            <NoAppointmentsCard
              className="mx-4"
              appointmentType="FUTURE"
              selfBookingEnabled={selfBookableConfigQuery.data?.enabled || false}
            />
          }
        >
          {(apts?.length ?? 0) > 0
            ? apts?.map((apt) => {
                return <AppointmentCard inCarousel key={apt.id} appointment={apt} />;
              })
            : null}
        </HomeSection>
        {paymentProfilesQuery.data?.length === 0 && (
          <NoticeCard
            title={t("home.page.updatePaymentInfo.title")}
            body={t("home.page.updatePaymentInfo.body")}
            linkText={t("Add Payment Method")}
            linkTo={paths.addPaymentMethod({ from: PathDefinitions.home })}
          />
        )}
        <NoticeCard
          title={t("home.page.updateMedicalInfo.title")}
          body={t("home.page.updateMedicalInfo.body")}
          linkText={t("home.page.updateMedicalInfo.link")}
          linkTo={paths.accountSetting({ setting: AccountSettings.medicalHistory })}
        />
      </div>
    </div>
  );
};
