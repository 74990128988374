import { Style } from "@react-pdf/types";
import { colors } from "@libs/design.config";
import { Text } from "components/PatientForms/FormPDFElements/Text";

type Props = {
  children?: string;
  required?: boolean;
  style?: Style;
};

export const FormElementTitle: React.FC<Props> = ({ children, required, style }) => {
  if (!children) {
    return null;
  }

  return (
    <Text style={[{ marginBottom: 4 }, style ?? {}]}>
      {children}
      {required && <Text style={{ color: colors.red }}> *</Text>}
    </Text>
  );
};
