import { TextareaHTMLAttributes, forwardRef } from "react";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { FormField, FormFieldProps } from "components/UI/FormField";
import { cxFormFieldStyle } from "components/UI/formFieldStyle";
import { useMergeFormContext, useFormContext } from "contexts/FormContext";

interface BaseProps {
  Icon?: IconComponent;
  disableResize?: boolean;
  inputClassName?: string;
  fullHeight?: boolean;
}

export type FormFieldTextareaProps = BaseProps &
  FormFieldProps &
  Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, keyof FormFieldProps>;

export const FormFieldTextarea = forwardRef<HTMLTextAreaElement, FormFieldTextareaProps>(
  (
    {
      disabled,
      required,
      label,
      error,
      Icon,
      className,
      inputClassName,
      children,
      disableErrorMessage,
      disableResize,
      fullHeight,
      id,
      layout,
      edit = true,
      ...rest
    },
    ref
  ) => {
    const fieldId = useEnsureId({ customId: id });
    const formContext = useFormContext();
    const mergedFormContext = useMergeFormContext(formContext, { layout });

    return (
      <FormField
        disabled={disabled}
        required={required}
        label={label}
        error={error}
        disableErrorMessage={disableErrorMessage}
        layout={mergedFormContext.layout}
        edit={edit}
        className={cx(className, fullHeight && "h-full flex flex-col")}
        containerClassName={cx(fullHeight && "flex-1 min-h-0")}
        id={fieldId}
      >
        {edit ? (
          <div className={cx(cxFormFieldStyle.wrapper, fullHeight && "h-full")}>
            <textarea
              id={fieldId}
              ref={ref}
              disabled={disabled}
              className={cx(
                cxFormFieldStyle.control({
                  hasIcon: Boolean(Icon),
                  hasLabel: Boolean(label),
                  layout: mergedFormContext.layout,
                }),
                cxFormFieldStyle.input,
                disableResize && "resize-none",
                fullHeight && "h-full overflow-y-auto",
                inputClassName
              )}
              {...rest}
            />
            {Icon ? (
              <div className={cxFormFieldStyle.iconContainer({ layout: mergedFormContext.layout })}>
                <Icon role="img" className={cxFormFieldStyle.icon({ disabled })} />
              </div>
            ) : null}
            {children}
          </div>
        ) : (
          <span
            className={cx(
              "whitespace-pre-line",
              cxFormFieldStyle.controlValueOnly({ layout: mergedFormContext.layout })
            )}
          >
            {rest.value || rest.defaultValue || "-"}
          </span>
        )}
      </FormField>
    );
  }
);
