import React from "react";
import { useTranslation } from "react-i18next";
import { Pill } from "components/UI/Pill";
import { cxFormFieldStyle } from "components/UI/formFieldStyle";
import { OTHER_ID } from "components/PatientForms/utils";
import { PatientFormFieldTitle } from "components/PatientForms/FormElements/FormFieldTitle";

export const ReadOnlyOptions: React.FC<{
  selected: string[];
  title: string;
  otherResponse?: string;
  required?: boolean;
}> = ({ selected, title, required, otherResponse }) => {
  const { t } = useTranslation();

  return (
    <>
      <PatientFormFieldTitle title={title} required={required} />
      <div className="flex flex-row flex-wrap gap-2 mt-1">
        {selected.length > 0 ? (
          [...selected].map((option) => (
            <Pill key={option}>{option === OTHER_ID ? `Other: ${otherResponse ?? "-"}` : option}</Pill>
          ))
        ) : (
          <div className={cxFormFieldStyle.controlValueOnly({ layout: "labelOut" })}>{t("Not Provided")}</div>
        )}
      </div>
    </>
  );
};
