import { useMemo } from "react";
import { useUserContext } from "contexts/UserContext";

const ONBOARDING_ORDER = [
  "GENERAL_INFO" as const,
  "INSURANCE_INFO" as const,
  "DENTAL_HISTORY_INFO" as const,
  "MEDICAL_HISTORY_INFO" as const,
  "PAYMENT_INFO" as const,
  "CONSENT" as const,
  "PDF_SUBMISSION" as const,
];

export type OnboardingStep = (typeof ONBOARDING_ORDER)[number];

export const useOnboardingOrder = () => {
  const { practice } = useUserContext();
  const { onboardedWithPaymentProvider } = practice;

  return useMemo(() => {
    if (!onboardedWithPaymentProvider) {
      return ONBOARDING_ORDER.filter((step) => step !== "PAYMENT_INFO");
    }

    return ONBOARDING_ORDER;
  }, [onboardedWithPaymentProvider]);
};
