import { CenteredResponsiveLayout } from "components/UI/CenteredResponsiveLayout";
import { Header } from "components/Main/Header/Header";
import { PatientOnboardingWizardRoute } from "components/Onboarding/PatientOnboardingWizard/PatientOnboardingWizardRoute";
import { useHideHeader, useHideTabs } from "contexts/LayoutContext";

export const OnboardingPages = () => {
  useHideTabs();
  useHideHeader();

  return (
    <div className="relative min-h-0 pt-[50px]">
      <Header reloadOnUserChanged />
      <CenteredResponsiveLayout size="2xl" className="bg-white border-b border-b-greyLighter">
        <PatientOnboardingWizardRoute />
      </CenteredResponsiveLayout>
    </div>
  );
};
