import { cx } from "@libs/utils/cx";
import { semibold16 } from "assets/styles/textSize";
import { PracticeBranding } from "components/UI/PracticeBranding";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";
import { Header } from "components/Main/Header/Header";

type Props = {
  title?: string;
  children: React.ReactNode;
  includeBranding?: boolean;
};
export const SignInWrapper: React.FC<Props> = ({ children, title, includeBranding = true }) => {
  const { screenMatches } = useResponsiveBreakpoint();

  return (
    <>
      {(includeBranding || title) && (
        <>
          {includeBranding && screenMatches.has("md") ? (
            <PracticeBranding />
          ) : (
            <>
              <div className="h-[50px]" />
              <Header signedOut />
            </>
          )}
          {title && <div className={cx("text-slate-900", semibold16)}>{title}</div>}
        </>
      )}
      {children}
    </>
  );
};
