import React from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ForgotPassword, useForgotPasswordState } from "components/ForgotPassword";
import { paths } from "utils/paths";
import { defaultSnackbarOptions } from "utils/snackbar";
import { SignInWrapper } from "components/SignIn/SignInWrapper";

export const SignInForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const onPasswordReset = React.useCallback(() => {
    navigate(paths.signIn());
    enqueueSnackbar(t("forgotPassword.successfulReset"), defaultSnackbarOptions);
  }, [navigate, enqueueSnackbar, t]);
  const { forgotPasswordState, handleUpdateForgotPasswordState, stepTitle } = useForgotPasswordState();

  return (
    <SignInWrapper includeBranding={false} title={stepTitle}>
      <ForgotPassword
        onPasswordReset={onPasswordReset}
        forgotPasswordState={forgotPasswordState}
        onUpdateForgotPasswordState={handleUpdateForgotPasswordState}
      />
    </SignInWrapper>
  );
};
