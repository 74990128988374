import { HOUR_IN_MINUTES, MINUTE_IN_SECONDS, SECOND_IN_MS } from "@libs/utils/date";

import { hasSessionStorage, MemoryStorage, wrapStorage } from "storage/createStorage";
import { StorageNamespaces } from "storage/namespaces";

export type SelfBookingV1 = {
  existingPatient?: boolean;
  isoDate?: string;
  isoTime?: string;
  providerId?: number;
  providerName?: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  email?: string;
  phone?: string;
  comments?: string;
  seenSooner?: boolean;
  categoryId?: number;
  hasInsurance?: boolean;
  carrierId?: number;
  carrierName?: string;
};

const TWO_HOURS = 2;
const expires = TWO_HOURS * HOUR_IN_MINUTES * MINUTE_IN_SECONDS * SECOND_IN_MS;

const version = "v1";
const key = "selfBooking";

const selfBookingStorage = hasSessionStorage() ? window.sessionStorage : new MemoryStorage();
const selfBooking = wrapStorage(selfBookingStorage)(StorageNamespaces.selfBooking);

export const getSelfBookingData = () => {
  return selfBooking.get<SelfBookingV1>(key, version);
};

export const setSelfBookingData = (selfBookingData: SelfBookingV1 | null) => {
  return selfBooking.set(key, selfBookingData, {
    expires,
    version,
  });
};
