import React, { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { cxStyles, ButtonProps } from "./Button";

export const ButtonLink = forwardRef<
  HTMLAnchorElement,
  Omit<ButtonProps, "inactive"> & React.AnchorHTMLAttributes<HTMLAnchorElement>
>(({ className, children, theme = "primary", size = "large", ...rest }, ref) => {
  return (
    <a
      {...rest}
      ref={ref}
      className={cx(
        cxStyles.container,
        cxStyles.themes[theme]({ disabled: false }),
        cxStyles.sizes[size],
        className
      )}
    >
      {children}
    </a>
  );
});

export const ButtonInternalLink = forwardRef<HTMLAnchorElement, Omit<ButtonProps, "inactive"> & LinkProps>(
  ({ className, children, theme = "primary", size = "large", ...rest }, ref) => {
    return (
      <Link
        {...rest}
        ref={ref}
        className={cx(
          cxStyles.container,
          cxStyles.themes[theme]({ disabled: false }),
          cxStyles.sizes[size],
          className
        )}
      >
        {children}
      </Link>
    );
  }
);
