import React from "react";
import { Hub } from "aws-amplify";
import { HubCallback } from "@aws-amplify/core";
import { SnackbarProvider } from "notistack";
import { captureException } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { IntlProvider } from "react-intl";
import { retryCodes } from "@libs/utils/statusCodes";
import { getToken } from "utils/auth";
import { PatientPortalApp } from "components/Main/PatientPortalApp";
import { isRecentlyActive } from "storage/activity";
import { paths } from "utils/paths";
import { normal14 } from "assets/styles/textSize";
import { isHttpResponseError } from "api/utils";

const retryConfig = {
  retry: (failureCount: number, error: unknown) => {
    if (isHttpResponseError(error)) {
      return retryCodes.has(error.status) && failureCount < MAX_RETRIES;
    }

    return false;
  },
};

const MAX_RETRIES = 3;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: retryConfig,
    mutations: retryConfig,
  },
});

export const App = () => {
  const [{ isLoading, token }, setState] = React.useState({
    isLoading: true,
    token: "",
  });
  const loadToken = React.useCallback(async () => {
    try {
      if (isRecentlyActive()) {
        const newToken = await getToken();

        setState({ isLoading: false, token: newToken });
      } else {
        setState({ isLoading: false, token: "" });
      }
    } catch (error) {
      captureException(error);
      window.location.assign(paths.signIn());
    }
  }, []);

  React.useEffect(() => {
    const onAuthMessage: HubCallback = async (listener) => {
      const payload = listener.payload;

      switch (payload.event) {
        case "signOut": {
          setState({
            isLoading: true,
            token: "",
          });
          break;
        }
        case "signIn":
        case "confirmSignUp": {
          const tok = await getToken();

          setState({
            isLoading: false,
            token: tok,
          });
          break;
        }
        default: {
          break;
        }
      }
    };

    Hub.listen("auth", onAuthMessage);

    return () => {
      Hub.remove("auth", onAuthMessage);
    };
  }, []);

  React.useEffect(() => {
    loadToken();
  }, [loadToken]);

  return (
    <SnackbarProvider className={normal14} maxSnack={3}>
      <IntlProvider locale="en">
        <QueryClientProvider client={queryClient}>
          {!isLoading && <PatientPortalApp token={token} />}
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </IntlProvider>
    </SnackbarProvider>
  );
};
