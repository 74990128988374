import { forwardRef, useState } from "react";
import { cx } from "@libs/utils/cx";
import { FormFieldInput, FormFieldInputProps } from "components/UI/FormFieldInput";
import { ReactComponent as Hide } from "assets/icons/currentColor/hide.svg";
import { ReactComponent as Show } from "assets/icons/currentColor/eye.svg";
import { cxIconStyles } from "components/UI/formFieldStyle";

export type FormFieldPasswordProps = Omit<FormFieldInputProps, "password" | "Icon">;

export const FormFieldPassword = forwardRef<HTMLInputElement, FormFieldInputProps>((props, ref) => {
  const [type, setType] = useState<"password" | "text">("password");
  const iconClassName = cxIconStyles.element({
    disabled: props.disabled,
  });

  return (
    <FormFieldInput {...props} ref={ref} type={type}>
      <button
        type="button"
        tabIndex={-1}
        className={cx(
          "absolute bottom-full z-0",
          props.layout === "labelOut" && "-translate-y-1.5",
          props.layout === "labelLeft" ? "right-0" : "right-1"
        )}
        onClick={() => setType((last) => (last === "password" ? "text" : "password"))}
      >
        {type === "password" ? <Hide className={iconClassName} /> : <Show className={iconClassName} />}
      </button>
    </FormFieldInput>
  );
});
