import React, { useCallback, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { ReactComponent as BackIcon } from "assets/icons/currentColor/left-caret.svg";
import ProfileBackground from "assets/icons/AccountProfileBackground.svg";
import { ReactComponent as InfoExclamationIcon } from "assets/icons/currentColor/info.svg";
import { ReactComponent as SignOutIcon } from "assets/icons/currentColor/sign-out.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/currentColor/settings.svg";
import { ReactComponent as InsuranceIcon } from "assets/icons/currentColor/shield.svg";
import { ReactComponent as MedicalFileIcon } from "assets/icons/currentColor/medical-file.svg";
import { ReactComponent as CreditCardsMultipleIcon } from "assets/icons/currentColor/bank-cards.svg";
import { ReactComponent as UserIcon } from "assets/icons/currentColor/user.svg";
import { formatThumbnailData } from "utils/user";
import { useHandleError } from "api/handleErrorResponse";
import { uploadProfileImage, deleteProfileImage } from "api/user/mutations";
import { useApiMutations } from "api/mutations";

import { CameraComponent } from "deprecated/camera-component";
import { useSignOut } from "hooks/useSignOut";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";
import { paths, PathDefinitions, AccountSettings } from "utils/paths";
import { useUserContext } from "contexts/UserContext";
import { useApiQueries } from "api/queries";
import { getPatientQuery } from "api/patient/queries";
import { EditProfileImageModal } from "components/Account/EditProfileImageModal";
import { AccountButtonItem, AccountLinkItem } from "components/Account/AccountOptionItems";

const Divider = () => <div className="h-px w-full bg-slate-300" />;

const useIsIndexAccountPage = () => {
  const location = useLocation();

  return location.pathname === "/account";
};

export const AccountNavigationRoute: React.FC = () => {
  const { signOut } = useSignOut();
  const { t } = useTranslation();
  const [imageEditing, setImageEditing] = useState<File | undefined>();
  const isAccountIndexPage = useIsIndexAccountPage();
  const { screenMatches } = useResponsiveBreakpoint();
  const { refetchUser, currentUser } = useUserContext();
  const { id: patientId, practiceId, thumbnail } = currentUser;

  const [patientQuery] = useApiQueries([
    getPatientQuery({ args: { patientId, practiceId, includeContactPatientDetails: true } }),
  ]);
  const { personalDetails, contactDetails } = patientQuery.data ?? {};
  const email = contactDetails?.email;
  const patientName = personalDetails?.firstName;
  const [uploadProfileImageMutation, deleteProfileImageMutation] = useApiMutations([
    uploadProfileImage,
    deleteProfileImage,
  ]);

  const handleError = useHandleError();
  const handleDeleteEditingImage = useCallback(() => {
    setImageEditing(undefined);
  }, []);
  const handleImageUpload = useCallback(
    (resizedImage: File) => {
      uploadProfileImageMutation.mutate(
        { practiceId, userId: patientId, image: resizedImage },
        {
          onSuccess: () => {
            refetchUser();
            setImageEditing(undefined);
          },
          onError: handleError,
        }
      );
    },
    [handleError, patientId, practiceId, refetchUser, uploadProfileImageMutation]
  );
  const handleImageSelected = useCallback((image: File) => {
    setImageEditing(image);
  }, []);
  const onDeleteProfilePicture = () => {
    deleteProfileImageMutation.mutate(
      { practiceId, userId: patientId },
      { onError: handleError, onSuccess: refetchUser }
    );
  };

  const hideOnMobile = cx(!isAccountIndexPage && "hidden sm:block");

  return (
    <>
      {imageEditing && (
        <EditProfileImageModal
          onClose={handleDeleteEditingImage}
          onDone={handleImageUpload}
          initialImage={imageEditing}
          isSaving={uploadProfileImageMutation.isLoading}
        />
      )}
      <div className="h-full flex">
        <div className={cx("flex flex-col w-full sm:w-[18rem]", hideOnMobile)}>
          <div
            className="min-h-[120] bg-cover"
            style={{
              backgroundImage: `url(${ProfileBackground})`,
            }}
          >
            <div className="flex items-center min-h-[104px] w-full flex-row pl-4 pt-4">
              <CameraComponent
                showDelete
                setImage={handleImageSelected}
                profilePicture={formatThumbnailData(thumbnail)}
                deleteProfilePicture={onDeleteProfilePicture}
                patientName={patientName}
                patientEmail={email}
                showName
              />
            </div>
          </div>
          <div className="flex flex-col pt-2 px-3 gap-1">
            <AccountLinkItem to={PathDefinitions.home} Icon={BackIcon} className="hidden sm:flex">
              {t("Go Back")}
            </AccountLinkItem>
            <Divider />

            <AccountLinkItem to={paths.accountProfile({ form: "personal" })} Icon={UserIcon}>
              {t("app.page.labels.account.myprofile")}
            </AccountLinkItem>

            <AccountLinkItem
              to={paths.accountSetting({ setting: AccountSettings.medicalHistory })}
              Icon={MedicalFileIcon}
            >
              {t("Medical Information")}
            </AccountLinkItem>

            <AccountLinkItem to={PathDefinitions.insurances} Icon={InsuranceIcon}>
              {t("app.page.labels.account.myInsurances")}
            </AccountLinkItem>

            <AccountLinkItem to={PathDefinitions.paymentMethods} Icon={CreditCardsMultipleIcon}>
              {t("Payment Methods")}
            </AccountLinkItem>
            <Divider />

            <AccountLinkItem
              to={paths.accountSetting({ setting: AccountSettings.changePassword })}
              Icon={SettingsIcon}
            >
              {t("Change Password")}
            </AccountLinkItem>
            <AccountButtonItem Icon={SignOutIcon} onClick={signOut}>
              {t("app.page.labels.account.signOut")}
            </AccountButtonItem>
            <Divider />
            <AccountLinkItem external to={PathDefinitions.externalArchy} Icon={InfoExclamationIcon}>
              {t("app.page.labels.account.about")}
            </AccountLinkItem>
          </div>
        </div>
        <div
          className={`
            h-full
            flex
            flex-col
            min-h-0
            flex-1
            border-l
            border-greyLightest
          `}
        >
          <Outlet />
        </div>
        {screenMatches.has("sm") && isAccountIndexPage && (
          <Navigate to={paths.accountProfile({ form: "personal" })} replace />
        )}
      </div>
    </>
  );
};
