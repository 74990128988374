import { FormSectionVO } from "@libs/api/generated-api";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { PatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { Box } from "components/PatientForms/FormPDFElements/Box";
import { PatientFormContentItem } from "components/PatientForms/FormPDFElements/PatientFormContentItem";
import { PatientAttributes } from "components/PatientForms/utils";

type Props = {
  element: FormSectionVO;
  patientPersonalDetails: PatientAttributes;
  responsesById: PatientResponses;
  // Used when printing to allow user to fill out answer
  edit?: boolean;
};
export const PatientFormSection: React.FC<Props> = ({
  element,
  patientPersonalDetails,
  responsesById,
  edit = false,
}) => {
  const { title, content } = element;

  return (
    <Box>
      <Box>
        <Text bold size="lg">
          {title}
        </Text>
      </Box>

      {content.map((item) => {
        return (
          <PatientFormContentItem
            key={item.uuid}
            contentItem={item}
            responsesById={responsesById}
            patientPersonalDetails={patientPersonalDetails}
            edit={edit}
          />
        );
      })}
    </Box>
  );
};
