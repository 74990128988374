import React from "react";
import { noop } from "@libs/utils/noop";
import { UseBooleanResult, useBoolean } from "@libs/hooks/useBoolean";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";

export interface LayoutContextValue {
  header: UseBooleanResult;
  tabs: UseBooleanResult;
  tabsOnBottom: boolean;
}

const initialState: UseBooleanResult = {
  on: noop,
  off: noop,
  toggle: noop,
  set: noop,
  isOn: true,
  isOff: false,
};

const Context = React.createContext<LayoutContextValue>({
  header: initialState,
  tabs: initialState,
  tabsOnBottom: false,
});

Context.displayName = "LayoutContext";
export const LayoutContext = Context;
export const useLayoutContext = () => React.useContext(LayoutContext);

export const useHideTabs = () => {
  const { tabs } = useLayoutContext();
  const tabsSet = tabs.set;

  React.useEffect(() => {
    tabsSet(false);

    return () => tabsSet(true);
  }, [tabsSet]);
};
export const useHideHeader = () => {
  const { header } = useLayoutContext();
  const headerSet = header.set;

  React.useEffect(() => {
    headerSet(false);

    return () => headerSet(true);
  }, [header, headerSet]);
};

export const LayoutContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { screenMatches } = useResponsiveBreakpoint();
  const header = useBoolean(true);
  const tabs = useBoolean(true);
  const largeScreen = screenMatches.has("md");
  const layoutValue = React.useMemo(() => {
    return {
      header,
      tabs,
      tabsOnBottom: !largeScreen,
    };
  }, [header, largeScreen, tabs]);

  return <LayoutContext.Provider value={layoutValue}>{children}</LayoutContext.Provider>;
};
