import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { PathConfigs } from "utils/paths";

interface DeserializerFn<T> {
  (e?: string): T;
}

export const toArray: DeserializerFn<string[]> = (s?: string) => s?.split(",") ?? [];

export const useSearchQueryParams = <T extends keyof PathConfigs>() => {
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    return {
      get: <
        K extends keyof NonNullable<PathConfigs[T]["query"]>,
        DeserializedReturnType extends NonNullable<PathConfigs[T]["query"]>[K],
      >(
        key: K,
        deserializer: DeserializerFn<DeserializedReturnType> = (e) => e as DeserializedReturnType
      ) => {
        const result = searchParams.get(key as string);

        if (result) {
          return deserializer(result);
        }

        return undefined;
      },
    };
  }, [searchParams]);
};
