import {
  MutationFunction,
  MutationKey,
  QueryClient,
  UseMutateAsyncFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { RequestParams, ErrorResponse, Api, HttpResponse } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { ApiName, Domains, getQueryKey, Head, mergeRequestParams } from "api/queries";
import { ApiClientContext } from "contexts/ApiClientContext";

export type HttpMutationErrorResponse = HttpResponse<unknown, ErrorResponse>;
export type UseApiMutationAsync<Response, Variables> = UseMutateAsyncFunction<
  HttpResponse<Response, ErrorResponse>,
  HttpMutationErrorResponse,
  Variables,
  unknown
>;

type ApiInstance = InstanceType<typeof Api>;

interface ApiMutationContext {
  api: ApiInstance;
  headers: HeadersInit;
  queryClient: QueryClient;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Mutation<D = any, P = any> = (
  context: ApiMutationContext
) => [MutationKey, MutationFunction<D, P>, UseMutationOptions<D, HttpMutationErrorResponse, P> | undefined];

type GetApiMethod<D extends Domains, A extends ApiName<D>> = ApiInstance[D][A] extends (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any
) => Promise<unknown>
  ? ApiInstance[D][A]
  : never;

type ExtractData<T> = T extends Promise<infer D> ? D : never;

type ApiMethodData<D extends Domains, A extends ApiName<D>> = ExtractData<ReturnType<GetApiMethod<D, A>>>;

interface MakeMutationOptions<D extends Domains, A extends ApiName<D>, P> {
  mutationKey: [D, A];
  formatParams: (args: P) => [...Head<Parameters<GetApiMethod<D, A>>>];
  requestOptions?: RequestParams;
  mutationOptions?: (
    queryClient: QueryClient
  ) => Omit<UseMutationOptions<ApiMethodData<D, A>, HttpMutationErrorResponse, P>, "mutationFn">;
}

type MakeMutation = <D extends Domains, A extends ApiName<D>, P>(
  options: MakeMutationOptions<D, A, P>
) => Mutation<ApiMethodData<D, A>, P>;

export const makeMutation: MakeMutation =
  (options) =>
  ({ api, headers, queryClient }) => [
    [getQueryKey(...options.mutationKey)],
    (args) => {
      const [domain, apiCall] = options.mutationKey;
      const method = api[domain][apiCall] as GetApiMethod<typeof domain, typeof apiCall>;

      return method(
        ...options.formatParams(args),
        mergeRequestParams({ headers }, { ...options.requestOptions })
      ) as Promise<ApiMethodData<typeof domain, typeof apiCall>>;
    },
    options.mutationOptions?.(queryClient),
  ];

export type MutationResult<T> = T extends Mutation<infer D, infer P>
  ? UseMutationResult<D, HttpMutationErrorResponse, P>
  : never;

export type MappedMutationResults<T> = {
  [K in keyof T]: MutationResult<T[K]>;
};

export const useApiMutations = <T extends Mutation[]>(configs: [...T]) => {
  const { httpClient } = useContext(ApiClientContext);
  const location = useLocation();
  const queryClient = useQueryClient();

  return configs.map((config) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const options = config({ api: httpClient, headers: { "X-GF-PATH": getFullUrl(location) }, queryClient });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMutation(...options);
  }) as MappedMutationResults<T>;
};
