import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PathDefinitions, paths, AccountSettings } from "utils/paths";
import { ReactComponent as EditIcon } from "assets/icons/currentColor/edit.svg";
import { ToggleHeader } from "components/UI/ToggleHeader";
import { ButtonIcon } from "components/UI/ButtonIcon";

type Props = {
  onClickEdit: Func;
  edit?: boolean;
  selectedValue: "MEDICAL_HISTORY" | "DENTAL_HISTORY";
};
export const MedicalInformationRouteHeader: React.FC<Props> = ({ onClickEdit, selectedValue, edit }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toggleHeaderOptions = useMemo(() => {
    return [
      { label: t("Medical History"), value: "MEDICAL_HISTORY" as const },
      { label: t("Dental History"), value: "DENTAL_HISTORY" as const },
    ];
  }, [t]);

  return (
    <ToggleHeader
      backTo={PathDefinitions.account}
      heightClass="h-16"
      options={toggleHeaderOptions}
      selectedValue={selectedValue}
      onChange={(_e, option) => {
        navigate(
          paths.accountSetting({
            setting:
              option.value === "DENTAL_HISTORY"
                ? AccountSettings.dentalHistory
                : AccountSettings.medicalHistory,
          })
        );
      }}
      disabled={edit}
      rightContent={edit ? undefined : <ButtonIcon theme="primary" Icon={EditIcon} onClick={onClickEdit} />}
    />
  );
};
