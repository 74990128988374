import { useBoolean } from "@libs/hooks/useBoolean";
import { MedicalInformationRouteHeader } from "components/Account/MedicalInformationRouteHeader";
import { useHideTabs } from "contexts/LayoutContext";
import { PatientForm } from "components/PatientForms/PatientForm";
import { ViewPatientFormResponses } from "components/Account/ViewPatientFormResponses";

export const AccountMedicalHistoryRoute = () => {
  useHideTabs();

  const editing = useBoolean(false);

  return (
    <div
      className={`
        relative
        flex-1
        flex
        flex-col
        border-l
        border-l-greyLighter
        bg-white
      `}
    >
      <MedicalInformationRouteHeader
        onClickEdit={editing.on}
        selectedValue="MEDICAL_HISTORY"
        edit={editing.isOn}
      />
      {editing.isOn ? (
        <PatientForm
          uuidOrSlug="MEDICAL_HISTORY"
          edit={editing.isOn}
          onSuccess={editing.off}
          reconsent
          onPrevious={editing.off}
        />
      ) : (
        <ViewPatientFormResponses uuidOrSlug="MEDICAL_HISTORY" />
      )}
    </div>
  );
};
