import { ReactNode } from "react";
import { cx } from "@libs/utils/cx";

export interface FormFieldContainerProps {
  children?: ReactNode;
  className?: string;
  error?: string;
  disabled?: boolean;
}

const cxStyle = {
  container: ({ error, disabled }: { disabled?: boolean; error?: string }) =>
    cx(
      "rounded border",
      disabled ? "bg-greyLightest" : "bg-white",
      disabled
        ? "border-greyLight"
        : error
          ? "border-red"
          : "focus-within:border-primaryTheme hover:border-primaryTheme"
    ),
};

export const FormFieldContainer: React.FC<FormFieldContainerProps> = ({
  disabled,
  children,
  className,
  error,
}) => {
  return <div className={cx(cxStyle.container({ error, disabled }), className)}>{children}</div>;
};
