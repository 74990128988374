import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { ApiClientProvider } from "contexts/ApiClientContext";
import { ForcedSignOutContextProvider } from "contexts/ForcedSignOutContext";
import { SessionIdleTimer } from "components/Main/SessionIdleTimer";
import { SignedInApp } from "components/Main/SignedInApp";
import { SignedOutApp } from "components/Main/SignedOutApp";
import { ErrorContent } from "components/UI/ErrorContent";
import { useGlobalApiErrorHandler } from "components/Main/useGlobalApiErrorHandler";

export const PatientPortalApp: React.FC<{ token?: string }> = ({ token }) => {
  const handleGlobalApiError = useGlobalApiErrorHandler();

  return (
    <SentryErrorBoundary fallback={<ErrorContent />}>
      <ApiClientProvider useToken={Boolean(token)} onErrorResponse={handleGlobalApiError}>
        {token ? (
          <ForcedSignOutContextProvider>
            <SignedInApp />
            <SessionIdleTimer />
          </ForcedSignOutContextProvider>
        ) : (
          <SignedOutApp />
        )}
      </ApiClientProvider>
    </SentryErrorBoundary>
  );
};
