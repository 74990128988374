import { useSearchParams } from "react-router-dom";
import React from "react";
import { getPracticeInfo } from "api/user/queries";
import { useApiQueries } from "api/queries";
import { useInstrumentationForPractice } from "hooks/useInstrumentationForPractice";

export const PRACTICE_UUID_KEY = "practiceUuid";

// Intended for pulling practice info when logged out
export const usePracticeInfo = (parsePracticeId = false) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const practiceUuid = localStorage.getItem(PRACTICE_UUID_KEY);

  React.useEffect(() => {
    const parsedUuid = searchParams.get("uuid");

    if (parsedUuid && parsePracticeId) {
      localStorage.setItem(PRACTICE_UUID_KEY, parsedUuid);
      searchParams.delete("uuid");
      setSearchParams(Object.fromEntries(new URLSearchParams(searchParams)));
    }
  }, [searchParams, parsePracticeId, setSearchParams]);

  const hasPracticeId = Boolean(practiceUuid);
  const [{ data: practice, isLoading, error, refetch }] = useApiQueries([
    getPracticeInfo({
      args: { practiceUuid: practiceUuid as string },
      queryOptions: { enabled: Boolean(practiceUuid) },
    }),
  ]);

  useInstrumentationForPractice(practice);

  return {
    practice,
    isLoading: hasPracticeId && isLoading,
    error,
    refetch,
  };
};
