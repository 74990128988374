import { createContext, MutableRefObject, useContext, useRef, FC } from "react";

type ContextValue = MutableRefObject<boolean>;

const Context = createContext<ContextValue>({} as ContextValue);

Context.displayName = "ForcedSignOutContext";
export const useForcedSignOutContext = () => useContext(Context);

export const ForcedSignOutContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const forcedSignOutRef = useRef(false);

  return <Context.Provider value={forcedSignOutRef}>{children}</Context.Provider>;
};
