import { Navigate } from "react-router-dom";
import { MainSignIn } from "components/SignIn/MainSignIn";
import { SignInForgotPassword } from "components/SignIn/SignInForgotPassword";

import { WelcomeLanding } from "components/SignIn/WelcomeLanding";

import { PathDefinitions, paths } from "utils/paths";
import { FirstSignIn } from "components/SignIn/FirstSignIn";
import { SelfBookingRoute } from "components/SelfBooking/SelfBookingRoute";
import { PatientFormPreviewRoute } from "components/PatientForms/PatientFormPreview/PatientFormPreviewRoute";
import { PatientFormTasksRoute } from "components/PatientForms/PatientFormTasks/PatientFormTasksRoute";
import { PatientKioskRoute } from "components/PatientForms/PatientKiosk/PatientKioskRoute";
import { GuestCheckoutRoute } from "components/GuestCheckout/GuestCheckoutRoute";
import { EmailPreferencesRoute } from "components/Account/EmailPreferencesRoute";
import { ConfirmAppointmentRoute } from "components/SelfBooking/ConfirmAppointmentRoute";

export const SignedOutRoutes: {
  path: string;
  element: JSX.Element;
}[] = [
  {
    path: PathDefinitions.loggedOutLanding,
    element: <Navigate to={paths.signIn()} replace />,
  },
  {
    path: PathDefinitions.welcomePage,
    element: <WelcomeLanding />,
  },
  {
    path: PathDefinitions.signIn,
    element: <MainSignIn />,
  },
  {
    path: PathDefinitions.signUp,
    element: <FirstSignIn />,
  },
  {
    path: PathDefinitions.forgotPasswordSignedOut,
    element: <SignInForgotPassword />,
  },
];

export const AnonRoutes = [
  {
    path: PathDefinitions.guestCheckout,
    element: <GuestCheckoutRoute />,
  },
  {
    path: PathDefinitions.selfBooking,
    element: <SelfBookingRoute />,
  },
  {
    path: PathDefinitions.previewForm,
    element: <PatientFormPreviewRoute />,
  },
  {
    path: PathDefinitions.formTasks,
    element: <PatientFormTasksRoute />,
  },
  {
    path: PathDefinitions.kiosk,
    element: <PatientKioskRoute />,
  },

  // Todo - remove these older public routes when the portal stops pointing to them
  {
    path: PathDefinitions.selfBookingOld,
    element: <SelfBookingRoute />,
  },
  {
    path: PathDefinitions.previewFormOld,
    element: <PatientFormPreviewRoute />,
  },
  {
    path: PathDefinitions.kioskOld,
    element: <PatientKioskRoute />,
  },
  {
    path: PathDefinitions.formTasksOld,
    element: <PatientFormTasksRoute />,
  },
  {
    path: PathDefinitions.emailPreferences,
    element: <EmailPreferencesRoute />,
  },
  {
    path: PathDefinitions.confirmAppointment,
    element: <ConfirmAppointmentRoute />,
  },
];
