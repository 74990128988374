import { stripAllButNumbers } from "@libs/utils/inputFormatting";
import { ssnRegex } from "@libs/utils/regex";

/* eslint-disable @typescript-eslint/no-magic-numbers */
export const SSN_LENGTH = 9;

const MIDDLE_SECTION = 3;
const LAST_SECTION = 5;

/**
 * Returns a SSN string in the format XXX-XX-XXXX.
 * @param val a string of numbers representing a full or partial SSN number
 * @returns A formatted SSN string.
 */
export const formatSSNInput = (value: string) => {
  if (!value) {
    return "";
  }

  const characters = [...value];
  let formatted = "";

  for (const [index, char] of characters.entries()) {
    if (index === MIDDLE_SECTION) {
      formatted += `-${char}`;
    } else if (index === LAST_SECTION) {
      formatted += `-${char}`;
    } else {
      formatted += char;
    }
  }

  return formatted;
};

/**
 * Formats a string using the SSN masking format, such as `XXX-XX-1234`
 * @param val the string to mask
 * @returns the masked string
 */
export const getFormattedSSNHidden = (val?: string) => {
  if (!val) {
    return `\u2022\u2022\u2022 \u2022\u2022 \u2022\u2022\u2022\u2022`;
  }

  return `\u2022\u2022\u2022 \u2022\u2022 ${val}`;
};

/**
 * Returns `true` if `value` is a valid SSN string, `false` otherwise.
 * @param value a string to test whether the string is a valid SSN number
 * @returns a boolean
 */
export const isSSN = (value: string) => {
  return ssnRegex.test(value);
};

export const parseSSN = (value: string) => stripAllButNumbers(value).slice(0, SSN_LENGTH);
