import React from "react";
import { useTranslation } from "react-i18next";
import { FormSelectInputElementVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { mirroredOptions } from "@libs/utils/forms";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { PatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { YesNoQuestion } from "components/PatientForms/FormPDFElements/YesNoQuestion";
import { Box } from "components/PatientForms/FormPDFElements/Box";
import { FormElementTitle } from "components/PatientForms/FormPDFElements/FormElementTitle";
import { PatientFormSelectInput } from "components/PatientForms/FormPDFElements/PatientFormSelectInput";
import { OTHER_ID } from "components/PatientForms/utils";

type Props = {
  element: FormSelectInputElementVO;
  responsesById: PatientResponses;
  edit: boolean;
};

export const PatientFormSelectElement: React.FC<Props> = ({ element, responsesById, edit }) => {
  const { uuid, title, settings, options } = element;
  const formatSettings = new Set(settings);
  const prevResponse = responsesById[uuid];
  const { t } = useTranslation();
  const required = formatSettings.has("REQUIRED");
  const prevSelectResponse = prevResponse?.type === "SELECT" ? prevResponse : undefined;
  const canSelectMultiple = formatSettings.has("ALLOW_MULTIPLE_SELECTIONS");
  const hasOtherOption = formatSettings.has("ALLOW_ADDITIONAL_OPTION");
  const elementOptions = React.useMemo(() => {
    const allOptions: { label: string; value: string }[] = mirroredOptions(options);
    const otherValue = prevSelectResponse?.other;
    const selected =
      (prevSelectResponse?.responses &&
        options.filter((value) => Boolean(prevSelectResponse.responses[value]))) ??
      [];

    if (isDefined(otherValue)) {
      selected.push("other");
    }

    return {
      allOptions,
      selectedValue: selected[0] as string | undefined,
      selectedSet: new Set(selected),
    };
  }, [options, prevSelectResponse]);
  const isOtherSelected = elementOptions.selectedSet.has(OTHER_ID);

  const otherResponseContent = elementOptions.selectedSet.has(OTHER_ID) && (
    <Text bold>
      Other - <Text italic>{prevSelectResponse?.other}</Text>
    </Text>
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Box>
      <FormElementTitle required={required}>{title}</FormElementTitle>
      {canSelectMultiple ? (
        <PatientFormSelectInput
          options={elementOptions.allOptions}
          isOtherSelected={isOtherSelected}
          hasOtherOption={hasOtherOption}
          otherResponse={prevSelectResponse?.other}
          responses={prevSelectResponse?.responses}
          type="checkbox"
        />
      ) : formatSettings.has("ENFORCE_EXPLICIT_CONSENT") ? (
        <>
          {elementOptions.allOptions
            .filter((item) => item.value !== OTHER_ID)
            .map((option, i) => {
              const key = `${uuid}-${i}`;

              return (
                <YesNoQuestion
                  key={key}
                  label={option.label}
                  selectedValue={prevSelectResponse?.responses[option.value]}
                />
              );
            })}
        </>
      ) : (
        <>
          {
            // Radio case:
            edit ? (
              <PatientFormSelectInput
                options={elementOptions.allOptions}
                isOtherSelected={isOtherSelected}
                hasOtherOption={hasOtherOption}
                otherResponse={prevSelectResponse?.other}
                responses={prevSelectResponse?.responses}
                type="radio"
              />
            ) : (
              <Text bold>{elementOptions.selectedValue ?? t("Not Provided")}</Text>
            )
          }
          {otherResponseContent}
        </>
      )}
    </Box>
  );
};
