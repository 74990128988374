import { Path, Svg, G } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";

type Props = {
  fill: string;
  style?: Style;
};
export const ArchyPdfLogo: React.FC<Props> = ({ fill, style }) => {
  return (
    <Svg width="54" height="18" viewBox="0 0 54 18" style={style}>
      <Path
        d="M13.6747 3.84961C13.045 3.14966 12.5102 2.92808 12.0587 2.95245C11.559 2.97942 10.9782 3.28144 10.3286 4.02848C9.68559 4.76783 9.08381 5.82331 8.54568 7.06356C7.55496 9.34694 6.86546 12.0547 6.50662 14.0139H4.23511C4.59104 11.9229 5.34398 8.8309 6.48934 6.19113C7.07126 4.84993 7.77974 3.56578 8.63195 2.5858C9.47748 1.6135 10.5768 0.81645 11.9368 0.743059C13.345 0.667066 14.4868 1.42329 15.3465 2.37891C16.2082 3.33672 16.9163 4.62929 17.4933 5.98489C18.6296 8.65445 19.3709 11.8426 19.7193 14.0139H17.4516C17.1004 11.9714 16.4178 9.1619 15.4309 6.84327C14.8967 5.58815 14.3024 4.54737 13.6747 3.84961Z"
        fill={fill}
      />
      <Path
        d="M38.2042 14.014H36.0168V2.15323H38.2042V5.9008C38.4659 5.70537 38.7357 5.56804 39.0134 5.48881C39.2912 5.4043 39.5743 5.36204 39.8627 5.36204C40.3915 5.36204 40.8883 5.4624 41.353 5.66312C41.8177 5.85855 42.221 6.13057 42.5629 6.47918C42.9101 6.82251 43.1825 7.22659 43.3801 7.6914C43.5831 8.15094 43.6846 8.64216 43.6846 9.16508V14.014H41.4812V9.16508H41.4972C41.4972 8.94324 41.4545 8.7346 41.369 8.53916C41.2836 8.33845 41.1661 8.16414 41.0165 8.01624C40.8669 7.86835 40.6933 7.75215 40.4957 7.66763C40.2981 7.58312 40.0871 7.54086 39.8627 7.54086C39.633 7.54086 39.4167 7.58312 39.2137 7.66763C39.0107 7.75215 38.8345 7.86835 38.6849 8.01624C38.5353 8.16414 38.4178 8.33845 38.3324 8.53916C38.2469 8.7346 38.2042 8.94324 38.2042 9.16508V14.014Z"
        fill={fill}
      />
      <Path
        d="M33.3407 8.80855L34.9431 7.21602C34.7509 6.89382 34.5212 6.60859 34.2541 6.36034C33.9924 6.11208 33.7039 5.90345 33.3888 5.73442C33.0789 5.56012 32.7504 5.42807 32.4032 5.33827C32.0614 5.24848 31.7115 5.20358 31.3536 5.20358C30.75 5.20358 30.1812 5.32507 29.647 5.56804C29.1182 5.81101 28.6535 6.14378 28.2528 6.56634C27.8576 6.98361 27.5451 7.47484 27.3154 8.04001C27.0857 8.60519 26.9709 9.21262 26.9709 9.8623C26.9709 10.4592 27.0857 11.0191 27.3154 11.542C27.5451 12.0649 27.8576 12.5218 28.2528 12.9127C28.6535 13.3035 29.1182 13.6125 29.647 13.8396C30.1812 14.0615 30.75 14.1724 31.3536 14.1724C31.7115 14.1724 32.0587 14.1302 32.3952 14.0456C32.7371 13.9664 33.0603 13.8476 33.3647 13.6891C33.6745 13.5307 33.9603 13.3379 34.222 13.1107C34.4891 12.8836 34.7241 12.6274 34.9271 12.3422L33.3247 10.7497C33.2392 10.9398 33.127 11.1141 32.9881 11.2726C32.8546 11.4258 32.6997 11.5578 32.5234 11.6687C32.3525 11.7744 32.1682 11.8563 31.9706 11.9144C31.7729 11.9725 31.5673 12.0015 31.3536 12.0015C31.0545 12.0015 30.7714 11.9461 30.5043 11.8351C30.2426 11.7242 30.0129 11.571 29.8153 11.3756C29.623 11.1802 29.4707 10.953 29.3586 10.6942C29.2464 10.4354 29.1903 10.1581 29.1903 9.8623C29.1903 9.49785 29.2464 9.16508 29.3586 8.86401C29.4707 8.56293 29.623 8.30411 29.8153 8.08755C30.0129 7.86571 30.2426 7.69404 30.5043 7.57256C30.7714 7.45107 31.0545 7.39033 31.3536 7.39033C31.5673 7.39033 31.7756 7.42202 31.9786 7.4854C32.1816 7.54879 32.3685 7.64122 32.5394 7.76271C32.7157 7.88419 32.8733 8.03473 33.0122 8.21432C33.1511 8.38863 33.2606 8.5867 33.3407 8.80855Z"
        fill={fill}
      />
      <Path
        d="M20.8094 14.014H22.9968V9.33939C22.9968 9.11226 23.0395 8.89834 23.125 8.69762C23.2104 8.49691 23.3279 8.3226 23.4775 8.17471C23.6271 8.02681 23.8033 7.91061 24.0063 7.82609C24.2093 7.74158 24.4256 7.69933 24.6553 7.69933H26.5863V5.54427H24.6553C24.1746 5.54427 23.7099 5.63142 23.2612 5.80573C22.8125 5.97475 22.4119 6.21772 22.0593 6.53464L21.3382 5.52842H20.8094V14.014Z"
        fill={fill}
      />
      <Path
        d="M47.2501 15.0677V17.2465L48.3718 17.2624C48.9754 17.2624 49.5416 17.1488 50.0704 16.9217C50.5992 16.6946 51.0613 16.3856 51.4566 15.9947C51.8572 15.6038 52.1723 15.1469 52.402 14.624C52.6317 14.1011 52.7492 13.5412 52.7546 12.9443V5.52842H50.5512V10.2188C50.5512 10.446 50.5084 10.6678 50.423 10.8844C50.3375 11.0956 50.22 11.2858 50.0704 11.4548C49.9209 11.6238 49.7446 11.7612 49.5416 11.8668C49.344 11.9672 49.133 12.0174 48.9086 12.0174C48.679 12.0174 48.4626 11.9804 48.2596 11.9064C48.062 11.8325 47.8884 11.7216 47.7388 11.5737C47.5893 11.4205 47.4691 11.233 47.3783 11.0111C47.2928 10.784 47.2501 10.5199 47.2501 10.2188V5.52842H45.0707V10.2188C45.0707 10.8263 45.1696 11.3756 45.3672 11.8668C45.5702 12.3528 45.8453 12.7674 46.1925 13.1107C46.545 13.4488 46.9536 13.7102 47.4184 13.8951C47.8831 14.08 48.3798 14.1724 48.9086 14.1724C49.1597 14.1724 49.4081 14.1222 49.6538 14.0219C49.9048 13.9215 50.1506 13.8212 50.3909 13.7208C50.3108 13.9162 50.2066 14.0958 50.0784 14.2596C49.9502 14.4286 49.8007 14.5738 49.6298 14.6953C49.4642 14.8168 49.2826 14.9119 49.0849 14.9806C48.8873 15.0492 48.6816 15.0836 48.468 15.0836L47.2501 15.0677Z"
        fill={fill}
      />
      <G opacity="0.8">
        <Path
          d="M4.63061 12.5704C4.8674 13.2064 4.73743 13.8392 4.34031 13.9838C3.94319 14.1283 3.42931 13.7299 3.19251 13.0939C2.95572 12.4578 3.08569 11.825 3.48281 11.6805C3.87993 11.5359 4.39381 11.9343 4.63061 12.5704Z"
          fill={fill}
        />
        <Path
          d="M4.33198 10.4024C4.58533 10.8628 4.52043 11.3815 4.18702 11.5609C3.85361 11.7403 3.37795 11.5124 3.1246 11.052C2.87125 10.5916 2.93615 10.0729 3.26956 9.8935C3.60297 9.71411 4.07863 9.94194 4.33198 10.4024Z"
          fill={fill}
        />
        <Path
          d="M4.06429 8.76537C4.29282 9.13374 4.26086 9.56414 3.99289 9.72669C3.72493 9.88925 3.32244 9.72241 3.0939 9.35404C2.86537 8.98567 2.89734 8.55527 3.16531 8.39272C3.43327 8.23016 3.83576 8.397 4.06429 8.76537Z"
          fill={fill}
        />
        <Path
          d="M4.02894 7.44563C4.22419 7.74195 4.19847 8.10071 3.9715 8.24694C3.74454 8.39318 3.40226 8.27151 3.20701 7.97519C3.01177 7.67887 3.03748 7.3201 3.26445 7.17387C3.49141 7.02763 3.83369 7.1493 4.02894 7.44563Z"
          fill={fill}
        />
        <Path
          d="M4.03275 6.35274C4.23616 6.576 4.25999 6.88266 4.08597 7.03769C3.91196 7.19271 3.60599 7.1374 3.40259 6.91413C3.19918 6.69087 3.17535 6.38421 3.34937 6.22918C3.52338 6.07416 3.82934 6.12947 4.03275 6.35274Z"
          fill={fill}
        />
        <Path
          d="M4.0644 5.48273C4.24226 5.6703 4.26855 5.93165 4.12314 6.06647C3.97773 6.20129 3.71567 6.15852 3.53782 5.97095C3.35997 5.78337 3.33367 5.52202 3.47908 5.3872C3.62449 5.25239 3.88655 5.29515 4.0644 5.48273Z"
          fill={fill}
        />
        <Path
          d="M4.41366 4.77454C4.59362 4.96433 4.6223 5.22686 4.47771 5.36091C4.33313 5.49496 4.07003 5.44978 3.89008 5.25998C3.71012 5.07019 3.68144 4.80766 3.82603 4.67361C3.97061 4.53956 4.23371 4.58475 4.41366 4.77454Z"
          fill={fill}
        />
        <Path
          d="M4.89678 4.09428C5.10198 4.26846 5.15795 4.53679 5.02182 4.69361C4.88568 4.85043 4.60898 4.83636 4.40379 4.66218C4.19859 4.488 4.14262 4.21967 4.27875 4.06285C4.41489 3.90603 4.69159 3.9201 4.89678 4.09428Z"
          fill={fill}
        />
        <Path
          d="M5.80108 3.8487C6.04532 3.99129 6.15532 4.25428 6.04676 4.4361C5.9382 4.61793 5.6522 4.64973 5.40796 4.50714C5.16372 4.36455 5.05372 4.10156 5.16228 3.91974C5.27083 3.73792 5.55683 3.70611 5.80108 3.8487Z"
          fill={fill}
        />
        <Path
          d="M6.84153 4.22704C7.11969 4.35188 7.27377 4.6087 7.18567 4.80064C7.09757 4.99259 6.80065 5.04699 6.52248 4.92215C6.24432 4.7973 6.09024 4.54049 6.17834 4.34855C6.26644 4.1566 6.56336 4.1022 6.84153 4.22704Z"
          fill={fill}
        />
        <Path
          d="M7.94028 4.74529C8.22708 4.87401 8.38428 5.14242 8.29139 5.34479C8.1985 5.54717 7.89071 5.60688 7.60391 5.47816C7.31711 5.34944 7.15991 5.08104 7.2528 4.87866C7.34568 4.67629 7.65348 4.61658 7.94028 4.74529Z"
          fill={fill}
        />
        <Path
          d="M9.05389 5.49956C9.39667 5.69079 9.57203 6.02551 9.44557 6.24718C9.3191 6.46885 8.9387 6.49354 8.59591 6.30231C8.25312 6.11108 8.07776 5.77636 8.20423 5.55469C8.33069 5.33302 8.7111 5.30834 9.05389 5.49956Z"
          fill={fill}
        />
        <Path
          d="M10.4145 6.44593C10.8349 6.70212 11.049 7.11309 10.8928 7.36385C10.7365 7.61461 10.269 7.61021 9.84855 7.35402C9.42813 7.09782 9.21398 6.68686 9.37025 6.4361C9.52652 6.18534 9.99403 6.18974 10.4145 6.44593Z"
          fill={fill}
        />
        <Path
          d="M12.1219 7.70531C12.6269 8.06195 12.8634 8.59058 12.6501 8.88603C12.4367 9.18148 11.8543 9.13187 11.3493 8.77522C10.8442 8.41858 10.6077 7.88996 10.821 7.59451C11.0344 7.29906 11.6168 7.34867 12.1219 7.70531Z"
          fill={fill}
        />
        <Path
          d="M14.0982 9.45407C14.762 9.94009 15.0995 10.602 14.8521 10.9325C14.6046 11.263 13.8659 11.137 13.202 10.6509C12.5382 10.1649 12.2007 9.50299 12.4482 9.17249C12.6956 8.84198 13.4344 8.96805 14.0982 9.45407Z"
          fill={fill}
        />
        <Path
          d="M16.8406 11.8357C17.7277 12.5366 18.1864 13.4271 17.8652 13.8245C17.544 14.222 16.5645 13.9759 15.6775 13.275C14.7905 12.5741 14.3318 11.6836 14.653 11.2862C14.9742 10.8887 15.9536 11.1348 16.8406 11.8357Z"
          fill={fill}
        />
      </G>
      <G opacity="0.6">
        <Path
          d="M4.06458 13.1428C4.04522 13.2796 3.69249 13.3439 3.27672 13.2864C2.86094 13.2289 2.53958 13.0714 2.55894 12.9346C2.57829 12.7978 2.93102 12.7335 3.3468 12.791C3.76257 12.8485 4.08393 13.006 4.06458 13.1428Z"
          fill={fill}
        />
        <Path
          d="M3.43684 12.5746C3.41285 12.7091 3.06253 12.7603 2.65438 12.6891C2.24622 12.6179 1.93479 12.4512 1.95878 12.3168C1.98276 12.1823 2.33308 12.1311 2.74124 12.2023C3.14939 12.2735 3.46082 12.4402 3.43684 12.5746Z"
          fill={fill}
        />
        <Path
          d="M2.81676 12.0202C2.79665 12.1309 2.51327 12.1731 2.1838 12.1146C1.85433 12.0561 1.60354 11.9189 1.62365 11.8082C1.64375 11.6976 1.92714 11.6553 2.25661 11.7138C2.58608 11.7724 2.83687 11.9095 2.81676 12.0202Z"
          fill={fill}
        />
        <Path
          d="M2.45893 11.5476C2.43854 11.6515 2.17386 11.688 1.86775 11.6292C1.56164 11.5704 1.33002 11.4386 1.35041 11.3348C1.3708 11.2309 1.63548 11.1944 1.94159 11.2532C2.2477 11.3119 2.47932 11.4438 2.45893 11.5476Z"
          fill={fill}
        />
        <Path
          d="M2.26626 11.0881C2.25279 11.1728 2.0135 11.2059 1.73179 11.1621C1.45007 11.1183 1.23261 11.0141 1.24608 10.9295C1.25954 10.8448 1.49884 10.8117 1.78055 10.8555C2.06227 10.8993 2.27973 11.0035 2.26626 11.0881Z"
          fill={fill}
        />
        <Path
          d="M2.22636 10.7527C2.20973 10.834 1.99619 10.8601 1.74941 10.8107C1.50262 10.7614 1.31605 10.6555 1.33268 10.5741C1.34931 10.4927 1.56285 10.4667 1.80963 10.516C2.05641 10.5653 2.24299 10.6713 2.22636 10.7527Z"
          fill={fill}
        />
        <Path
          d="M2.41751 10.3844C2.40803 10.4705 2.21706 10.5205 1.99097 10.4962C1.76488 10.4718 1.58928 10.3823 1.59876 10.2962C1.60824 10.2101 1.79921 10.1601 2.0253 10.1844C2.25139 10.2088 2.42699 10.2983 2.41751 10.3844Z"
          fill={fill}
        />
        <Path
          d="M3.14179 10.2323C3.12802 10.3344 2.92574 10.392 2.68999 10.3609C2.45424 10.3298 2.27428 10.2218 2.28805 10.1197C2.30182 10.0176 2.5041 9.96001 2.73985 9.9911C2.97561 10.0222 3.15556 10.1302 3.14179 10.2323Z"
          fill={fill}
        />
        <Path
          d="M4.25733 10.301C4.24483 10.4172 3.99249 10.486 3.6937 10.4546C3.39491 10.4231 3.16282 10.3035 3.17532 10.1873C3.18781 10.0711 3.44016 10.0023 3.73895 10.0337C4.03774 10.0652 4.26982 10.1848 4.25733 10.301Z"
          fill={fill}
        />
        <Path
          d="M5.40932 10.4681C5.39605 10.5914 5.13656 10.6653 4.82974 10.633C4.52292 10.6008 4.28495 10.4746 4.29821 10.3512C4.31148 10.2279 4.57096 10.154 4.87779 10.1862C5.18461 10.2185 5.42258 10.3447 5.40932 10.4681Z"
          fill={fill}
        />
        <Path
          d="M7.29836 10.8229C7.28514 10.9693 6.83374 11.0491 6.29013 11.0011C5.74651 10.9531 5.31654 10.7955 5.32976 10.6491C5.34298 10.5027 5.79438 10.4229 6.33799 10.4709C6.88161 10.5189 7.31158 10.6765 7.29836 10.8229Z"
          fill={fill}
        />
        <Path
          d="M9.2833 11.3113C9.26349 11.48 8.7747 11.5625 8.19156 11.4956C7.60841 11.4286 7.15174 11.2376 7.17155 11.0688C7.19137 10.9001 7.68016 10.8176 8.2633 10.8846C8.84645 10.9516 9.30312 11.1426 9.2833 11.3113Z"
          fill={fill}
        />
        <Path
          d="M11.7357 11.8905C11.7128 12.0852 11.1093 12.1759 10.3876 12.093C9.6659 12.0101 9.0994 11.7851 9.12226 11.5905C9.14512 11.3958 9.74869 11.3051 10.4704 11.388C11.192 11.4709 11.7585 11.6959 11.7357 11.8905Z"
          fill={fill}
        />
        <Path
          d="M14.798 12.6672C14.7733 12.8849 13.9761 12.9748 13.0174 12.8681C12.0588 12.7614 11.3018 12.4985 11.3266 12.2809C11.3513 12.0633 12.1485 11.9733 13.1071 12.08C14.0658 12.1867 14.8228 12.4496 14.798 12.6672Z"
          fill={fill}
        />
        <Path
          d="M18.5102 13.7672C18.4734 14.0154 17.4408 14.071 16.2038 13.8916C14.9668 13.7121 13.9939 13.3655 14.0307 13.1173C14.0676 12.8692 15.1002 12.8135 16.3371 12.993C17.5741 13.1725 18.547 13.5191 18.5102 13.7672Z"
          fill={fill}
        />
        <Path
          d="M5.58381 14.0139C5.62586 13.9818 5.65198 13.9443 5.659 13.9021C5.69413 13.6911 5.23891 13.4413 4.64223 13.3441C4.04555 13.247 3.53337 13.3393 3.49824 13.5504C3.4716 13.7104 3.727 13.8928 4.11224 14.0139H5.58381Z"
          fill={fill}
        />
      </G>
    </Svg>
  );
};
