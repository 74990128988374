import { Navigate, Route } from "react-router-dom";
import { Fragment, JSXElementConstructor, ReactNode } from "react";
import { ProcessPaymentRoute } from "components/Billing/ProcessPayment/ProcessPaymentRoute";
import { PathDefinitions } from "utils/paths";
import { ManagePaymentPage } from "components/Account/ManagePaymentPage";
import { NotFoundRoute } from "components/NotFoundRoute";
import { PatientInsurancesPage } from "components/PatientInsurances/PatientInsurancesPage";
import { AddPatientInsuranceRoute } from "components/PatientInsurances/AddPatientInsuranceRoute";
import { AccountRoutes } from "components/Account/AccountRoutes";
import { AppointmentDetailsRoute } from "components/AppointmentDetailsRoute";
import { SentryRoutes } from "components/Routing/SentryRoutes";
import { AuthLayout } from "components/Main/AuthLayout";
import { AnonRoutes } from "components/Main/routes";
import { InvoicesRoute } from "components/Billing/InvoicesRoute";
import { InvoiceDetailsRoute } from "components/Billing/InvoiceDetailsRoute";
import { AddPaymentMethodRoute } from "components/Billing/AddPaymentMethodRoute";
import { HomeRoute } from "components/HomeRoute";
import { AppointmentsRoute } from "components/AppointmentsRoute";
import { AccountNavigationRoute } from "components/Account/AccountNavigationRoute";
import { OnboardingPages } from "components/Onboarding/OnboardingPages";

const authRoutes: {
  path: string;
  element: JSX.Element;
  layout?: JSXElementConstructor<{ children: ReactNode }>;
  childRoutes?: { path: string; element: JSX.Element }[];
}[] = [
  {
    path: PathDefinitions.home,
    element: <HomeRoute />,
    layout: AuthLayout,
  },
  {
    path: PathDefinitions.appointments,
    element: <AppointmentsRoute />,
    layout: AuthLayout,
    childRoutes: [
      {
        path: PathDefinitions.appointmentDetails,
        element: <AppointmentDetailsRoute />,
      },
    ],
  },
  {
    path: PathDefinitions.invoices,
    element: <InvoicesRoute />,
    layout: AuthLayout,
  },
  {
    path: PathDefinitions.invoiceDetails,
    element: <InvoiceDetailsRoute />,
    layout: AuthLayout,
  },
  {
    path: PathDefinitions.pay,
    element: <ProcessPaymentRoute />,
    layout: AuthLayout,
  },
  {
    path: PathDefinitions.insurancesAdd,
    element: <AddPatientInsuranceRoute />,
    layout: AuthLayout,
  },
  {
    path: `${PathDefinitions.account}/*`,
    element: <AccountRoutes />,
    layout: AuthLayout,
  },

  {
    path: PathDefinitions.paymentMethodsAdd,
    element: <AddPaymentMethodRoute />,
    layout: AuthLayout,
  },
  {
    path: PathDefinitions.onboarding,
    element: <OnboardingPages />,
    layout: AuthLayout,
  },

  ...AnonRoutes,
];

export const AuthedRoutes = () => {
  return (
    <SentryRoutes>
      {authRoutes.map((route) => {
        const Layout = route.layout || Fragment;

        return (
          <Route key={route.path} path={route.path} element={<Layout>{route.element}</Layout>}>
            {route.childRoutes?.map((childRoute) => (
              <Route key={childRoute.path} path={childRoute.path} element={childRoute.element} />
            ))}
          </Route>
        );
      })}

      <Route
        element={
          <AuthLayout>
            <AccountNavigationRoute />
          </AuthLayout>
        }
      >
        <Route path={PathDefinitions.paymentMethods} element={<ManagePaymentPage />} />
        <Route path={PathDefinitions.insurances} element={<PatientInsurancesPage />} />
      </Route>
      {[PathDefinitions.signIn, PathDefinitions.signUp].map((path) => {
        return <Route path={path} key={path} element={<Navigate to={PathDefinitions.home} replace />} />;
      })}
      <Route
        path="*"
        element={
          <AuthLayout>
            <NotFoundRoute />
          </AuthLayout>
        }
      />
    </SentryRoutes>
  );
};
