import { Hub } from "aws-amplify";
import { HubCallback } from "@aws-amplify/core";
import { useCallback, useEffect } from "react";
import { useBroadcastChannel, Channels } from "hooks/useBroadcastChannel";
import { getSignOutUrl } from "utils/auth";
import { useForcedSignOutContext } from "contexts/ForcedSignOutContext";
import { clearLastVisitedDate } from "storage/scheduling";

export const useSignOutListeners = () => {
  const forcedSignOutRef = useForcedSignOutContext();
  const handleAuthChannel = useCallback((e: MessageEvent<{ type: "signOut"; forced: boolean }>) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (e.data.type === "signOut") {
      window.location.assign(getSignOutUrl(e.data.forced));
    }
  }, []);

  const channelRef = useBroadcastChannel({
    name: Channels.auth,
    onMessage: handleAuthChannel,
  });

  useEffect(() => {
    const onAuthMessage: HubCallback = (listener) => {
      const payload = listener.payload;

      switch (payload.event) {
        case "signOut": {
          if (channelRef.current) {
            channelRef.current.postMessage({
              type: "signOut",
              forced: forcedSignOutRef.current,
            });
          }

          // This only needs to be done here because this clears local storage for all
          // tabs.
          // Allows so that when users sign back in they see "today" on the schedule
          clearLastVisitedDate();
          window.location.assign(getSignOutUrl(forcedSignOutRef.current));
          break;
        }
        default: {
          break;
        }
      }
    };
    const removeSubscription = Hub.listen("auth", onAuthMessage);

    return () => {
      removeSubscription();
    };
  }, [channelRef, forcedSignOutRef]);
};
