import { useTranslation } from "react-i18next";
import { RadioList } from "components/UI/RadioList";
import { SubscriberInfo } from "components/PatientInsurances/hooks/useInsuranceDraft";
import { FormFieldInput } from "components/UI/FormFieldInput";
import { FormFieldSsnInput } from "components/UI/FormFieldSsnInput";
import { CreateInsuranceValidation } from "components/PatientInsurances/formData";

interface SelectInsuranceIdTypeProps {
  idType: SubscriberInfo["idType"];
  onChangeIdType: (idType: SubscriberInfo["idType"]) => void;
  onChangeSsn: (ssn: string) => void;
  onChangeSubscriberId: (subscriberId: string) => void;
  label?: string;
  ssnLastFour?: string;
  validation:
    | CreateInsuranceValidation["primarySubscriber"]
    | CreateInsuranceValidation["dependentSubscriber"];
  subscriberId?: string;
}

export const SelectInsuranceIdType: React.FC<SelectInsuranceIdTypeProps> = ({
  idType,
  onChangeIdType,
  onChangeSsn,
  onChangeSubscriberId,
  label,
  subscriberId,
  ssnLastFour,
  validation,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <RadioList
        className="mt-5"
        label={label ?? t("Provide one of the following information")}
        required
        selectedValue={idType}
        options={[
          { value: "subscriberId", label: t("Subscriber ID") },
          { value: "ssn", label: t("Subscriber SSN") },
        ]}
        onChange={(e) => onChangeIdType(e.target.value as SubscriberInfo["idType"])}
      />
      {idType === "ssn" ? (
        <FormFieldSsnInput
          id="subscriberSSN"
          edit
          required
          error={validation.ssn.$error}
          ssnLastFour={ssnLastFour}
          onValueChange={(value) => {
            onChangeSsn(value);
          }}
        />
      ) : (
        <FormFieldInput
          id="subscriberId"
          value={subscriberId}
          error={validation.subscriberId.$error}
          placeholder={t("Please enter subscriber id")}
          onChange={(e) => onChangeSubscriberId(e.target.value)}
        />
      )}
    </>
  );
};
