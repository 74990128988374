import { FC, ReactNode, PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { cx } from "@libs/utils/cx";
import { Backdrop } from "components/UI/Backdrop";
import { ModalContainer, ModalHeader } from "components/UI/ModalComponents";
import { usePortalElement } from "contexts/PortalContext";
import { useKeyEventCallback } from "hooks/useKeyEventCallback";
import { useDisableScrollBody } from "hooks/useDisableScrollBody";
import { useClickOutsideTracker } from "contexts/ClickOutsideListenerContext";

const cxSizeStyles = {
  /* eslint-disable @typescript-eslint/naming-convention */
  "3xs": "max-w-sm w-[90%]",
  "2xs": "max-w-md w-[90%]",
  /* eslint-enable @typescript-eslint/naming-convention */
  xs: "max-w-xl w-[90%]",
  sm: "max-w-screen-sm w-[97%] md:w-[90%]",
  md: "max-w-screen-md w-[97%] md:w-[90%]",
  lg: "max-w-screen-lg w-[97%] md:w-[90%]",
  xl: "max-w-screen-xl w-[97%] md:w-[90%]",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "2xl": "max-w-screen-2xl w-[97%] md:w-[90%]",
  full: "h-[97%] w-[97%] md:h-[90%] md:w-[90%]",
  fit: "max-w-[97%] md:max-w-[90%]",
};

export interface ModalProps {
  title?: ReactNode;
  centerVertically?: boolean;
  size?: keyof typeof cxSizeStyles;
  height?: string | number;
  onClose?: Func;
  dataTestId?: string;
  portal?: boolean;
  onClickOutside?: Func;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  title,
  centerVertically = true,
  size = "md",
  height,
  onClose,
  children,
  dataTestId,
  onClickOutside,
  portal = true,
}) => {
  const currentPortal = usePortalElement();

  useDisableScrollBody({ enabled: true });
  useKeyEventCallback("keydown", "Escape", onClose);

  const tracker = useClickOutsideTracker();

  const content = (
    <div {...tracker} data-testid={dataTestId} className="absolute inset-0 z-10">
      <Backdrop className="animate-fadeIn" onClick={onClickOutside} />
      <ModalContainer
        style={{ ...(height === undefined ? undefined : { maxHeight: height }) }}
        centerVertically={centerVertically}
        className={cx(
          height === undefined ? "max-h-[97%] md:max-h-[85%]" : "h-[97%] md:h-[85%]",
          cxSizeStyles[size]
        )}
      >
        {title ? <ModalHeader onClose={onClose}>{title}</ModalHeader> : null}
        {children}
      </ModalContainer>
    </div>
  );

  return portal ? (currentPortal ? createPortal(content, currentPortal) : null) : content;
};
