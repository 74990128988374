import React from "react";
import { cx } from "@libs/utils/cx";
import { ButtonIconContent } from "components/UI/ButtonIconContent";
import { ReactComponent as CalendarIcon } from "assets/icons/currentColor/calendar.svg";

interface ReactDatePickerNavInputProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormatValue: (value?: string) => string;
  value?: string;
  className?: string;
}

export const DatePickerNavInput = React.forwardRef<HTMLButtonElement, ReactDatePickerNavInputProps>(
  ({ value, onClick, onFormatValue, className }, ref) => {
    return (
      <button
        type="button"
        className={cx(
          `flex
           text-sm
           font-sansSemiBold
           items-center
           justify-center
           cursor-pointer`,
          className
        )}
        onClick={onClick}
        ref={ref}
      >
        <div className="flex items-center justify-center">
          <ButtonIconContent Icon={CalendarIcon} theme="primary" className="mr-1" />
          <div className="whitespace-nowrap">{onFormatValue(value)}</div>
        </div>
      </button>
    );
  }
);
