import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { GeneralInfoVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useApiMutations } from "api/mutations";
import { uploadProfileImage, updateOnboardingInfo } from "api/user/mutations";
import { useHandleError } from "api/handleErrorResponse";
import { useApiQueries } from "api/queries";
import { getOnboardingInfoQuery, getOnboardingOptions } from "api/forms/queries";
import { QueryResult } from "components/UI/QueryResult";
import { CameraComponent } from "deprecated/camera-component";
import { formatThumbnailData } from "utils/user";
import { semibold16 } from "assets/styles/textSize";
import { GeneralInformationForm } from "components/Onboarding/GeneralInformationForm";
import { useUserContext } from "contexts/UserContext";
import { RegistrationSubmissionFooter } from "components/Onboarding/RegistrationSubmissionFooter";
import { OnboardingLoading } from "components/Onboarding/OnboardingLoading";
import { EditProfileImageModal } from "components/Account/EditProfileImageModal";
import { getPatientSummary } from "api/patient/queries";

type Props = {
  onSuccess: Func;
};

const PATIENT_FORM = "patient-general-info";

export const GeneralInformation: React.FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const { currentUser, practice, refetchUser } = useUserContext();
  const [imageEditing, setImageEditing] = useState<File | undefined>();

  const { practiceId, id: patientId, thumbnail } = currentUser;

  const [infoQuery, onboardingOptionsQuery, patientQuery] = useApiQueries([
    getOnboardingInfoQuery({ args: { patientId, practiceId, query: { onboardingState: "GENERAL_INFO" } } }),
    getOnboardingOptions({ args: { practiceId } }),
    getPatientSummary({
      args: { patientId, practiceId },
    }),
  ]);

  const [uploadProfileImageMutation] = useApiMutations([uploadProfileImage]);
  const handleImageSelected = useCallback((image: File) => {
    setImageEditing(image);
  }, []);
  const handleUploadImage = React.useCallback(
    (resizedImage: File) => {
      uploadProfileImageMutation.mutate(
        { practiceId, userId: patientId, image: resizedImage },
        {
          onSettled: () => {
            setImageEditing(undefined);
            refetchUser();
          },
          onError: handleError,
        }
      );
    },
    [handleError, patientId, practiceId, refetchUser, uploadProfileImageMutation]
  );

  const [{ mutateAsync: mutateOnboardingAsync, isLoading: isSaving }] = useApiMutations([
    updateOnboardingInfo,
  ]);

  const handleSubmit = React.useCallback(
    (generalInfo: GeneralInfoVO) => {
      mutateOnboardingAsync(
        {
          practiceId,
          patientId,
          onboardingState: "GENERAL_INFO",
          data: {
            generalInfo,
          },
        },
        {
          onSuccess,
          onError: handleError,
        }
      );
    },
    [handleError, mutateOnboardingAsync, onSuccess, patientId, practiceId]
  );

  return (
    <>
      {imageEditing && (
        <EditProfileImageModal
          onClose={() => {
            setImageEditing(undefined);
          }}
          onDone={handleUploadImage}
          initialImage={imageEditing}
          isSaving={uploadProfileImageMutation.isLoading}
        />
      )}

      <QueryResult
        queries={[infoQuery, onboardingOptionsQuery, patientQuery]}
        loading={<OnboardingLoading />}
      >
        {infoQuery.data?.generalInfo && onboardingOptionsQuery.data && patientQuery.data && (
          <div className="flex flex-col gap-4 p-4">
            <GeneralInformationForm
              id={PATIENT_FORM}
              info={infoQuery.data.generalInfo}
              onboardingOptions={onboardingOptionsQuery.data}
              onSubmit={handleSubmit}
              canEditEmail={Boolean(!infoQuery.data.generalInfo.contactDetails.email)}
              showPreferredPronouns={practice.practicePreferences.showPreferredPronouns}
              patient={patientQuery.data}
            >
              <div className={cx("text-secondaryTheme", semibold16)}>{t("Personal Details")}</div>
              <CameraComponent
                fieldText="Upload profile picture"
                setImage={handleImageSelected}
                profilePicture={formatThumbnailData(thumbnail)}
              />
            </GeneralInformationForm>

            <RegistrationSubmissionFooter form={PATIENT_FORM} isSubmitting={isSaving} />
          </div>
        )}
      </QueryResult>
    </>
  );
};
