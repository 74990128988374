import { useEffect, useRef } from "react";
import { noop } from "@libs/utils/noop";

export enum Channels {
  auth = "auth",
}

export const useBroadcastChannel = ({
  name,
  onMessage,
  onMessageError,
}: {
  name: Channels;
  onMessage?: (event: MessageEvent) => void;
  onMessageError?: (event: MessageEvent) => void;
}) => {
  const channelRef = useRef<BroadcastChannel | null>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (window.BroadcastChannel) {
      const channel = new BroadcastChannel(name);

      channelRef.current = channel;

      if (onMessage) {
        channel.addEventListener("message", onMessage);
      }

      if (onMessageError) {
        channel.addEventListener("messageerror", onMessageError);
      }

      return () => {
        if (onMessage) {
          channel.removeEventListener("message", onMessage);
        }

        if (onMessageError) {
          channel.removeEventListener("messageerror", onMessageError);
        }

        channel.close();
      };
    }

    return noop;
  }, [name, onMessage, onMessageError]);

  return channelRef;
};
