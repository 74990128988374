import { Navigate, Route } from "react-router-dom";
import React, { useEffect } from "react";
import { Hub } from "aws-amplify";
import { HubCallback } from "@aws-amplify/core";
import { setTag as setSentryTag, setUser as setSentryUser } from "@sentry/react";
import { setRecentlyActive } from "storage/activity";
import { SentryRoutes } from "components/Routing/SentryRoutes";
import { paths } from "utils/paths";
import { SignedOutLayout } from "components/Main/SignedOutLayout";
import { SIGNED_IN_IDLE_TIMEOUT } from "config/react";
import { SignedOutRoutes, AnonRoutes } from "./routes";

export const SignedOutApp: React.FC = () => {
  useEffect(() => {
    setSentryUser(null);
    setSentryTag("onboardingState", undefined);

    const onMessage: HubCallback = (listener) => {
      const payload = listener.payload;

      if (payload.event === "signIn") {
        setRecentlyActive(SIGNED_IN_IDLE_TIMEOUT);
      }
    };

    return Hub.listen("auth", onMessage);
  }, []);

  return (
    <SentryRoutes>
      {SignedOutRoutes.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={<SignedOutLayout>{route.element}</SignedOutLayout>}
          />
        );
      })}
      {AnonRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
      <Route path="*" element={<Navigate to={paths.signIn()} replace />} />
    </SentryRoutes>
  );
};
