// Sentry
import {
  init as sentryInit,
  reactRouterV6Instrumentation,
  setUser as sentrySetUser,
  setTags as setSentryTags,
  setExtras as setSentryExtras,
} from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import { PracticeInfoVO, UserInfoVO } from "@libs/api/generated-api";
import { EnvValues } from "env";

const sentrySampleRates: Record<EnvValues["REACT_APP_ENVIRONMENT"], number> = {
  // TODO: change to lower value, for example `0.2` (20%) when we are live in
  // production with enough clients that we'd have to turn this down. In the
  // meantime we want to see all error since we don't have a lot of traffic, so
  // let's set to 1.
  production: 1,
  staging: 1,
  preprod: 1,
  demo: 1,
  development: 0,
};

interface InitializeSentryOptions {
  appEnv: EnvValues["REACT_APP_ENVIRONMENT"];
  dsn: string;

  release?: string;
}

export const initializeSentry = ({ appEnv, dsn, release }: InitializeSentryOptions) => {
  // Per https://docs.sentry.io/platforms/javascript/guides/react/
  sentryInit({
    enabled: appEnv in sentrySampleRates,
    release,
    environment: appEnv,
    dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: sentrySampleRates[appEnv],
  });
};

export const setSentryUserProperties = (user: UserInfoVO | null) => {
  if (user) {
    const { id, status, practiceId, practiceTimezoneId } = user;

    sentrySetUser({ id: String(id) });
    setSentryTags({
      ["practice.id"]: String(practiceId),
      ["practice.timezoneId"]: practiceTimezoneId,
      ["user.status"]: status,
    });
  } else {
    sentrySetUser(null);
    setSentryTags({
      ["practice.id"]: null,
      ["practice.timezoneId"]: null,
      ["user.status"]: null,
    });
  }
};
export const setSentryPracticeProperties = (practice: PracticeInfoVO | null) => {
  if (practice) {
    setSentryExtras({
      ["practice.name"]: practice.name,
      ["practice.uuid"]: practice.uuid,
    });
  } else {
    setSentryExtras({
      ["practice.name"]: null,
      ["practice.uuid"]: null,
    });
  }
};
