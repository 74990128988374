import React from "react";
import { ReactComponent as Placeholder32 } from "assets/icons/Placeholder_32x32.svg";
import { formatThumbnailData } from "utils/user";

export const UserAvatar: React.FC<{ thumbnail?: string }> = ({ thumbnail }) => {
  return thumbnail ? (
    <img src={formatThumbnailData(thumbnail)} alt="Profile" className="rounded-full w-full h-full" />
  ) : (
    <Placeholder32 className="rounded-full w-full h-full" />
  );
};
