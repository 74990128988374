import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SelfBookingAppointmentRequest } from "@libs/api/generated-api";
import { formatPhoneNumber } from "@libs/utils/phone";
import { formatISOTimeAsAmPm, formatLongDayOfMonth, getLocalDate } from "@libs/utils/date";
import { useHandleError } from "api/handleErrorResponse";
import { useApiMutations } from "api/mutations";
import { useApiQueries } from "api/queries";
import { bookAppointment } from "api/self-booking/mutations";
import { getSelfBookingConfig } from "api/self-booking/queries";
import { getPracticeInfo } from "api/user/queries";
import { SelfBookingStep1 } from "components/SelfBooking/SelfBookingStep1";
import { SelfBookingStep2 } from "components/SelfBooking/SelfBookingStep2";
import { SelfBookingStep3 } from "components/SelfBooking/SelfBookingStep3";
import { QueryResult } from "components/UI/QueryResult";
import { PathDefinitions, SelfBookingParams } from "utils/paths";
import { useSelfBookingState } from "components/SelfBooking/useSelfBookingState";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { useSearchQueryParams } from "hooks/useSearchQueryParams";
import { useInstrumentationForPractice } from "hooks/useInstrumentationForPractice";

// eslint-disable-next-line complexity
export const SelfBookingRoute: React.FC = () => {
  const handleError = useHandleError();
  const { t } = useTranslation();
  const { practiceUuid } = useParams<SelfBookingParams>();
  const query = useSearchQueryParams<PathDefinitions.selfBooking>();
  const [step, setStep] = useState("1");
  const [confirmInfo, setConfirmInfo] = useState<{ day: string; time: string; isScheduled: boolean }>();

  const { resetState } = useSelfBookingState();

  const [bookAppointmentMutation] = useApiMutations([bookAppointment]);

  // It's gauranteed if we are on this route.
  const uuid = practiceUuid as string;

  const [practiceQuery, selfBookableConfigQuery] = useApiQueries([
    getPracticeInfo({
      args: {
        practiceUuid: uuid,
      },
    }),
    getSelfBookingConfig({
      args: {
        practiceUuid: uuid,
      },
    }),
  ]);

  useInstrumentationForPractice(practiceQuery.data);

  const handleBookAppointment = async (request: SelfBookingAppointmentRequest) => {
    try {
      await bookAppointmentMutation.mutateAsync({
        practiceUuid: uuid,
        data: {
          ...request,
          messageCampaignUuid: query.get("messageCampaignUuid"),
        },
      });

      const category = selfBookableConfigQuery.data?.appointmentCategories.find(
        (cat) => cat.id === request.appointmentCategoryId
      );

      setConfirmInfo({
        day: formatLongDayOfMonth(getLocalDate(request.date)),
        time: formatISOTimeAsAmPm(request.startTime),
        isScheduled: category?.selfBookingConfig?.bookingType === "SCHEDULE",
      });
      setStep("4");
      resetState();
    } catch (e) {
      handleError(e);
    }
  };

  const isEnabled =
    selfBookableConfigQuery.data &&
    selfBookableConfigQuery.data.enabled &&
    selfBookableConfigQuery.data.appointmentCategories.length > 0;

  const phoneNumber = practiceQuery.data?.phoneNumber || "";

  return (
    <div className="flex flex-col h-full items-center bg-white overflow-y-auto">
      <QueryResult
        queries={[practiceQuery, selfBookableConfigQuery]}
        loading={
          <div className="w-full h-full">
            <Skeleton className="w-full h-full p-6" />
          </div>
        }
      >
        {!isEnabled || !practiceQuery.data || !selfBookableConfigQuery.data ? (
          <AnonUserMessagePage
            homeUrl={practiceQuery.data?.website}
            logoUrl={practiceQuery.data?.logo?.url}
            title={t("selfBooking.unavailableTitle")}
            subTitle={
              <div className="text-center">
                {t("selfBooking.unavailableText")}{" "}
                <a href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
              </div>
            }
          />
        ) : (
          <>
            {(!step || step === "1") && (
              <SelfBookingStep1
                practiceInfo={practiceQuery.data}
                config={selfBookableConfigQuery.data}
                onNextStep={() => setStep("2")}
              />
            )}
            {step === "2" && (
              <SelfBookingStep2
                practiceInfo={practiceQuery.data}
                config={selfBookableConfigQuery.data}
                onNextStep={() => setStep("3")}
              />
            )}
            {step === "3" && (
              <SelfBookingStep3
                practiceInfo={practiceQuery.data}
                config={selfBookableConfigQuery.data}
                onNextStep={handleBookAppointment}
                onStartOver={() => setStep("1")}
              />
            )}
            {step === "4" && (
              <AnonUserMessagePage
                homeUrl={practiceQuery.data.website}
                logoUrl={practiceQuery.data.logo?.url}
                title={t("selfBooking.completeTitle")}
                subTitle={
                  <div className="text-center">
                    {t("selfBooking.completeText", {
                      type: confirmInfo?.isScheduled
                        ? t("selfBooking.scheduled")
                        : t("selfBooking.requested"),
                      day: confirmInfo?.day ?? "sdf",
                      time: confirmInfo?.time ?? "",
                    })}
                  </div>
                }
              />
            )}
          </>
        )}
      </QueryResult>
    </div>
  );
};
