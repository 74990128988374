import { differenceInYears } from "date-fns";
import { setTag as setSentryTag } from "@sentry/react";
import {
  OnboardingInfoVO,
  PatientVO,
  UpdateOnboardingInfoRequest,
  UserInfoVO,
} from "@libs/api/generated-api";
import { getLocalDate } from "@libs/utils/date";
import { updateCachedData } from "api/cache";
import { makeMutation } from "api/mutations";
import { getQueryKey } from "api/queries";

const getProfileImageQueryKeysToInvalidate = (userId: number, practiceId: number) => [
  [getQueryKey("practices", "getPatient"), { patientId: userId, practiceId }],
  [getQueryKey("v2", "getFamilyMembersV2")],
  [getQueryKey("practices", "getPatients")],
  [getQueryKey("practices", "lookupPatient")],
  [getQueryKey("practices", "getProfileImage"), { userId, practiceId }],
  [getQueryKey("practices", "getEmployee"), { employeeId: userId, practiceId }],
  [getQueryKey("practices", "getEmployees"), { practiceId }],
];

export const deleteProfileImage = makeMutation({
  mutationKey: ["practices", "deleteProfileImage"],
  formatParams: (args: { userId: number; practiceId: number }) => [args.userId, args.practiceId],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { userId, practiceId }) => {
      const keys = getProfileImageQueryKeysToInvalidate(userId, practiceId);

      for (const key of keys) {
        queryClient.invalidateQueries(key);
      }
    },
  }),
});

export const uploadProfileImage = makeMutation({
  mutationKey: ["practices", "uploadProfileImage"],
  formatParams: (args: { userId: number; practiceId: number; image: File }) => [
    args.userId,
    args.practiceId,
    { image: args.image },
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { userId, practiceId }) => {
      const keys = getProfileImageQueryKeysToInvalidate(userId, practiceId);

      for (const key of keys) {
        queryClient.invalidateQueries(key);
      }
    },
  }),
});

export const updateOnboardingInfo = makeMutation({
  mutationKey: ["practices", "updateOnboardingInfo"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    onboardingState: NonNullable<UserInfoVO["onboardingState"]>;
    data?: UpdateOnboardingInfoRequest;
  }) => [args.practiceId, args.patientId, { onboardingState: args.onboardingState }, args.data ?? {}],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { patientId, data: requestData, practiceId }) => {
      const nextOnboardingState = response.data.data.nextOnboardingState;

      if (requestData?.insuranceInfo) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getPatientInsurances"),
          {
            practiceId,
            patientId,
          },
        ]);
      }

      updateCachedData<UserInfoVO[]>(
        queryClient,
        { queryKey: [getQueryKey("user", "getUserLoginInfo"), null], exact: true },
        (data) => {
          return data.map((item) => {
            if (item.id === patientId) {
              return { ...item, onboardingState: nextOnboardingState };
            }

            return item;
          });
        }
      );

      if (requestData?.generalInfo) {
        updateCachedData<PatientVO>(
          queryClient,
          {
            queryKey: [getQueryKey("practices", "getPatient"), { practiceId, patientId }],
            exact: false,
          },
          (lastData) => {
            const dob = requestData.generalInfo?.personalDetails.dob;
            const age = dob ? differenceInYears(new Date(), getLocalDate(dob)) : lastData.personalDetails.age;

            return {
              ...lastData,
              contactDetails: {
                ...lastData.contactDetails,
                ...requestData.generalInfo?.contactDetails,
              },
              personalDetails: {
                ...lastData.personalDetails,
                ...requestData.generalInfo?.personalDetails,
                age,
              },
            };
          }
        );
        setSentryTag("onboardingState", nextOnboardingState);
        updateCachedData<OnboardingInfoVO>(
          queryClient,
          {
            queryKey: [getQueryKey("practices", "getOnboardingInfo"), { practiceId, patientId }],
          },
          (lastData) => {
            return {
              ...lastData,
              generalInfo: requestData.generalInfo,
            };
          }
        );
      }
    },
  }),
});
