import { ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { isOneOf } from "@libs/utils/isOneOf";
import { FormFieldContainer } from "components/UI/FormFieldContainer";
import { FormFieldLabel } from "components/UI/FormFieldLabel";
import { FormFieldLayout } from "components/UI/formFieldStyle";
import { FormFieldError } from "components/UI/FormFieldError";
import { FormFieldNarrowContainer } from "components/UI/FormFieldNarrrowContainer";

export interface FormFieldProps {
  label?: ReactNode;
  children?: ReactNode;
  required?: boolean;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  error?: string;
  edit?: boolean;
  disableErrorMessage?: boolean;
  layout?: FormFieldLayout;
}

export const cxStyle = {
  label: ({ layout, edit }: { layout: FormFieldLayout; edit: boolean }) =>
    cx(
      layout === "labelLeft" && "flex-1 items-center text-xs",
      layout === "labelIn" && edit && "!px-4 pt-1 text-xxs",
      layout === "labelOut" && "pb-1 text-sm",
      layout !== "labelLeft" && !edit && "pb-1 text-base"
    ),
};

export const FormField: React.FC<
  Omit<FormFieldProps, "layout"> & { layout: FormFieldLayout; id: string }
  // eslint-disable-next-line complexity
> = ({
  disabled,
  id,
  label,
  children,
  required,
  className,
  containerClassName,
  error,
  edit = true,
  layout,
  disableErrorMessage = false,
}) => {
  const formFieldLabel = label && (
    <FormFieldLabel
      content={label}
      error={error}
      disabled={disabled}
      required={required}
      edit={edit}
      id={id}
      inline={layout === "labelLeft" || layout === "tableValue"}
      className={cxStyle.label({ layout, edit })}
    />
  );

  return (
    <div className={className}>
      {isOneOf(layout, ["labelIn", "labelOut", "tableValue"]) && !edit && (
        <>
          {label && formFieldLabel}
          {children}
        </>
      )}
      {layout === "labelIn" && edit && (
        <FormFieldContainer className={containerClassName} disabled={disabled} error={error}>
          {formFieldLabel}
          {children}
        </FormFieldContainer>
      )}
      {(layout === "labelOut" || layout === "tableValue") && edit && (
        <>
          {formFieldLabel}
          <FormFieldContainer className={containerClassName} disabled={disabled} error={error}>
            {children}
          </FormFieldContainer>
        </>
      )}
      {layout === "labelLeft" && (
        <div className="flex text-xs items-start">
          {formFieldLabel}
          <FormFieldNarrowContainer disabled={disabled} error={error} edit={edit}>
            {children}
          </FormFieldNarrowContainer>
        </div>
      )}
      {error && edit && !disableErrorMessage && (
        <FormFieldError indent={true} className="pt-1">
          {error}
        </FormFieldError>
      )}
    </div>
  );
};
