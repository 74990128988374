import { FormEvent, useState } from "react";
import { MessageSubscriptionVO, UpdateMessageSubscriptionRequest } from "@libs/api/generated-api";
import { FormFieldInput } from "components/UI/FormFieldInput";
import { Form } from "components/UI/Form";
import { AsyncButton } from "components/UI/AsyncButton";
import { EmailPreferencesFields } from "components/Account/EmailPreferencesFields";

export const EmailPreferencesForm: React.FC<{
  onSubmit: (formState: UpdateMessageSubscriptionRequest) => void;
  isSaving: boolean;
  preferences: MessageSubscriptionVO;
}> = ({ onSubmit, isSaving, preferences }) => {
  const [state, setState] = useState<UpdateMessageSubscriptionRequest>({
    unsubscribeAll: preferences.unsubscribeAll,
    unsubscribeMarketing: preferences.unsubscribeMarketing,
    unsubscribeTransactional: preferences.unsubscribeTransactional,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(state);
  };

  return (
    <Form onSubmit={handleSubmit} className="flex flex-col gap-y-6">
      <>
        <FormFieldInput edit={false} value={preferences.email} type="email" label="Email Address" />
        <EmailPreferencesFields preferences={state} onUpdate={setState} />
        <AsyncButton isLoading={isSaving} className="w-fit" type="submit">
          Save Preferences
        </AsyncButton>
      </>
    </Form>
  );
};
