import { FC, useEffect, useState } from "react";
import Script from "react-load-script";
import { googleConfig } from "config/google-config";

const styles = {
  addressWrapper: {
    position: "relative",
    width: "100%",
  },
  locationInput: {
    position: "absolute",
    top: 10,
    right: 15,
    left: 15,
    bottom: 10,
  },
  locationAutoComplete: {
    position: "absolute",
    top: 10,
    right: 15,
    left: 15,
  },
  textInput: {
    width: "100%",
    height: 34,
    border: "none",
    outline: "none",
    padding: "0px 12px 0px 12px",
  },
};
const GooglePlacesComponent: FC<{
  fieldValue: string | undefined;
  onSelectAddress: (address: {
    address1: string;
    state: string;
    country: string;
    locality: string;
    postcode: string;
  }) => void;
}> = ({ onSelectAddress, fieldValue }) => {
  const googleKey = googleConfig.GooglePlaces.googlePlacesAPIKey;
  const [inputValue, setInputValue] = useState<string | undefined>("");

  let autocomplete: google.maps.places.Autocomplete | undefined;
  let address1Field: HTMLInputElement | null = null;

  useEffect(() => {
    setInputValue(fieldValue);
  }, [fieldValue]);

  const initAutocomplete = () => {
    address1Field = document.querySelector("#ship-address");
    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US and Canada.

    if (address1Field) {
      autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: { country: [] },
        fields: ["address_components"],
        types: ["address"],
      });
      //address1Field.focus();
      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      //autocomplete.addListener("place_changed", fillInAddress);
      window.google.maps.event.addListener(autocomplete, "place_changed", fillInAddress);
      address1Field.addEventListener("change", onAddressChange);
    }
  };

  // eslint-disable-next-line complexity, max-statements
  function fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = autocomplete?.getPlace();
    let address1 = "";
    let postcode = "";
    let locality = "";
    let locality1 = "";
    let state = "";
    let country = "";

    if (place?.address_components) {
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case "route": {
            address1 += component.short_name;
            break;
          }

          case "premise": {
            address1 += component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case "locality": {
            locality = component.long_name;
            break;
          }
          case "administrative_area_level_2": {
            locality1 = component.long_name;
            break;
          }
          case "administrative_area_level_1": {
            state = component.short_name;
            break;
          }
          case "country": {
            country = component.short_name;
            break;
          }
          default: {
            break;
          }
        }
      }
    }

    const addressObject = {
      address1,
      postcode,
      locality: locality && locality.trim().length !== 0 ? locality : locality1,
      state,
      country,
    };

    onSelectAddress(addressObject);
  }

  const onAddressChange = () => {
    const addressObject = {
      address1: address1Field?.value ?? "",
      postcode: "",
      locality: "",
      state: "",
      country: "",
    };

    onSelectAddress(addressObject);
  };

  return (
    <div>
      {googleKey && (
        <>
          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`}
            onLoad={initAutocomplete}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              height: 36,
              border: "1px solid #D8D8D8",
              boxSizing: "border-box",
              borderRadius: "4px",
              padding: "1px 1px 1px 1px",
              outline: "none",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              value={inputValue}
              onChange={(event) => {
                if (event.target.value.trim().length === 0) {
                  onSelectAddress({
                    address1: "",
                    state: "",
                    country: "",
                    locality: "",
                    postcode: "",
                  });
                }

                setInputValue(event.target.value);
              }}
              id="ship-address"
              name="ship-address"
              style={styles.textInput}
            />
          </div>
        </>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default GooglePlacesComponent;
