import { FormPageVO } from "@libs/api/generated-api";
import { PatientFormConsent } from "components/PatientForms/FormElements/PatientFormConsent";
import { PatientFormInputElement } from "components/PatientForms/FormElements/PatientFormInputElement";
import { PatientFormProcedureTableElement } from "components/PatientForms/FormElements/PatientFormProcedureTableElement";
import { PatientFormSection } from "components/PatientForms/FormElements/PatientFormSection";
import { PatientFormTextBlock } from "components/PatientForms/FormElements/PatientFormTextBlock";
import { PatientFormValidation } from "components/PatientForms/hooks/usePatientFormValidation";
import { PatientResponses, ResponseChangedCallback } from "components/PatientForms/hooks/usePatientResponses";
import { isInputElement } from "components/PatientForms/utils";

type Props = {
  page: FormPageVO;
  edit: boolean;
  validation: PatientFormValidation;
  responsesById: PatientResponses;
  onChangeResponse: ResponseChangedCallback;
};

export const PatientFormPage: React.FC<Props> = ({
  page,
  edit,
  responsesById,
  onChangeResponse,
  validation,
}) => {
  const sharedProps = {
    edit,
    responsesById,
    onChangeResponse,
  };

  return (
    <>
      {page.content.map((item) => {
        if (isInputElement(item)) {
          return (
            <PatientFormInputElement
              key={item.uuid}
              element={item}
              validation={validation}
              {...sharedProps}
            />
          );
        }

        switch (item.type) {
          case "CONSENT": {
            return (
              <PatientFormConsent key={item.uuid} validation={validation} element={item} {...sharedProps} />
            );
          }
          case "SECTION": {
            return (
              <PatientFormSection key={item.uuid} element={item} validation={validation} {...sharedProps} />
            );
          }
          case "TEXT_BLOCK": {
            return <PatientFormTextBlock key={item.uuid} element={item} {...sharedProps} />;
          }
          case "PROCEDURE_TABLE": {
            return <PatientFormProcedureTableElement key={item.uuid} element={item} />;
          }
          default: {
            return null;
          }
        }
      })}
    </>
  );
};
