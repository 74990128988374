import { useEffect } from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { setSentryPracticeProperties } from "components/Main/initializeSentry";

export const useInstrumentationForPractice = (practice?: PracticeInfoVO) => {
  useEffect(() => {
    if (practice) {
      setSentryPracticeProperties(practice);
    }
  }, [practice]);
};
