import React from "react";
import { useNavigate } from "react-router-dom";
import { InsuranceDetailsVO, UpdatePatientInsuranceRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiMutations } from "api/mutations";
import { addPatientInsurance, updatePatientInsurance } from "api/patientInsurance/mutations";
import { InsuranceDraftFields } from "components/PatientInsurances/hooks/useInsuranceDraft";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useHandleError } from "api/handleErrorResponse";
import { PathDefinitions } from "utils/paths";
import { getDraftPatientInsuranceFormSchema } from "components/PatientInsurances/formData";

type UseSubmitInsurance = {
  draftInsurance: InsuranceDraftFields;
  insuranceId?: number;
};

export const getAddInsuranceFormFields = (draftInsurance: InsuranceDraftFields): InsuranceDetailsVO => {
  const { type, primarySubscriber, dependentSubscriber } = draftInsurance;
  const subscriber = type === "PRIMARY_SUBSCRIBER" ? primarySubscriber : dependentSubscriber;
  const isDependent = type === "DEPENDENT";

  return {
    type,
    primarySubscriber: {
      carrierId: subscriber.carrierId,
      subscriberId: subscriber.idType === "subscriberId" ? subscriber.subscriberId : undefined,
      ssn: subscriber.idType === "ssn" ? subscriber.ssn : undefined,
      employer: subscriber.employer,
      dob: isDependent ? dependentSubscriber.dob : undefined,
      relation: isDependent ? dependentSubscriber.relation : undefined,
      firstName: isDependent ? dependentSubscriber.firstName : undefined,
      lastName: isDependent ? dependentSubscriber.lastName : undefined,
    },
  };
};

const getUpdateInsuranceFormFields = (
  draftInsurance: InsuranceDraftFields
): UpdatePatientInsuranceRequest => {
  const { type, primarySubscriber, dependentSubscriber } = draftInsurance;
  const subscriber = type === "PRIMARY_SUBSCRIBER" ? primarySubscriber : dependentSubscriber;

  return {
    carrierId: subscriber.carrierId,
    subscriberId: subscriber.idType === "subscriberId" ? subscriber.subscriberId : undefined,
    ssn: subscriber.idType === "ssn" ? subscriber.ssn : undefined,
    employer: subscriber.employer,
    relation: type === "DEPENDENT" ? dependentSubscriber.relation : undefined,
  };
};

export const useSubmitInsurance = ({ draftInsurance, insuranceId }: UseSubmitInsurance) => {
  const { practiceId, id: patientId } = useCurrentUser();
  const navigate = useNavigate();
  const schema = getDraftPatientInsuranceFormSchema(draftInsurance);
  const { result: validation, validate, reset } = useValidation(draftInsurance, schema);
  const [
    { mutate: mutateAddPatientInsurance, isLoading: isAdding },
    { mutate: mutateUpdatePatientInsurance, isLoading: isUpdating },
  ] = useApiMutations([addPatientInsurance, updatePatientInsurance]);
  const handleError = useHandleError();
  const handleSubmit = React.useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      if (insuranceId) {
        mutateUpdatePatientInsurance(
          {
            patientId,
            practiceId,
            insuranceId,
            data: getUpdateInsuranceFormFields(draftInsurance),
          },
          {
            onSuccess: () => {
              navigate(PathDefinitions.insurances);
            },
            onError: handleError,
          }
        );
      } else {
        mutateAddPatientInsurance(
          {
            patientId,
            practiceId,
            data: getAddInsuranceFormFields(draftInsurance),
          },
          {
            onSuccess: () => {
              navigate(PathDefinitions.insurances);
            },
            onError: handleError,
          }
        );
      }
    },
    [
      validate,
      insuranceId,
      mutateAddPatientInsurance,
      patientId,
      practiceId,
      draftInsurance,
      handleError,
      navigate,
      mutateUpdatePatientInsurance,
    ]
  );

  return { handleSubmit, resetValidation: reset, validation, validate, isSubmitting: isAdding || isUpdating };
};

export type UseSubmitInsuranceResult = ReturnType<typeof useSubmitInsurance>;
