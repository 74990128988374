import { GuestCheckoutSummaryRequest, MultiInvoicePaymentRequest } from "@libs/api/generated-api";
import { makeQuery } from "api/queries";
import { noCache, oneMinuteCache } from "api/utils";
import { getNextPageParam, makeInfiniteQuery } from "api/infiniteQueries";

export const getInvoicesInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getInvoices"],
  formatParams: ({
    patientId,
    practiceId,
    ...query
  }: {
    patientId: number;
    practiceId: number;
    includeOrders?: boolean;
    includePayments?: boolean;
    includePayerInfo?: boolean;
    includePracticeInfo?: boolean;
    sortDirection?: "ASCENDING" | "DESCENDING";
    sortField?: string;
    states?: ("FINALIZED" | "VOID" | "PARTIALLY_PAID" | "PAID")[];
    paymentStates?: (
      | "CREATED"
      | "IN_PROGRESS"
      | "AWAITING_RESULT"
      | "PENDING"
      | "SETTLED"
      | "DENIED"
      | "CHARGEBACK"
      | "VOID"
    )[];
    /** @format int32 */
    pageNumber: number;
    /** @format int32 */
    pageSize: number;
  }) => [patientId, practiceId, query],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getPatientPayment = makeQuery({
  queryKey: ["practices", "getPayment1"],
  formatParams: (args: { practiceId: number; patientId: number; paymentUuid: string }) => [
    args.practiceId,
    args.patientId,
    args.paymentUuid,
  ],
  queryOptions: oneMinuteCache,
});

export const getInvoiceByUuid = makeQuery({
  queryKey: ["practices", "getInvoice"],
  formatParams: (args: {
    patientId: number;
    practiceId: number;
    invoiceUuid: string;
    query: {
      includeOrders?: boolean;
      includePayments?: boolean;
      includePayerInfo?: boolean;
      includePracticeInfo?: boolean;
      paymentStates?: (
        | "CREATED"
        | "IN_PROGRESS"
        | "AWAITING_RESULT"
        | "PENDING"
        | "SETTLED"
        | "DENIED"
        | "CHARGEBACK"
        | "VOID"
      )[];
    };
  }) => [args.patientId, args.practiceId, args.invoiceUuid, args.query],
  queryOptions: oneMinuteCache,
});

export const printPayment = makeQuery({
  queryKey: ["practices", "printPayment"],
  formatParams: (args: { practiceId: number; patientId: number; paymentUuid: string }) => [
    args.practiceId,
    args.patientId,
    args.paymentUuid,
  ],
  queryOptions: oneMinuteCache,
});

export const getPracticeBillingSetting = makeQuery({
  queryKey: ["practices", "getPracticeBillingSetting"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: oneMinuteCache,
});
export const printInvoice = makeQuery({
  queryKey: ["practices", "printInvoice"],
  formatParams: (args: { practiceId: number; patientId: number; invoiceUuid: string }) => [
    args.patientId,
    args.practiceId,
    args.invoiceUuid,
  ],
  queryOptions: oneMinuteCache,
});

export const fetchGuestCheckoutSummary = makeQuery({
  queryKey: ["public", "fetchGuestCheckoutSummary"],
  formatParams: (args: { practiceUuid: string; patientToken: string; data: GuestCheckoutSummaryRequest }) => [
    args.practiceUuid,
    { patientToken: args.patientToken },
    args.data,
  ],
  queryOptions: oneMinuteCache,
});

export const getStatementsInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getStatements"],
  formatParams: ({
    patientId,
    practiceId,
    ...query
  }: {
    patientId: number;
    practiceId: number;

    /** @format int32 */
    pageNumber: number;
    /** @format int32 */
    pageSize: number;
  }) => [patientId, practiceId, query],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const printStatement = makeQuery({
  queryKey: ["practices", "printStatement"],
  formatParams: (args: { practiceId: number; patientId: number; statementUuid: string }) => [
    args.practiceId,
    args.patientId,
    args.statementUuid,
  ],
  queryOptions: oneMinuteCache,
});

export const fetchPatientOutstandingBalanceSummary = makeQuery({
  queryKey: ["practices", "fetchPatientOutstandingBalanceSummary"],
  formatParams: (args: { practiceId: number; patientId: number }) => [args.patientId, args.practiceId],
  queryOptions: oneMinuteCache,
});

// Runs the createPatientInitiatedMultiInvoicePaymentRecord mutation with commit false so it effectively just fetches the data.
export const getInvoicesDryRun = makeQuery({
  queryKey: ["practices", "createPatientInitiatedMultiInvoicePaymentRecord"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    data: Pick<MultiInvoicePaymentRequest, "invoiceUuids">;
  }) => [args.practiceId, args.patientId, { ...args.data, commit: false }],
  queryOptions: noCache,
});

export const getPaymentProfiles = makeQuery({
  queryKey: ["practices", "getAllPaymentProfilesByPatient"],
  formatParams: (args: { patientId: number; practiceId: number }) => [args.patientId, args.practiceId],
  queryOptions: oneMinuteCache,
});
