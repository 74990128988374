import { createContext, useContext } from "react";
import { useQuerySelector } from "hooks/useQuerySelector";
import { REACT_RENDER_ROOT } from "config/react";

export const PortalContext = createContext(REACT_RENDER_ROOT);

PortalContext.displayName = "PortalIdContext";

export const usePortalId = () => useContext(PortalContext);
export const usePortalElement = () => {
  const portalId = usePortalId();

  return useQuerySelector(`#${portalId}`);
};
