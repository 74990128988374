import React from "react";
import { UserInfoVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { semibold14, semibold24 } from "assets/styles/textSize";
import { SignInWrapper } from "components/SignIn/SignInWrapper";
import { AvatarButton } from "components/UI/AvatarButton";

type Props = {
  users: UserInfoVO[];
  onUserSelected: (user: UserInfoVO) => void;
};

const UserItem: React.FC<{
  user: UserInfoVO;
  onClick: (user: UserInfoVO) => void;
}> = ({ user, onClick }) => {
  const handleUserClick = React.useCallback(() => onClick(user), [user, onClick]);

  return (
    <div className="space-y-2 text-center">
      <AvatarButton
        onClick={handleUserClick}
        className="max-w-[140px] w-full aspect-square"
        thumbnail={user.thumbnail}
      />
      <button
        type="button"
        onClick={handleUserClick}
        className={cx("text-secondaryTheme hover:text-opacity-70", semibold14)}
      >
        {user.fullName}
      </button>
    </div>
  );
};

export const SelectPatient: React.FC<Props> = ({ users, onUserSelected }) => {
  return (
    <div className="flex flex-1 items-center justify-center h-full">
      <div
        className={`
          flex
          flex-col
          items-center
          justify-center
          p-6
          max-w-md
          w-full
        `}
      >
        <div className="max-w-md w-full flex flex-col space-y-6">
          <SignInWrapper>
            <div className={cx("text-secondaryTheme", semibold24)}>Select Patient</div>
            <div>
              {
                // Safari requires extra div wrapping img elem https://stackoverflow.com/a/44773109/409101
              }
              <div className="grid grid-cols-2 gap-6">
                {users.map((user) => (
                  <UserItem key={user.id} user={user} onClick={onUserSelected} />
                ))}
              </div>
            </div>
          </SignInWrapper>
        </div>
      </div>
    </div>
  );
};
