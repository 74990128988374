import { minutesToMilliseconds, hoursToMilliseconds } from "date-fns";
import { useMemo } from "react";
import { HttpResponse } from "@libs/api/generated-api";
import { ApiErrorResponse, ApiQueryResult, ApiResponse } from "api/queries";
import { UseInfiniteApiListQueryResult, UseInfiniteApiQueryResult } from "api/infiniteQueries";

export const cacheForSession = { staleTime: Number.POSITIVE_INFINITY, cacheTime: Number.POSITIVE_INFINITY };

export const oneMinuteCache = { staleTime: minutesToMilliseconds(1) };

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const fiveMinuteCache = { staleTime: minutesToMilliseconds(5) };

export const noCache = {
  cacheTime: 0,
};

// Cache for one hour
export const oneHourCache = { staleTime: hoursToMilliseconds(1) };

const hasErrorOnResponse = (error: unknown) => {
  const typedError = error as HttpResponse<unknown, unknown>;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return typedError && typedError.error != null && typedError.ok === false;
};

/**
 * Use this in a catch statement to convert the error to a typed HttpResponse.
 * @param error The error to check.
 * @returns True if its an HttpResponse error with error JSON parsed, otherwise false.
 */
export const isHttpResponseError = (error: unknown): error is ApiErrorResponse => {
  const typedError = error as ApiErrorResponse;

  return hasErrorOnResponse(error) && !(typedError.error instanceof Error);
};

/**
 * Use this in a catch statement to convert the error to a typed HttpResponse.
 * @param error The error to check.
 * @returns True if its an HttpResponse error that could not parse the error JSON and has an Error instance instead, otherwise false.
 */
export const isResponseParseError = (error: unknown): error is HttpResponse<unknown, Error> => {
  return hasErrorOnResponse(error) && !isHttpResponseError(error);
};
export const getPagingDetails = (query: ApiQueryResult) => query.apiResponse?.data.pageDetails;

export const flattenPages = <D>(data: UseInfiniteApiListQueryResult<D>["data"]) =>
  data?.pages.flatMap((page) => page.data);

export const useFlattenPages = <D>(data: UseInfiniteApiListQueryResult<D>["data"]) =>
  useMemo(() => flattenPages(data), [data]);

export const getInfiniteQueryPagingDetails = <D>(data: UseInfiniteApiQueryResult<D>["data"]) =>
  data?.pages[0]?.apiResponse.data.pageDetails;

export const getIsLoadingMore = <D>(query: UseInfiniteApiQueryResult<D>) =>
  Boolean(!query.isLoading && !query.isError && query.hasNextPage);

export const getInfiniteScrollOptionsFromQuery = <D>(query: UseInfiniteApiQueryResult<D>) => {
  return {
    loading: query.isFetching,
    hasNextPage: Boolean(query.hasNextPage),
    onLoadMore: query.fetchNextPage,
    disabled: Boolean(query.error),
  };
};

export const getTempId = () => `temp-${crypto.randomUUID()}`;
export const isTempId = (id: string) => id.startsWith("temp-");

export const SEARCH_DEBOUNCE_DELAY_MS = 500;

// For generating an empty response if cache data is already available when making a query
// used by image editing
export function generateInitialData<T>(data: T): ApiResponse<T> {
  return {
    data: {
      pageDetails: {},
      data,
    },
    error: { pageDetails: {} },
    ok: true,
    status: 200,
    statusText: "",
    redirected: false,
    type: "basic",
    headers: new Headers(),
    url: "",
    body: null,
    bodyUsed: false,
    formData: () => Promise.resolve(new FormData()),
    clone: () => new Response(),
    arrayBuffer: () => Promise.resolve(new ArrayBuffer(0)),
    blob: () => Promise.resolve(new Blob()),
    json: () => Promise.resolve({}),
    text: () => Promise.resolve(""),
  };
}
