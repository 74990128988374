import { useTranslation } from "react-i18next";
import { Button } from "components/UI/Button";
import { ButtonInternalLink } from "components/UI/ButtonLink";
import { paths } from "utils/paths";

export const EmailPreferencesUpdated: React.FC<{ logoUrl: string | undefined; onBack: Func }> = ({
  logoUrl,
  onBack,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`
        max-w-xs
        mx-auto
        flex
        flex-col
        items-center
        justify-center
        text-center
        h-full
      `}
    >
      {logoUrl && (
        <img src={logoUrl} alt={t("Practice Logo")} className="object-contain w-[80px] h-[80px] mb-6" />
      )}

      <p className="font-sansSemiBold text-sm mb-1">{t("We have updated your preferences")}</p>
      <p className="text-xs mb-8">
        {t("If you wish to resubscribe you can go back to the previous page or sign in")}
      </p>

      <ButtonInternalLink className="min-w-button mb-4" theme="primary" to={paths.signIn()}>
        Sign In
      </ButtonInternalLink>
      <Button onClick={onBack} theme="link">
        Go Back
      </Button>
    </div>
  );
};
