import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useHandleError } from "api/handleErrorResponse";
import { Spinner } from "components/UI/Spinner";
import { ApiResponse } from "api/queries";
import { isBrowser, isDeviceType } from "utils/userAgent";
import { ReactComponent as DownloadIcon } from "assets/icons/currentColor/download.svg";
import { ButtonIconContent } from "components/UI/ButtonIconContent";

export const DownloadAssetLink: React.FC<{
  onClickGetAssetLink: () => Promise<UseQueryResult<ApiResponse<string>, unknown> | undefined>;
}> = ({ onClickGetAssetLink }) => {
  const [url, setUrl] = React.useState<string | null>(null);
  const downloading = useBoolean(false);
  const handleError = useHandleError();
  const setDownloading = downloading.set;
  const handleLinkClicked = React.useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!url) {
        e.preventDefault();
        setDownloading(true);

        try {
          const download = await onClickGetAssetLink();
          const downloadedUrl = download?.data?.data.data;

          if (!downloadedUrl) {
            throw new Error("Download not found");
          }

          setUrl(downloadedUrl);

          if (isDeviceType(["iPad", "iPhone"]) || isBrowser(["Safari"])) {
            window.location.assign(downloadedUrl);
          } else {
            window.open(downloadedUrl, "_blank");
          }
        } catch (err) {
          handleError(err);
        }

        setDownloading(false);
      }
    },
    [handleError, onClickGetAssetLink, setDownloading, url]
  );

  return (
    <a className="text-sm" target="_blank" href={url ?? "#"} onClick={handleLinkClicked}>
      {downloading.isOn ? (
        <Spinner size="sm" animation="border" variant="primary" />
      ) : (
        <ButtonIconContent Icon={DownloadIcon} iconClassName="text-primaryTheme" />
      )}
    </a>
  );
};
