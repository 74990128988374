import { useContext, useMemo } from "react";
import { UseInfiniteQueryResult, useInfiniteQuery, UseInfiniteQueryOptions } from "@tanstack/react-query";
import {
  ApiQueriesContext,
  getQueryKey,
  QuerySettings,
  ApiResponse,
  Domains,
  ApiName,
  GetApiMethod,
  MakeQueryOptions,
  ApiMethodData,
  mergeRequestParams,
  ApiErrorResponse,
} from "api/queries";
import { ApiClientContext } from "contexts/ApiClientContext";

interface InfiniteQuerySettings<P extends { pageNumber: number }, D>
  extends Omit<QuerySettings<P, D>, "queryOptions"> {
  queryOptions?: UseInfiniteQueryOptions<ApiResponse<D>, ApiErrorResponse>;
}

// Dashboard data has another layer of nesting for heterogeneous data
export type BaseApiEntryResult<T = unknown> = {
  entries: T[];
};

export type UseInfiniteApiQueryResult<D = unknown> = UseInfiniteQueryResult<
  { data: D; apiResponse: ApiResponse<D> },
  ApiErrorResponse
>;

export type UseInfiniteApiListQueryResult<D = unknown> = UseInfiniteApiQueryResult<D[]>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InfiniteQuery<D = any> = (
  context: ApiQueriesContext
) => UseInfiniteQueryOptions<ApiResponse<D>, ApiErrorResponse>;

type InfiniteQueryFunc<P extends { pageNumber: number }, D> = (
  options: InfiniteQuerySettings<P, D>
) => InfiniteQuery<D>;

interface MakeInfiniteQueryOptions<D extends Domains, A extends ApiName<D>, P>
  extends Omit<MakeQueryOptions<D, A, P>, "queryOptions"> {
  queryOptions?: UseInfiniteQueryOptions<ApiResponse<ApiMethodData<D, A>>, ApiErrorResponse>;
}

type MakeInfiniteQuery = <D extends Domains, A extends ApiName<D>, P extends { pageNumber: number }>(
  options: MakeInfiniteQueryOptions<D, A, P>
) => InfiniteQueryFunc<P, ApiMethodData<D, A>>;

export const makeInfiniteQuery: MakeInfiniteQuery =
  (options) =>
  ({ args, queryOptions, requestOptions }) =>
  ({ api, headers }) => ({
    // We add an "infinite" key to the end, because if a `useQuery` and a `useInfiniteQuery` get called with the same params,
    // for the same API, there is a collision on the cache. Normally this would be ok, but infinite queries wrap the cache
    // entry with pagination information, so it will break the other query, or visa versa
    //
    // "infinite" is in the last position so that both the infinite and normal query caches
    // for a given api can be decached by passing all of the previous query key items.
    queryKey: [getQueryKey(...options.queryKey), args, "infinite"],
    queryFn: ({ pageParam }) => {
      const [domain, apiCall] = options.queryKey;
      const method = api[domain][apiCall] as GetApiMethod<typeof domain, typeof apiCall>;
      const params = options.formatParams({
        ...args,
        pageNumber: pageParam === undefined ? args.pageNumber : (pageParam as number),
      });

      return method(...params, mergeRequestParams({ headers }, requestOptions)) as Promise<
        ApiResponse<ApiMethodData<typeof domain, typeof apiCall>>
      >;
    },
    ...options.queryOptions,
    ...queryOptions,
  });
export const unwrapInfiniteQuery = <D>(result: UseInfiniteQueryResult<ApiResponse<D>, ApiErrorResponse>) => {
  // HACK: Make react-query v4 behave like react-query v3 when it comes to
  // enabled/isLoading. Revert this PR when we upgrade to react-query v5:
  // https://github.com/grindfoundryinc/grindfoundry-ui/pull/1831. More info is
  // included in the PR description and comments.
  const isLoadingV3 = result.isLoading && result.fetchStatus !== "idle";

  return {
    ...result,
    data: result.data && {
      ...result.data,
      pages: result.data.pages.map((page) => ({
        ...page,
        data: page.data.data,
        apiResponse: page,
      })),
    },
    isLoading: isLoadingV3,
  };
};

export const getNextPageParam = <Page extends ApiResponse>(lastPage: Page | undefined) => {
  const SECOND_PAGE = 2;

  if (!lastPage) {
    return SECOND_PAGE;
  }

  const pageDetails = lastPage.data.pageDetails;
  const pageNumber = pageDetails?.pageNumber;
  const totalPages = pageDetails?.totalPages;

  if (pageNumber === undefined || pageNumber === totalPages || totalPages === 0) {
    // Per react-query docs, must return `undefined` if we've reached the last page
    // eslint-disable-next-line consistent-return
    return;
  }

  return pageNumber + 1;
};

type MappedInfiniteQuery<T> = T extends InfiniteQuery<infer D> ? UseInfiniteApiQueryResult<D> : never;

export const useInfiniteApiQuery = <T extends InfiniteQuery>(getInfiniteQueryOptions: T) => {
  const { httpClient } = useContext(ApiClientContext);
  const options = getInfiniteQueryOptions({
    api: httpClient,
  });
  const infiniteQuery = useInfiniteQuery(options);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useMemo(() => unwrapInfiniteQuery(infiniteQuery) as any as MappedInfiniteQuery<T>, [infiniteQuery]);
};

export const PAGE_SIZE = 20;
