import React, { FormEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { captureException as sentryCaptureException } from "@sentry/react";
import { useSnackbar } from "notistack";
import { GuestCheckoutSummaryVO, PracticeInfoVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { semibold14 } from "assets/styles/textSize";
import { ApiQueryResult } from "api/queries";
import { QueryResult } from "components/UI/QueryResult";
import { Header } from "components/Main/Header/Header";
import { useCardDraft, useFinixForm } from "hooks/useFinixForm";
import { finixAppId, finixEnv } from "config/finix";
import { NewCardForm } from "components/Account/NewCardForm";
import { Form } from "components/UI/Form";
import { ButtonInternalLink } from "components/UI/ButtonLink";
import { PathDefinitions } from "utils/paths";
import { FormFieldInput } from "components/UI/FormFieldInput";
import { getEmailValidationSchema } from "components/SignIn/validationUtil";
import { useApiMutations } from "api/mutations";
import { createGuestCheckoutPayment } from "api/billing/mutations";
import { useHandleError } from "api/handleErrorResponse";
import { defaultSnackbarOptions } from "utils/snackbar";
import { AsyncButton } from "components/UI/AsyncButton";
import { PatientBalanceAsOf } from "components/Billing/PatientBalanceAsOf";
import { CallPracticeToCompletePayment } from "components/Billing/ProcessPayment/CallPracticeToCompletePayment";

export const GuestCheckoutForm: React.FC<{
  checkoutSummaryQuery: ApiQueryResult<GuestCheckoutSummaryVO>;
  practiceUuid: string;
  patientToken: string;
  canProcessPaymentOnline?: boolean;
  onPaymentComplete: (email: string) => void;
  practice: PracticeInfoVO;
}> = ({ checkoutSummaryQuery, practiceUuid, patientToken, onPaymentComplete, practice }) => {
  const { t } = useTranslation();
  const formOptions = {
    includeAcknowedgeFuturePayments: false,
  };
  const outstandingBalance = checkoutSummaryQuery.data?.outstandingBalance ?? 0;
  const cardDraftProps = useCardDraft(formOptions);
  const [email, setEmail] = useState("");
  const schema = React.useMemo(() => {
    return {
      email: getEmailValidationSchema(email),
    };
  }, [email]);
  const handleError = useHandleError();
  const { enqueueSnackbar } = useSnackbar();
  const contactValidation = useValidation({ email }, schema);
  const [createGuestCheckoutPaymentMutation] = useApiMutations([createGuestCheckoutPayment]);
  const createGuestCheckoutPaymentMutateAsync = createGuestCheckoutPaymentMutation.mutateAsync;
  const handleFinixFormSubmission: FinixFormSubmissionCallback = useCallback(
    async (err, response) => {
      if (err) {
        sentryCaptureException(new Error("Error occured while submitting credit card details to Finix"));
        enqueueSnackbar(t("billing.addCardFailed"), defaultSnackbarOptions);

        return;
      }

      try {
        await createGuestCheckoutPaymentMutateAsync({
          practiceUuid,
          data: {
            paymentToken: response.data.id,
            patientToken,
            email,
            currencyAmount: {
              amount: outstandingBalance,
              currency: "USD",
            },
          },
        });
        onPaymentComplete(email);
      } catch (e) {
        handleError(e);
      }
    },
    [
      createGuestCheckoutPaymentMutateAsync,
      email,
      enqueueSnackbar,
      handleError,
      onPaymentComplete,
      outstandingBalance,
      patientToken,
      practiceUuid,
      t,
    ]
  );
  const finixUseFormProps = useFinixForm({
    finixEnvironment: finixEnv,
    applicationId: finixAppId,
    formSubmissionHandler: handleFinixFormSubmission,
  });

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (contactValidation.validate().$isValid && cardDraftProps.validation.validate().$isValid) {
        finixUseFormProps.submitForm?.();
      }
    },
    [cardDraftProps.validation, contactValidation, finixUseFormProps]
  );
  const { loadingFinix, isFormValid, isSubmitting } = finixUseFormProps;

  return (
    <div className="h-full bg-white">
      <Header signedOut />
      <div className="h-[50px]" />
      <QueryResult queries={[checkoutSummaryQuery]}>
        <div className="flex flex-col p-4 items-center">
          <div className="flex flex-col gap-4 w-full max-w-md overflow-y-auto">
            {checkoutSummaryQuery.data && (
              <PatientBalanceAsOf value={outstandingBalance} asOfDate={new Date()} />
            )}
            {practice.onboardedWithPaymentProvider ? (
              <Form id="add-card-form" onSubmit={handleSubmit}>
                <NewCardForm
                  title={<div className={semibold14}>{t("pages.guestCheckout.addPaymentMethod")}</div>}
                  className="w-full"
                  {...formOptions}
                  {...finixUseFormProps}
                  {...cardDraftProps}
                />

                <div className="flex flex-col gap-4">
                  <FormFieldInput
                    type="email"
                    disabled={loadingFinix}
                    error={contactValidation.result.email.$error}
                    label={t("Email")}
                    placeholder={t("app.page.labels.placeholder.email")}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <AsyncButton
                    type="submit"
                    disabled={
                      !isFormValid ||
                      [contactValidation.result, cardDraftProps.validation.result].some(
                        (item) => item.$isValid === false
                      )
                    }
                    isLoading={isSubmitting || createGuestCheckoutPaymentMutation.isLoading}
                  >
                    {t("Pay Now")}
                  </AsyncButton>
                  <ButtonInternalLink size="small" theme="link" to={PathDefinitions.signIn}>
                    {t("app.signInForMore")}
                  </ButtonInternalLink>
                </div>
              </Form>
            ) : (
              <CallPracticeToCompletePayment practice={practice} />
            )}
          </div>
        </div>
      </QueryResult>
    </div>
  );
};
