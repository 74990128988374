import { InsuranceDetailsVO, UpdatePatientInsuranceRequest } from "@libs/api/generated-api";
import { makeMutation } from "api/mutations";
import { getQueryKey } from "api/queries";

export const addPatientInsurance = makeMutation({
  mutationKey: ["practices", "addPatientInsurance"],
  formatParams: (args: { practiceId: number; patientId: number; data: InsuranceDetailsVO }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { patientId, practiceId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurances"),
        {
          practiceId,
          patientId,
        },
      ]);
    },
  }),
});

export const updatePatientInsurance = makeMutation({
  mutationKey: ["practices", "updatePatientInsurance"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    insuranceId: number;
    data: UpdatePatientInsuranceRequest;
  }) => [args.practiceId, args.patientId, args.insuranceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { patientId, practiceId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurance"),
        {
          practiceId,
          patientId,
        },
      ]);
    },
  }),
});
