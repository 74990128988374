import React, { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { format, isBefore, parseISO } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { CancelAppointmentModal } from "components/AppointmentDetailsRoute/CancelAppointmentModal";
import { RescheduleAppointmentModal } from "components/AppointmentDetailsRoute/RescheduleAppointmentModal";
import { usePracticeInfo } from "hooks/usePracticeInfo";
import { ReactComponent as PhoneIcon } from "assets/icons/currentColor/call.svg";
import { ReactComponent as DirectionIcon } from "assets/icons/VectorDirection.svg";
import { Button } from "components/UI/Button";
import { tConvert } from "common/utils/app-utils";
import { paths } from "utils/paths";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useApiQueries } from "api/queries";
import { getPatientAppointmentQuery } from "api/scheduling/queries";
import { normal14, semibold14 } from "assets/styles/textSize";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";

// eslint-disable-next-line complexity
export const AppointmentDetailsRoute: React.FC = () => {
  const isDrawerOpen = useBoolean(true);
  const [dialogOpen, setDialogOpen] = useState<undefined | "reschedule" | "cancel">();
  const { t } = useTranslation();
  const { practiceId, id: patientId } = useCurrentUser();
  const { practice } = usePracticeInfo();
  const { appointmentId } = useParams();
  // const [searchParams] = useSearchParams();
  // const fromUrl = searchParams.get("from");

  // TODO - integrate full screen layout when navigating from home screen
  // const isFullScreen = searchParams.get("fullScreen");
  const navigate = useNavigate();
  const closeDrawer = isDrawerOpen.off;
  const openDrawer = isDrawerOpen.on;

  React.useEffect(() => {
    if (appointmentId) {
      openDrawer();
    } else {
      closeDrawer();
    }
  }, [appointmentId, closeDrawer, openDrawer]);

  // get appointment details api
  const [{ data: appointmentDetail, isLoading: isLoadingAppt }] = useApiQueries([
    getPatientAppointmentQuery({
      args: { practiceId, appointmentId: Number(appointmentId), patientId },
      queryOptions: { enabled: Boolean(appointmentId) },
    }),
  ]);

  const resetPopupState = useCallback(() => {
    setDialogOpen(undefined);
  }, []);

  const startTime = tConvert(appointmentDetail?.startTime ?? new Date().toISOString());
  const endTime = tConvert(appointmentDetail?.endTime ?? new Date().toISOString());
  const apptDate = React.useMemo(
    () => parseISO(appointmentDetail?.date ?? new Date().toISOString()),
    [appointmentDetail?.date]
  );
  const day = format(apptDate, "eee");
  const month = format(apptDate, "MMM");
  const dateOfMonth = format(apptDate, "dd");
  const date = `${day}${","} ${month} ${dateOfMonth}`;
  const dateTime = `${date} | ${startTime} - ${endTime}`;

  const dtMode = useMemo(() => {
    if (!appointmentDetail?.date) {
      return "PAST";
    }

    return isBefore(parseISO(appointmentDetail.date), new Date()) ? "PAST" : "FUTURE";
  }, [appointmentDetail?.date]);

  const phoneNumber = practice?.phoneNumber || "";

  return (
    <Flyover
      clickAwayToClose
      size="md"
      onClose={() => {
        navigate(paths.appointments());
      }}
      title={t("app.page.labels.appointmentDetails")}
    >
      <FlyoverContent>
        <div className="flex flex-col h-full w-full">
          <div className="grow h-14 overflow-auto">
            <div className="py-4">
              <div className={semibold14}>{t("app.pages.labels.patient")}</div>
              {isLoadingAppt ? (
                <Skeleton />
              ) : (
                <div className={semibold14}>{appointmentDetail?.patient.fullDisplayName}</div>
              )}
            </div>

            <div className="py-4">
              <div className={semibold14}>{t("app.pages.labels.dateAndTime")}</div>
              <div className={normal14}>{dateTime}</div>
            </div>

            <div className="py-4">
              <div className={semibold14}>{t("app.pages.labels.practice")}</div>
              <div className={normal14}>{practice?.name}</div>
              <div className="flex pt-1">
                <div className="flex pt-1 pl-px">
                  <a className="md:pointer-events-none" href={`tel:+1${phoneNumber}`}>
                    <div className="flex">
                      <div>
                        <PhoneIcon className="text-primaryTheme" />
                      </div>
                      <div className="pl-1.5 block md:hidden">
                        <div className={cx("text-primaryTheme", semibold14)}>
                          {t("app.pages.labels.call")}
                        </div>
                      </div>
                      <div className="pl-1.5 hidden md:block">
                        <div className={cx("text-primaryTheme", semibold14)}>{`1${phoneNumber}`}</div>
                      </div>
                    </div>
                  </a>
                  <a
                    href={`https://maps.google.com/?q=${practice?.addressDetails?.address1 || ""}`}
                    target="_blank"
                  >
                    <div className="flex">
                      <div className="pl-9">
                        <DirectionIcon />
                      </div>
                      <div className="pl-1.5">
                        <div className={cx("text-primaryTheme", semibold14)}>
                          {t("app.pages.labels.getDirection")}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="py-4">
              <div className={semibold14}>{t("app.pages.labels.provider")}</div>

              {isLoadingAppt ? (
                <Skeleton />
              ) : (
                <div className={normal14}>{appointmentDetail?.provider.name.fullDisplayName}</div>
              )}
            </div>

            <div className="py-4">
              <div className={semibold14}>{t("app.pages.labels.procedures")}</div>

              {appointmentDetail ? (
                appointmentDetail.patientProcedures.map((patientProcedure) => {
                  return (
                    <div key={patientProcedure.id}>
                      <div className={normal14}>{patientProcedure.fullDisplayName}</div>
                    </div>
                  );
                })
              ) : (
                <Skeleton count={5} />
              )}
            </div>
          </div>

          {dialogOpen === "cancel" && (
            <CancelAppointmentModal
              onClose={resetPopupState}
              goToAppointments={() => navigate(paths.appointments())}
            />
          )}
          {dialogOpen === "reschedule" && (
            <RescheduleAppointmentModal
              onClose={resetPopupState}
              goToAppointments={() => navigate(paths.appointments())}
            />
          )}
        </div>
      </FlyoverContent>
      {dtMode === "FUTURE" && (
        <FlyoverFooter paddingClassName="p-2" className="bg-white" actions>
          <Button
            size="custom"
            className="px-1 sm:px-2 py-2"
            theme="secondary"
            onClick={() => setDialogOpen("cancel")}
          >
            {t("Cancel Appointment")}
          </Button>
          <Button className="px-1 sm:px-2 py-2" size="custom" onClick={() => setDialogOpen("reschedule")}>
            {t("Request Reschedule")}
          </Button>
        </FlyoverFooter>
      )}
    </Flyover>
  );
};
