import { Route } from "react-router-dom";
import { AccountSettings } from "utils/paths";
import { AccountForgotPassword } from "components/Account/AccountForgotPassword";
import { SentryRoutes } from "components/Routing/SentryRoutes";
import { AccountMedicalHistoryRoute } from "components/Account/AccountMedicalHistoryRoute";
import { AccountDentalHistoryRoute } from "components/Account/AccountDentalHistoryRoute";
import { AccountProfile } from "components/Account/AccountProfile";
import { AccountNavigationRoute } from "components/Account/AccountNavigationRoute";
import { ChangePasswordRoute } from "components/Account/ChangePasswordRoute";

export const AccountRoutes = () => {
  return (
    <SentryRoutes>
      <Route index element={<AccountNavigationRoute />} />
      <Route element={<AccountNavigationRoute />}>
        <Route path={AccountSettings.forms} element={<AccountProfile />} />
        <Route path={AccountSettings.changePassword} element={<ChangePasswordRoute />} />
        <Route path={AccountSettings.forgotPassword} element={<AccountForgotPassword />} />
        <Route path={AccountSettings.dentalHistory} element={<AccountDentalHistoryRoute />} />
        <Route path={AccountSettings.medicalHistory} element={<AccountMedicalHistoryRoute />} />
      </Route>
    </SentryRoutes>
  );
};
