import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { enqueueSnackbar } from "notistack";
import { captureMessage } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import { PaymentVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getInvoicesDryRun, getPatientPayment, getPaymentProfiles } from "api/billing/queries";
import { ApiQueryResult, useApiQueries } from "api/queries";
import { useHideTabs } from "contexts/LayoutContext";

import { PathDefinitions } from "utils/paths";
import { useApiMutations } from "api/mutations";
import { createPatientInitiatedMultiInvoicePaymentRecord } from "api/billing/mutations";
import { useHandleError } from "api/handleErrorResponse";
import { ProcessPaymentForm } from "components/Billing/ProcessPayment/ProcessPaymentForm";
import { TitleBar } from "components/UI/TitleBar";
import { LoadingPaymentProfiles } from "components/UI/PaymentProfile";
import { QueryResult } from "components/UI/QueryResult";
import { PaymentCompleteScreen } from "components/Billing/PaymentCompleteScreen";
import { getPatientQuery } from "api/patient/queries";
import { ButtonInternalLink } from "components/UI/ButtonLink";
import { defaultSnackbarOptions } from "utils/snackbar";
import { toArray, useSearchQueryParams } from "hooks/useSearchQueryParams";
import { invalidateInvoices } from "api/billing/cache";
import { useUserContext } from "contexts/UserContext";

const REFETCH_INTERVAL_WHILE_SAVING_MS = 5000;

const paymentDidFail = (
  paymentState?: PaymentVO["state"]
): paymentState is "VOID" | "DENIED" | "CHARGEBACK" => {
  return paymentState ? isOneOf(paymentState, ["VOID", "DENIED", "CHARGEBACK"]) : false;
};
const MAX_RETRY_COUNT = 10;
const usePollPaymentStatus = ({
  submittedPaymentQuery,
  isPollingPaymentStatus,
  handlePaymentSuccess,
  handlePaymentFailed,
}: {
  submittedPaymentQuery: ApiQueryResult<PaymentVO>;
  isPollingPaymentStatus: boolean;
  handlePaymentSuccess: (payment: PaymentVO) => void;
  handlePaymentFailed: (payment?: PaymentVO) => void;
}) => {
  const { t } = useTranslation();
  const paymentCompleted = useBoolean(false);
  const paymentCompletedOn = paymentCompleted.on;
  const retryCount = useRef(0);

  const submittedPayment = submittedPaymentQuery.data;

  useEffect(() => {
    if (isPollingPaymentStatus && submittedPayment) {
      if (retryCount.current > MAX_RETRY_COUNT) {
        enqueueSnackbar(t("processPayment.page.error.TIMEOUT"), defaultSnackbarOptions);
        captureMessage("Payment timed out");
        handlePaymentFailed();
        retryCount.current = 0;
      } else if (submittedPayment.state === "SETTLED") {
        paymentCompletedOn();
        handlePaymentSuccess(submittedPayment);
        retryCount.current = 0;
      } else if (paymentDidFail(submittedPayment.state)) {
        handlePaymentFailed(submittedPayment);
        retryCount.current = 0;
        enqueueSnackbar(t(`processPayment.page.error.${submittedPayment.state}`), defaultSnackbarOptions);
      } else {
        retryCount.current += 1;
      }
    }
  }, [
    handlePaymentFailed,
    handlePaymentSuccess,
    isPollingPaymentStatus,
    paymentCompletedOn,
    submittedPayment,
    t,
  ]);

  return useMemo(
    () => ({
      paymentCompleted: paymentCompleted.isOn,
      handlePaymentSuccess,
    }),
    [handlePaymentSuccess, paymentCompleted.isOn]
  );
};

export const ProcessPaymentRoute: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  useHideTabs();

  const handleError = useHandleError();
  const { currentUser, practice } = useUserContext();

  const { practiceId, id: patientId } = currentUser;
  const [paymentUuidProcessing, setPaymentUuidProcessing] = useState<string | null>(null);
  const searchParams = useSearchQueryParams<PathDefinitions.pay>();

  const invoiceUuids = searchParams.get("invoiceUuids", toArray);
  const backUrl = searchParams.get("from") ?? PathDefinitions.invoices;
  const [{ mutateAsync: createMultiInvoicePaymentRecordMutateAsync, isLoading: isSubmittingPayment }] =
    useApiMutations([createPatientInitiatedMultiInvoicePaymentRecord]);
  const isPollingPaymentStatus = Boolean(paymentUuidProcessing);
  const hasFetchedDryRun = useRef(false);

  const [invoicesQuery, paymentMethodsQuery, submittedPaymentQuery, patientQuery] = useApiQueries([
    getInvoicesDryRun({
      args: {
        practiceId,
        patientId,
        data: { invoiceUuids },
      },
      queryOptions: {
        // We only want this to fetch a single time during this mount
        enabled: !hasFetchedDryRun.current,
      },
    }),
    getPaymentProfiles({ args: { patientId, practiceId } }),
    getPatientPayment({
      args: { patientId, practiceId, paymentUuid: paymentUuidProcessing ?? "" },
      queryOptions: {
        enabled: Boolean(paymentUuidProcessing),
        refetchInterval: REFETCH_INTERVAL_WHILE_SAVING_MS,
      },
    }),
    getPatientQuery({
      args: { patientId, practiceId, includeContactPatientDetails: true },
      queryOptions: { enabled: isSubmittingPayment },
    }),
  ]);

  useEffect(() => {
    if (invoicesQuery.isFetchedAfterMount) {
      hasFetchedDryRun.current = invoicesQuery.isFetchedAfterMount;
    }
  }, [invoicesQuery.isFetchedAfterMount]);

  const handleSubmit = useCallback(
    async ({ amount, paymentProfileUuid }: { amount: number; paymentProfileUuid: string }) => {
      const idempotencyUuid = crypto.randomUUID();

      try {
        const paymentResult = await createMultiInvoicePaymentRecordMutateAsync({
          patientId,
          practiceId,
          data: {
            invoiceUuids: invoicesQuery.data?.invoices.map((invoice) => invoice.uuid) ?? [],
            commit: true,
            payment: {
              type: "CHARGE",
              method: "STORED_PROFILE",
              currencyAmount: {
                currency: "USD",
                amount,
              },
              idempotencyUuid,
              paymentProfileUuid,
            },
          },
        });

        setPaymentUuidProcessing(paymentResult.data.data.payment.uuid);
      } catch (e) {
        handleError(e);
      }
    },
    [
      createMultiInvoicePaymentRecordMutateAsync,
      handleError,
      invoicesQuery.data?.invoices,
      patientId,
      practiceId,
    ]
  );
  const handlePaymentSuccess = useCallback(() => {
    invalidateInvoices({
      queryClient,
      patientId,
      practiceId,
      invoiceUUids: invoicesQuery.data?.invoices.map((invoice) => invoice.uuid) ?? [],
    });
  }, [queryClient, patientId, practiceId, invoicesQuery.data?.invoices]);
  const handlePaymentFailed = useCallback(() => {
    setPaymentUuidProcessing(null);
  }, []);
  const { paymentCompleted } = usePollPaymentStatus({
    submittedPaymentQuery,
    isPollingPaymentStatus,
    handlePaymentSuccess,
    handlePaymentFailed,
  });

  const invoiceDetails = invoicesQuery.data;
  const hasNegativeBalance = invoiceDetails && invoiceDetails.payment.currencyAmount.amount < 0;

  // Patient portal doesn't process discounts, if they have negative balance, the practice handles it for now
  return hasNegativeBalance ? (
    <Navigate to={PathDefinitions.invoices} replace />
  ) : paymentCompleted ? (
    <PaymentCompleteScreen
      email={patientQuery.data?.contactDetails.email ?? ""}
      isLoading={patientQuery.isLoading}
    >
      <ButtonInternalLink theme="link" to={PathDefinitions.invoices}>
        {t("payments.page.backToInvoices")}
      </ButtonInternalLink>
    </PaymentCompleteScreen>
  ) : (
    <div className="relative flex flex-col h-full min-h-0 bg-white">
      <TitleBar backTo={backUrl} title={t("processPayment.page.title")} />
      <div className="h-full flex flex-col items-center pt-6 px-6 overflow-y-auto">
        <div className="flex flex-col gap-6 max-w-2xl w-full">
          <QueryResult
            loading={
              <>
                <Skeleton containerClassName="w-full" count={5} />{" "}
                <div className="flex flex-col gap-2">
                  <LoadingPaymentProfiles />
                </div>
              </>
            }
            queries={[invoicesQuery, paymentMethodsQuery]}
          >
            <ProcessPaymentForm
              isSubmitting={isPollingPaymentStatus || isSubmittingPayment}
              onSubmit={handleSubmit}
              invoiceUuids={invoiceUuids}
              invoicesQuery={invoicesQuery}
              paymentMethodsQuery={paymentMethodsQuery}
              onboardedWithPaymentProvider={practice.onboardedWithPaymentProvider}
            />
          </QueryResult>
        </div>
      </div>
    </div>
  );
};
