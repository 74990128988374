import React from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { StatementVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { UseInfiniteApiQueryResult } from "api/infiniteQueries";
import { useFlattenPages } from "api/utils";
import { ScrollableInfiniteQueryResult } from "components/UI/ScrollableInfiniteQueryResult";
import { useInfiniteScrollQuery } from "hooks/useInfiniteScrollQuery";
import { Card } from "components/UI/Cards";
import { semibold14 } from "assets/styles/textSize";
import { useApiQueries } from "api/queries";
import { useCurrentUser } from "hooks/useCurrentUser";
import { printStatement } from "api/billing/queries";
import { DownloadAssetLink } from "components/UI/DownloadAssetLink";
import { EmptyContent } from "components/Billing/InvoicesRoute/EmptyContent";

const LoadingState = () => <Skeleton count={5} className="h-16 my-2" />;

const StatementCard: React.FC<{ statement: StatementVO }> = ({ statement }) => {
  const { practiceId, id: patientId } = useCurrentUser();
  const [printStatementQuery] = useApiQueries([
    printStatement({
      args: {
        practiceId,
        patientId,
        statementUuid: statement.uuid,
      },
      queryOptions: {
        enabled: false,
      },
    }),
  ]);
  const { t } = useTranslation();

  return (
    <Card className="flex justify-between items-center min-w-60 min-h-12" inCarousel={false}>
      <div className={semibold14}>
        {t("app.pages.payments.statementDate", {
          date: formatISODate(statement.asOfDate),
          interpolation: { escapeValue: false },
        })}
      </div>

      <DownloadAssetLink onClickGetAssetLink={printStatementQuery.refetch} />
    </Card>
  );
};

export const StatementsList: React.FC<{
  statementsQuery: UseInfiniteApiQueryResult<StatementVO[]>;
  className?: string;
}> = ({ statementsQuery, className }) => {
  const { t } = useTranslation();
  const statements = useFlattenPages(statementsQuery.data) ?? [];
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: statementsQuery });

  return (
    <div ref={rootRef} className={cx("flex flex-col gap-4", className)}>
      {statementsQuery.isFetched && statements.length === 0 ? (
        <EmptyContent>{t("No statements found.")}</EmptyContent>
      ) : (
        <ScrollableInfiniteQueryResult
          infiniteQuery={statementsQuery}
          loadMoreClassName="w-full"
          scrollRef={scrollRef}
          loading={<LoadingState />}
          loadMore={<LoadingState />}
        >
          {statements.map((item) => {
            return <StatementCard key={item.uuid} statement={item} />;
          })}
        </ScrollableInfiniteQueryResult>
      )}
    </div>
  );
};
