import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { RequiredAsterisk } from "components/UI/RequiredAsterisk";

export const FormSectionTitle: FC<{
  children?: ReactNode;
  subTitle?: ReactNode;
  useCustomStyling?: boolean;
  required?: boolean;
}> = ({ children, subTitle, useCustomStyling = false, required = false }) => {
  return (
    <div className={cx(!useCustomStyling && "font-sansSemiBold text-sm mb-2")}>
      {children}
      {required && <RequiredAsterisk />}
      {subTitle && <div className="text-xs/4 text-greyMedium font-sans mt-1">{subTitle}</div>}
    </div>
  );
};
