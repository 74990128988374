import React from "react";

import { useTranslation } from "react-i18next";
import {
  ContactDetailsVO,
  GeneralInfoVO,
  OnboardingOptionsVO,
  PatientSummaryVO,
  PersonalDetailsVO,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Form } from "components/UI/Form";
import { useObjectState } from "hooks/useObjectState";
import { semibold16 } from "assets/styles/textSize";
import { useCurrentUser } from "hooks/useCurrentUser";
import { usePracticeInfo } from "hooks/usePracticeInfo";
import { PersonalDetailFields } from "components/Account/PersonalDetailFields";
import { ContactDetailFields } from "components/Account/ContactDetailFields";

type Props = {
  onSubmit: (generalInfo: GeneralInfoVO) => void;
  id: string;
  info: GeneralInfoVO;
  showPreferredPronouns?: boolean;
  children?: React.ReactNode;
  canEditEmail: boolean;
  onboardingOptions: OnboardingOptionsVO;
  patient: PatientSummaryVO;
};

export const GeneralInformationForm: React.FC<Props> = ({
  onSubmit,
  id,
  info,
  children,
  canEditEmail,
  patient,
  showPreferredPronouns = false,
  onboardingOptions,
}) => {
  const { t } = useTranslation();
  const { id: patientId } = useCurrentUser();
  const { practice } = usePracticeInfo();

  const [userInfo, updateUserInfo] = useObjectState(info);
  const { personalDetails, contactDetails, referredBy } = userInfo;

  const handlePersonalDetailsUpdated = React.useCallback(
    (partial: Partial<PersonalDetailsVO>) => {
      updateUserInfo({ personalDetails: { ...personalDetails, ...partial } });
    },
    [personalDetails, updateUserInfo]
  );
  const handleReferredByUpdated = React.useCallback(
    (value: string | undefined) => {
      updateUserInfo({ referredBy: value });
    },
    [updateUserInfo]
  );
  const handleContactDetailsUpdated = React.useCallback(
    (partial: Partial<ContactDetailsVO>) => {
      updateUserInfo({ contactDetails: { ...contactDetails, ...partial } });
    },
    [contactDetails, updateUserInfo]
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(userInfo);
    },
    [onSubmit, userInfo]
  );

  return (
    <>
      {children}
      <Form id={id} className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <PersonalDetailFields
          edit
          onboardingOptions={onboardingOptions}
          onPersonalDetailsUpdated={handlePersonalDetailsUpdated}
          onReferredByUpdated={handleReferredByUpdated}
          personalDetails={personalDetails}
          patientId={patientId}
          referredBy={referredBy}
          showPreferredPronouns={showPreferredPronouns}
        />
        <div className={cx("text-secondaryTheme", semibold16)}>{t("Contact Details")}</div>
        <ContactDetailFields
          contact={patient.contact}
          canEditEmail={canEditEmail}
          contactDetails={contactDetails}
          onContactDetailsUpdated={handleContactDetailsUpdated}
          edit
        />
        <div className="text-xs text-grey-700">
          {t("app.registration.sms.disclaimer", { practiceName: practice?.name })}
        </div>
      </Form>
    </>
  );
};
