import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/UI/Button";
import { CognitoErrorCode } from "utils/auth";
import { normal14 } from "assets/styles/textSize";

type Props = {
  cognitoErrorCode?: CognitoErrorCode;
  errorMessage?: string;
  onClickReConfirm: Func;
  onClickCancel: Func;
  text: string;
};

export const ConfirmEmailToSignIn: React.FC<Props> = ({ onClickCancel, text, onClickReConfirm }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-6">
      <div className={normal14}>{text}</div>
      <div className="flex flex-col gap-y-4">
        <Button type="button" onClick={onClickReConfirm}>
          {t("Continue")}
        </Button>
        <Button type="button" theme="secondary" onClick={onClickCancel}>
          {t("Cancel")}
        </Button>
      </div>
    </div>
  );
};
