import { QueryClient } from "@tanstack/react-query";
import { InvoiceVO } from "@libs/api/generated-api";
import { deleteFromPaginatedCache } from "api/cache";
import { getQueryKey } from "api/queries";

export const invalidateInvoices = ({
  queryClient,
  patientId,
  practiceId,
  invoiceUUids = [],
}: {
  queryClient: QueryClient;
  patientId: number;
  practiceId: number;
  invoiceUUids?: string[];
}) => {
  deleteFromPaginatedCache<InvoiceVO>({
    queryClient,
    queryFilters: {
      queryKey: [
        getQueryKey("practices", "getInvoices"),
        { patientId, practiceId, states: ["FINALIZED", "PARTIALLY_PAID"] },
      ],
    },
    shouldDelete: (invoice) => invoiceUUids.includes(invoice.uuid),
    decrementTotalElements: true,
  });
  queryClient.invalidateQueries({
    queryKey: [getQueryKey("practices", "getInvoices"), { patientId, practiceId, states: ["PAID"] }],
    exact: false,
  });

  for (const invoiceUuid of invoiceUUids) {
    queryClient.invalidateQueries([
      getQueryKey("practices", "getInvoice"),
      { patientId, practiceId, invoiceUuid },
    ]);
  }
};
