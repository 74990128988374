import { cx } from "@libs/utils/cx";
import { ButtonIconSizes, cxButtonIconSizes } from "components/UI/ButtonIconContent";

export type FormFieldLayout = "labelIn" | "labelOut" | "labelLeft" | "tableValue";

export const cxIconStyles = {
  container: ({ layout, clickable }: { layout?: FormFieldLayout; clickable?: boolean }) =>
    cx(
      "absolute right-0 top-1/2 -translate-y-1/2 z-0",
      layout === "labelLeft" ? "" : "pr-3",
      !clickable && "pointer-events-none"
    ),
  element: ({ disabled, error, size }: { disabled?: boolean; error?: string; size?: ButtonIconSizes }) =>
    cx(cxButtonIconSizes[size ?? "sm"], disabled ? "text-slate-400" : error ? "text-red" : "text-slate-700"),
};

export const cxFormFieldStyle = {
  wrapper: "relative",
  input: "placeholder:text-greyLight focus-visible:outline-none",
  controlValueOnly: ({ layout }: { layout: FormFieldLayout }) =>
    cx(
      "block text-sm w-full",
      !["tableValue", "labelLeft"].includes(layout) && "text-greyDark font-sansSemiBold"
    ),
  control: ({
    hasIcon,
    hasLabel,
    layout,
    fontSizeClassName,
  }: {
    hasIcon: boolean;
    hasLabel: boolean;
    layout: FormFieldLayout;
    fontSizeClassName?: string;
  }) =>
    cx(
      "text-left w-full disabled:text-greyMedium rounded block",
      fontSizeClassName ?? "text-sm",
      layout === "labelLeft" ? "bg-transparent" : "",
      layout === "labelIn" ? (hasLabel ? "py-1" : "py-2") : layout === "labelOut" ? "py-2" : "py-0.5",
      hasIcon && "pr-8",
      layout === "labelLeft" ? "px-1" : hasIcon ? "pl-4" : "!px-4"
    ),
  iconContainer: (args: { layout?: FormFieldLayout; clickable?: boolean }) => cxIconStyles.container(args),
  icon: (args: { disabled?: boolean; error?: string; size?: ButtonIconSizes }) => cxIconStyles.element(args),
};
